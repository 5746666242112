import React from 'react';
import styles from './settings-general.component.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { countries } from '../../../../shared/utils/countries';
import { toast } from '../../../../shared/components/modals/toast/toast-manager';
import {
	updateCompany,
	getCompanyForUser,
	deleteCompanyLogo,
	downloadCompanyLogo,
} from '../../../../store/settings/slice';

import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconClose, IconSimplePlus } from '../../../../shared/utils/icon';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

function SettingsGeneralComponent() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const { company } = useAppSelector((state) => state.settings);
	const [openUploadLogo, setOpenUploadLogo] = React.useState(false);
	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);

	type FormData = {
		name: string;
		scale: string;
		industry: string;
		street: string;
		city: string;
		logo: FileList;
		country: string;
		postalCode: string;
		vatNumber: string;
	};

	const dataSchema = yup.object().shape({
		name: yup.string().required(),
		scale: yup.string(),
		industry: yup.string(),
		street: yup.string(),
		city: yup.string(),
		postalCode: yup.string(),
		vatNumber: yup
			.string()
			.optional()
			.matches(/^[A-Z]{2}[0-9A-Z]{8,12}$/i, { excludeEmptyString: true }),
	});

	const {
		register,
		handleSubmit,
		control,
		getValues,
		formState: { isValid },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const onSubmit = (data: FormData) => {
		if (user && company) {
			const formData = new FormData();

			formData.append('companyId', company.id);
			formData.append('name', data.name.toLowerCase());

			if (data.logo) {
				formData.append('logoName', data.logo[0].name);
				formData.append('file', data.logo[0]);
			}

			if (data.country) {
				formData.append('country', data.country);
			} else if (company.country) {
				formData.append('country', company.country);
			}

			if (data.street) {
				formData.append('street', data.street);
			} else {
				formData.append('street', company.street);
			}

			if (data.city) {
				formData.append('city', data.city);
			} else {
				formData.append('city', company.city);
			}

			if (data.vatNumber) {
				formData.append('vatNumber', data.vatNumber);
			} else {
				formData.append('vatNumber', company.vatNumber);
			}

			if (data.scale) {
				formData.append('companyScale', data.scale);
			} else {
				formData.append('companyScale', company.companyScale);
			}

			if (data.industry) {
				formData.append('industry', data.industry);
			} else {
				formData.append('industry', company.industry);
			}

			if (data.postalCode) {
				formData.append('postCode', data.postalCode);
			} else {
				formData.append('postCode', company.postCode);
			}

			if (data.logo) {
				if (
					data.logo[0].type === 'image/png' ||
					data.logo[0].type === 'image/jpeg' ||
					data.logo[0].type === 'image/jpg' ||
					data.logo[0].type === 'image/svg' ||
					data.logo[0].type === 'image/webp'
				) {
					dispatch(updateCompany(formData)).then(() => {
						dispatch(getCompanyForUser());

						toast.show({
							id: user?.graphUserId,
							title: t('Company details update success') as string,
							duration: 10000,
							type: 'success',
						});
					});
				} else {
					toast.show({
						id: user?.graphUserId,
						title: t('Please, provide an image type file as logo') as string,
						duration: 10000,
						type: 'failed',
					});
				}
			} else {
				dispatch(updateCompany(formData)).then(() => {
					dispatch(getCompanyForUser());

					toast.show({
						id: user?.graphUserId,
						title: t('Company details update success') as string,
						duration: 10000,
						type: 'success',
					});
				});
			}
		}
	};
	const modalRef = React.useRef<HTMLDivElement>(null);
	const handleUploadFile = () => {
		setOpenUploadLogo(!openUploadLogo);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleUploadFile();
				}, 200);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleUploadFile]);
	const handleDeleteConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleDeleteFile = () => {
		user &&
			dispatch(deleteCompanyLogo()).then(() => {
				dispatch(getCompanyForUser());
				handleDeleteConfirmBox();

				toast.show({
					id: user?.graphUserId,
					title: t('Logo delete success') as string,
					duration: 10000,
					type: 'success',
				});
			});
	};

	const logo: FileList = getValues('logo') as unknown as FileList;
	if (logo && logo.length === 1 && openUploadLogo) {
		setOpenUploadLogo(false);
	}

	// ----------

	const [LogoCompany, setLogoCompany] = React.useState<string | undefined>(undefined);

	React.useEffect(() => {
		if (company?.companyLogo) {
			dispatch(downloadCompanyLogo()).then((response) => {
				if (response.payload !== undefined) {
					setLogoCompany(response.payload as string);
				} else {
					setLogoCompany(undefined);
				}
			});
		}
	}, [company]);

	return (
		<div className={styles.container}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<h2>{t('Company details')}</h2>
				<p>{t('Provide your company details such as company name, company size and industry')}</p>
				<div className={styles.containerDetails}>
					<div className={styles.input}>
						<label>{t('Company name')}</label>
						<input defaultValue={company?.name} {...register('name')} />
					</div>
					<div className={styles.input}>
						<label>{t('Size of the company')}</label>
						<select defaultValue={company?.companyScale} {...register('scale')}>
							<option value='' disabled hidden>
								{t('How many people are in your company') as string}
							</option>
							<option value='1 to 5'>{t('1 to 5')}</option>
							<option value='6 to 20'>{t('6 to 20')}</option>
							<option value='21 to 50'>{t('21 to 50')}</option>
							<option value='51 to 100'>{t('51 to 100')}</option>
							<option value='100+'>{t('100+')}</option>
						</select>
					</div>
					<div className={styles.input}>
						<label>{t('Industry')}</label>
						<input defaultValue={company?.industry} {...register('industry')} />
					</div>
					<div className={styles.input}>
						<label>{t('Company logo')}</label>
						{company?.companyLogo ? (
							<div className={styles.addLogo}>
								<img className={styles.logo} src={LogoCompany} alt='company logo' />
								<div className={styles.iconClose} onClick={() => handleDeleteConfirmBox()}>
									<IconClose />
								</div>
							</div>
						) : (
							<div className={styles.addLogo}>
								<div className={styles.icon} onClick={() => handleUploadFile()}>
									<IconSimplePlus />
								</div>
								{logo && logo.length === 1 ? (
									<p>{logo[0].name}</p>
								) : (
									<p style={{ cursor: 'pointer' }} onClick={() => handleUploadFile()}>
										{t('Upload logo')}
									</p>
								)}
								{openUploadLogo && (
									<div className={styles.containerLogo} ref={modalRef}>
										<input type={'file'} {...register('logo')} />
									</div>
								)}
							</div>
						)}
					</div>
					{openConfirmBox && (
						<div className={styles.confirmBox}>
							<div>
								<p>{t('Are you sure you want to delete this logo?')}</p>
								<button onClick={() => handleDeleteFile()}>{t('Yes')}</button>
								<button
									onClick={() => {
										handleDeleteConfirmBox();
									}}>
									{t('Cancel')}
								</button>
							</div>
						</div>
					)}
				</div>
				<h2>{t('Company address')}</h2>
				<p>{t('Provide your company address details, such as country, city, street and postal code')}</p>
				<div className={styles.containerDetails}>
					<div className={styles.country}>
						<label>{t('Country')}</label>
						{company && (
							<Controller
								name='country'
								control={control}
								defaultValue={t(company.country) as string}
								render={({ field }) => (
									<Stack spacing={2}>
										<Autocomplete
											id='country'
											disableClearable
											freeSolo
											{...field}
											onChange={(event, newValue) => {
												field.onChange(newValue);
											}}
											options={countries.map((country) => t(country) as string)}
											renderInput={(param) => (
												<TextField
													className={styles.textArea}
													sx={{
														'& .MuiOutlinedInput-root': {
															border: 'none',
														},
														'& .MuiOutlinedInput-notchedOutline': {
															border: 'none',
														},
														'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
															{
																paddingTop: 0,
																paddingBottom: 0,
															},
														'backgroundColor': 'white',
														'marginTop': '15px',
													}}
													{...param}
												/>
											)}
										/>
									</Stack>
								)}
							/>
						)}
					</div>
					<div className={styles.input}>
						<label>{t('City')}</label>
						<input defaultValue={company?.city} {...register('city')} />
					</div>
					<div className={styles.input}>
						<label>{t('Street')}</label>
						<input defaultValue={company?.street} {...register('street')} />
					</div>
					<div className={styles.input}>
						<label>{t('Postal code')}</label>
						<input defaultValue={company?.postCode} {...register('postalCode')} />
					</div>
				</div>
				<h2>{t('VAT')}</h2>
				<p>{t('Provide your VAT number')}</p>
				<div className={styles.containerDetails}>
					<div className={styles.vat}>
						<div className={styles.input}>
							<label>{t('VAT number')}</label>
							<input defaultValue={company?.vatNumber} {...register('vatNumber')} />
						</div>
					</div>
				</div>
				<button type='submit' disabled={!isValid}>
					{t('Save')}
				</button>
			</form>
		</div>
	);
}

export default SettingsGeneralComponent;
