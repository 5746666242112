import React, { useEffect, useState } from 'react';
import { ContactsGraphContract, UsersCompanyContract } from '../../store/user/type';
import { Attendees } from '../../pages/meetings/modals/meeting-create.modal';
import { AttendeeMerge, attendeeMergeMapper } from '../utils/mappers/attendee-merge.mapper';

interface Props {
    userCompany: UsersCompanyContract[] | undefined,
    contacts: ContactsGraphContract[] | undefined,
    attendees?: Attendees[]
}

const useMergeAttendees = ({ userCompany, contacts, attendees }: Props): [AttendeeMerge[], React.Dispatch<React.SetStateAction<AttendeeMerge[]>>] => {
    const [mergedAttendees, setMergedAttendees] = useState<AttendeeMerge[]>([]);

	const mergeAttendees = () => {
		const newMergedAttendees = new Set();

		const isUniqueAttendee = (email: string) => {
			return !attendees?.some(
				(attendee) =>
					attendee.emailAddress.address === email,
			);
		};

        if(userCompany) {
            userCompany		
			.filter(
				(contact) => isUniqueAttendee(contact.userName)
			)
			.forEach((userC) => {
				const mergedAttendee = attendeeMergeMapper(userC.name, userC.userName);
				if(newMergedAttendees.has(mergeAttendees)) return;
				return newMergedAttendees.add(mergedAttendee);
			});
        }
		
        if(contacts) {
            contacts?.filter(
				(contact) =>
					contact.emailAddresses &&
					contact.emailAddresses.length > 0 &&
					contact.emailAddresses[0].address !== '' &&
					contact.displayName !== '',
			)
			.filter(
				(contact) => isUniqueAttendee(contact.emailAddresses[0].address)
			)
			.forEach((contact) => {
				const mergedAttendee = attendeeMergeMapper(contact.displayName, contact.emailAddresses[0].address);
				if(newMergedAttendees.has(mergeAttendees)) return;
				return newMergedAttendees.add(mergedAttendee);
			});
        }
		

		const convertedNewMergedAttendees = [...newMergedAttendees] as AttendeeMerge[];
		if(convertedNewMergedAttendees.length > 0) setMergedAttendees(convertedNewMergedAttendees);
	};
	

	useEffect(() => {
		mergeAttendees();
	}, [contacts, userCompany, attendees]);

    return [mergedAttendees, setMergedAttendees];
};

export default useMergeAttendees;