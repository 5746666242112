import React from 'react';
import styles from './login-button.component.module.scss';
import { IconRight } from '../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import { AuthenticationProvider } from '../../../store/user/type';
import { useAppDispatch } from '../../../store/hooks';
import { subscribeForUpdate } from '../../../store/register/slice';

interface Props {
	hasAcceptTheRights?: boolean;
	handleClick: () => void;
	showIcons?: boolean;
	children: string;
	auth?: AuthenticationProvider;
	mailCheck: string;
}

const LoginButton: React.FC<Props> = ({
	hasAcceptTheRights = true,
	handleClick,
	showIcons = true,
	children,
	auth,
	mailCheck,
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [send, isSend] = React.useState(false);

	const subscribe = () => {
		dispatch(subscribeForUpdate(mailCheck)).then(() => {
			isSend(true);
		});
	};

	return (
		<>
			<button
				className={styles.button}
				onClick={handleClick}
				disabled={hasAcceptTheRights ? false : true}
				style={{ backgroundColor: hasAcceptTheRights ? '' : 'rgba(128, 128, 128, 0.3)' }}>
				<div className={styles.loginButton}>
					{auth && auth === AuthenticationProvider.Microsoft && (
						<div className={styles.icon}>
							<img
								src={`https://www.google.com/s2/favicons?domain=${'hotmail.com'}&sz=256`}
								style={{ height: '20px' }}
								alt='Logo Office 365'
							/>
						</div>
					)}

					{auth && auth === AuthenticationProvider.Google && (
						<div className={styles.icon}>
							<img
								src={`https://www.google.com/s2/favicons?domain=${'google.com'}&sz=256`}
								style={{ height: '20px' }}
								alt='Logo Office 365'
							/>
						</div>
					)}

					<div className={styles.flex}>
						<span>{t(children)}</span>
						<span style={{ display: showIcons ? 'inline-block' : 'none' }}>
							<IconRight />
						</span>
					</div>
				</div>
			</button>

			{auth && auth === AuthenticationProvider.Google && (
				<div className={styles.googleInfo}>
					<p>
						{t('For now, connection through Google is only available in beta testing for selected users')}
					</p>
					{!send ? (
						<button className={styles.button} onClick={() => subscribe()}>
							<div className={styles.loginButton}>
								<span>{t('Subscribe to join the Google beta testing program')}</span>
							</div>
						</button>
					) : (
						<div style={{ marginTop: '50px' }}>
							<p className={styles.fadeRight}>
								{t('You will be notified as soon as you can start using 5DAYS with Google.')}
							</p>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default LoginButton;
