import React from 'react';
import styles from './public-meeting.module.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
	getShareDetails,
	getSharedMeeting,
	getSharedMeetingNext,
	getSharedMeetingPrev,
} from '../../store/sharing/slice';
import { SharingContractForMap } from '../../store/sharing/type';
import { setSelectedMeeting } from '../../store/meetings/slice';
import { MeetingContract } from '../../store/meetings/types';
import i18n from '../../../i18n';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/nl';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { RequestStatusType } from '../../store/shared/types';
import Loader from '../../shared/components/loader/loader.component';
import { transformLanguage } from '../../shared/utils/language';
import MeetingsPage from '../meetings/meetings.page';
import HeaderComponent from './components/header.component';
import FormConfirmMailComponent from './components/form-confirm-mail.component';

function PublicMeetingPage() {
	const dispatch = useAppDispatch();
	const params = useParams();
	const shareId = params.shareID;
	const { shareMeetingRequestStatus } = useAppSelector((state) => state.sharing);

	const storedShareId = localStorage.getItem(`shareId/${shareId}`);

	const [shareDetails, setSharedDetails] = React.useState<SharingContractForMap>();

	const browserLang = window.navigator.language;

	i18n.changeLanguage(transformLanguage(browserLang));
	moment.locale(transformLanguage(browserLang));

	React.useEffect(() => {
		if (storedShareId) {
			dispatch(getShareDetails(storedShareId)).then((result) => {
				setSharedDetails(result.payload as SharingContractForMap);
			});
		}
	}, [dispatch, storedShareId]);

	React.useEffect(() => {
		if (shareDetails) {
			dispatch(getSharedMeeting({ shareId: shareDetails.id })).then((resultMeeting) => {
				dispatch(setSelectedMeeting(resultMeeting.payload as MeetingContract));
				dispatch(getSharedMeetingPrev({ shareId: shareDetails.id, token: shareDetails.accessToken }));
				dispatch(getSharedMeetingNext({ shareId: shareDetails.id, token: shareDetails.accessToken }));
			});
		}
	}, [dispatch, shareDetails]);

	return (
		<>
			<HeaderComponent />
			<div className={styles.main}>
				{shareMeetingRequestStatus.type === RequestStatusType.InProgress && <Loader></Loader>}
				{shareMeetingRequestStatus.type === RequestStatusType.Success && (
					<>
						{storedShareId &&
						shareDetails &&
						new Date(shareDetails.accessTokenExpirationDate) > new Date() ? (
							<div className={styles.sharedMeetingContainer}>
								<MeetingsPage />
							</div>
						) : (
							<FormConfirmMailComponent shareDetails={shareDetails} />
						)}
					</>
				)}
				{shareMeetingRequestStatus.type === RequestStatusType.Failed && (
					<span>{shareMeetingRequestStatus.errorMessage}</span>
				)}
			</div>
		</>
	);
}

export default PublicMeetingPage;
