export const jobTitles = [
    'Software Engineer',
    'Technical Program Manager',
    'Senior Software Engineer',
    'Site Reliability Engineer',
    'Quality Assurance Engineer',
    'Software Test Engineer',
    'Software Engineer Intern',
    'Data Engineer',
    'Senior Product Manager',
    'Senior Developer',
    'Automation Engineer',
    'Linux Engineer',
    'Front End Developer',
    'Data engineer/a',
    'Account Executive',
    'Enterprise Account Executive',
    'Relationship Manager',
    'Account Manager',
    'Sales Manager',
    'Account Director',
    'Enterprise Account Manager',
    'Sales Consultant',
    'Sales Director',
    'Sales Specialist',
    'Senior Representative',
    'Collection Agent',
    'Market Manager',
    'Sales',
    'Account Representative',
    'Senior Sales Leader',
    'Sales Associate',
    'Regional Sales Manager',
    'Partnership Manager',
    'Comercial de ventas',
    'Solution Specialist',
    'Sales and Lettings Negotiator',
    'Senior Relationship Manager',
    'Consultant',
    'Manager',
    'Solutions Manager',
    'Operations Manager',
    'Site Manager',
    'Management Trainee',
    'Event Manager',
    'Site Lead',
    'Contract Manager',
    'Deployment Manager',
    'Learning and Development Manager',
    'Senior Manager',
    'Vice President of Business Development',
    'Business Development Manager',
    'Facilities Manager',
    'Director of Engineering',
    'Vice President',
    'Sourcing Manager',
    'Senior Operation Manager',
    'Business Intelligence Manager',
    'Team Leader',
    'Marketing Manager',
    'Marketing Specialist',
    'Campaign Manager',
    'Senior Marketing Manager',
    'Senior Digital Marketer',
    'Brand Manager',
    'Marketing Executive',
    'Content Manager',
    'Brand Ambassador',
    'Recruiter',
    'Recruiting Coordinator',
    'Recruitment Resourcer',
    'Technical Recruiter',
    'Senior Compensation Analyst',
    'Director of Security',
    'Human Resources Specialist',
    'Regional Recruiter',
    'Safety Specialist',
    'Sourcing Specialist',
    'Senior Technical Recruiter',
    'Receptionist',
    'Operations Associate',
    'Administrative Assistant',
    'Billing Specialist',
    'Office Administrator',
    'Center Manager',
    'Executive Assistant',
    'Student Assistant',
    'Government Executive',
    'Operations Intern',
    'Assistant Secretary',
    'Project Intern',
    'Personal Assistant',
    'Systems Analyst',
    'Data Analyst',
    'Operations Analyst',
    'Technology Analyst',
    'Technical Consultant',
    'User Experience Designer',
    'Customer Insights Analyst',
    'Senior Business Process Analyst',
    'Instructional Designer',
    'Senior User Experience Designer',
    'Business Analyst',
    'Analyst',
    'Senior Data Scientist',
    'Senior Analyst',
    'Data Science Intern',
    'Support Specialist',
    'Customer Service Manager',
    'Customer Success Manager',
    'Senior Customer Service Representative',
    'Customer Specialist',
    'Customer Service Representative',
    'Support Associate',
    'Customer Support Representative',
    'Program Manager',
    'Project Manager',
    'Strategic Project Manager',
    'Project Manager/Business Analyst',
    'Project Coordinator',
    'Technician',
    'Operator',
    'Equipment Technician',
    'Editor',
    'Audio Visual Engineer',
    'Writer/Editor',
    'Communications Associate',
    'Video Editor',
    'Accounts Payable Clerk',
    'Senior Billing Analyst',
    'Director of Financial Planning and Analysis',
    'Finance Manager',
    'Accountant',
    'Billing Analyst',
    'Procurement Analyst',
    'Security Officer',
    'Test Engineer',
    'Senior System Engineer',
    'Senior Engineering Manager',
    'System Engineer',
    'Trainer',
    'Training Coordinator',
    'Director of Admissions',
    'Training Developer',
    'Dance Instructor',
    'Production Manager',
    'Server',
    'Barista',
    'Cook',
    'IT Support',
    'Data Center Technician',
    'Senior Network Engineer',
    'Helpdesk Technician',
    'Life Science Consultant',
    'Laboratory Supervisor',
    'Content Strategist',
    'Cinematographer',
    'Social Worker',
    'Wellness Coordinator',
    'Mentor',
    'Quarry Manager',
    'Independent Contractor',
    'Lawyer',
    'Compliance Officer',
    'Behavior Technician',
    'Logistic Coordinator',
    'Equipment Operator'
];
