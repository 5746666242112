import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ErrorUtil from '../../shared/utils/error.util';
import { createRequestStatus, RequestStatus, RequestStatusType } from '../shared/types';
import { AgendaContract, UpdateAgendaItemPayload } from './type';
import AgendaAPI from '../../api/agenda-api';

interface AgendaState {
	agendaItems?: AgendaContract[];
	agendaRequestStatus: RequestStatus;

	agendaAll?: AgendaContract[];
	agendaAllRequestStatus: RequestStatus;
}

const initialState: AgendaState = {
	agendaItems: undefined,
	agendaRequestStatus: createRequestStatus(RequestStatusType.New),

	agendaAll: undefined,
	agendaAllRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getAllAgendaItems = createAsyncThunk('agenda/items/meeting/all', async (): Promise<AgendaContract[]> => {
	return AgendaAPI.getAgendaItems();
});

export const getAllAgendaItemForAMeeting = createAsyncThunk(
	'agenda/items/meeting',
	async (iCalUId: string): Promise<AgendaContract[]> => {
		return AgendaAPI.getAgendaItemsForAMeeting(iCalUId);
	},
);

export const createAgendaItem = createAsyncThunk(
	'agenda/item/create',
	async (body: AgendaContract): Promise<AgendaContract> => {
		return AgendaAPI.createAgendaItem(body);
	},
);

export const deleteAgendaItem = createAsyncThunk('agenda/delete', async (itemId: string): Promise<undefined> => {
	return AgendaAPI.deleteAgendaItem(itemId);
});

export const updateAgendaItem = createAsyncThunk(
	'agenda/item/update',
	async (body: AgendaContract): Promise<AgendaContract> => {
		return AgendaAPI.updateAgendaItem(body);
	},
);

const slice = createSlice({
	name: 'agenda',
	initialState,
	reducers: {
		addAgendaItem: (state, action: PayloadAction<AgendaContract>) => {
			const newitem = action.payload;
			if (state.agendaItems) {
				state.agendaItems.push(newitem);
			}
		},

		removeAgendaItems: (state, action: PayloadAction<string>) => {
			const itemId = action.payload;
			if (state.agendaItems) {
				state.agendaItems = state.agendaItems.filter((item) => item.id !== itemId);
			}
		},

		sortAgenda: (state) => {
			if (state.agendaItems) {
				state.agendaItems.sort((a, b) => {
					const orderNumberA = Number.parseInt(a.orderNumber, 10);
					const orderNumberB = Number.parseInt(b.orderNumber, 10);

					return orderNumberA - orderNumberB;
				});
			}
		},

		updateAgendaItemList: (state, action: PayloadAction<AgendaContract>) => {
			const { id, orderNumber } = action.payload;
			if (state.agendaItems) {
				const updatedAgenda = state.agendaItems.map((item) => {
					if (item.id === id) {
						return { ...item, orderNumber };
					}

					if (Number(item.orderNumber) >= Number(orderNumber)) {
						return { ...item, orderNumber: String(Number.parseInt(item.orderNumber) + 1) };
					}

					return item;
				});

				state.agendaItems = updatedAgenda;
			}
		},

		updateAgendaItemState: (state, action: PayloadAction<UpdateAgendaItemPayload>) => {
			const { agendaId, newData } = action.payload;
			if (state.agendaItems) {
				const agendaIndex = state.agendaItems.findIndex((agenda) => agenda.id === agendaId);
				if (agendaIndex !== -1) {
					const updatedAgenda = [...state.agendaItems];
					updatedAgenda[agendaIndex] = {
						...updatedAgenda[agendaIndex],
						...newData,
					};

					state.agendaItems = updatedAgenda;
				}
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getAllAgendaItemForAMeeting.pending, (state) => {
			state.agendaRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllAgendaItemForAMeeting.fulfilled, (state, action) => {
			state.agendaRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.agendaItems = action.payload;
		});
		builder.addCase(getAllAgendaItemForAMeeting.rejected, (state, action) => {
			state.agendaRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAllAgendaItems.pending, (state) => {
			state.agendaAllRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllAgendaItems.fulfilled, (state, action) => {
			state.agendaAllRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.agendaAll = action.payload;
		});
		builder.addCase(getAllAgendaItems.rejected, (state, action) => {
			state.agendaAllRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const { addAgendaItem, removeAgendaItems, sortAgenda, updateAgendaItemState, updateAgendaItemList } = actions;
export default reducer;
