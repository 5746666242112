import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './tooltip-icon.module.scss';

interface Props {
	id: string;
	title: string;
	placement: string;
}

const TooltipIcon: React.FC<Props> = ({ id, title, placement }) => {
	return (
		<Tooltip
			noArrow
			place={
				placement === 'right'
					? 'right'
					: placement === 'left'
					? 'left'
					: placement === 'bottom'
					? 'bottom'
					: placement === 'top'
					? 'top'
					: 'bottom'
			}
			id={id}
			style={{
				border: '1px solid #D7D7D7',
				borderRadius: '12px',
				backgroundColor: '#f7f7f7',
				color: 'black',
				opacity: 1,
				zIndex: 9999,
				marginLeft: '1px',
				boxShadow: '1px 1px 2px rgba(128, 128, 128, .1)',
			}}>
			<div className={styles.tooltip}>
				<div className={styles.tooltipContentContainer}>
					<p className={styles.tooltipContent}>{title}</p>
				</div>
			</div>
		</Tooltip>
	);
};

export default TooltipIcon;
