import React from 'react';
import styles from './view-switcher.module.scss';
import { IconClock, IconTodoCalendarView, IconTodoKaban, IconTodoListView } from '../../utils/icon';

interface Props {
    handleGroupChange: (view: string) => void,
    view: string,
}

const ViewSwitcher: React.FC<Props> = ({ handleGroupChange, view }) => {
    const sliderPosition = () => {
        switch (view) {
            case 'Simple':
                return 0;
            case 'Board':
                return 50;
        }
    };


    return (
        <div className={styles.icons}>
            <div className={styles.slider} style={{ left: `${sliderPosition()}%` }} />
            <div
                className={view === 'Simple' ? styles.iconSelect : styles.icon}
                onClick={() => {
                    handleGroupChange('Simple');
                }}>
                <IconTodoListView />
            </div>
            <div
                className={view === 'Board' ? styles.iconSelect : styles.icon}
                onClick={() => {
                    handleGroupChange('Board');
                }}>
                <IconTodoKaban />
            </div>
        </div>
    );
};

export default ViewSwitcher; 