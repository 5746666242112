import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorBoundaryInner from './error-boundary.component';

const ErrorBoundary: FunctionComponent<OwnProps> = (props: OwnProps) => {
	const [hasError, setHasError] = useState(false);
	const location = useLocation();
	useEffect(() => {
		// This makes sure navigation keeps working after an errorUtil occurs
		// by resetting the hasError state on every navigation change
		if (hasError) {
			setHasError(false);
		}
	}, [location.key]);
	return (
		<ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
			{props.children}
		</ErrorBoundaryInner>
	);
};

interface OwnProps {
	children: ReactNode;
}

export default ErrorBoundary;
