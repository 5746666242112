import FormLayoutDesktop from './desktop/form-layout-desktop.component';
import FormLayout from './web/form-layout.component';

export enum PlatformType {
    web,
    desktop,
}

export const getLayout = (type: PlatformType) => {
    switch(type) {
        case PlatformType.web:
            return FormLayout;
        case PlatformType.desktop:
            return FormLayoutDesktop;
    }
};