import React from 'react';
import styles from './meetings-details-notes.module.scss';
import MeetingsDetailsNotesContent from './meetings-details-notes-content';

interface Props {
	storedAccessMail?: string;
}

const MeetingsDetailsNotesBridge: React.FC<Props> = ({ storedAccessMail }) => {
	return (
		<>
			<div className={styles.overviewList} id='meetingsNotes'>
				<MeetingsDetailsNotesContent storedAccessMail={storedAccessMail} />
			</div>
		</>
	);
};

export default MeetingsDetailsNotesBridge;
