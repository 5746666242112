import React from 'react';
import styles from './settings-user-management.component.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../../../store/user/type';
import { UserDBContract } from '../../../../store/settings/type';
import { updateUser, getCompanyForUser, getAllCompanies } from '../../../../store/settings/slice';
import { toast } from '../../../../shared/components/modals/toast/toast-manager';
import { IconClose } from '../../../../shared/utils/icon';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { jobTitles } from '../../../../shared/utils/jobtitle';
import CustomSelect from '../../../register/components/custom-select.component';
import { userRoleFivedays } from '../../../../shared/utils/register-options';

interface Props {
	close: () => void;
	userToUpdate: UserDBContract;
}

const SettingsUserUpdate: React.FC<Props> = ({ close, userToUpdate }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const [userRole, setUserRole] = React.useState<string[]>([userToUpdate.role]);

	const [animation, setAnimation] = React.useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			close();
			setAnimation(false);
		}, 300);
	};

	type UserFormValues = {
		mail: string;
		jobTitle: string;
	};

	const userFormSchema = yup.object().shape({
		mail: yup.string(),
	});

	const {
		register,
		handleSubmit: handleSubmitUserForm,
		control,
	} = useForm<UserFormValues>({
		resolver: yupResolver(userFormSchema),
	});

	const handleUserRole = (el: string) => {
		const value = [el!];
		setUserRole(value);
	};

	const handleSubmitUpdate = (data: UserFormValues) => {
		const body = {
			role: userRole[0] === 'Admin' ? UserRole.ADMIN : UserRole.USER,
			userToUpdate: userToUpdate.username,
			jobTitle: data.jobTitle ? data.jobTitle : userToUpdate.jobTitle,
		};
		user &&
			dispatch(updateUser(body)).then(() => {
				closeModal();
				dispatch(getCompanyForUser());
				dispatch(getAllCompanies());

				toast.show({
					id: user?.graphUserId,
					title: t('User update success') as string,
					duration: 10000,
					type: 'success',
				});
			});
	};

	return (
		<div className={`${styles.update} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div
					className={styles.icon}
					onClick={() => {
						closeModal();
					}}>
					<IconClose />
				</div>
				<h1>{t('Edit user')}</h1>
				<form onSubmit={handleSubmitUserForm(handleSubmitUpdate)}>
					<div className={styles.inputWrapper}>
						<label>{t('user name')}</label>
						<input value={userToUpdate.name} disabled />
					</div>
					<br />
					<div className={styles.inputWrapper}>
						<label>{t('Email address')}</label>
						<input value={userToUpdate.username} disabled />
					</div>
					<br />
					<div className={`${styles.country} ${styles.inputWrapper}`}>
						<label>{t('Role with the Organisation')}</label>
						<Controller
							name='jobTitle'
							control={control}
							defaultValue={userToUpdate.jobTitle}
							render={({ field }) => (
								<Stack spacing={2}>
									<Autocomplete
										id='JobTitle'
										disableClearable
										freeSolo
										{...field}
										filterOptions={(options, state) =>
											state.inputValue.length > 0
												? options.filter((option) =>
														option
															.toLowerCase()
															.startsWith(state.inputValue.toLowerCase().trim()),
												  )
												: options
										}
										slotProps={{
											paper: {
												sx: {
													'& .MuiAutocomplete-listbox': {
														'padding': 0,
														'backgroundColor': 'white',
														'width': 'calc(100% + 20px)',
														'left': '-10px',
														'position': 'absolute',
														'top': '12px',
														'borderRadius': '6px',
														'boxShadow': '1px 1px 10px rgba(0, 0, 0, 0.2)',

														'&::-webkit-scrollbar': {
															width: '6px',
														},
														'&::-webkit-scrollbar-thumb': {
															background: '#888',
															borderRadius: '10px',
															marginBlock: '10px',
														},
														'& .MuiAutocomplete-option': {
															'fontSize': '14px',
															'borderRadius': '10px',
															'margin': '5px',
															'paddingInline': '8px',
															'paddingBlock': '12px',

															'&[aria-selected="true"]': {
																backgroundColor: 'rgba(5, 173, 86, 0.10)',
															},

															'&[data-focus="true"]': {
																backgroundColor: 'rgba(5, 173, 86, 0.10)',
															},

															'&:hover': {
																backgroundColor: 'rgba(128, 128, 128, 0.05)',
															},
														},
													},
												},
											},
										}}
										onChange={(event, newValue) => {
											if (newValue !== null && newValue !== undefined) {
												field.onChange(newValue);
											}
										}}
										onInputChange={(value) => {
											field.onChange(value);
										}}
										options={jobTitles.map((job) => t(job) as string)}
										renderInput={(param) => (
											<TextField
												placeholder={t('Enter your role') as string}
												sx={{
													'& .MuiOutlinedInput-root': {
														border: 'none',
														padding: 0,
														margin: 0,
													},
													'& .MuiOutlinedInput-notchedOutline': {
														border: 'none',
													},
													'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
														{
															paddingTop: 0,
															paddingBottom: 0,
															padding: 0,
															margin: 0,
															border: 'none',
														},
													'backgroundColor': '#fbfaf9',
													'& input': {
														margin: 0,
														padding: 0,
														fontSize: '14px',
														lineHeight: 1,
														zIndex: 5,
													},
													'border': 'transparent',
													'background-color': 'transparent',
													'paddingTop': '20px',
												}}
												{...param}
											/>
										)}
									/>
								</Stack>
							)}
						/>
					</div>
					<br />
					<CustomSelect options={userRoleFivedays} handleValue={handleUserRole} value={userRole}>
						Role
					</CustomSelect>
					<br />
					<button type='submit'>{t('Save')}</button>
				</form>
			</div>
		</div>
	);
};

export default SettingsUserUpdate;
