import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import styles from './project-meetings.module.scss';
import { IconDownChev, IconSimplePlus, IconUpChev } from '../../../../../shared/utils/icon';
import DetailsMeetingsTableComponent from './project-meetings-table';
import MeetingCreateModal from '../../../../meetings/modals/meeting-create.modal';
import { getMeetingTime, isAfter, isSameOrAfter } from '../../../../../shared/utils/date.util';
import { isBefore } from 'date-fns';
import OutlinedButton from '../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import { getMeetingsProject } from '../../../../../store/project/slice';

const ProjectMeetings = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectProject, projectsMeetings } = useAppSelector((state) => state.project);

	useEffect(() => {
		const fetchData = async () => {
			if (selectProject) {
				await dispatch(getMeetingsProject(selectProject.id));
			}
		};
		fetchData();
	}, [selectProject]);

	const [toggleCreateMeeting, setToggleCreateMeeting] = React.useState(false);
	const handleToggleCreateMeeting = () => {
		return setToggleCreateMeeting(!toggleCreateMeeting);
	};

	const [next, setNext] = React.useState(true);
	const [past, setPast] = React.useState(false);
	const [futur, setFutur] = React.useState(false);

	const handleNext = () => {
		setNext(!next);
	};

	const handlePast = () => {
		setPast(!past);
	};

	const handleFutur = () => {
		setFutur(!futur);
	};

	return (
		<>
			<div className={styles.over}>
				<OutlinedButton onClick={() => handleToggleCreateMeeting()}>
					<IconSimplePlus />
					{t('Add new meeting')}
				</OutlinedButton>
				<div className={styles.overviewList}>
					<div>
						<table>
							<thead>
								<tr>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
								</tr>
							</thead>

							<div className={styles.category}>
								<div className={styles.titleCat} onClick={() => handleNext()}>
									<p>{t('Next meeting')}</p>
									<div className={styles.number}>
										{projectsMeetings &&
											projectsMeetings
												.filter(
													(meeting) =>
														isSameOrAfter(getMeetingTime(meeting.meetingStartDate)) &&
														isBefore(new Date(), new Date(meeting.meetingEndDate)),
												)
												.slice(0, 1).length}
									</div>
									<span className={styles.categoryIcon}>
										{!next ? <IconDownChev /> : <IconUpChev />}
									</span>
								</div>
							</div>

							{projectsMeetings && next && (
								<>
									<DetailsMeetingsTableComponent
										meetings={projectsMeetings
											.filter(
												(meeting) =>
													isSameOrAfter(getMeetingTime(meeting.meetingStartDate)) &&
													isBefore(new Date(), new Date(meeting.meetingEndDate)),
											)
											.slice(0, 1)}
									/>
								</>
							)}

							<div className={styles.category}>
								<div className={styles.titleCat} onClick={() => handlePast()}>
									<p>{t('Past meetings')}</p>
									<div className={styles.number}>
										{projectsMeetings &&
											projectsMeetings.filter((meeting) =>
												isBefore(new Date(meeting.meetingEndDate), new Date()),
											).length}
									</div>
									<span>{!past ? <IconDownChev /> : <IconUpChev />}</span>
								</div>
							</div>

							{past && projectsMeetings && (
								<>
									<DetailsMeetingsTableComponent
										meetings={projectsMeetings.filter((meeting) =>
											isBefore(new Date(meeting.meetingEndDate), new Date()),
										)}
									/>
								</>
							)}

							<div className={styles.category}>
								<div className={styles.titleCat} onClick={() => handleFutur()}>
									<p>{t('Upcoming meetings')}</p>
									<div className={styles.number}>
										{projectsMeetings &&
											projectsMeetings
												.filter((meeting) => isAfter(getMeetingTime(meeting.meetingStartDate)))
												.slice(1, projectsMeetings.length).length}
									</div>
									<span>{!futur ? <IconDownChev /> : <IconUpChev />}</span>
								</div>
							</div>

							{futur && projectsMeetings && (
								<>
									<DetailsMeetingsTableComponent
										meetings={projectsMeetings
											.filter((meeting) => isAfter(getMeetingTime(meeting.meetingStartDate)))
											.slice(1, projectsMeetings.length)}
									/>
								</>
							)}
						</table>
					</div>
				</div>
			</div>
			{toggleCreateMeeting && selectProject && (
				<MeetingCreateModal
					close={handleToggleCreateMeeting}
					date={new Date().toString()}
					onDateSelect={() => undefined}
					projectId={selectProject.id}
				/>
			)}
		</>
	);
};

export default ProjectMeetings;
