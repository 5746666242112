import React, { createContext, useContext, useState, ReactNode } from 'react';

interface EditorContextType {
	isModalOpen: boolean;
	setIsModalOpen: (value: boolean) => void;
}

const EditorContext = createContext<EditorContextType | undefined>(undefined);

export const EditorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return <EditorContext.Provider value={{ isModalOpen, setIsModalOpen }}>{children}</EditorContext.Provider>;
};

export const useEditor = () => {
	const context = useContext(EditorContext);
	if (context === undefined) {
		throw new Error('useEditor must be used within an EditorProvider');
	}
	return context;
};
