import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { NotificationsProjectType } from '../../../../../store/project/type';
import UpdateNoteComponent from './components/update-note.component';
import styles from './project-info.module.scss';
import UpdateMeetingComponent from './components/update-meeting.component';
import UpdateTodoComponent from './components/update-todo.component';
import UpdateTodoCompleteComponent from './components/update-todo-complete.component';
import { SeeNotificationsProject, getNotificationsProject, seeNotification } from '../../../../../store/project/slice';
import { IconSeen } from '../../../../../shared/utils/icon';
import InfoFilterModal from './modal/info-filter.modal';
import UpdateFilesComponent from './components/update-files.component';
import NewMention from './components/new-mention.component';
import NewMentionComment from './components/new-mention-comment.component';
import FilterBtn from '../../../../../shared/components/buttons/filterBtn/filter-btn.component';

interface Props {
	switchTab: (nbr: number) => void;
}

const ProjectLastInfo: React.FC<Props> = ({ switchTab }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectProject, projectNotifications } = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);

	useEffect(() => {
		const fetchData = async () => {
			if (selectProject) {
				await dispatch(getNotificationsProject(selectProject.id));
			}
		};
		fetchData();
	}, []);

	const [visibleNotifications, setVisibleNotifications] = useState<number>(10);

	const loadMore = () => {
		setVisibleNotifications((prev) => prev + 10);
	};

	// FILTER //
	const [filterModal, setFilterModal] = React.useState(false);
	const [showSeenNotification, setShowSeenNotification] = React.useState(false);

	const handleShowSeenNotification = (show: boolean) => {
		setShowSeenNotification(show);
	};

	const handleFilterModal = () => {
		setFilterModal(!filterModal);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleFilterModal]);

	// ALL READ

	const handleMarkAllRead = async () => {
		if (user && selectProject) {
			const notificationsToBeRead = projectNotifications?.filter(
				(notification) => !notification.userSeenNotif.includes(user.userName),
			);

			notificationsToBeRead?.forEach((notification) => {
				dispatch(SeeNotificationsProject(notification)).then(() => {
					dispatch(seeNotification({ notificationId: notification.id, user: user.userName }));
				});
			});
		}
	};

	const trueCount = [showSeenNotification].filter(Boolean).length;

	return (
		<>
			<div className={styles.main}>
				<div className={styles.filters}>
					<button className={styles.buttonFilter} onClick={() => handleMarkAllRead()}>
						<span>
							<IconSeen />
						</span>
						{t('Mark all as read')}
					</button>
					<FilterBtn
						showFilterModal={filterModal}
						onClick={() => handleFilterModal()}
						trueCount={trueCount}
					/>
					{filterModal && (
						<div className={styles.filterModalWrapper} ref={modalRefFilter}>
							<InfoFilterModal
								showSeenNotification={showSeenNotification}
								handleShowSeenNotification={handleShowSeenNotification}
							/>
						</div>
					)}
				</div>
				{projectNotifications &&
					user &&
					projectNotifications
						.filter((notification) => {
							if (showSeenNotification && notification.userSeenNotif.includes(user.userName)) {
								return true;
							}

							if (!notification.userSeenNotif.includes(user.userName)) {
								return true;
							}

							return false;
						})
						.slice(0, visibleNotifications)
						.map((notification) => (
							<div key={notification.id}>
								{notification.type === NotificationsProjectType.NEW_NOTE && (
									<UpdateNoteComponent notification={notification} switchTab={switchTab} />
								)}
								{notification.type === NotificationsProjectType.NEW_MEETING && (
									<UpdateMeetingComponent notification={notification} />
								)}
								{notification.type === NotificationsProjectType.NEW_TODO && (
									<UpdateTodoComponent notification={notification} switchTab={switchTab} />
								)}
								{notification.type === NotificationsProjectType.TODO_COMPLETE && (
									<UpdateTodoCompleteComponent notification={notification} switchTab={switchTab} />
								)}
								{notification.type === NotificationsProjectType.NEW_FILE && (
									<UpdateFilesComponent notification={notification} switchTab={switchTab} />
								)}
								{notification.type === NotificationsProjectType.NEW_MENTION && (
									<NewMention notification={notification} switchTab={switchTab} />
								)}
								{notification.type === NotificationsProjectType.NEW_MENTION_COMMENT && (
									<NewMentionComment notification={notification} switchTab={switchTab} />
								)}
							</div>
						))}

				<button className={styles.load} onClick={loadMore}>
					{t('Load more')}
				</button>
			</div>
		</>
	);
};

export default ProjectLastInfo;
