export const companySizeRate = ['1-5', '6-20', '21-50', '51-100', '100+'];

export const FiveDaysUsages = [
	'Business Meetings',
	'Client Meetings',
	'Meeting Minutes Templates',
	'Steering Committee Meetings',
	'Operations Meetings',
	'Performance Review Meetings',
	'Staff Meetings',
	'Stand-Up Meetings',
	'Strategic Meetings',
	'Weekly Team Meetings',
	'1-on-1 Meetings',
	'Agency Meetings',
	'Agile Meetings',
	'Board Meetings',
	'Customer Success Meetings',
	'Design Meetings',
	'Engineering Meetings',
	'Executive Meetings',
	'Finance Meetings',
	'Government Meetings',
];

export const userRoleFivedays = ['Admin', 'User'];
