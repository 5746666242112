/* eslint-disable unicorn/prefer-add-event-listener */
import React, { useEffect, useState } from 'react';
import styles from './project-files.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { FileContract } from '../../../../../store/meetings/types';
import {
	deleteFilesProject,
	deleteProjectFile,
	downloadProjectFile,
	getFilesProject,
	getPeopleShareFile,
	updateProjectCount,
} from '../../../../../store/project/slice';
import { toast } from '../../../../../shared/components/modals/toast/toast-manager';
import saveAs from 'file-saver';
import { IconDelete, IconDoc, IconDownload, IconEdit, IconSimplePlus } from '../../../../../shared/utils/icon';
import DeleteConfirmBox from '../../../../../shared/utils/delete-box';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import ProjectFormFiles from './project-files-form.modal';

import OutlinedButton from '../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import { RequestStatusType } from '../../../../../store/shared/types';
import TabsFilesShimmers from '../shimmers/shimmers-tabs-files';
import { handleMarkdownPreview } from '../../../../../shared/utils/preview/mdpreview';
import { handleAudioPreview } from '../../../../../shared/utils/preview/audiopreview';
import { handleVideoPreview } from '../../../../../shared/utils/preview/videopreview';
import { handleDocxPreview } from '../../../../../shared/utils/preview/docpreview';
import { handleCsvPreview } from '../../../../../shared/utils/preview/csvpreview';
import { handleXlsxPreview } from '../../../../../shared/utils/preview/xlsxpreview';
import { handlePowerPointPreview } from '../../../../../shared/utils/preview/handlepppreview';
import TooltipIcon from '../../../../../shared/components/tooltip/tooltip-icon.component';
import UpdateFilesModal from '../../../../../shared/components/updateFIlesModal/update-files.modal';

const ProjectFilesComponent = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const modalRef = React.useRef<HTMLDivElement>(null);
	const { selectProject, projectFiles, projectFilesRequestStatus } = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [fileToDelete, setFileToDelete] = React.useState<FileContract | undefined>(undefined);

	const [openUpdateAccessRight, setOpenUpdateAccessRight] = React.useState<number | undefined>(undefined);
	const [fileToUpdate, setFileToUpdate] = React.useState<FileContract | undefined>(undefined);
	const [filePreviews, setFilePreviews] = useState<{ [key: string]: string | null }>({});

	useEffect(() => {
		const fetchData = async () => {
			if (selectProject) {
				await dispatch(getFilesProject(selectProject.id));
			}
		};
		fetchData();
	}, [selectProject]);

	const generateFilePreview = (file: FileContract) => {
		if (selectProject)
			dispatch(downloadProjectFile({ projectId: selectProject.id, fileId: file.id })).then((result) => {
				const fileBlob = result.payload as Blob;
				if (fileBlob.type.startsWith('image/')) {
					setFilePreviews((prev) => ({ ...prev, [file.id]: URL.createObjectURL(fileBlob) }));
				} else {
					setFilePreviews((prev) => ({ ...prev, [file.id]: null }));
				}
			});
	};

	useEffect(() => {
		if (projectFiles) {
			projectFiles.forEach((file) => generateFilePreview(file));
		}
	}, [projectFiles]);

	// ---Function //

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleUpdateFile = (index: number) => {
		setOpenUpdateAccessRight((prev) => (prev === index ? undefined : index));
	};

	const handleDeleteFiles = () => {
		if (selectProject) {
			const bodyUpdateCount = {
				projectId: selectProject.id,
				noteCount: selectProject.countNotes ? +selectProject.countNotes : 0,
				meetingsCount: selectProject.countMeetings ? +selectProject.countMeetings : 0,
				todoCount: selectProject.countTodos ? +selectProject.countTodos : 0,
				filesCount: selectProject.countFiles ? +selectProject.countFiles - 1 : 1,
			};

			dispatch(updateProjectCount(bodyUpdateCount));
		}

		if (fileToDelete && user && selectProject) {
			dispatch(
				deleteProjectFile({
					projectId: selectProject.id,
					fileId: fileToDelete.id,
				}),
			).then(() => {
				handleConfirmBox();
				dispatch(deleteFilesProject(fileToDelete.id));

				toast.show({
					id: user?.graphUserId,
					title: t('File delete success') as string,
					duration: 10000,
					type: 'success',
				});
			});
		}
	};

	// ---------

	const handleFileDeleteClick = (file: FileContract) => {
		setFileToDelete(file);
		handleConfirmBox();
	};

	const handleFileUpdateClick = (file: FileContract, index: number) => {
		dispatch(getPeopleShareFile(file.id)).then(() => {
			setFileToUpdate(file);
			handleUpdateFile(index);
		});
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setOpenUpdateAccessRight(undefined);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [modalRef]);

	// --------

	const handleDownloadFile = (fileId: string, fileName: string) => {
		if (selectProject)
			dispatch(downloadProjectFile({ projectId: selectProject.id, fileId })).then((result) => {
				saveAs(result.payload as Blob, fileName);
			});
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const handlePreviewFile = (fileId: string, fileName: string) => {
		if (selectProject) {
			dispatch(downloadProjectFile({ projectId: selectProject.id, fileId })).then((result) => {
				const fileBlob = result.payload as Blob;

				const previewUrl = URL.createObjectURL(fileBlob);

				if (fileBlob.type.startsWith('audio/')) {
					handleAudioPreview(previewUrl);
				} else if (fileBlob.type.startsWith('video/')) {
					handleVideoPreview(previewUrl);
				} else if (fileName.endsWith('.docx')) {
					handleDocxPreview(fileBlob);
				} else if (fileName.endsWith('.csv')) {
					handleCsvPreview(fileBlob);
				} else if (fileName.endsWith('.xlsx')) {
					handleXlsxPreview(fileBlob);
				} else if (fileName.endsWith('.md')) {
					handleMarkdownPreview(fileBlob);
				} else if (fileName.endsWith('.pptx')) {
					handlePowerPointPreview(fileBlob);
				} else if (
					fileBlob.type.startsWith('image/') ||
					fileBlob.type === 'application/pdf' ||
					fileBlob.type.startsWith('text/')
				) {
					window.open(previewUrl, '_blank', 'popup');
				} else {
					alert('This file type cannot be previewed.');
				}
			});
		}
	};

	const modalRefForm = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefForm.current && !modalRefForm.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	// ------------

	return (
		<div className={styles.over}>
			<OutlinedButton onClick={() => handleMenu()}>
				<IconSimplePlus /> {t('Add a file')}
			</OutlinedButton>
			{projectFilesRequestStatus.type === RequestStatusType.InProgress && (
				<div>
					<TabsFilesShimmers />
				</div>
			)}
			<div className={styles.files}>
				{projectFilesRequestStatus.type === RequestStatusType.Success &&
					projectFiles &&
					projectFiles.map((file: FileContract, index: number) => {
						const name = file.name.replace(/(https?:\/\/)?(www\.)?/, '');

						return (
							<div key={index}>
								<div className={styles.file}>
									<div>
										{file.type === 'URL' ? (
											<div className={styles.link}>
												<div className={styles.globe}>
													<img
														src={`https://www.google.com/s2/favicons?domain=${name}&sz=${256}`}
														alt=''
													/>
												</div>

												<div className={styles.fileName}>
													<a
														target='_blank'
														rel='noreferrer'
														href={file.location}
														data-tooltip-id={file.id}
														data-tooltip-content={file.name}>
														{file.displayName ? file.displayName : name}
													</a>
													<div className={styles.accessRight}>
														<div>
															<p>{t(file.accessRightType)}</p>
														</div>
													</div>
												</div>
											</div>
										) : (
											<>
												{filePreviews[file.id] ? (
													<img
														src={filePreviews[file.id] as string}
														alt={file.name}
														style={{
															width: '30px',
															height: '30px',
															objectFit: 'cover',
														}}
													/>
												) : (
													<Icon
														{...getFileTypeIconProps({
															extension: file?.name.split('.').pop(),
															size: 24,
														})}
													/>
												)}
												<div className={styles.fileName}>
													<a
														onClick={() => handlePreviewFile(file.id, file.name)}
														data-tooltip-id={file.id}
														data-tooltip-content={file.name}
														style={{ cursor: 'pointer' }}>
														{file.displayName ? file.displayName : file.name}
													</a>
													<div className={styles.accessRight}>
														<div>
															<p>{t(file.accessRightType)}</p>
														</div>
													</div>
												</div>
												<div
													onClick={() => handleDownloadFile(file.id, file.name)}
													className={styles.iconDown}
													style={{ cursor: 'pointer' }}>
													<IconDownload />
												</div>
											</>
										)}
									</div>

									{user?.graphUserId === file.graphUserId && (
										<div
											className={styles.close}
											onClick={() => handleFileUpdateClick(file, index)}>
											<IconEdit />
										</div>
									)}

									{user?.graphUserId === file.graphUserId ? (
										<div className={styles.close} onClick={() => handleFileDeleteClick(file)}>
											<IconDelete />
										</div>
									) : (
										<></>
									)}

									<TooltipIcon id={file.id} title={t(file.name)} placement='top' />
								</div>
								{openUpdateAccessRight === index && fileToUpdate && user && (
									<UpdateFilesModal
										file={fileToUpdate}
										userId={user.graphUserId}
										mail={user.userName}
										close={handleUpdateFile}
									/>
								)}
							</div>
						);
					})}
			</div>

			{menu && user && (
				<div className={styles.form} ref={modalRefForm}>
					<p>
						<IconDoc /> {t('Add a file')}
					</p>
					<ProjectFormFiles userId={user.graphUserId} close={handleMenu} />
				</div>
			)}

			{openConfirmBox && fileToDelete && (
				<DeleteConfirmBox
					handleDelete={handleDeleteFiles}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this file?'
				/>
			)}
		</div>
	);
};

export default ProjectFilesComponent;
