import React from 'react';
import { NotificationProjectContract } from '../../../../../../store/project/type';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import styles from './update-note.module.scss';
import { IconSeen } from '../../../../../../shared/utils/icon';
import { SeeNotificationsProject, seeNotification } from '../../../../../../store/project/slice';
import { PersonaSize } from '@fluentui/react';
import { Avatar } from '../../../../../../shared/utils/avatar';

interface Props {
	notification: NotificationProjectContract;
	switchTab: (nbr: number, noteId?: string) => void;
}

const NewMention: React.FC<Props> = ({ notification, switchTab }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectProject } = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);

	const handleSeenNotif = () => {
		if (selectProject && user) {
			dispatch(SeeNotificationsProject(notification)).then(() => {
				dispatch(seeNotification({ notificationId: notification.id, user: user.userName }));
			});
		}
	};

	return (
		<>
			<div
				className={styles.notif}
				style={{ opacity: notification.userSeenNotif.includes(user ? user.userName : '') ? 0.5 : 1 }}>
				<div>
					<h3>{t('New mention')}</h3>{' '}
					<p>
						{notification.userFromName} {t('has mentioned a new person')}.{' '}
						<span className={styles.link} onClick={() => switchTab(3, notification.noteId)}>
							{notification.itemTitle}
						</span>
					</p>
                    <div className={styles.avatar} style={{ width: '24px', marginTop: '16px' }}>
						<Avatar size={PersonaSize.size32} name={notification.userFromName} mail={notification.userFromMail} index={1} />
					</div>
				</div>
				<div className={styles.icon} onClick={() => handleSeenNotif()}>
					<IconSeen />
				</div>
			</div>
		</>
	);
};

export default NewMention;
