import { CompanyContract } from '../settings/type';

export class BillingContract {
	constructor(
		public id: string,
		public companyId: string,
		public plan: BillingPlan,
		public company: CompanyContract,
		public endDateTrial: string,
		public usersForPlan: number,
	) {}
}

export enum BillingPlan {
	FREEBETA = 'Free Beta',
	TRIAL = 'Trial',
	PERUSER = 'Per User',
	UNLIMITED = 'Unlimited',
}
