import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';
import { Tab } from '../../../shared/types/component-interface';
import { Domain } from '../../../shared/types/meetings-graph-interface';
import { useAppSelector } from '../../../store/hooks';
import { groupByDomain, sortByGroup } from '../../../shared/utils/domainext';
import { People } from '../../../store/notes/type';
import styles from '../components/note-people.module.scss';
import AttendeesTab from './note-people-tab';

// ----

const AttendeesModal = () => {
	const { t } = useTranslation();
	const { sharedPeopleNote } = useAppSelector((state) => state.notes);

	const sortedPeople = sortByGroup(sharedPeopleNote ? sharedPeopleNote : []);
	const groupedPeople = groupByDomain(sortedPeople);
	const domains = Object.keys(groupedPeople);
	const results = domains.map((domain) => ({
		domain,
		emailAddresses: groupedPeople[domain].map((item: People) => ({
			name: item.username,
			address: item.mail,
		})),
	}));

	// --------------

	const [activeTabIndex, setActiveTabIndex] = React.useState(0);

	const [tabs] = React.useState<Tab[]>([
		{
			label: t('All'),
			component: <AttendeesTab results={results} />,
		},
		...results.map((result: Domain) => ({
			label: result.domain,
			component: <AttendeesTab emailAddresses={result.emailAddresses} />,
		})),
	]);

	return (
		<>
			<div className={styles.modal}>
				<div className={styles.tabs}>
					{tabs.map((tab, index) => (
						<a
							style={{ display: 'flex', gap: '10px' }}
							key={index}
							onClick={() => setActiveTabIndex(index)}
							className={index === activeTabIndex ? styles.activeTab : ''}>
							{tab.label}
						</a>
					))}
				</div>
				<div>{tabs[activeTabIndex].component}</div>
			</div>
		</>
	);
};

export default AttendeesModal;
