import React, {
	FormEvent,
	useEffect,
	useRef,
	useState,
	FocusEvent,
	CSSProperties,
	MouseEvent,
	forwardRef,
	ReactNode,
} from 'react';
import styles from './input.module.scss';
import useClickOutside from '../../hooks/use-click-outside.hook';
import { IconDownArrow } from '../../utils/icon';
import ShimmerDiv from 'shimmer-effects-react/dist/components/ShimmerDiv/ShimmerDiv';
import ShimmerText from '../shimmers/shimmer-text.component';

export interface Items {
	id?: string;
	leading?: ReactNode;
	title: string;
	subTitle?: string;
	trailing?: string;
}

interface Props {
	placeHolder: string;
	register?: any;
	defaultValue?: Items;
	getValue?: (item: Items | undefined) => void;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
	onClick?: () => void;
	renderPlaceHolder?: boolean,
	type?: string;
	style?: CSSProperties;
	renderModal?: Items[];
	resetFieldOnSelect?: boolean;
	customModal?: ReactNode;
	value?: string;
	clickableField?: boolean;
	isLoading?: boolean;
}

const Input: React.FC<Props> = ({
	placeHolder,
	register,
	defaultValue,
	onChange,
	getValue,
	onFocus,
	onClick,
	renderPlaceHolder = true,
	type = 'text',
	style,
	renderModal,
	resetFieldOnSelect = false,
	customModal,
	value,
	clickableField = false,
	isLoading,
}) => {
	const [constantValue, setConstantValue] = useState<string>('');
	const [inputValue, setInputValue] = useState<string>('');
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [search, setSearch] = useState('');
	const modalRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (defaultValue) {
			setInputValue(defaultValue.title);
			setConstantValue(defaultValue.title);
		}
	}, []);

	const shimmerCount = renderModal ? renderModal.length : 0;

	const handleClick = (e: MouseEvent<HTMLDivElement>, item: Items) => {
		setIsModalOpen(false);

		if (resetFieldOnSelect) {
			setInputValue('');
			return;
		}

		setConstantValue(e.currentTarget.textContent ?? '');
		setInputValue(e.currentTarget.textContent ?? '');

		if (getValue) getValue(item);
	};

	const handleInput = (e: FormEvent<HTMLInputElement>) => {
		setInputValue(e.currentTarget.value);
		setSearch(e.currentTarget.value);
	};

	useClickOutside(modalRef, () => {
		setIsModalOpen(false);

		if (inputValue.length !== 0) {
			setInputValue(constantValue);
			return;
		}

		setInputValue('');

		if (getValue) getValue(undefined);
	});

	return (
		<div
			className={styles.inputContainer}
			onClick={clickableField ? () => !isModalOpen && setIsModalOpen(true) : onClick}
			style={{ cursor: clickableField ? 'pointer' : '', marginTop: renderPlaceHolder ? '' : '0px' }}>
			<input
				spellCheck={false}
				className={styles.nameMeeting}
				defaultValue={defaultValue}
				{...register}
				placeholder=''
				type={type}
				value={inputValue}
				onInput={handleInput}
				onChange={onChange}
				onFocus={onFocus}
				style={{ ...style, pointerEvents: clickableField ? 'none' : 'auto', paddingBlock: renderPlaceHolder ? '' : '10px' }}
				onClick={() => setIsModalOpen(true)}
			/>
			{renderPlaceHolder && (
				<div className={styles.meetingPlaceholder}>{placeHolder}</div>
			)}
			{renderModal && (
				<div className={styles.inputIcon}>
					<IconDownArrow />
				</div>
			)}
			{renderModal && isModalOpen && (
				<div className={styles.inputModal} ref={modalRef}>
					{!isLoading &&
						renderModal
							?.filter((element) => (clickableField ? element : element.title.includes(search)))
							.map((item) => (
								<div
									className={styles.inputModalItems}
									key={item.id}
									onClick={(e) => handleClick(e, item)}>
									{item.leading && <div>{item.leading}</div>}
									<div>{item.title}</div>
								</div>
							))}
					{isLoading &&
						Array.from({ length: shimmerCount }).map((_, index) => (
							<div className={styles.inputMeetingModalItems} key={index}>
								<ShimmerText height={15} width={150} rounded={1} />
								<div></div>
							</div>
						))}
				</div>
			)}

			{customModal && isModalOpen && customModal}
		</div>
	);
};

export default Input;
