import React from 'react';
import { NotificationProjectContract } from '../../../../../../store/project/type';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import styles from './update-todo.module.scss';
import { Avatar } from '../../../../../../shared/utils/avatar';
import { IconMeetings, IconSeen } from '../../../../../../shared/utils/icon';
import { SeeNotificationsProject, seeNotification } from '../../../../../../store/project/slice';
import { getDateFormatWithYear } from '../../../../../../shared/utils/date.util';
import { PersonaSize } from '@fluentui/react';

interface Props {
	notification: NotificationProjectContract;
	switchTab: (nbr: number) => void;
}

const UpdateTodoComponent: React.FC<Props> = ({ notification, switchTab }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectProject } = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);

	const handleSeenNotif = () => {
		if (selectProject && user) {
			dispatch(SeeNotificationsProject(notification)).then(() => {
				dispatch(seeNotification({ notificationId: notification.id, user: user.userName }));
			});
		}
	};

	return (
		<>
			<div
				className={styles.notif}
				style={{ opacity: notification.userSeenNotif.includes(user ? user.userName : '') ? 0.5 : 1 }}>
				<div>
					<h3>{t('New todo')}</h3>
					<p className={styles.date}>
						<IconMeetings />
						{notification.todoDueDate && notification.todoDueDate !== '' ? (
							<>{t('No due date')}</>
						) : notification.todoDueDate && notification.todoDueDate !== 'Invalid Date' ? (
							<>{getDateFormatWithYear(notification.todoDueDate)}</>
						) : (
							<>{t('No due date')}</>
						)}
					</p>
					<p className={styles.link} onClick={() => switchTab(4)}>
						{notification.itemTitle}
					</p>
					<div className={styles.avatar} style={{ width: '24px' }}>
						<Avatar size={PersonaSize.size32} name={notification.todoAssignee} mail={notification.todoAssignee} index={1} />
					</div>
				</div>
				<div className={styles.icon} onClick={() => handleSeenNotif()}>
					<IconSeen />
				</div>
			</div>
		</>
	);
};

export default UpdateTodoComponent;
