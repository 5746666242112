import React from 'react';
import styles from './note-filter.module.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

interface Props {
	showPersonalNote: boolean;
	handleShowPersoNote: (show: boolean) => void;
	showSharedNote: boolean;
	handleShowSharedNote: (show: boolean) => void;
	showArchived: boolean;
	handleShowArchived: (show: boolean) => void;
}

const NotesFilterModal: React.FC<Props> = ({
	showPersonalNote,
	handleShowPersoNote,
	showSharedNote,
	handleShowSharedNote,
	showArchived,
	handleShowArchived,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={styles.container}>
				<div className={styles.list}>
					{/* <h2>{t('Select filters')}</h2> */}
					<div
						className={styles.row}
						onClick={() => handleShowPersoNote(showPersonalNote === true ? false : true)}>
						<p>{t('My notes')}</p>
						<input
							type='checkbox'
							checked={showPersonalNote === true ? true : false}
							onChange={(e) => handleShowPersoNote(e.target.checked)}
						/>
					</div>

					<div
						className={styles.row}
						onClick={() => handleShowSharedNote(showSharedNote === true ? false : true)}>
						<p>{t('Notes shared with me')}</p>
						<input
							type='checkbox'
							checked={showSharedNote === true ? true : false}
							onChange={(e) => handleShowSharedNote(e.target.checked)}
						/>
					</div>

					<div
						className={styles.row}
						onClick={() => handleShowArchived(showArchived === true ? false : true)}>
						<p>{t('Notes archived')}</p>
						<input
							type='checkbox'
							checked={showArchived === true ? true : false}
							onChange={(e) => handleShowArchived(e.target.checked)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotesFilterModal;
