import React, { KeyboardEvent, useRef } from 'react';
import styles from './meetings-details-notes.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { AccessRightType } from '../../../../../../store/meetings/types';
import MeetingsDetailsNotesText from './meetings-details-notes-text';
import { IconSimplePlus } from '../../../../../../shared/utils/icon';
import NotesFilterModal from './meeting-details-notes-filter';
import { useParams } from 'react-router-dom';
import { createNotes, getAllNoteForAMeeting, postPeopleShareNote } from '../../../../../../store/notes/slice';
import { getDateFormatL } from '../../../../../../shared/utils/date.util';
import {
	createNotesShared,
	getAllNoteForAMeetingShared,
	postPeopleShareNoteShared,
} from '../../../../../../store/sharing/slice';
import { getCommentsForANoteForMeetings } from '../../../../../../store/comments/slice';
import { extractDomainWithExt } from '../../../../../../shared/utils/domainext';
import { NotesContract } from '../../../../../../store/notes/type';
import { NotificationsType } from '../../../../../../store/notifications/type';
import { SendNotifications } from '../../../../../../store/notifications/slice';
import OutlinedButton from '../../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import FilterBtn from '../../../../../../shared/components/buttons/filterBtn/filter-btn.component';
import { RequestStatusType } from '../../../../../../store/shared/types';
import TabsNotesShimmers from '../../../../../project/components/tabs/shimmers/shimmers-tabs-notes';
import ShimmerNote from '../../../../../../shared/components/shimmers/shimmerNote/shimmer-note.component';
import { updateSelectedMeetingCount } from '../../../../../../store/meetings/slice';

interface Props {
	storedAccessMail?: string;
}

const MeetingsDetailsNotesContent: React.FC<Props> = ({ storedAccessMail }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const params = useParams();
	const shareId = params.shareID;
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const { selectedMeetingNotes, selectMeetingNoteRequestStatus } = useAppSelector((state) => state.notes);
	const { shareNotes, shareNotesRequestStatus } = useAppSelector((state) => state.sharing);
	const inputRef = useRef<HTMLInputElement | null>(null);

	// filters //

	const [showFilters, setShowFilters] = React.useState(false);

	const handleShowFilterModal = () => {
		setShowFilters(!showFilters);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (user) {
			const selectedMeetingNoteIds = selectedMeetingNotes
				? selectedMeetingNotes.map((note) => note.graphiCalUId)
				: [];
			const allNoteIds = selectedMeetingNoteIds;
			dispatch(getCommentsForANoteForMeetings(allNoteIds));
		} else {
			const shareNotesIds = shareNotes ? shareNotes.map((note) => note.graphiCalUId) : [];
			const allNoteIds = shareNotesIds;
			dispatch(getCommentsForANoteForMeetings(allNoteIds));
		}
	}, [selectedMeeting]);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	React.useEffect(() => {
		inputRef.current?.focus();
	}, []);

	// -----

	const [showPersonalNote, setShowPersonalNote] = React.useState<boolean>(true);

	const handleShowPersoNote = (show: boolean) => {
		setShowPersonalNote(show);
	};

	const [showSharedNote, setShowSharedNote] = React.useState<boolean>(true);

	const handleShowSharedNote = (show: boolean) => {
		setShowSharedNote(show);
	};

	const [showArchived, setShowArchived] = React.useState<boolean>(false);

	const handleShowArchived = (show: boolean) => {
		setShowArchived(show);
	};

	// ----------------

	const [editingIndex, setEditingIndex] = React.useState('');

	const handleIsEditing = (index: string) => {
		setEditingIndex(index);
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		if (!menu) {
			handleIsEditing('');
		}
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	// ____________

	const [title, setTitle] = React.useState('');
	const [access, setAccess] = React.useState(AccessRightType.EVERYONE);

	// -------

	const domain = extractDomainWithExt(user ? user.email : storedAccessMail ? storedAccessMail : '');

	const createNote = async () => {
		if (user) {
			if (selectedMeeting) {
				const bodyUpCount = {
					iCalUId: selectedMeeting.iCalUId,
					noteCount: selectedMeeting.countNotes ? +selectedMeeting.countNotes + 1 : 1,
					agendaCount: selectedMeeting.countAgenda ? +selectedMeeting.countAgenda : 0,
					todoCount: selectedMeeting.countTodos ? +selectedMeeting.countTodos : 0,
				};

				dispatch(updateSelectedMeetingCount(bodyUpCount));
			}

			const attendeeEmails = selectedMeeting
				? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];
			const body = {
				id: '',
				title: title
					? title
					: `${t('New draft note')} ${selectedMeeting?.subject} (${getDateFormatL(new Date())})`,
				text: '',
				graphUserId: user ? user.graphUserId : '',
				graphUserName: user ? user.displayName : '',
				meetingAttendees: attendeeEmails.length > 0 ? attendeeEmails : [user.email],
				graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
				meetingTitle: selectedMeeting ? selectedMeeting.subject : '',
				meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
				tags: [],
				updateNote: new Date(),
				createdOn: new Date(),
				updateOn: new Date(),
				accessRightType: access,
				accessRightCompanies: domain ? [domain] : [],
				archived: false,
				projectId: '',
			};

			handleMenu();

			dispatch(createNotes(body)).then((res) => {
				const createdNote = res.payload as NotesContract;

				const createNotificationBody = (attendee: string) => {
					return {
						id: undefined,
						userFromName: user.displayName,
						userFromMail: user.userName,
						userToName: attendee,
						userToMail: attendee,
						type: NotificationsType.NEW_MEETING_NOTE,
						noteId: createdNote.id,
						todoId: undefined,
						graphiCalUId: createdNote.graphiCalUId,
						projectId: undefined,
						meetingStartDate: createdNote.meetingStartDate,
						itemTitle: createdNote.title,
						visible: true,
						meetingName: createdNote.meetingTitle,
					};
				};

				switch (access) {
					case AccessRightType.EVERYONE: {
						createdNote.meetingAttendees.forEach((attendee) => {
							if (attendee !== user.userName) {
								const bodyNotification: any = createNotificationBody(attendee);
								dispatch(SendNotifications(bodyNotification));
							}
						});

						break;
					}
					case AccessRightType.SHARED: {
						createdNote.meetingAttendees.forEach((attendee) => {
							if (attendee !== user.userName) {
								const bodyNotification: any = createNotificationBody(attendee);
								dispatch(SendNotifications(bodyNotification));
							}
						});

						selectedMeeting?.attendees.forEach((att) => {
							if (att.emailAddress.address !== user.userName) {
								const bodyAtt = {
									id: '1',
									username: att.emailAddress.name,
									mail: att.emailAddress.address,
								};

								dispatch(postPeopleShareNote({ body: bodyAtt, noteId: createdNote.id }));
							}
						});

						break;
					}
					case AccessRightType.INTERN: {
						const internUserSharingMeeting = user.userCompany.filter((userCompany) =>
							createdNote.meetingAttendees.includes(userCompany.userName),
						);
						internUserSharingMeeting.forEach((attendee) => {
							if (attendee.userName !== user.userName) {
								const bodyNotification: any = createNotificationBody(attendee.userName);
								dispatch(SendNotifications(bodyNotification));
							}
						});

						break;
					}
					// No default
				}

				selectedMeeting && dispatch(getAllNoteForAMeeting(selectedMeeting?.iCalUId));
				setTitle('');
				setAccess(AccessRightType.EVERYONE);
			});
		} else if (shareId && selectedMeeting && storedAccessMail) {
			const attendeeEmails = selectedMeeting
				? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];
			const body = {
				id: '',
				title: `${t('New draft note')} ${selectedMeeting.subject} (${getDateFormatL(new Date())})`,
				text: '',
				graphUserId: storedAccessMail,
				graphUserName: storedAccessMail,
				meetingAttendees: attendeeEmails,
				graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
				meetingTitle: selectedMeeting ? selectedMeeting.subject : '',
				meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
				tags: [],
				updateNote: new Date(),
				createdOn: new Date(),
				updateOn: new Date(),
				accessRightType: AccessRightType.EVERYONE,
				accessRightCompanies: domain ? [domain] : [],
				archived: false,
				projectId: '',
			};

			handleMenu();

			dispatch(createNotesShared({ body, shareId })).then((res) => {
				const newData = res.payload as NotesContract;

				if (access === AccessRightType.SHARED) {
					selectedMeeting?.attendees.forEach((att) => {
						if (att.emailAddress.address !== storedAccessMail) {
							const bodyAtt = {
								id: '1',
								username: att.emailAddress.name,
								mail: att.emailAddress.address,
							};

							dispatch(postPeopleShareNoteShared({ body: bodyAtt, noteId: newData.id }));
						}
					});
				}

				selectedMeeting &&
					dispatch(
						getAllNoteForAMeetingShared({ iCalUId: selectedMeeting?.iCalUId, userId: storedAccessMail }),
					);

				setTitle('');
				setAccess(AccessRightType.EVERYONE);
			});
		}
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			createNote();
		}
	};

	const trueCount = [showPersonalNote, showSharedNote, showArchived].filter(Boolean).length;

	return (
		<>
			<div className={styles.filters}>
				<div>
					<OutlinedButton onClick={() => createNote()}>
						<IconSimplePlus /> {t('Create a new note')}
					</OutlinedButton>
				</div>
				<FilterBtn
					showFilterModal={showFilters}
					onClick={() => handleShowFilterModal()}
					trueCount={trueCount}
				/>
				{showFilters && (
					<div ref={modalRefFilter}>
						<NotesFilterModal
							showPersonalNote={showPersonalNote}
							handleShowPersoNote={handleShowPersoNote}
							showSharedNote={showSharedNote}
							handleShowSharedNote={handleShowSharedNote}
							showArchived={showArchived}
							handleShowArchived={handleShowArchived}
						/>
					</div>
				)}
			</div>
			{user ? (
				<>
					{selectMeetingNoteRequestStatus.type === RequestStatusType.InProgress ? (
						<ShimmerNote />
					) : (
						<>
							{selectedMeetingNotes && selectedMeetingNotes.length > 0 && (
								<MeetingsDetailsNotesText
									notes={selectedMeetingNotes.filter((note) => {
										if (!note || (!showArchived && note?.archived)) {
											return false;
										}

										if (
											(showPersonalNote && note?.graphUserId === user?.graphUserId) ||
											(showSharedNote && note?.graphUserId !== user?.graphUserId)
										) {
											return true;
										}

										return false;
									})}
									editingIndex={editingIndex}
									handleIsEditing={handleIsEditing}
								/>
							)}
						</>
					)}
				</>
			) : (
				<>
					{shareNotesRequestStatus.type === RequestStatusType.InProgress ? (
						<>
							<TabsNotesShimmers />
						</>
					) : (
						<>
							{shareNotes && shareNotes.length > 0 && (
								<MeetingsDetailsNotesText
									notes={shareNotes.filter((note) => {
										if (!note || (!showArchived && note?.archived)) {
											return false;
										}

										if (
											(showPersonalNote && note?.graphUserId === storedAccessMail) ||
											(showSharedNote && note?.graphUserId !== storedAccessMail)
										) {
											return true;
										}

										return false;
									})}
									storedAccessMail={storedAccessMail}
									editingIndex={editingIndex}
									handleIsEditing={handleIsEditing}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default MeetingsDetailsNotesContent;
