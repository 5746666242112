import React, { CSSProperties, MouseEventHandler , ReactNode } from 'react';
import styles from './option-btn.module.scss';

interface Props {
    children: ReactNode,
    onClick: MouseEventHandler,
    style?: CSSProperties,
}

const OptionButton: React.FC<Props> = ({ children, onClick, style }) => {
    return (
        <div className={styles.optionBtn} onClick={onClick} style={style}>
            {children}
        </div>
    );
    
};

export default OptionButton;