import React from 'react';
import {
	useCommands,
	useActive,
	HeadingLevelButtonGroup,
	ListButtonGroup,
	ToggleUnderlineButton,
	DropdownButton,
	CommandMenuItem,
	ToggleStrikeButton,
	ToggleBlockquoteButton,
	InsertHorizontalRuleButton,
	TextAlignmentButtonGroup,
	IndentationButtonGroup,
	useRemirrorContext,
	useKeymap,
} from '@remirror/react';
import 'remirror/styles/all.css';
import styles from '../note-editor.module.scss';
import NotesEditorColorsModal from './notes-editor-colors.modal';
import {
	IconCode,
	IconEdit,
	IconTable,
	IconTableColDelete,
	IconTableDelete,
	IconTableRowDelete,
	IconTodos,
} from '../../../../shared/utils/icon';
import { CodeMirrorExtension } from '@remirror/extension-codemirror6';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { TodosNodeExtension } from './todos-extension.component';
import { MeetingContract } from '../../../../store/meetings/types';
import { NotesContract } from '../../../../store/notes/type';
import { useParams } from 'react-router-dom';
import { deleteTodo, deleteTodosNotes, getCurrentUserTodos } from '../../../../store/todos/slice';
import { deleteTodoShared, deleteTodosShareNote } from '../../../../store/sharing/slice';
import { LinkToolbar } from './menu-link';

const CreateCodeMirrorButton = ({ language }: { language: string }) => {
	const { commands } = useRemirrorContext<CodeMirrorExtension>({ autoUpdate: true });
	const { createCodeMirror } = commands;
	const enabled = createCodeMirror.enabled({ language });

	return (
		<button
			onMouseDown={(event) => event.preventDefault()}
			onClick={() => createCodeMirror({ language })}
			disabled={!enabled}>
			<IconCode />
		</button>
	);
};

interface Props {
	userId: string;
	noteId: string;
	manager: any;
	userName: string;
	userMail: string;
	note: NotesContract;
	meeting?: MeetingContract;
}

export const MenuFixed: React.FC<Props> = ({ userId, noteId, manager, userName, userMail, note, meeting }) => {
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	const FONT_SIZES = ['8', '10', '12', '14', '16', '18', '24', '30'];

	const {
		toggleBold,
		focus,
		toggleItalic,
		setFontSize,
		createTodo,
		createTable,
		deleteTableColumn,
		deleteTableRow,
		checkTodo,
		...commandsT
	} = useCommands();

	useKeymap('Backspace', (): boolean => {
		const result = checkTodo() as unknown as Array<string>;

		if (result.length > 0) {
			result.forEach((res) => {
				if (user) {
					dispatch(deleteTodo(res)).then(() => {
						dispatch(getCurrentUserTodos()).then(() => {
							dispatch(deleteTodosNotes(res));
							dispatch(getCurrentUserTodos());
						});
					});
				} else {
					dispatch(deleteTodoShared(res)).then(() => {
						dispatch(deleteTodosShareNote(res));
					});
				}
			});
		}

		return false;
	});

	const params = useParams();
	const shareId = params.shareID;

	const active = useActive();
	const { fontSize } = useActive();

	const [colorCode, handleSetColorText] = React.useState('#000000');
	const [openColorMenu, setOpenColorMenu] = React.useState(false);

	const handleColorMenu = () => {
		setOpenColorMenu(!openColorMenu);
	};

	const handleSetColor = (color: string) => {
		handleSetColorText(color);
	};

	const handleSetTextColor = (color: string) => {
		commandsT.removeTextHighlight();
		commandsT.setTextColor(color);
	};

	// ------

	const modalRefColor = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefColor.current && !modalRefColor.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleColorMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleColorMenu]);

	// -----

	const [colorHLCode, setColorHLCode] = React.useState('#000000');
	const [openColorHLMenu, setOpenColorHLMenu] = React.useState(false);

	const handleColorHLMenu = () => {
		setOpenColorHLMenu(!openColorHLMenu);
	};

	const handleSetColorHL = (color: string) => {
		setColorHLCode(color);
	};

	const handleSetTextColorHL = (color: string) => {
		commandsT.removeTextColor();
		commandsT.setTextHighlight(color);
	};

	// ------

	const modalRefColorHL = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefColorHL.current && !modalRefColorHL.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleColorHLMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleColorHLMenu]);

	// -----

	const todosExtension = new TodosNodeExtension();

	const handleToggleTodosExtension = async () => {
		const selected = window.getSelection() as Selection;
		const title = selected.toString();

		if (user) {
			const todo = await todosExtension.createTodoBackend(
				title !== '' ? title : 'New Todo',
				noteId,
				userId,
				dispatch,
				userName,
				userMail,
				note.graphiCalUId,
				note.graphiCalUId,
				note.meetingTitle,
				note.meetingStartDate,
				note.meetingAttendees,
				note.projectId,
			);

			createTodo(todo.id, todo.title, todo.noteId);
		} else {
			const todo = await todosExtension.createTodoBackendInvite(
				title !== '' ? title : 'New Todo',
				noteId,
				userId,
				dispatch,
				userName,
				userMail,
				shareId,
				note.graphiCalUId,
				note.graphiCalUId,
				note.meetingTitle,
				note.meetingStartDate,
				note.meetingAttendees,
			);

			createTodo(todo.id, todo.title, todo.noteId);
		}
	};

	const [position, setPosition] = React.useState('static');

	const [positionSet, setPositionSet] = React.useState(false);

	// NOTE PAGE //

	const element = document.querySelector('#overviewNote');

	element &&
		element.addEventListener(
			'scroll',
			() => {
				if (element && element.scrollTop > 280 && !positionSet) {
					setPosition('fixed');
					setPositionSet(true);
				} else if (element && element.scrollTop < 250 && positionSet) {
					setPosition('static');
					setPositionSet(false);
				}
			},
			{ passive: true },
		);

	// MEETING PAGE

	const notesScroll = document.querySelector('#meetingsNotes');
	const noteElement = document.querySelector(`#noteid${note.id}`);

	notesScroll &&
		noteElement &&
		notesScroll.addEventListener(
			'scroll',
			() => {
				const rect = noteElement.getBoundingClientRect();
				const noteDistanceFromTop = rect.top + notesScroll.scrollTop;

				if (noteDistanceFromTop && notesScroll.scrollTop > noteDistanceFromTop - 100 && !positionSet) {
					setPosition('fixed');
					setPositionSet(true);
				} else if (noteDistanceFromTop && notesScroll.scrollTop < noteDistanceFromTop - 100 && positionSet) {
					setPosition('static');
					setPositionSet(false);
				}
			},
			{ passive: true },
		);

	// Project PAGE

	const notesProjectScroll = document.querySelector('#meetingsNotesProject');
	const noteProjectElement = document.querySelector(`#noteIdProject${note.id}`);

	notesProjectScroll &&
		noteProjectElement &&
		notesProjectScroll.addEventListener(
			'scroll',
			() => {
				const rect = noteProjectElement.getBoundingClientRect();
				const noteDistanceFromTop = rect.top + notesProjectScroll.scrollTop;

				if (noteDistanceFromTop && notesProjectScroll.scrollTop > noteDistanceFromTop - 100 && !positionSet) {
					setPosition('fixed');
					setPositionSet(true);
				} else if (
					noteDistanceFromTop &&
					notesProjectScroll.scrollTop < noteDistanceFromTop - 100 &&
					positionSet
				) {
					setPosition('static');
					setPositionSet(false);
				}
			},
			{ passive: true },
		);

	return (
		<div
			className={styles.menu}
			style={{
				position: 'sticky',
				zIndex: '99',
				backgroundColor: 'white',
				top: noteElement ? '5px' : noteProjectElement ? '5px' : '5px',
			}}>
			<div className={styles.group}>
				<button
					onClick={() => {
						toggleBold();
						focus();
					}}
					style={{
						fontWeight: active.bold() ? 'bold' : undefined,
						backgroundColor: active.bold() ? '#f2fcf7' : 'transparent',
					}}>
					B
				</button>
				<button
					onClick={() => {
						toggleItalic();
						focus();
					}}
					style={{
						fontWeight: active.italic() ? 'bold' : undefined,
						backgroundColor: active.italic() ? '#f2fcf7' : 'transparent',
					}}>
					I
				</button>
				<ToggleUnderlineButton />
				<ToggleStrikeButton />
			</div>

			<div className={styles.group}>
				<DropdownButton aria-label='Set font size' icon='fontSize'>
					{FONT_SIZES.map((size) => (
						<CommandMenuItem
							key={size}
							commandName='setFontSize'
							onSelect={() => setFontSize(size)}
							enabled={setFontSize.enabled(size)}
							active={fontSize({ size })}
							label={size}
							icon={undefined}
							displayDescription={false}
						/>
					))}
				</DropdownButton>

				<HeadingLevelButtonGroup showAll />
			</div>

			<div className={styles.group}>
				<button className={styles.color} onClick={() => handleColorMenu()}>
					<div>A</div>
					<div style={{ backgroundColor: colorCode }} className={styles.col}></div>
					{openColorMenu && (
						<div ref={modalRefColor} className={styles.colors}>
							<NotesEditorColorsModal
								setColor={handleSetTextColor}
								handleSetColor={handleSetColor}
								handleColorMenu={handleColorMenu}
							/>
						</div>
					)}
				</button>

				<button className={styles.color} onClick={() => handleColorHLMenu()}>
					<span>
						<IconEdit />
					</span>
					<div style={{ backgroundColor: colorHLCode }} className={styles.col}></div>
					{openColorHLMenu && (
						<div ref={modalRefColorHL} className={styles.colors}>
							<NotesEditorColorsModal
								setColor={handleSetTextColorHL}
								handleSetColor={handleSetColorHL}
								handleColorMenu={handleColorHLMenu}
								isHl={true}
							/>
						</div>
					)}
				</button>
			</div>

			<div className={styles.group}>
				<TextAlignmentButtonGroup />
			</div>
			<div className={styles.group}>
				<IndentationButtonGroup />
			</div>

			<div className={styles.group}>
				<ListButtonGroup />
			</div>

			<div className={styles.group}>
				<ToggleBlockquoteButton />
			</div>

			<div className={styles.group}>
				<InsertHorizontalRuleButton />
			</div>

			<div className={styles.group}>
				<button
					onMouseDown={(event) => event.preventDefault()}
					data-testid='btn-3-3'
					onClick={() => createTable({ rowsCount: 3, columnsCount: 3, withHeaderRow: true })}>
					<IconTable />
				</button>
				<button onMouseDown={(event) => event.preventDefault()} onClick={() => commandsT.deleteTable()}>
					<IconTableDelete />
				</button>
				<button onMouseDown={(event) => event.preventDefault()} onClick={() => deleteTableColumn()}>
					<IconTableColDelete />
				</button>
				<button onMouseDown={(event) => event.preventDefault()} onClick={() => deleteTableRow()}>
					<IconTableRowDelete />
				</button>
			</div>
			<div className={styles.group}>
				<LinkToolbar />
			</div>
			<div className={styles.group}>
				<button onClick={handleToggleTodosExtension}>
					<IconTodos />
				</button>
			</div>
		</div>
	);
};
