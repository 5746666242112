/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef } from 'react';
import styles from './meetings-details-todos.module.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { TodosContract, TodosStates } from '../../../../../../store/todos/type';
import {
	addTodosSelectedMeeting,
	createTodos,
	updateTodoAssignee,
	updateTodoSelectMeeting,
	updateTodoSelectMeetingPrev,
} from '../../../../../../store/todos/slice';

import { IconDownArrow, IconSimplePlus, IconTodosSidebar } from '../../../../../../shared/utils/icon';
import { Avatar } from '../../../../../../shared/utils/avatar';

import {
	addTodosSelectedMeetingShare,
	createTodosShared,
	updateTodoMeetingPrevShared,
	updateTodoMeetingShared,
} from '../../../../../../store/sharing/slice';
import { useParams } from 'react-router-dom';

import { Attendees } from '../../../../../../shared/types/meetings-graph-interface';
import { NotificationsType } from '../../../../../../store/notifications/type';
import { SendNotifications } from '../../../../../../store/notifications/slice';
import { updateSelectedMeetingCount } from '../../../../../../store/meetings/slice';

interface Props {
	close: () => void;
	storedAccessMail?: string;
}

const MeetingsDetailsTodosFormComponents: React.FC<Props> = ({ close, storedAccessMail }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const params = useParams();
	const shareId = params.shareID;
	const inputRef = useRef<HTMLFormElement | null>(null);

	type FormData = {
		title: string;
		assignee: string;
		mail: string;
	};

	const dataSchema = yup.object().shape({
		title: yup.string().required(),
		assignee: yup.string(),
		mail: yup.string(),
	});

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const [dropdown, setDropdown] = React.useState(false);

	const handleDropDown = () => {
		setDropdown(!dropdown);
	};

	useEffect(() => {
		if (inputRef) {
			const inputChild = inputRef.current?.firstChild as HTMLInputElement;
			inputChild.focus();
		}
	}, []);

	const [assignee, setAssignee] = React.useState(user ? user.name : storedAccessMail);

	React.useEffect(() => {
		if (user) {
			setValue('assignee', user.name);
			setValue('mail', user.email);
		} else {
			setValue('assignee', storedAccessMail ? storedAccessMail : '');
			setValue('mail', storedAccessMail ? storedAccessMail : '');
		}
	}, []);

	const handleNewAssignee = (data: Attendees) => {
		setAssignee(data.emailAddress.name);
		setValue('assignee', data.emailAddress.name);
		setValue('mail', data.emailAddress.address);
		handleDropDown();
	};

	const createTodo = (data: FormData) => {
		const attendeeEmails = selectedMeeting
			? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
			: [];

		const body: any = {
			id: user?.email,
			title: data.title,
			text: '',
			state: TodosStates.TODO,
			dueDate: undefined,
			duration: undefined,
			graphUserId: user?.graphUserId,
			noteId: undefined,
			assigneeDisplayName: data.mail,
			graphiCalUId: selectedMeeting?.iCalUId,
			meetingGraphId: selectedMeeting?.graphEventId,
			meetingName: selectedMeeting?.subject,
			meetingStartDate: selectedMeeting?.start,
			createdOn: new Date().toISOString(),
			tags: [],
			projectId: undefined,
			assigneeName: data.assignee,
			meetingAttendees: attendeeEmails,
		};

		const bodyShared: any = {
			id: storedAccessMail,
			title: data.title,
			text: '',
			state: TodosStates.TODO,
			dueDate: undefined,
			duration: undefined,
			graphUserId: storedAccessMail ? storedAccessMail : '',
			noteId: undefined,
			assigneeDisplayName: data.mail,
			graphiCalUId: selectedMeeting?.iCalUId,
			meetingGraphId: selectedMeeting?.graphEventId,
			meetingName: selectedMeeting?.subject,
			meetingStartDate: selectedMeeting?.start,
			createdOn: new Date().toISOString(),
			tags: [],
			projectId: undefined,
			assigneeName: data.assignee,
			meetingAttendees: attendeeEmails,
		};

		if (user) {
			dispatch(addTodosSelectedMeeting(body));
			reset();
			setAssignee(user ? user.name : storedAccessMail);
			setValue('assignee', user.name);
			setValue('mail', user.email);
			close();

			dispatch(createTodos(body)).then((res) => {
				if (selectedMeeting) {
					const todo = res.payload as TodosContract;

					const bodyUpCount = {
						iCalUId: selectedMeeting.iCalUId,
						noteCount: selectedMeeting.countNotes ? +selectedMeeting.countNotes : 0,
						agendaCount: selectedMeeting.countAgenda ? +selectedMeeting.countAgenda : 0,
						todoCount: selectedMeeting.countTodos ? +selectedMeeting.countTodos + 1 : 1,
					};

					dispatch(updateSelectedMeetingCount(bodyUpCount));

					if (user) {
						const newTodo: any = {
							id: todo.id,
							title: todo.title,
							text: '',
							state: TodosStates.TODO,
							dueDate: todo.dueDate,
							duration: 0,
							graphUserId: user.graphUserId,
							noteId: null,
							assigneeDisplayName: todo.assigneeDisplayName,
							graphiCalUId: selectedMeeting.iCalUId,
							meetingGraphId: selectedMeeting.graphEventId,
							meetingName: selectedMeeting.subject,
							meetingStartDate: selectedMeeting.start,
							createdOn: new Date().toISOString(),
							tags: [],
							assigneeName: todo.assigneeName,
							meetingAttendees: todo.meetingAttendees,
						};

						if (todo.assigneeDisplayName !== user.userName) {
							const bodyNotification: any = {
								id: undefined,
								userFromName: user ? user.name : storedAccessMail,
								userFromMail: user ? user.userName : storedAccessMail,
								userToName: todo.assigneeName,
								userToMail: todo.assigneeDisplayName,
								type: NotificationsType.NEW_TODO,
								noteId: undefined,
								todoId: todo.id,
								graphiCalUId: undefined,
								projectId: undefined,
								meetingStartDate: undefined,
								itemTitle: todo.title,
								visible: true,
								meetingName: undefined,
							};

							dispatch(SendNotifications(bodyNotification));
						}

						dispatch(updateTodoAssignee({ todoId: user?.email, newTodo }));
						dispatch(updateTodoSelectMeeting({ todoId: user?.email, newTodo }));
						dispatch(updateTodoSelectMeetingPrev({ todoId: user?.email, newTodo }));

					}
				}

			});

		} else if (shareId && selectedMeeting && storedAccessMail) {
			reset();
			setAssignee(storedAccessMail ? storedAccessMail : '');
			setValue('assignee', storedAccessMail ? storedAccessMail : '');
			setValue('mail', storedAccessMail ? storedAccessMail : '');
			dispatch(addTodosSelectedMeetingShare(bodyShared));
			close();

			dispatch(createTodosShared({ bodyShared, sharedId: shareId })).then((res) => {
				const todo = res.payload as TodosContract;
				const newTodo: any = {
					id: todo.id,
					title: todo.title,
					text: '',
					state: TodosStates.TODO,
					dueDate: todo.dueDate,
					duration: 0,
					graphUserId: todo.graphUserId,
					noteId: null,
					assigneeDisplayName: todo.assigneeDisplayName,
					graphiCalUId: selectedMeeting.iCalUId,
					meetingGraphId: selectedMeeting.graphEventId,
					meetingName: selectedMeeting.subject,
					meetingStartDate: selectedMeeting.start,
					createdOn: new Date().toISOString(),
					tags: [],
					assigneeName: todo.assigneeName,
					meetingAttendees: todo.meetingAttendees,
				};

				dispatch(updateTodoMeetingShared({ todoId: storedAccessMail, newTodo }));
				dispatch(updateTodoMeetingPrevShared({ todoId: storedAccessMail, newTodo }));
			});
		}
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					if (dropdown) handleDropDown();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleDropDown]);

	return (
		<div className={styles.form}>
			<p className={styles.modalTitle}>
				<div className={styles.icon}>
					<IconTodosSidebar width='23' height='23' />
				</div>{' '}
				{t('Add new todo')}
			</p>
			<form ref={inputRef} onSubmit={handleSubmit(createTodo)}>
				<input
					type='text'
					{...register('title')}
					id='description'
					placeholder={t('Write your todo here') as string}
				/>
				{errors.title && <p className={styles.error}>{t('Title is required')}</p>}
				<div className={styles.selectCustom} ref={modalRef}>
					<button type='button' onClick={() => handleDropDown()}>
						<p>{assignee}</p>
						<IconDownArrow />
					</button>
					{dropdown && (
						<div className={styles.dropdown}>
							<p
								onClick={() =>
									handleNewAssignee({
										type: '',
										emailAddress: {
											name: 'Unasigned',
											address: '',
										},
									})
								}>
								<Avatar name={'Unasigned'} mail={''} index={2} />
								{t('Unasigned')}
							</p>
							{selectedMeeting &&
								selectedMeeting.attendees.map((attendee, index) => (
									<p key={index} onClick={() => handleNewAssignee(attendee)}>
										<Avatar
											name={attendee.emailAddress.name}
											mail={attendee.emailAddress.address}
											index={index}
										/>
										{attendee.emailAddress.name}
									</p>
								))}
						</div>
					)}
				</div>
				<button type='submit' className={styles.formButton}>
					<IconSimplePlus /> {t('Create')}
				</button>
			</form>
		</div>
	);
};

export default MeetingsDetailsTodosFormComponents;
