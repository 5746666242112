import HttpClient from '../shared/http/http-client';
import { ApiUrls, UrlParamBuilder } from './api-urls';
import { NotificationsContract, NotificationsSendContract } from '../store/notifications/type';

export default class NotificationsAPI {
	public static async sendNotifications(
		bodyNotification: NotificationsSendContract,
	): Promise<NotificationsSendContract> {
		const { data } = await HttpClient.post<NotificationsSendContract, NotificationsSendContract>(
			ApiUrls.sendNotification,
			bodyNotification,
		);
		return data;
	}

	public static async updateNewNotifications(): Promise<NotificationsContract[]> {
		const { data } = await HttpClient.get<NotificationsContract[]>(ApiUrls.updateNewNotifications);
		return data;
	}

	public static async updateSeenNotifications(notificationId: string): Promise<NotificationsContract[]> {
		const { data } = await HttpClient.get<NotificationsContract[]>(
			ApiUrls.updateSeenNotifications,
			new UrlParamBuilder().addNotificationId(notificationId).build(),
		);
		return data;
	}

	public static async getCurrentUserNotifications(): Promise<NotificationsContract[]> {
		const { data } = await HttpClient.get<NotificationsContract[]>(ApiUrls.getCurrentUserNotifications);
		return data;
	}

	public static async hideNotifications(notificationId: string): Promise<NotificationsContract> {
		const { data } = await HttpClient.get<NotificationsContract>(
			ApiUrls.hideNotifications,
			new UrlParamBuilder().addNotificationId(notificationId).build(),
		);
		return data;
	}
}
