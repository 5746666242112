import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PanelState } from './types';

interface LayoutState {
	panelState: PanelState;
}

const initialState: LayoutState = {
	panelState: PanelState.Hidden,
};

const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		togglePanel: (state: LayoutState, action: PayloadAction<PanelState>) => {
			state.panelState = action.payload;
		},
	},
});

const { actions, reducer } = layoutSlice;
export const { togglePanel } = actions;
export default reducer;
