export const steps = [
	{
		selector: '.meetingsList',
		content: 'This is the Meetings',
	},
	{
		selector: '.meetings',
		content: 'This is the Meetings',
	},
	{
		selector: '.calendar',
		content: 'This is the Calendar',
	},
	{
		selector: '.todos',
		content: 'This is the todos',
	},
	{
		selector: '.notes',
		content: 'This is the notes',
	},
	{
		selector: '.avatar',
		content: 'This is the Avatar',
	},
];
