import React from 'react';
import styles from './header.component.module.scss';
import { useTranslation } from 'react-i18next';
import LogoFivedays from '../../../../images/logo/Logo.png';
import fire from '../../../../images/icones/fire-regular 1.png';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../config/msal-auth-config.';

const HeaderComponent = () => {
	const { t } = useTranslation();
	const { instance } = useMsal();
	const handleLoginRedirect = () => {
		instance.loginRedirect(loginRequest);
	};

	return (
		<div className={styles.header}>
			<div className={styles.right}>
				<img src={LogoFivedays} alt='' />
			</div>
			<div className={styles.left}>
				<p>
					Start <span className={styles.bold}>for free</span> the next collaborative meeting platform
				</p>
				<button className={styles.transparantButton} onClick={handleLoginRedirect}>
					<img src={fire} alt='' />
					{t('Sign up')}
				</button>
				<button onClick={handleLoginRedirect}>{t('Log in')}</button>
			</div>
			<div></div>
		</div>
	);
};

export default HeaderComponent;
