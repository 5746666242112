import React from 'react';
import styles from './info-filter.module.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

interface Props {
	showSeenNotification: boolean;
	handleShowSeenNotification: (show: boolean) => void;
}

const InfoFilterModal: React.FC<Props> = ({ showSeenNotification, handleShowSeenNotification }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={styles.container}>
				<div className={styles.list}>
					<h2>{t('Select filters')}</h2>
					<div
						className={styles.row}
						onClick={() => handleShowSeenNotification(showSeenNotification === true ? false : true)}>
						<p>{t('Seen notifications')}</p>
						<input
							className={styles.infoCheckbox}
							type='checkbox'
							checked={showSeenNotification === true ? true : false}
							onChange={(e) => handleShowSeenNotification(e.target.checked)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default InfoFilterModal;
