import React, { ReactNode, MouseEventHandler } from 'react';
import styles from './five-days-btn.module.scss';

interface Props {
    children: ReactNode,
    onClick?: MouseEventHandler,
    type?: 'button' | 'submit' | 'reset' | undefined,
}

const FiveDaysBtn: React.FC<Props> = ({ 
    children, 
    onClick,
    type = 'button',
}) => {
    return (
        <button className={styles.fiveDaysButton} type={type} onClick={onClick}>
			{children}
		</button>
    );
    
};

export default FiveDaysBtn;