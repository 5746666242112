import { RuntimeConfig } from './runtime-config';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export const googleAuthConfig: UserManagerSettings = {
	authority: RuntimeConfig.getGoogleClientAuthorityURI(),
	client_id: RuntimeConfig.getGoogleClientID(),
	client_secret: RuntimeConfig.getGoogleClientSecret(),
	redirect_uri: RuntimeConfig.getRedirectURI(),
	scope: RuntimeConfig.getGoogleClientScopes(),
	userStore: new WebStorageStateStore({ store: window.localStorage }),
	silent_redirect_uri: RuntimeConfig.getRedirectURI(),
};
