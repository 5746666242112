import { UserLanguage } from '../../store/shared/types';

export const transformLanguage = (browserLang: string): UserLanguage => {
	let transformedLang: UserLanguage;
	const langCode = browserLang.split('-')[0].toLowerCase();

	switch (langCode) {
		case 'en':
			transformedLang = UserLanguage.EN;
			break;
		case 'fr':
			transformedLang = UserLanguage.FR;
			break;
		case 'nl':
			transformedLang = UserLanguage.NL;
			break;
		case 'de':
			transformedLang = UserLanguage.DE;
			break;
		default:
			transformedLang = UserLanguage.EN;
			break;
	}

	return transformedLang;
};
