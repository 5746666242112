import React, { useEffect } from 'react';
import styles from './toast.module.scss';
import { IconClose } from '../../../utils/icon';

export interface ToastProps {
	id: string;
	destroy: () => void;
	title: string;
	duration?: number;
	type: string;
}

const Toast: React.FC<ToastProps> = (props) => {
	const { destroy, title, duration, type = 0, id } = props;

	useEffect(() => {
		if (!duration) return;

		const timer = setTimeout(() => {
			destroy();
		}, duration);

		return () => clearTimeout(timer);
	}, [destroy, duration]);

	return (
		<div className={styles.toastContainer}>
			<div className={styles.toastHeader}>
				<div className={type as string}>{title}</div>
				<div className={styles.icon} onClick={destroy}>
					<IconClose />
				</div>
			</div>
		</div>
	);
};

export default Toast;
