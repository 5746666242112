import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './note-connect.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IconClose, IconDownArrow, IconLink } from '../../../shared/utils/icon';
import { getDateFormatL } from '../../../shared/utils/date.util';
import { NotesContract } from '../../../store/notes/type';
import { updateNotes } from '../../../store/notes/slice';
import { MeetingContract } from '../../../store/meetings/types';
import { addLastMeetings, getLastMeetings, getMeetingsForSelectOption } from '../../../store/meetings/slice';
import NotesFormMeetingsModalComponent from '../components/notes.form-meetings.modal.component';

export interface Props {
	handleClose: () => void;
	note: NotesContract;
	handleNote: (data: NotesContract) => void;
}

const NotesMeetingConnect = ({ handleClose, note, handleNote }: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { projects } = useAppSelector((state) => state.project);
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [animation, setAnimation] = useState<boolean>(false);

	// -----

	type FormData = {
		meeting: MeetingContract;
	};

	const dataSchema = yup.object().shape({
		meeting: yup.object(),
	});

	const { handleSubmit, setValue } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	// ----

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleClose();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClose]);

	// ----

	let timeoutId: any;

	const [isSearching, setIsSearching] = React.useState(false);
	const [meetingConnect, setMeetingConnect] = React.useState<MeetingContract>();
	const [openMeetingMenu, setOpenMeetingMenu] = React.useState(false);

	const handleMeetingMenu = () => {
		setIsSearching(false);
		setOpenMeetingMenu(!openMeetingMenu);
	};

	const onChangeHandlerMeeting = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getMeetingsForSelectOption(filter)).then(() => {
				setIsSearching(true);
			});
		}, 200);
	};

	React.useEffect(() => {
		dispatch(getLastMeetings()).then((res) => {
			const m = res.payload as MeetingContract[];

			dispatch(addLastMeetings(m));
		});
	}, [dispatch]);

	const handleChangeMeeting = (selectedValue: MeetingContract) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setValue('meeting', selectedValue);
			setMeetingConnect(selectedValue);
			handleMeetingMenu();
		}, 1000);
	};

	// ----

	const onSubmit = (data: FormData) => {
		if (data.meeting && user) {
			const attendeeEmails = data.meeting
				? data.meeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];

			const body = {
				id: note.id,
				title: note.title,
				text: note.text,
				graphUserId: note.graphUserId,
				graphUserName: note.graphUserName,
				meetingAttendees: data.meeting ? attendeeEmails : note.meetingAttendees,
				graphiCalUId: data.meeting ? data.meeting.iCalUId : note.graphiCalUId,
				meetingTitle: data.meeting ? data.meeting.subject : note.meetingTitle,
				meetingStartDate: data.meeting ? data.meeting.start : note.meetingStartDate,
				tags: note.tags,
				updateNote: new Date(),
				createdOn: note.createdOn,
				updateOn: note.updateOn,
				accessRightType: note.accessRightType,
				accessRightCompanies: note.accessRightCompanies,
				archived: note.archived,
				projectId: note.projectId,
			};

			dispatch(updateNotes({ noteId: note.id, body })).then((res) => {
				const newBody = res.payload as NotesContract;
				handleNote(newBody);
				closeModal();
			});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.titleWrapper}>
					<h3>
						<IconLink />
						{t('Connected Meeting')}
					</h3>
					<div className={styles.closeButton}>
						<div
							className={styles.icon}
							onClick={() => {
								closeModal();
							}}>
							<IconClose />
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.linkMeeting} onClick={() => handleMeetingMenu()}>
						{!meetingConnect ? (
							<>
								{note.graphiCalUId ? (
									<>
										{' '}
										<span>{t('Connected meeting')}</span>
										{note.meetingTitle} ({getDateFormatL(note.meetingStartDate)})
									</>
								) : (
									<>{t('Connected meeting')}</>
								)}
							</>
						) : (
							<>
								{' '}
								<span>{t('Connected meeting')}</span>
								{meetingConnect.subject} ({getDateFormatL(meetingConnect.start)})
							</>
						)}
						<span className={styles.iconColor}>
							<IconDownArrow />
						</span>
					</div>
					{openMeetingMenu && (
						<NotesFormMeetingsModalComponent
							isSearching={isSearching}
							onChangeHandler={onChangeHandlerMeeting}
							handleChangeMeeting={handleChangeMeeting}
							close={handleMeetingMenu}
						/>
					)}
					<div className={styles.btnContainer}>
						<button className={styles.addBtn}>{t('Save')}</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default NotesMeetingConnect;
