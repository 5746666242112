import React from 'react';
import { UsersCompanyContract } from '../../../store/user/type';
import styles from '../components/header/project-details-people.module.scss';
import { Avatar } from '../../../shared/utils/avatar';

interface props {
	people: UsersCompanyContract[];
}

const ProjectPeopleCompanyModal = ({ people }: props) => {
	return (
		<>
			<div className={styles.modal}>
				<div className={styles.attendeesList}>
					{people &&
						people.map((attendee, indexAt) => (
							<div key={indexAt} className={styles.flex}>
								<div style={{ width: '24px' }}>
									<Avatar name={attendee.name} mail={attendee.userName} index={indexAt} />
								</div>
								<p>{attendee.name}</p>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export default ProjectPeopleCompanyModal;
