import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks';
import styles from './fivedays-companies.module.scss';
import FivedaysCompaniesModal from './fivedays-companies.modal';

const FivedaysCompaniesComponent = () => {
	const { t } = useTranslation();
	const { companies } = useAppSelector((state) => state.settings);

	const [openModal, setOpenModal] = React.useState(false);
	const [companyId, setCompanyId] = React.useState('');

	const handleModal = (id: string) => {
		setCompanyId(id);
		setOpenModal(!openModal);
	};

	return (
		<>
			<div className={styles.container}>
				{companies &&
					companies.map((company) => (
						<div key={company.id} className={styles.card} onClick={() => handleModal(company.id)}>
							<p className={styles.name}>{company.name}</p>
							<p>
								{company.users?.length} {t('Users')}
							</p>
							<p>
								{t('Plan')} : {t(company?.billingPlan?.plan)}
							</p>
						</div>
					))}
			</div>
			{openModal && <FivedaysCompaniesModal close={handleModal} companyId={companyId} />}
		</>
	);
};

export default FivedaysCompaniesComponent;
