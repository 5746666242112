import React from 'react';
import styles from './meetings-details-agenda.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import Loader from '../../../../../../shared/components/loader/loader.component';
import { RequestStatusType } from '../../../../../../store/shared/types';
import MeetingsDetailsAgendaTable from './meetings-details-agenda-table';
import { getAllAgendaItemForAMeeting, getAllAgendaItems, sortAgenda } from '../../../../../../store/agenda/slice';
import { getAgendaForAMeetingShared, sortAgendaShared } from '../../../../../../store/sharing/slice';
import MeetingsDetailsAgendaForm from './meetings-details-agenda-form';
import { IconSimplePlus } from '../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import OutlinedButton from '../../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import TabsAgendaShimmers from '../shimmers/shimmers-agenda';

const MeetingsDetailsAgenda = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { agendaItems, agendaRequestStatus } = useAppSelector((state) => state.agenda);
	const { shareAgenda, shareAgendaRequestStatus } = useAppSelector((state) => state.sharing);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedMeeting) {
				if (user) {
					await dispatch(getAllAgendaItemForAMeeting(selectedMeeting.iCalUId));
					await dispatch(getAllAgendaItems());
				} else {
					await dispatch(getAgendaForAMeetingShared(selectedMeeting.iCalUId));
				}
			}
		};
		fetchData();
	}, [selectedMeeting]);

	React.useEffect(() => {
		if (user) {
			dispatch(sortAgenda());
		}
	}, [agendaRequestStatus]);

	React.useEffect(() => {
		if (!user) {
			dispatch(sortAgendaShared());
		}
	}, [shareAgendaRequestStatus]);

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	return (
		<>
			{user ? (
				<div className={styles.over}>
					<OutlinedButton onClick={() => handleMenu()}>
						<IconSimplePlus /> {t('Add a new point to the agenda')}
					</OutlinedButton>
					<div className={styles.overviewList}>
						<div>
							{agendaRequestStatus.type === RequestStatusType.InProgress && (
								<>
									<TabsAgendaShimmers />
								</>
							)}

							{agendaRequestStatus.type === RequestStatusType.Success && (
								<MeetingsDetailsAgendaTable agenda={agendaItems} />
							)}

							{agendaRequestStatus.type === RequestStatusType.Failed && (
								<span>{agendaRequestStatus.errorMessage}</span>
							)}
						</div>
					</div>
					{menu && (
						<div ref={modalRef}>
							<MeetingsDetailsAgendaForm close={handleMenu} />
						</div>
					)}
				</div>
			) : (
				<div className={styles.over}>
					<button className={styles.cancel} onClick={() => handleMenu()}>
						<IconSimplePlus />
						{t('Add a new point to the agenda')}
					</button>
					<div className={styles.overviewList}>
						<div>
							{shareAgendaRequestStatus.type === RequestStatusType.InProgress && (
								<span>
									<Loader></Loader>
								</span>
							)}

							{shareAgendaRequestStatus.type === RequestStatusType.Success && (
								<MeetingsDetailsAgendaTable agenda={shareAgenda} />
							)}

							{shareAgendaRequestStatus.type === RequestStatusType.Failed && (
								<span>{shareAgendaRequestStatus.errorMessage}</span>
							)}
						</div>
					</div>
					{menu && (
						<div ref={modalRef}>
							<MeetingsDetailsAgendaForm close={handleMenu} />
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default MeetingsDetailsAgenda;
