import HttpClient from '../shared/http/http-client';
import { CreateMeetingSeriesContract, MeetingForSeriesContract } from '../store/series/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';

export default class SeriesAPI {
	public static async getMeetingsForASerie(iCalUId: string): Promise<MeetingForSeriesContract[]> {
		const { data } = await HttpClient.get<MeetingForSeriesContract[]>(
			ApiUrls.GetAllMeetingsForASerie,
			new UrlParamBuilder().addEventiCalUId(iCalUId).build(),
		);
		return data;
	}

	public static async CreateMeetingsForASerie(
		body: CreateMeetingSeriesContract,
	): Promise<CreateMeetingSeriesContract> {
		const { data } = await HttpClient.post<CreateMeetingSeriesContract, CreateMeetingSeriesContract>(
			ApiUrls.PostMeetingSerie,
			body,
		);
		return data;
	}
}
