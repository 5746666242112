/* eslint-disable unicorn/prefer-add-event-listener */
export const markdownToHtml = (markdown: string): string => {
	// Replace headers
	let html = markdown
		.replace(/^###### (.*)$/gm, '<h6>$1</h6>') // H6
		.replace(/^##### (.*)$/gm, '<h5>$1</h5>') // H5
		.replace(/^#### (.*)$/gm, '<h4>$1</h4>') // H4
		.replace(/^### (.*)$/gm, '<h3>$1</h3>') // H3
		.replace(/^## (.*)$/gm, '<h2>$1</h2>') // H2
		.replace(/^# (.*)$/gm, '<h1>$1</h1>'); // H1

	// Replace bold and italic
	html = html
		.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
		.replace(/\*(.*?)\*/g, '<em>$1</em>'); // Italic

	// Replace blockquotes
	html = html.replace(/^> (.*)$/gm, '<blockquote>$1</blockquote>');

	// Replace unordered lists
	html = html.replace(/^\* (.*)$/gm, '<ul><li>$1</li></ul>');
	html = html.replace(/<\/ul>\n<ul>/g, ''); // Avoid nested <ul>

	// Replace ordered lists
	html = html.replace(/^\d+\. (.*)$/gm, '<ol><li>$1</li></ol>');
	html = html.replace(/<\/ol>\n<ol>/g, ''); // Avoid nested <ol>

	// Replace links
	html = html.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');

	// Replace horizontal rules
	html = html.replace(/^-{3,}$/gm, '<hr>');

	// Replace line breaks
	html = html.replace(/\n/g, '<br>');

	return html;
};

export const handleMarkdownPreview = (fileBlob: Blob) => {
	const reader = new FileReader();
	reader.onload = (e) => {
		const markdownText = e.target?.result as string;
		if (typeof markdownText !== 'string') {
			alert('Error: The file content is not a valid string.');
			return;
		}
		const htmlContent = markdownToHtml(markdownText);
		const previewWindow = window.open('', '_blank', 'popup');
		if (previewWindow) {
			previewWindow.document.write(`
				<html>
					<head>
						<title>Markdown Preview</title>
						<style>
							body {
								font-family: Arial, sans-serif;
								line-height: 1.6;
								padding: 20px;
								background-color: #f9f9f9;
							}
							h1, h2, h3, h4 {
								color: #333;
							}
							pre {
								background-color: #f4f4f4;
								padding: 10px;
								border-radius: 4px;
								overflow-x: auto;
							}
							code {
								background-color: #f4f4f4;
								padding: 2px 4px;
								border-radius: 4px;
							}
							blockquote {
								border-left: 4px solid #ccc;
								padding-left: 10px;
								color: #666;
								margin: 20px 0;
							}
						</style>
					</head>
					<body>
						${htmlContent}
					</body>
				</html>
			`);
			previewWindow.document.close();
		}
	};
	reader.readAsText(fileBlob);
};
