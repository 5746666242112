import HttpClient from '../shared/http/http-client';
import { BotNotesContract, DataMeetingBotContract, MistralCall } from '../store/recall/type';
import { TodosContract } from '../store/todos/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';

export default class RecallAPI {
	public static async getMeetingsDataBot(iCalUId: string): Promise<DataMeetingBotContract> {
		const { data } = await HttpClient.get<DataMeetingBotContract>(
			ApiUrls.GetMeetingBotData,
			new UrlParamBuilder().addEventiCalUId(iCalUId).build(),
		);
		return data;
	}

	public static async getMeetingsDataBotTranscript(botId: string): Promise<BotNotesContract> {
		const { data } = await HttpClient.get<BotNotesContract>(
			ApiUrls.GetMeetingBotDataTranscript,
			new UrlParamBuilder().addBotId(botId).build(),
		);
		return data;
	}

	public static async runBotAnalyze(botId: string): Promise<boolean> {
		const { data } = await HttpClient.get<boolean>(
			ApiUrls.RunBotAnalyze,
			new UrlParamBuilder().addBotId(botId).build(),
		);
		return data;
	}

	public static async deleteMeetingsDataBot(iCalUId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteMeetingBotData,
			new UrlParamBuilder().addEventiCalUId(iCalUId).build(),
		);
		return data;
	}

	public static async createMeetingsDataBot(body: DataMeetingBotContract): Promise<DataMeetingBotContract> {
		const { data } = await HttpClient.post<DataMeetingBotContract, DataMeetingBotContract>(
			ApiUrls.CreateMeetingBotData,
			body,
		);
		return data;
	}

	public static async generateMistralSummary(body: MistralCall): Promise<string> {
		const { data } = await HttpClient.post<MistralCall, string>(ApiUrls.GenerateSummary, body);
		return data;
	}

	public static async GenerateMistralTodos(body: MistralCall): Promise<TodosContract[]> {
		const { data } = await HttpClient.post<MistralCall, TodosContract[]>(
			ApiUrls.GenerateTodos,
			body,
			new UrlParamBuilder().addBotId(body.botId).build(),
		);
		return data;
	}
}
