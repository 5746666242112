import HttpClient from '../shared/http/http-client';
import { ApiUrls, UrlParamBuilder } from './api-urls';
import { CompanyContract, RequestContract } from '../store/settings/type';
import { UserContract } from '../store/user/type';
import { SendInvitationContract, UpdateUserRole } from '../store/shared/types';

export default class SettingsAPI {
	public static async getCompanyUser(): Promise<CompanyContract> {
		const { data } = await HttpClient.get<CompanyContract>(ApiUrls.GetOrganization);
		return data;
	}

	public static async getUserInvitationLink(userId: string): Promise<string> {
		const { data } = await HttpClient.get<string>(
			ApiUrls.GetInvitationLink,
			new UrlParamBuilder().addUserId(userId).build(),
		);
		return data;
	}

	public static async updateUser(body: UpdateUserRole): Promise<UserContract> {
		const { data } = await HttpClient.patch<UpdateUserRole, UserContract>(ApiUrls.UpdateUser, body);
		return data;
	}

	public static async updateUserAllowOption(): Promise<CompanyContract> {
		const { data } = await HttpClient.get<CompanyContract>(ApiUrls.UpdateCompanyUserAllowOpt);
		return data;
	}

	public static async deleteUser(userToRevokeId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.RevokeUser,
			new UrlParamBuilder().adduserToRevokeId(userToRevokeId).build(),
		);
		return data;
	}

	public static async updateCompany(body: FormData): Promise<FormData> {
		const { data } = await HttpClient.patchFile<FormData, FormData>(ApiUrls.UpdateCompany, body);
		return data;
	}

	public static async deleteCompanyLogo(): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(ApiUrls.DeleteCompanyLogo);
		return data;
	}

	public static async downloadCompanyLogo(): Promise<ArrayBuffer | Blob> {
		const { data } = await HttpClient.getImage<ArrayBuffer | Blob>(ApiUrls.DownloadCompanyLogo);
		return data;
	}

	public static async sendInvitation(body: SendInvitationContract): Promise<UserContract> {
		const { data } = await HttpClient.post<SendInvitationContract, UserContract>(
			ApiUrls.SettingsSendInvitation,
			body,
		);
		return data;
	}

	public static async resendInvitation(body: SendInvitationContract): Promise<UserContract> {
		const { data } = await HttpClient.post<SendInvitationContract, UserContract>(ApiUrls.ResendInvitation, body);
		return data;
	}

	public static async acceptOrRevoqueARequest(body: RequestContract): Promise<RequestContract> {
		const { data } = await HttpClient.patch<RequestContract, RequestContract>(
			ApiUrls.AcceptOrRevoqueARequest,
			body,
		);
		return data;
	}

	public static async getCompanyRequests(): Promise<RequestContract[]> {
		const { data } = await HttpClient.get<RequestContract[]>(ApiUrls.getAllRequestForACompany);
		return data;
	}
}
