import React from 'react';
import styles from './meetings-details-header.module.scss';
import MeetingDetailsHeaderButtons from './meetings-details-header-buttons';
import MeetingDetailsAttendees from './meeting-details-header-attendees';
import { TagContract, MeetingContract } from '../../../../../store/meetings/types';

export interface MeetingDetailsHeaderProps {
	handleTagsChanged: (tags: TagContract[]) => void;
	selectedMeeting: MeetingContract | undefined;
}

const MeetingDetailsHeader = ({ handleTagsChanged, selectedMeeting }: MeetingDetailsHeaderProps) => {
	return selectedMeeting !== undefined ? (
		<div className={styles.headerMeeting}>
			<div className={styles.flex}>
				<div className={styles.flexTitleBtn}>
					<h1>{selectedMeeting.subject}</h1>
					<MeetingDetailsHeaderButtons meeting={selectedMeeting} />
				</div>
				<MeetingDetailsAttendees meeting={selectedMeeting} />
			</div>
		</div>
	) : (
		<></>
	);
};

export default MeetingDetailsHeader;
