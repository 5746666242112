/* eslint-disable unicorn/prefer-add-event-listener */
export const handleCsvPreview = (fileBlob: Blob) => {
	const reader = new FileReader();
	reader.onload = (e) => {
		const csvData = e.target?.result as string;
		const rows = csvData.split('\n').map((row) => row.split(','));
		const previewWindow = window.open('', '_blank', 'popup');
		if (previewWindow) {
			previewWindow.document.write(`
				<html>
					<head>
						<title>CSV Preview</title>
						<style>
							table {
								width: 100%;
								border-collapse: collapse;
							}
							th, td {
								border: 1px solid #000;
								padding: 8px;
								text-align: left;
							}
						</style>
					</head>
					<body>
						<table>
							${rows.map((row) => `<tr>${row.map((cell) => `<td>${cell}</td>`).join('')}</tr>`).join('')}
						</table>
					</body>
				</html>
			`);
			previewWindow.document.close();
		}
	};
	reader.readAsText(fileBlob);
};
