/* eslint-disable unicorn/prefer-ternary */
import React from 'react';
import MeetingsDetailsNotesBridge from './meetings-details-notes-bridge.component';
import styles from './meetings-details-notes.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { getAllNoteForAMeeting } from '../../../../../../store/notes/slice';
import { getAllNoteForAMeetingShared } from '../../../../../../store/sharing/slice';
import { EditorProvider } from '../../../../../../shared/contexts/editor-context';

interface Props {
	storedAccessMail?: string;
}

const MeetingsDetailsNotes: React.FC<Props> = ({ storedAccessMail }) => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedMeeting) {
				if (user) {
					await dispatch(getAllNoteForAMeeting(selectedMeeting.iCalUId));
				} else {
					await dispatch(
						getAllNoteForAMeetingShared({
							iCalUId: selectedMeeting.iCalUId,
							userId: storedAccessMail ? storedAccessMail : '',
						}),
					);
				}
			}
		};
		fetchData();
	}, [selectedMeeting]);

	return (
		<div className={styles.over}>
			<EditorProvider>
				<MeetingsDetailsNotesBridge storedAccessMail={storedAccessMail} />
			</EditorProvider>
		</div>
	);
};

export default MeetingsDetailsNotes;
