import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './tooltip-meeting-calendar.module.scss';

interface Props {
	id: string;
	title: string;
}

const TooltipMeetingCalendar: React.FC<Props> = ({ id, title }) => {
	return (
		<Tooltip
			noArrow
			place='bottom'
			id={id}
			style={{
				border: '1px solid #D7D7D7',
				borderRadius: '12px',
				backgroundColor: '#f7f7f7',
				color: 'black',
				opacity: 1,
			}}>
			<div className={styles.tooltip}>
				<div className={styles.tooltipContentContainer}>
					<p className={styles.tooltipContent}>{title}</p>
				</div>
			</div>
		</Tooltip>
	);
};

export default TooltipMeetingCalendar;
