import React from 'react';
import styles from '../note-editor.module.scss';
import { IconHLDelete } from '../../../../shared/utils/icon';
import { useCommands } from '@remirror/react';

interface Props {
	setColor: (color: string) => void;
	handleSetColor: (color: string) => void;
	handleColorMenu: () => void;
	isHl?: boolean;
}

const NotesEditorColorsModal: React.FC<Props> = ({ setColor, handleSetColor, handleColorMenu, isHl }) => {
	const { ...commandsT } = useCommands();

	return (
		<>
			<div
				style={{ backgroundColor: '#C0C0C0' }}
				onClick={() => {
					setColor('#C0C0C0');
					handleSetColor('#C0C0C0');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#808080' }}
				onClick={() => {
					setColor('#808080');
					handleSetColor('#808080');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#000000' }}
				onClick={() => {
					setColor('#000000');
					handleSetColor('#000000');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#FF0000' }}
				onClick={() => {
					setColor('#FF0000');
					handleSetColor('#FF0000');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#800000' }}
				onClick={() => {
					setColor('#800000');
					handleSetColor('#800000');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#FFFF00' }}
				onClick={() => {
					setColor('#FFFF00');
					handleSetColor('#FFFF00');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#808000' }}
				onClick={() => {
					setColor('#808000');
					handleSetColor('#808000');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#00FF00' }}
				onClick={() => {
					setColor('#00FF00');
					handleSetColor('#00FF00');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#008000' }}
				onClick={() => {
					setColor('#008000');
					handleSetColor('#008000');
					handleColorMenu();
				}}
				className={styles.cols}></div>
			<div
				style={{ backgroundColor: '#00FFFF' }}
				onClick={() => {
					setColor('#00FFFF');
					handleSetColor('#00FFFF');
					handleColorMenu();
				}}
				className={styles.cols}></div>

			<div
				style={{ backgroundColor: '#008080' }}
				onClick={() => {
					setColor('#008080');
					handleSetColor('#008080');
					handleColorMenu();
				}}
				className={styles.cols}></div>

			<div
				style={{ backgroundColor: '#0000FF' }}
				onClick={() => {
					setColor('#0000FF');
					handleSetColor('#0000FF');
					handleColorMenu();
				}}
				className={styles.cols}></div>

			<div
				style={{ backgroundColor: '#000080' }}
				onClick={() => {
					setColor('#000080');
					handleSetColor('#000080');
					handleColorMenu();
				}}
				className={styles.cols}></div>

			<div
				style={{ backgroundColor: '#FF00FF' }}
				onClick={() => {
					setColor('#FF00FF');
					handleSetColor('#FF00FF');
					handleColorMenu();
				}}
				className={styles.cols}></div>

			<div
				style={{ backgroundColor: '#800080' }}
				onClick={() => {
					setColor('#800080');
					handleSetColor('#800080');
					handleColorMenu();
				}}
				className={styles.cols}></div>

			{!isHl && (
				<div
					onMouseDown={(event) => event.preventDefault()}
					onClick={() => commandsT.removeTextColor()}
					className={styles.cols}>
					<IconHLDelete />
				</div>
			)}

			{isHl && (
				<div
					onMouseDown={(event) => event.preventDefault()}
					onClick={() => commandsT.removeTextHighlight()}
					className={styles.cols}>
					<IconHLDelete />
				</div>
			)}
		</>
	);
};

export default NotesEditorColorsModal;
