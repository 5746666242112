import React, { useEffect, useMemo, useState } from 'react';
import { NodeViewComponentProps, useCommands } from '@remirror/react';
import { IconClose, IconEdit } from '../../../../shared/utils/icon';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Avatar } from '../../../../shared/utils/avatar';
import { TodosContract, TodosStates } from '../../../../store/todos/type';
import {
	completeTodos,
	deleteTodo,
	deleteTodosNotes,
	getCurrentUserTodos,
	getNotesTodos,
	updateTodoStateNote,
	updateTodoTitleNote,
	updateTodos,
} from '../../../../store/todos/slice';
import { NotificationsType } from '../../../../store/notifications/type';
import { SendNotifications } from '../../../../store/notifications/slice';
import { useParams } from 'react-router-dom';
import {
	deleteTodoShared,
	deleteTodosShareNote,
	getNotesTodosShared,
	updateTodoSharedStateNote,
	updateTodoTitleNoteShared,
	updateTodosShared,
} from '../../../../store/sharing/slice';
import TodosFormModal from '../../../todos/modals/todos.form.modal';
import { TodosNodeExtension } from './todos-extension.component';
import { AccessRightType } from '../../../../store/meetings/types';
import { useEditor } from '../../../../shared/contexts/editor-context';
import { useDebounce } from '../../../../shared/hooks/use-debounce.hook';

export function TodoInsideEditor({ node, forwardRef }: NodeViewComponentProps) {
	const { todoId, noteId } = node?.attrs;
	const { notesTodos } = useAppSelector((state) => state.todos);
	const { shareTodosNote } = useAppSelector((state) => state.sharing);
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { meetings, selectedMeeting } = useAppSelector((state) => state.meetings);
	const { projectsSharePeople, projects } = useAppSelector((state) => state.project);
	const params = useParams();
	const shareId = params.shareID;
	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);
	const { createTodo, deleteTodoInsideEditor, syncYjs } = useCommands();
	const [openUpdate, setOpenUpdate] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const { setIsModalOpen } = useEditor();
	const [localTitle, setLocalTitle] = useState('');
	const debouncedTitle = useDebounce(localTitle, 1000); // 1000ms delay

	const todo = user
		? notesTodos?.find((todoTF: TodosContract) => todoTF.id === todoId)
		: shareTodosNote?.find((todoTF) => todoTF.id === todoId);

	const handleTitleChange = (data: TodosContract, newTitle: string) => {
		const body = {
			id: data.id,
			title: newTitle,
			text: data.text,
			state: data.state,
			dueDate: data.dueDate,
			duration: data.duration,
			graphUserId: data.graphUserId,
			noteId: data.noteId,
			assigneeDisplayName: data.assigneeDisplayName,
			graphiCalUId: data.graphiCalUId,
			meetingGraphId: data.meetingGraphId,
			meetingName: data.meetingName,
			meetingStartDate: data.meetingStartDate,
			createdOn: new Date(),
			tags: data.tags,
			projectId: data.projectId,
		};
		const bodyUpdate = { todoId: data.id, newTitle };

		if (user) {
			dispatch(updateTodos({ body, todoId: data.id })).then(() => {
				dispatch(updateTodoTitleNote(bodyUpdate));
				syncYjs();
			});
		} else if (storedAccessMail) {
			const bodyComplete = { ...body, tags: undefined };
			dispatch(updateTodosShared({ body: bodyComplete, todoId: data.id })).then(() => {
				dispatch(updateTodoTitleNoteShared(bodyUpdate));
				syncYjs();
			});
		}
	};

	useEffect(() => {
		if (todo) {
			setLocalTitle(todo.title);
		}
	}, [todo?.title]);

	useEffect(() => {
		if (debouncedTitle && todo && debouncedTitle !== todo.title) {
			handleTitleChange(todo, debouncedTitle);
		}
	}, [debouncedTitle]);

	const todosExtension = useMemo(() => new TodosNodeExtension(), []);

	const [attendees, setAttendees] = React.useState<any>();

	useEffect(() => {
		if (user) {
			dispatch(getNotesTodos(noteId));
		} else {
			dispatch(getNotesTodosShared(noteId));
		}
	}, [dispatch, noteId, user]);

	useEffect(() => {
		if (todo) {
			if (user) {
				const rightProjects = projects?.find((p) => p.id === todo.projectId);
				const rightMeetings = meetings?.find((m) => m.iCalUId === todo.graphiCalUId);

				if (rightMeetings) {
					setAttendees(
						rightMeetings?.attendees.map((attendee) => ({
							name: attendee.emailAddress.name,
							email: attendee.emailAddress.address,
						})),
					);
				} else if (todo.meetingAttendees)
					setAttendees(
						todo.meetingAttendees.map((attendee) => ({
							name: attendee,
							email: attendee,
						})),
					);

				if (rightProjects) {
					if (rightProjects.accessRightType === AccessRightType.SHARED && projectsSharePeople) {
						setAttendees(
							projectsSharePeople?.map((attendee) => ({ name: attendee.username, email: attendee.mail })),
						);
					} else {
						setAttendees(
							user.userCompany.map((attendee) => ({ name: attendee.name, email: attendee.userName })),
						);
					}
				}
			} else {
				const rightMeetings = selectedMeeting?.iCalUId === todo.graphiCalUId ? selectedMeeting : null;

				if (rightMeetings)
					setAttendees(
						rightMeetings.attendees.map((attendee) => ({
							name: attendee.emailAddress.name,
							email: attendee.emailAddress.address,
						})),
					);
			}
		}
	}, [todo]);

	const sendNotification = (todoC: TodosContract, fromName: string, fromMail: string) => {
		const bodyNotification = {
			id: undefined,
			userFromName: fromName,
			userFromMail: fromMail,
			userToName: todoC.graphUserId,
			userToMail: todoC.graphUserId,
			type: NotificationsType.TODO_COMPLETE,
			noteId: undefined,
			todoId: todoC.id,
			graphiCalUId: undefined,
			projectId: undefined,
			meetingStartDate: undefined,
			itemTitle: todoC.title,
			visible: true,
			meetingName: undefined,
		};
		dispatch(SendNotifications(bodyNotification));
	};

	const createSharedTodoBody = (todoC: TodosContract, state: TodosStates) => ({
		id: todoC.id,
		title: todoC.title,
		text: todoC.text,
		state,
		dueDate: todoC.dueDate,
		duration: todoC.duration,
		graphUserId: todoC.graphUserId,
		noteId: todoC.noteId,
		assigneeDisplayName: todoC.assigneeDisplayName,
		graphiCalUId: todoC.graphiCalUId,
		meetingGraphId: todoC.meetingGraphId,
		meetingName: todoC.meetingName,
		meetingStartDate: todoC.meetingStartDate,
		createdOn: todoC.createdOn,
		tags: [],
		assigneeName: todoC.assigneeName,
		archived: todoC.archived,
		projectId: todoC.projectId,
	});

	const handleCompleteChange = (checked: boolean, todoC: TodosContract) => {
		const state = checked ? TodosStates.DONE : TodosStates.TODO;
		const body = { state, index: todoC.orderAssignee };
		const bodyUpdate = { todoId: todoC.id, stateTodo: state };

		if (user) {
			dispatch(completeTodos({ body, todoId: todoC.id })).then(() => {
				if (todo?.noteId) {
					dispatch(updateTodoStateNote(bodyUpdate));
					syncYjs();
				}
				if (checked && user.graphUserId !== todoC.graphUserId) {
					sendNotification(todoC, user.displayName, user.email);
				}
			});
		} else if (storedAccessMail) {
			const bodyComplete = createSharedTodoBody(todoC, TodosStates.DONE);
			dispatch(updateTodosShared({ body: bodyComplete, todoId: todoC.id })).then(() => {
				dispatch(updateTodoSharedStateNote(bodyComplete));
				syncYjs();
				if (checked && storedAccessMail !== todoC.graphUserId) {
					sendNotification(todoC, storedAccessMail, storedAccessMail);
				}
			});
		}
	};

	const handleDeleteTodo = () => {
		if (!todo) return;
		if (user) {
			deleteTodoInsideEditor(todo.id);
			dispatch(deleteTodosNotes(todo.id));
			dispatch(deleteTodo(todo.id)).then(() => {
				dispatch(getCurrentUserTodos()).then(() => {
					if (todo.noteId) {
						dispatch(deleteTodosNotes(todo.id));
						syncYjs();
					}
				});
			});
		} else {
			deleteTodoInsideEditor(todo.id);
			dispatch(deleteTodosShareNote(todo.id));
			dispatch(deleteTodoShared(todo.id)).then(() => {
				dispatch(deleteTodosShareNote(todo.id));
				syncYjs();
			});
		}
	};

	const handleToggleTodosExtension = async (event: React.KeyboardEvent) => {
		if (event.key === 'Enter' && todo) {
			const todoToBeCreated = user
				? await todosExtension.createTodoBackend(
						'new todo',
						noteId,
						todo.graphUserId,
						dispatch,
						todo.assigneeName,
						todo.assigneeDisplayName,
						todo.graphiCalUId,
						todo.graphiCalUId,
						todo.meetingName,
						todo.meetingStartDate,
						todo.meetingAttendees,
						todo.projectId,
				  )
				: await todosExtension.createTodoBackendInvite(
						'new todo',
						noteId,
						todo.graphUserId,
						dispatch,
						todo.assigneeName,
						todo.assigneeDisplayName,
						shareId,
						todo.graphiCalUId,
						todo.graphiCalUId,
						todo.meetingName,
						todo.meetingStartDate,
						todo.meetingAttendees,
				  );

			createTodo(todoToBeCreated.id, todoToBeCreated.title, todoToBeCreated.noteId);
		}
	};

	const handleOpenModal = () => {
		setIsModalOpen(true);
		setOpenUpdate(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setOpenUpdate(false);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		// Only handle backspace when not editing the title
		const target = e.target as HTMLElement;
		if (e.key === 'Backspace' && target.tagName !== 'INPUT') {
			handleDeleteTodo();
		}
	};

	return (
		<>
			{todo && (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '15px',
						height: '40px',
						borderRadius: '5px',
						marginTop: '10px',
						marginBottom: '10px',
						marginLeft: '2px',
						marginRight: '2px',
						maxWidth: '400px',
						padding: '10px',
						border: '1px solid #e0e0e0',
						backgroundColor: isFocused ? '#f0f0f0' : 'transparent',
						outline: isFocused ? '2px solid green' : 'none',
					}}
					contentEditable={false}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onKeyDown={handleKeyDown}
					ref={forwardRef}
					tabIndex={1}>
					<input
						style={{ height: '20px', width: '20px', margin: '0' }}
						type='checkbox'
						onChange={(e) => handleCompleteChange(e.target.checked, todo)}
						checked={todo.state === TodosStates.DONE}
					/>

					<input
						style={{
							border: 'none',
							height: '20px',
							padding: '0',
							margin: '0',
							background: 'none',
							width: '100%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							textDecoration: todo.state === TodosStates.DONE ? 'line-through' : 'none',
						}}
						type='text'
						value={localTitle}
						onChange={(e) => setLocalTitle(e.target.value)}
						onBlur={(e) => {
							// Immediately update on blur
							if (e.target.value !== todo.title) {
								handleTitleChange(todo, e.target.value);
							}
						}}
						onKeyDown={(e) => {
							// Stop propagation to prevent parent div from handling the event, and therefore delete the todo
							if (e.key === 'Backspace') {
								e.stopPropagation();
							}
						}}
					/>

					<Avatar name={todo.assigneeName} mail={todo.assigneeDisplayName} index={1} />

					<div style={{ height: '20px', cursor: 'pointer' }} onClick={handleOpenModal}>
						<IconEdit />
					</div>
					<div style={{ height: '20px' }} onClick={handleDeleteTodo}>
						<IconClose />
					</div>
				</div>
			)}
			{openUpdate && todo && (
				<TodosFormModal
					toggle={handleCloseModal}
					handleToggle={handleCloseModal}
					todo={todo}
					attendees={attendees}
					syncYjs={syncYjs}
				/>
			)}
		</>
	);
}
