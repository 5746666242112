import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { getShareDetails, getSharedMeeting } from '../../store/sharing/slice';
import { SharingContractForMap } from '../../store/sharing/type';
import styles from './public-meeting.module.scss';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/msal-auth-config.';
import { getTimeFormat } from '../../shared/utils/date.util';
import { MeetingContract } from '../../store/meetings/types';
import { Avatar } from '../../shared/utils/avatar';
import { IconMapDir } from '../../shared/utils/icon';
import { RouteUrls } from '../../routes/routes-config';
import HeaderInviteComponent from './components/header-invite.component';
import FormConfirmMailComponent from './components/form-confirm-mail.component';

function PublicMeetingPageForm() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const params = useParams();
	const dispatch = useAppDispatch();
	const { instance } = useMsal();
	const handleLoginRedirect = () => {
		instance.loginRedirect(loginRequest);
	};

	const storedShareId = params.shareID;

	const [shareDetails, setSharedDetails] = React.useState<SharingContractForMap>();
	const [meeting, setMeeting] = React.useState<MeetingContract>();
	const [meetingDate, setMeetingDate] = React.useState<Date>(new Date());
	const dayOfWeek = ['Sunday', 'Monday', 'Thuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const monthOfYear = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	React.useEffect(() => {
		if (meeting) {
			const newMeetingDate = new Date(meeting.start);

			setMeetingDate(newMeetingDate);
		}
	}, [meeting]);

	React.useEffect(() => {
		if (storedShareId) {
			dispatch(getShareDetails(storedShareId)).then((result) => {
				setSharedDetails(result.payload as SharingContractForMap);
				const data = result.payload as SharingContractForMap;
				dispatch(getSharedMeeting({ shareId: data.id })).then((resultMeeting) => {
					const dataMeeting = resultMeeting.payload as MeetingContract;
					setMeeting(dataMeeting);
				});
			});
		}
	}, [dispatch, storedShareId]);

	React.useEffect(() => {
		if (shareDetails) {
			dispatch(getSharedMeeting({ shareId: shareDetails.id })).then((resultMeeting) => {
				setMeeting(resultMeeting.payload as MeetingContract);
			});
		}
	}, [dispatch, shareDetails]);

	const [showFormInvite, setShowFormInvite] = React.useState(false);
	const handleShowFormInvite = () => {
		setShowFormInvite(!showFormInvite);
	};

	return (
		<>
			{showFormInvite ? (
				<>
					<HeaderInviteComponent returnInvite={handleShowFormInvite} />
					<FormConfirmMailComponent shareDetails={shareDetails} />
				</>
			) : (
				<div className={styles.mainInvite}>
					<div className={styles.header}>
						<h1>Hello</h1>
						<p>{t('You have been invited on 5days 🎉')}</p>
						<div className={styles.buttons}>
							<button onClick={() => handleShowFormInvite()}>{t('Join as a guest')}</button>
							<button className={styles.transparantButton} onClick={() => handleLoginRedirect()}>
								{t('Log in')}
							</button>
						</div>
					</div>
					<div className={styles.main}>
						<p className={styles.next}>{t('Your next meeting')}</p>
						{meeting && (
							<div className={styles.meetingWrapper}>
								<div className={styles.meetingContainer}>
									<div>
										<p className={styles.date}>{getTimeFormat(meeting.start, meeting.end)}</p>
									</div>
									<div className={styles.flex}>
										<p className={styles.meetingSubject}>{meeting.subject}</p>
										<div className={styles.avatar}>
											<Avatar
												name={meeting.organizer.name}
												mail={meeting.organizer.address}
												index={1}
											/>
											{meeting.tags && (
												<div className={styles.tag}>
													{meeting.tags.slice(0, 1).map((tag) => (
														<p key={tag.id}>{tag.title}</p>
													))}
												</div>
											)}
										</div>
										{meeting.location && (
											<p className={styles.location}>
												<IconMapDir />
												<span className={styles.lo}>{meeting.location}</span>
											</p>
										)}
									</div>
								</div>
								<div className={styles.meetingDescription}>
									<p className={styles.fullDate}>
										{t(dayOfWeek[meetingDate.getDay()])} {meetingDate.getDate()}{' '}
										{t(monthOfYear[meetingDate.getMonth()])}
									</p>
								</div>
							</div>
						)}
					</div>
					<div className={styles.footer}>
						<p className={styles.join}>
							{t('Join 5days for')} <span className={styles.freeDays}>{t('30 free days')}</span>
						</p>
						<p className={styles.info}>{t('The next collaborative meeting generation')}</p>
						<div className={styles.buttons}>
							<button onClick={() => navigate(RouteUrls.Home)}>{t('Join 5days')}</button>
							<button className={styles.transparantButton}>
								<a target='blank' href='https://www.getfivedays.com/faq-fivedays-app'>
									{t('Learn more')}
								</a>
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default PublicMeetingPageForm;
