import React, { useEffect } from 'react';
import styles from './project-notes.module.scss';
import ProjectNotesContent from './project-notes-content';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getNotesCommentsProject, getNotesProject } from '../../../../../store/project/slice';
import { RequestStatusType } from '../../../../../store/shared/types';
import ShimmerNote from '../../../../../shared/components/shimmers/shimmerNote/shimmer-note.component';
import { EditorProvider } from '../../../../../shared/contexts/editor-context';

const ProjectNotes = () => {
	const dispatch = useAppDispatch();
	const { selectProject, projectsNotesRequestStatus } = useAppSelector((state) => state.project);

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			if (selectProject) {
				await dispatch(getNotesProject(selectProject.id));
				await dispatch(getNotesCommentsProject(selectProject.id));
			}
		};
		fetchData();
	}, [selectProject]);

	React.useEffect(() => {
		if (location.search.includes('?id')) {
			const noteId = location.search.split('/')[1];

			const noteElement = document.querySelector(`#noteIdProject${noteId}`);

			if (noteElement) {
				if (noteElement) {
					noteElement.scrollIntoView({
						behavior: 'smooth',
					});
					navigate('/project');
				}
			} else {
				console.error('Container or Note Element not found.');
			}
		}
	}, [location]);

	return (
		<div className={styles.over}>
			<EditorProvider>
				<>
					<div className={styles.overviewList} id='meetingsNotesProject'>
						{projectsNotesRequestStatus.type === RequestStatusType.InProgress && (
							<div>
								<ShimmerNote />
							</div>
						)}

						{projectsNotesRequestStatus.type === RequestStatusType.Success && <ProjectNotesContent />}
					</div>
				</>
			</EditorProvider>
		</div>
	);
};

export default ProjectNotes;
