import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsersAPI from '../../api/user-api';
import { createRequestStatus, RequestStatus, RequestStatusType } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import { RootState } from '../index';
import { AvatarData } from './type';

interface AvatarsState {
	getUserAvatarRequestStatus: RequestStatus;
	getUsersAvatarRequestStatus: RequestStatus;
	usersAvatars: {
		[key: string]: AvatarData;
	};
}

const initialState: AvatarsState = {
	getUserAvatarRequestStatus: createRequestStatus(RequestStatusType.New),
	getUsersAvatarRequestStatus: createRequestStatus(RequestStatusType.New),
	usersAvatars: {},
};

export const getAvatarForCurrentUser = createAsyncThunk(
	'user/avatar',
	async (userMail: string, { getState }): Promise<AvatarData> => {
		const state = getState() as RootState;
		const { usersAvatars } = state.avatars;
		if (usersAvatars[userMail] !== undefined) {
			return new Promise<AvatarData>((resolve) => {
				resolve(usersAvatars[userMail]);
			});
		} else {
			const avatar: ArrayBuffer | Blob = await UsersAPI.getUserAvatar();
			const isFiveDays: boolean = await UsersAPI.checkUser(userMail);
			const blob = avatar instanceof Blob ? avatar : new Blob([avatar]);
			const reader = new FileReader();
			return new Promise<AvatarData>((resolve, reject) => {
				reader.onloadend = () => {
					const result = reader.result;
					if (typeof result === 'string') {
						resolve({ avatar: result, isFiveDays: isFiveDays });
					} else {
						reject(new Error('Failed to convert avatar to string'));
					}
				};
				reader.readAsDataURL(blob);
			});
		}
	},
);

export const getAvatarForUserByEmail = createAsyncThunk(
	'user/attendees/avatar',
	async (userMail: string, { getState }): Promise<AvatarData> => {
		const state = getState() as RootState;
		const { usersAvatars } = state.avatars;
		if (usersAvatars[userMail] !== undefined) {
			return new Promise<AvatarData>((resolve) => {
				resolve(usersAvatars[userMail]);
			});
		} else {
			const avatar: ArrayBuffer | Blob = await UsersAPI.getUsersAvatar(userMail);
			const isFiveDays: boolean = await UsersAPI.checkUser(userMail);
			const blob = avatar instanceof Blob ? avatar : new Blob([avatar]);
			const reader = new FileReader();
			return new Promise<AvatarData>((resolve, reject) => {
				reader.onloadend = () => {
					const result = reader.result;
					if (typeof result === 'string') {
						resolve({ avatar: result, isFiveDays: isFiveDays });
					} else {
						reject(new Error('Failed to convert avatar to string'));
					}
				};
				reader.readAsDataURL(blob);
			});
		}
	},
);

export const getUsersAvatarFromGoogle = createAsyncThunk(
	'attendees/google/avatar',
	async (mail: string): Promise<AvatarData> => {
		const avatar: string = await UsersAPI.getUsersAvatarGoogle(mail);
		const isFiveDays: boolean = await UsersAPI.checkUser(mail);
		return { avatar, isFiveDays };
	},
);

const avatarsSlice = createSlice({
	name: 'avatars',
	initialState,
	reducers: {},
	extraReducers(builder): void {
		builder.addCase(getAvatarForCurrentUser.pending, (state) => {
			state.getUserAvatarRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAvatarForCurrentUser.fulfilled, (state, action) => {
			state.getUserAvatarRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.usersAvatars[action.meta.arg] = action.payload;
		});
		builder.addCase(getAvatarForCurrentUser.rejected, (state, action) => {
			state.getUsersAvatarRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
		builder.addCase(getAvatarForUserByEmail.pending, (state) => {
			state.getUsersAvatarRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAvatarForUserByEmail.fulfilled, (state, action) => {
			state.getUsersAvatarRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.usersAvatars[action.meta.arg] = action.payload;
		});
		builder.addCase(getAvatarForUserByEmail.rejected, (state, action) => {
			state.getUsersAvatarRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getUsersAvatarFromGoogle.pending, (state) => {
			state.getUserAvatarRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getUsersAvatarFromGoogle.fulfilled, (state, action) => {
			state.getUserAvatarRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.usersAvatars[action.meta.arg] = action.payload;
		});
		builder.addCase(getUsersAvatarFromGoogle.rejected, (state, action) => {
			state.getUsersAvatarRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = avatarsSlice;
export default reducer;
