import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ErrorUtil from '../../shared/utils/error.util';
import {
	FileContract,
	MeetingContract,
	TagContract,
	AccessRightType,
	InformationsContract,
	UpdateMeetingCount,
	MeetingCalendarContract,
} from './types';
import { createRequestStatus, RequestStatus, RequestStatusType, UpdateMeetingFilesAccess } from '../shared/types';
import MeetingsAPI from '../../api/meeting-api';
import { UpdateAndCreateGraphEventContract, UpdateMeeting } from '../../shared/types/meetings-graph-interface';
import { MeetingForSeriesContract } from '../series/type';

interface MeetingsState {
	meetings?: MeetingContract[];
	meetingsRequestStatus: RequestStatus;
	selectedMeeting?: MeetingContract;

	selectedMeetingInformation?: InformationsContract;
	selectedMeetingInformationRequestStatus: RequestStatus;

	prevMeeting?: MeetingContract;
	prevMeetings?: MeetingForSeriesContract[] | MeetingContract[];

	meetingsForWeek?: MeetingContract[];
	meetingsRequestStatusForWeek: RequestStatus;
	selectedMeetingForWeek?: MeetingContract;

	meetingsForCalendar?: MeetingCalendarContract[];
	meetingForCalendarResquestStatus: RequestStatus;

	creatingMeeting?: UpdateAndCreateGraphEventContract;
	creatingMeetingsRequestStatus: RequestStatus;

	updateMeeting?: UpdateAndCreateGraphEventContract;
	updateMeetingsRequestStatus: RequestStatus;

	deleteMeeting?: [];
	deleteMeetingsRequestStatus: RequestStatus;

	meetingsOption?: MeetingContract[];
	meetingsOptionRequestStatus: RequestStatus;

	meetingsForSeriesState?: MeetingContract[];
	meetingsRequestStatusForSeries: RequestStatus;

	lastMeetings?: MeetingContract[];
	lastMeetingsRequestStatus: RequestStatus;
}

const initialState: MeetingsState = {
	meetings: undefined,
	meetingsRequestStatus: createRequestStatus(RequestStatusType.New),
	selectedMeeting: undefined,

	selectedMeetingInformation: undefined,
	selectedMeetingInformationRequestStatus: createRequestStatus(RequestStatusType.New),

	prevMeeting: undefined,
	prevMeetings: undefined,

	meetingsForWeek: undefined,
	meetingsRequestStatusForWeek: createRequestStatus(RequestStatusType.New),
	selectedMeetingForWeek: undefined,

	meetingsForCalendar: undefined,
	meetingForCalendarResquestStatus: createRequestStatus(RequestStatusType.New),

	creatingMeeting: undefined,
	creatingMeetingsRequestStatus: createRequestStatus(RequestStatusType.New),

	updateMeeting: undefined,
	updateMeetingsRequestStatus: createRequestStatus(RequestStatusType.New),

	deleteMeeting: undefined,
	deleteMeetingsRequestStatus: createRequestStatus(RequestStatusType.New),

	meetingsOption: undefined,
	meetingsOptionRequestStatus: createRequestStatus(RequestStatusType.New),

	meetingsForSeriesState: undefined,
	meetingsRequestStatusForSeries: createRequestStatus(RequestStatusType.New),

	lastMeetings: undefined,
	lastMeetingsRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getMeetings = createAsyncThunk(
	'meetings/getMeetings',
	async ({ startDate, endDate }: { startDate: Date; endDate: Date }): Promise<MeetingContract[]> => {
		return MeetingsAPI.getMeetings(startDate, endDate);
	},
);

export const getMeetingsForSelectOption = createAsyncThunk(
	'meetings/todo/getMeetings',
	async (filter: string): Promise<MeetingContract[] | undefined> => {
		if (filter.length === 0) {
			return undefined;
		}
		return MeetingsAPI.getMeetingsForOption(filter);
	},
);

export const getMeetingInformations = createAsyncThunk(
	'meetings/information',
	async (iCalUId: string): Promise<InformationsContract> => {
		return MeetingsAPI.getMeetingsDescription(iCalUId);
	},
);

export const getLastMeetings = createAsyncThunk('meetings/last/getMeetings', async (): Promise<MeetingContract[]> => {
	return MeetingsAPI.getLastMeetings();
});

export const getMeeting = createAsyncThunk('meetings/getOneByID', async (eventId: string): Promise<MeetingContract> => {
	return MeetingsAPI.getMeeting(eventId);
});

export const getMeetingsForAWeek = createAsyncThunk(
	'meetings/getMeetingsForAWeek',
	async ({ startDate, endDate }: { startDate: Date; endDate: Date }): Promise<MeetingContract[]> => {
		return MeetingsAPI.getMeetings(startDate, endDate);
	},
);

export const getMeetingsForCalendar = createAsyncThunk(
	'meetings/calendar/month',
	async ({ startDate, endDate }: { startDate: Date; endDate: Date }): Promise<MeetingCalendarContract[]> => {
		return MeetingsAPI.getMeetingsCalendar(startDate, endDate);
	},
);

export const getMeetingsForSeries = createAsyncThunk(
	'meetings/getMeetingsForSeries',
	async ({
		startDate,
		endDate,
		seriesId,
	}: {
		startDate: Date;
		endDate: Date;
		seriesId: string;
	}): Promise<MeetingContract[]> => {
		return MeetingsAPI.getMeetingsForReccurence(startDate, endDate, seriesId);
	},
);

export const createMeeting = createAsyncThunk(
	'meetings/createMeeting',
	async (meeting: UpdateAndCreateGraphEventContract): Promise<MeetingContract> => {
		return MeetingsAPI.createMeeting(meeting);
	},
);

export const updateMeeting = createAsyncThunk(
	'meetings/updateMeeting',
	async ({
		body,
		eventId,
	}: {
		body: UpdateAndCreateGraphEventContract;
		eventId: string;
	}): Promise<MeetingContract> => {
		return MeetingsAPI.updateMeeting(body, eventId);
	},
);

export const deleteMeeting = createAsyncThunk('meetings/deleteMeeting', async (eventId: string): Promise<undefined> => {
	return MeetingsAPI.deleteMeeting(eventId);
});

export const addMeetingFile = createAsyncThunk(
	'meetings/files/addFile',
	async ({ body, selectedMeetingID }: { body: FormData; selectedMeetingID: string }): Promise<FormData> => {
		return MeetingsAPI.addFileToMeeting(body, selectedMeetingID);
	},
);

export const deleteMeetingFile = createAsyncThunk(
	'meetings/files/deleteFile',
	async ({ selectedMeetingID, fileId }: { selectedMeetingID: string; fileId: string }): Promise<undefined> => {
		return MeetingsAPI.deleteFileToMeeting(selectedMeetingID, fileId);
	},
);

export const updateMeetingTags = createAsyncThunk(
	'meetings/tags',
	async ({ tags, selectedMeetingID }: { tags: TagContract[]; selectedMeetingID: string }): Promise<TagContract[]> => {
		return MeetingsAPI.updateTagsMeeting(tags, selectedMeetingID);
	},
);

export const updateMeetingFile = createAsyncThunk(
	'meetings/files/updateAccess',
	async ({
		body,
		selectedMeetingID,
		fileId,
	}: {
		body: UpdateMeetingFilesAccess;
		selectedMeetingID: string;
		fileId: string;
	}): Promise<FileContract> => {
		return MeetingsAPI.updateFileToMeetingAccess(body, selectedMeetingID, fileId);
	},
);

export const downloadMeetingFile = createAsyncThunk(
	'meetings/files/downloadFile',
	async ({ selectedMeetingID, fileId }: { selectedMeetingID: string; fileId: string }): Promise<Blob> => {
		return MeetingsAPI.downloadFileMeeting(selectedMeetingID, fileId);
	},
);

const meetingsSlice = createSlice({
	name: 'meetings',
	initialState,
	reducers: {
		reverseMeetings: (state: MeetingsState) => {
			if (state.meetings) state.meetings = [...state.meetings].reverse();
		},
		reverseMeetingsOption: (state: MeetingsState) => {
			if (state.meetingsOption) state.meetingsOption = [...state.meetingsOption].reverse();
		},
		setSelectedMeeting: (state: MeetingsState, action: PayloadAction<MeetingContract | undefined>) => {
			state.selectedMeeting = action.payload;
		},
		setPrevMeeting: (state: MeetingsState, action: PayloadAction<MeetingContract | undefined>) => {
			state.prevMeeting = action.payload;
		},
		setPrevMeetings: (
			state: MeetingsState,
			action: PayloadAction<MeetingForSeriesContract[] | MeetingContract[] | undefined>,
		) => {
			state.prevMeetings = action.payload;
		},
		updateSelectedMeetingTags: (state: MeetingsState, action: PayloadAction<TagContract[]>) => {
			state.selectedMeeting = Object.assign({}, state.selectedMeeting, { tags: action.payload });
		},

		updateSelectedMeetingFiles: (state: MeetingsState, action: PayloadAction<FileContract[]>) => {
			const newFiles = action.payload;

			const updatedSelectedMeeting = state.selectedMeeting
				? { ...state.selectedMeeting, files: newFiles }
				: undefined;
			const updatedMeetings =
				state.meetings?.map((meeting) =>
					meeting.iCalUId === state.selectedMeeting?.iCalUId ? { ...meeting, files: newFiles } : meeting,
				) ?? [];

			return {
				...state,
				selectedMeeting: updatedSelectedMeeting,
				meetings: updatedMeetings,
			};
		},

		deleteFilesSuccess: (state: MeetingsState, action: PayloadAction<string>) => {
			const updatedSelectedMeeting = state.selectedMeeting
				? {
						...state.selectedMeeting,
						files: state.selectedMeeting.files?.filter((file) => file.id !== action.payload),
				  }
				: undefined;
			if (state.meetings) {
				const updatedMeetings = state.meetings.map((meeting) =>
					meeting.iCalUId === state.selectedMeeting?.iCalUId
						? { ...meeting, files: meeting.files?.filter((file) => file.id !== action.payload) }
						: meeting,
				);

				return {
					...state,
					selectedMeeting: updatedSelectedMeeting,
					meetings: updatedMeetings,
				};
			}
		},

		addFileSuccess: (state: MeetingsState, action: PayloadAction<FileContract>) => {
			const updatedSelectedMeeting = state.selectedMeeting
				? {
						...state.selectedMeeting,
						files: state.selectedMeeting.files
							? [...state.selectedMeeting.files, action.payload]
							: [action.payload],
				  }
				: undefined;
			const updatedMeetings = state.meetings?.map((meeting) =>
				meeting.iCalUId === state.selectedMeeting?.iCalUId
					? {
							...meeting,
							files: meeting.files ? [...meeting.files, action.payload] : [action.payload],
					  }
					: meeting,
			);

			return {
				...state,
				selectedMeeting: updatedSelectedMeeting,
				meetings: updatedMeetings,
			};
		},

		updateSelectedMeeting: (state: MeetingsState, action: PayloadAction<UpdateMeeting | undefined>) => {
			state.selectedMeeting = Object.assign({}, state.selectedMeeting, action.payload);
		},

		updateSelectedMeetingForMeetings: (state: MeetingsState, action: PayloadAction<UpdateMeeting | undefined>) => {
			const val = action.payload;
			if (state.meetings) {
				const meeting = state.meetings.find((m) => m.iCalUId === val?.IcalUid);

				if (meeting && val) {
					meeting.subject = val.subject;
					meeting.start = val.start ? val.start : new Date().toString();
					meeting.end = val.end ? val.end : new Date().toString();
					meeting.projectId = val.projectId;
					meeting.attendees = val.attendees as any;
					meeting.group = val.group as any;
					meeting.location = val.location;
					meeting.isOnlineMeeting = val.isOnlineMeeting;
					meeting.description = val.description;
				}
			}
		},

		updateSelectedMeetingCount: (state: MeetingsState, action: PayloadAction<UpdateMeetingCount | undefined>) => {
			const val = action.payload;
			if (state.meetings && val) {
				const meeting = state.meetings.find((m) => m.iCalUId === val.iCalUId);

				if (meeting && val) {
					meeting.countNotes = val.noteCount;
					meeting.countTodos = val.todoCount;
					meeting.countAgenda = val.agendaCount;
				}
			}
		},

		updateSelectedMeetingCountTodos: (state: MeetingsState, action: PayloadAction<string>) => {
			const val = action.payload;
			if (state.meetings && val) {
				const meeting = state.meetings.find((m) => m.iCalUId === val);

				if (meeting && val) {
					meeting.countTodos = meeting.countTodos ? +meeting.countTodos - 1 : 1;
				}
			}
		},

		deleteMeetingSuccess: (state: MeetingsState, action: PayloadAction<string>) => {
			state.meetings = state.meetings?.filter((meeting) => meeting.graphEventId !== action.payload);
		},

		updateFileAccessRightSuccess: (
			state: MeetingsState,
			action: PayloadAction<{
				fileId: string;
				accessRightTypeSelect: AccessRightType;
				accessRightCompanies: string[];
				displayName: string;
			}>,
		) => {
			const { fileId, accessRightTypeSelect, accessRightCompanies, displayName } = action.payload;
			const updatedSelectedMeeting = state.selectedMeeting
				? {
						...state.selectedMeeting,
						files: state.selectedMeeting.files?.map((file) => {
							if (file.id === fileId) {
								return {
									...file,
									accessRightType: accessRightTypeSelect,
									accessRightCompanies,
									displayName,
								};
							}
							return file;
						}),
				  }
				: undefined;
			const updatedMeetings = state.meetings?.map((meeting) =>
				meeting.iCalUId === state.selectedMeeting?.iCalUId
					? {
							...meeting,
							files: meeting.files?.map((file) => {
								if (file.id === fileId) {
									return {
										...file,
										accessRightType: accessRightTypeSelect,
										accessRightCompanies,
										displayName,
									};
								}
								return file;
							}),
					  }
					: meeting,
			);

			return {
				...state,
				selectedMeeting: updatedSelectedMeeting,
				meetings: updatedMeetings,
			};
		},

		addLastMeetings: (state, action: PayloadAction<MeetingContract[]>) => {
			const lastMeetings = action.payload;

			return {
				...state,
				meetingsOption: lastMeetings,
			};
		},
	},
	extraReducers(builder): void {
		builder.addCase(getMeetings.pending, (state) => {
			state.meetingsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getMeetings.fulfilled, (state, action) => {
			state.meetingsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.meetings = action.payload;
		});
		builder.addCase(getMeetings.rejected, (state, action) => {
			state.meetingsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getMeetingInformations.pending, (state) => {
			state.selectedMeetingInformationRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getMeetingInformations.fulfilled, (state, action) => {
			state.selectedMeetingInformationRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.selectedMeetingInformation = action.payload;
		});
		builder.addCase(getMeetingInformations.rejected, (state, action) => {
			state.selectedMeetingInformationRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getMeetingsForSelectOption.pending, (state) => {
			state.meetingsOptionRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getMeetingsForSelectOption.fulfilled, (state, action) => {
			state.meetingsOptionRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.meetingsOption = action.payload;
		});
		builder.addCase(getMeetingsForSelectOption.rejected, (state, action) => {
			state.meetingsOptionRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getMeetingsForAWeek.pending, (state) => {
			state.meetingsRequestStatusForWeek = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getMeetingsForAWeek.fulfilled, (state, action) => {
			state.meetingsRequestStatusForWeek = createRequestStatus(RequestStatusType.Success);
			state.meetingsForWeek = action.payload;
		});
		builder.addCase(getMeetingsForAWeek.rejected, (state, action) => {
			state.meetingsRequestStatusForWeek = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getMeetingsForCalendar.pending, (state) => {
			state.meetingForCalendarResquestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getMeetingsForCalendar.fulfilled, (state, action) => {
			state.meetingForCalendarResquestStatus = createRequestStatus(RequestStatusType.Success);
			state.meetingsForCalendar = action.payload;
		});
		builder.addCase(getMeetingsForCalendar.rejected, (state, action) => {
			state.meetingForCalendarResquestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(createMeeting.pending, (state) => {
			state.creatingMeetingsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(createMeeting.fulfilled, (state, action) => {
			state.creatingMeetingsRequestStatus = createRequestStatus(RequestStatusType.Success);
		});
		builder.addCase(createMeeting.rejected, (state, action) => {
			state.creatingMeetingsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(updateMeeting.pending, (state) => {
			state.updateMeetingsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(updateMeeting.fulfilled, (state, action) => {
			state.updateMeetingsRequestStatus = createRequestStatus(RequestStatusType.Success);
		});
		builder.addCase(updateMeeting.rejected, (state, action) => {
			state.updateMeetingsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(deleteMeeting.pending, (state) => {
			state.deleteMeetingsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(deleteMeeting.fulfilled, (state, action) => {
			state.deleteMeetingsRequestStatus = createRequestStatus(RequestStatusType.Success);
		});
		builder.addCase(deleteMeeting.rejected, (state, action) => {
			state.deleteMeetingsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getMeetingsForSeries.pending, (state) => {
			state.meetingsRequestStatusForSeries = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getMeetingsForSeries.fulfilled, (state, action) => {
			state.meetingsRequestStatusForSeries = createRequestStatus(RequestStatusType.Success);
			state.meetingsForSeriesState = action.payload;
		});
		builder.addCase(getMeetingsForSeries.rejected, (state, action) => {
			state.meetingsRequestStatusForSeries = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getLastMeetings.pending, (state) => {
			state.lastMeetingsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getLastMeetings.fulfilled, (state, action) => {
			state.lastMeetingsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.lastMeetings = action.payload;
		});
		builder.addCase(getLastMeetings.rejected, (state, action) => {
			state.lastMeetingsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = meetingsSlice;
export const {
	setSelectedMeeting,
	updateSelectedMeetingTags,
	updateSelectedMeetingFiles,
	updateFileAccessRightSuccess,
	addFileSuccess,
	deleteFilesSuccess,
	updateSelectedMeeting,
	deleteMeetingSuccess,
	reverseMeetings,
	reverseMeetingsOption,
	addLastMeetings,
	setPrevMeeting,
	setPrevMeetings,
	updateSelectedMeetingForMeetings,
	updateSelectedMeetingCount,
	updateSelectedMeetingCountTodos,
} = actions;
export default reducer;
