import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RegisterAPI from '../../api/register-api';
import { UpdateCurrentUserContract, UserContract } from '../user/type';
import { InvitationDataContract } from './type';
import {
	RequestStatus,
	RequestStatusType,
	SendInvitationContract,
	SendInvitationsContract,
	createRequestStatus,
} from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import SettingsAPI from '../../api/settings-api';

interface InvitationsState {
	invitationData?: InvitationDataContract;
	invitationDataRequestStatus: RequestStatus;
	invite?: UserContract;
	inviteUserRequestStatus: RequestStatus;
}

const initialState: InvitationsState = {
	invitationData: undefined,
	invitationDataRequestStatus: createRequestStatus(RequestStatusType.New),
	invite: undefined,
	inviteUserRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const sendInvitationUsers = createAsyncThunk(
	'register/users/invite',
	async (body: SendInvitationsContract): Promise<UserContract> => {
		return RegisterAPI.sendInvitation(body);
	},
);

export const completeInvitation = createAsyncThunk(
	'register/completeInvitation',
	async (updateCurrentUserContract: UpdateCurrentUserContract): Promise<UserContract> => {
		return RegisterAPI.registerInvite(updateCurrentUserContract);
	},
);

export const getInvitationData = createAsyncThunk(
	'register/invitation',
	async (invitationId: string): Promise<InvitationDataContract> => {
		return RegisterAPI.getInvitationData(invitationId);
	},
);

export const getInvitationLink = createAsyncThunk('invitation/link', async (userId: string): Promise<string> => {
	return SettingsAPI.getUserInvitationLink(userId);
});

export const sendInvitationUser = createAsyncThunk(
	'settings/users/invite',
	async (body: SendInvitationContract): Promise<UserContract> => {
		return SettingsAPI.sendInvitation(body);
	},
);

export const resendInvitationUser = createAsyncThunk(
	'settings/users/resendInvite',
	async (body: SendInvitationContract): Promise<UserContract> => {
		return SettingsAPI.resendInvitation(body);
	},
);

const slice = createSlice({
	name: 'invitations',
	initialState,
	reducers: {},
	extraReducers(builder): void {
		builder.addCase(getInvitationData.pending, (state) => {
			state.invitationDataRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getInvitationData.fulfilled, (state, action) => {
			state.invitationDataRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.invitationData = action.payload;
		});
		builder.addCase(getInvitationData.rejected, (state, action) => {
			state.invitationDataRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(sendInvitationUser.pending, (state) => {
			state.inviteUserRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(sendInvitationUser.fulfilled, (state, action) => {
			state.inviteUserRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.invite = action.payload;
		});
		builder.addCase(sendInvitationUser.rejected, (state, action) => {
			state.inviteUserRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { reducer } = slice;
export default reducer;
