import React from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import styles from './notes.comment.component.module.scss';
import { Avatar } from '../../../shared/utils/avatar';

interface Props {
    textValue: string,
    onChange: (e: any) => void, 
    mentionSuggestor: () => void,
}

const MentionComment: React.FC<Props> = ({ textValue, onChange, mentionSuggestor }) => {

    const renderSuggestion = (
        suggestion: SuggestionDataItem,
        search: string,
        highlightedDisplay: React.ReactNode,
        index: number,
        focused: boolean,
    ): React.ReactNode => {
        return (
            <div className={`suggestion-item ${focused ? 'focused' : ''} ${styles.mentionSuggestion}`} key={suggestion.id}>
                <Avatar name={suggestion.display ?? ''} mail={suggestion.id as string ?? ''} index={index} />{suggestion.display}
            </div>
        );
    };

    return (
        <MentionsInput
            spellCheck={false}
            value={textValue}
            onChange={onChange}
            className={styles.textArea}
            style={{ 
                suggestions: { 
                    marginLeft: '20px',
                    marginTop: '40px',
                    list: { 
                        top: '50px',
                        boxSadow: '1px 1px 5px rgba(0, 0, 0, 0.1)', 
                        borderRadius: '6px', 
                        overflow: 'hidden', 
                        border: '1px solid #edebe9' 
                    }, 
                } }}
        >
            <Mention
                markup='@[__display__](user:{__id__})'
                trigger='@'
                data={mentionSuggestor}
                displayTransform={(_, display) => `${display}`}
                renderSuggestion={renderSuggestion}
                className={styles.mention}
            />
        </MentionsInput>
    );
};

export default MentionComment;