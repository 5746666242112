import React from 'react';
import styles from './meeting-update-create.modal.module.scss';
import { useTranslation } from 'react-i18next';
import { getHour, getMin, getRightTimeSlot, getStartOfDayDate } from '../../../shared/utils/date.util';
import { MeetingContract } from '../../../store/meetings/types';
import DateSelectorComponent from './date-selector.component';

interface Props {
	date: Date;
	handleDatePicker: (date: Date) => void;
	close: () => void;
	meeting?: MeetingContract;
	type: string;
}

const MeetingFormDatePickerModal: React.FC<Props> = ({ date, handleDatePicker, close, meeting, type }) => {
	const { t } = useTranslation();
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [newDate, setnewDate] = React.useState(date);
	const [hour, setHour] = React.useState(
		meeting && type === 'start'
			? getHour(meeting.start)
			: meeting && type === 'end'
			? getHour(meeting.end)
			: getHour(date),
	);
	const [min, setMin] = React.useState(
		meeting && type === 'start'
			? getMin(meeting.start)
			: meeting && type === 'end'
			? getMin(meeting.end)
			: getMin(date),
	);
	const [mid, setMid] = React.useState('am');

	const handleDateSelect = (dateSelect: Date) => {
		setnewDate(dateSelect);
	};

	const confirmNewDate = () => {
		if (mid === 'am') {
			handleDatePicker(getRightTimeSlot(getStartOfDayDate(newDate), hour, min));
		} else {
			handleDatePicker(getRightTimeSlot(getStartOfDayDate(newDate), hour + 12, min));
		}
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					close();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close]);

	return (
		<>
			<div className={styles.datePicker} ref={modalRef}>
				<DateSelectorComponent selectedDate={newDate} onDateSelect={handleDateSelect} type='agenda' />
				<button onClick={() => confirmNewDate()} className={styles.confirm}>
					{t('Confirm')}
				</button>
			</div>
		</>
	);
};

export default MeetingFormDatePickerModal;
