import React, { useState } from 'react';
import styles from './copilot-todos.module.scss';
import { IconLeft } from '../../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { TodosContract } from '../../../../../../../store/todos/type';
import { Avatar } from '../../../../../../../shared/utils/avatar';
import { MistralTodoUpdate } from '../../../../../../../store/recall/type';
import { updateTodoMistral } from '../../../../../../../store/recall/slice';

export interface Props {
	handleClose: (index: string) => void;
	todo: TodosContract;
}

const CopilotTodosFormModal = ({ handleClose, todo }: Props) => {
	const { t } = useTranslation();
	const [animation, setAnimation] = useState<boolean>(false);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { projects } = useAppSelector((state) => state.project);
	const dispatch = useAppDispatch();
	const [modalAssignee, setModalAssignee] = React.useState(false);
	const modalRef = React.useRef<HTMLDivElement>(null);

	const [name, setName] = React.useState(todo.assigneeName);
	const [mail, setMail] = React.useState(todo.assigneeDisplayName);
	const [title, setTitle] = React.useState(todo.title);
	const [projectid, SetProjectId] = React.useState(todo.projectId);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose('');
			setAnimation(false);
		}, 300);
	};

	const handleModalAssignee = () => {
		setModalAssignee(!modalAssignee);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleModalAssignee();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleModalAssignee]);

	const handleChangeAssignee = (nameUp: string, mailUp: string) => {
		handleModalAssignee();
		setName(nameUp);
		setMail(mailUp);
	};

	const handleChangeProjectId = (id: string) => {
		SetProjectId(id);
	};

	const handleUpdate = async () => {
		const body: MistralTodoUpdate = {
			id: todo.id,
			newAttMail: mail,
			newAttName: name,
			newTitle: title,
			projectId: projectid,
		};

		dispatch(updateTodoMistral(body));
		closeModal();
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.close} onClick={() => closeModal()}>
					<IconLeft />
					{t('Back')}
				</div>
				<div>
					<div className={styles.form}>
						<div className={styles.assignee} onClick={() => handleModalAssignee()}>
							<div className={styles.containerAvatar}>
								<div className={styles.avatar}>
									<Avatar name={name} mail={mail} index={2} />
								</div>
								{modalAssignee && (
									<div className={styles.modalAssignee} ref={modalRef}>
										<div
											className={styles.row}
											onClick={() => handleChangeAssignee('Unasigned', '')}>
											<Avatar name={'Unasigned'} mail={''} index={2} />
											<div>{t('Unasigned')}</div>
										</div>
										{selectedMeeting &&
											selectedMeeting.attendees.map((att) => (
												<div
													key={att.emailAddress.address}
													className={styles.row}
													onClick={() =>
														handleChangeAssignee(
															att.emailAddress.name,
															att.emailAddress.address,
														)
													}>
													<Avatar
														name={att.emailAddress.name}
														mail={att.emailAddress.address}
														index={2}
													/>
													<div>{att.emailAddress.name}</div>
												</div>
											))}
									</div>
								)}
							</div>
							<div>{name}</div>
						</div>

						<div className={styles.inputWrapper}>
							<label htmlFor='Title'>{t('Title')}</label>
							<input type='text' value={title} onChange={(e) => setTitle(e.target.value)} />
						</div>

						<div className={styles.inputWrapper}>
							<label htmlFor='Project'>{t('Project')}</label>

							<select value={projectid} onChange={(e) => handleChangeProjectId(e.target.value)}>
								<option value=''></option>
								{projects &&
									projects.map((project) => (
										<option key={project.id} value={project.id}>
											{project.projectName}
										</option>
									))}
							</select>
						</div>
						<div className={styles.button}>
							<button onClick={() => handleUpdate()}>{t('Save')}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CopilotTodosFormModal;
