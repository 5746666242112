import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { TodosContract, TodosStates, UpdateTodo } from '../../../store/todos/type';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import { IconRightChev } from '../../../shared/utils/icon';
import { completeTodos, updateTodoState, updateTodoStateSelectMeeting } from '../../../store/todos/slice';
import styles from '../todos.module.scss';
import { SendNotifications } from '../../../store/notifications/slice';
import { NotificationsType } from '../../../store/notifications/type';
import { updateTodoStateProject, SendNotificationsProject } from '../../../store/project/slice';
import { NotificationsProjectType } from '../../../store/project/type';
import { updateTodosShared, updateTodoSharedState } from '../../../store/sharing/slice';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SectionType, useUpdateTodoMutation } from '../../../shared/hooks/use-todos-mutations.hook';
import { PayloadAction } from '@reduxjs/toolkit';

interface Props {
    todo: TodosContract,
    handleUpdate: (todo: TodosContract) => void,
    sectionType: SectionType,
    updateFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>,
}

const TitleCell: React.FC<Props> = ({ todo, handleUpdate, sectionType, updateFn }) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);
    const paramsID = useParams();
    const shareId = paramsID.shareID;
    const { t } = useTranslation();

    const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

    const [openTitleMenu, setOpenTitleMenu] = useState<boolean>(false);
    const [titleInputValue, setTitleInputValue] = useState<string>(todo.title);

    const titleInputModalRef = useRef<HTMLInputElement | null>(null);
    const titleInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (todo.id.slice(0, 4) === 'temp') {
            setOpenTitleMenu(true);
            setTimeout(() => {
                if (titleInputModalRef.current) {
                    titleInputModalRef.current.focus();
                }
            }, 0);
        }
    }, []);

    const updateTodoTitleMutation = useUpdateTodoMutation();

    const handleUpdateTitle = async () => {
        setOpenTitleMenu(false);

        if (todo.title === titleInputValue) {
            return;
        }

        const body = {
            ...todo,
            title: titleInputValue,
        };

        updateTodoTitleMutation.mutate({ id: todo.id, newTodo: body, oldTodo: todo, updateFn });
    };

    useClickOutside(titleInputRef, () => handleUpdateTitle());

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleUpdateTitle();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const onChangeComplete = (checked: boolean, todo: TodosContract) => {
        const body = {
            state: checked === true ? TodosStates.DONE : TodosStates.TODO,
            index: todo.orderAssignee,
        };

        const bodyUpdate = {
            todoId: todo.id,
            stateTodo: checked === true ? TodosStates.DONE : TodosStates.TODO,
        };

        if (user) {
            dispatch(updateTodoState(bodyUpdate));
            dispatch(updateTodoStateProject(bodyUpdate));
            dispatch(updateTodoStateSelectMeeting(bodyUpdate));

            dispatch(completeTodos({ body, todoId: todo.id })).then((res) => {
                if (res.meta.requestStatus === 'fulfilled') {
                    dispatch(updateTodoState(bodyUpdate));
                    dispatch(updateTodoStateProject(bodyUpdate));
                    dispatch(updateTodoStateSelectMeeting(bodyUpdate));

                    if (checked === true && user && user.graphUserId !== todo.graphUserId) {
                        const bodyNotification = {
                            id: undefined,
                            userFromName: user.displayName,
                            userFromMail: user.email,
                            userToName: todo.graphUserId,
                            userToMail: todo.graphUserId,
                            type: NotificationsType.TODO_COMPLETE,
                            noteId: undefined,
                            todoId: todo.id,
                            graphiCalUId: undefined,
                            projectId: undefined,
                            meetingStartDate: undefined,
                            itemTitle: todo.title,
                            visible: true,
                            meetingName: undefined,
                        };

                        dispatch(SendNotifications(bodyNotification));
                    }

                    if (checked === true && user && todo.projectId) {
                        const bodyNotificationProject = {
                            id: '',
                            userFromName: user.name,
                            userFromMail: user.email,
                            type: NotificationsProjectType.TODO_COMPLETE,
                            noteId: '',
                            todoId: todo.id,
                            todoDueDate: todo.dueDate,
                            itemTitle: todo.title,
                            graphiCalUId: '',
                            meetingStartDate: '',
                            meetingEndDate: '',
                            meetingName: '',
                            meetingAttendees: [],
                            projectId: todo.projectId,
                            userSeenNotif: [],
                            todoAssignee: todo.assigneeDisplayName,
                        };

                        dispatch(SendNotificationsProject(bodyNotificationProject));
                    }
                }
            });
        } else if (storedAccessMail?.toLowerCase() === todo.assigneeDisplayName.toLowerCase()) {
            const bodyComplete = TodosContract.toNewState(todo, TodosStates.DONE);

            dispatch(updateTodosShared({ body: bodyComplete, todoId: todo.id })).then(() => {
                dispatch(updateTodoSharedState(bodyComplete));

                if (checked === true && storedAccessMail && storedAccessMail !== todo.graphUserId) {
                    const bodyNotification = {
                        id: undefined,
                        userFromName: storedAccessMail,
                        userFromMail: storedAccessMail,
                        userToName: todo.graphUserId,
                        userToMail: todo.graphUserId,
                        type: NotificationsType.TODO_COMPLETE,
                        noteId: undefined,
                        todoId: todo.id,
                        graphiCalUId: undefined,
                        projectId: undefined,
                        meetingStartDate: undefined,
                        itemTitle: todo.title,
                        visible: true,
                        meetingName: undefined,
                    };

                    dispatch(SendNotifications(bodyNotification));
                }
            });
        }
    };

    return (
        <div className={styles.titleTodo} ref={titleInputRef} onClick={() => {
            setOpenTitleMenu(true);
            setTimeout(() => {
                titleInputModalRef.current?.focus();
            }, 0);
        }}>
            <div className={styles.flex}>
                <div className={styles.todoChekbox}>
                    <input
                        type='checkbox'
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            onChangeComplete(e.target.checked, todo);
                            e.stopPropagation();
                        }}
                        checked={todo.state === TodosStates.DONE ? true : false}
                    />
                </div>
                <p style={{ opacity: todo.state === TodosStates.DONE ? .5 : 1 }} className={styles.titleTodoInput}>{todo.title}</p>
            </div>
            {openTitleMenu &&
                <ModalLayout top='0' left='21px' right='0' bottom='0'>
                    <div className={styles.inputTitleModalContainer}>
                        <input
                            spellCheck={false}
                            ref={titleInputModalRef}
                            value={titleInputValue}
                            onKeyDown={handleKeyDown}
                            onInput={(e) => {
                                setTitleInputValue(e.currentTarget.value);
                            }}
                            className={styles.modalTitleInput} type='text'
                        />
                    </div>
                </ModalLayout>
            }
            <div className={styles.titleTodoIcon} onClick={(e) => {
                e.stopPropagation();
                handleUpdate(todo);
            }}>
                <IconRightChev height='14' width='14' />
            </div>
        </div>
    );
};

export default TitleCell;