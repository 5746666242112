export class NotificationsContract {
	constructor(
		public id: string,
		public userFromName: string,
		public userFromMail: string,
		public userToName: string,
		public userToMail: string,
		public type: NotificationsType,
		public noteId: string,
		public todoId: string,
		public graphiCalUId: string,
		public projectId: string,
		public meetingStartDate: string,
		public itemTitle: string,
		public visible: boolean,
		public meetingName: string,
		public isNew: boolean,
		public seen: boolean,
		public createdOn: Date,
	) { }
}

export class NotificationsSendContract {
	constructor(
		public id: string | undefined,
		public userFromName: string | undefined,
		public userFromMail: string | undefined,
		public userToName: string | undefined,
		public userToMail: string | undefined,
		public type: NotificationsType,
		public noteId: string | undefined,
		public todoId: string | undefined,
		public graphiCalUId: string | undefined,
		public projectId: string | undefined,
		public meetingStartDate: string | undefined,
		public itemTitle: string | undefined,
		public visible: boolean,
		public meetingName: string | undefined,
	) { }
}

export enum NotificationsType {
	NEW_TODO = 'NEW_TODO',
	TODO_COMPLETE = 'TODO_COMPLETE',
	NEW_NOTE = 'NEW_NOTE',
	NEW_MEETING_NOTE = 'NEW_MEETING_NOTE',
	NEW_PROJECT_NOTE = 'NEW_PROJECT_NOTE',
	NEW_PROJECT = 'NEW_PROJECT',
	NEW_MEETING = 'NEW_MEETING',
	NEW_COMMENT = 'NEW_COMMENT',
	MENTION = 'MENTION',
}
