import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import styles from './note-connect.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ProjectContract } from '../../../store/project/type';
import { IconClose, IconLink } from '../../../shared/utils/icon';
import { getDateFormatL } from '../../../shared/utils/date.util';
import { NotesContract } from '../../../store/notes/type';
import { updateNotes } from '../../../store/notes/slice';

export interface Props {
	handleClose: () => void;
	note: NotesContract;
	handleNote: (data: NotesContract) => void;
}

const NotesProjectConnect = ({ handleClose, note, handleNote }: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { projects } = useAppSelector((state) => state.project);
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleClose();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClose]);

	type FormData = {
		project: ProjectContract;
	};

	const dataSchema = yup.object().shape({
		project: yup.object(),
	});

	const { handleSubmit, control } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const onSubmit = (data: FormData) => {
		if (data.project && user) {
			const body = {
				id: note.id,
				title: note.title,
				text: note.text,
				graphUserId: note.graphUserId,
				graphUserName: note.graphUserName,
				meetingAttendees: note.meetingAttendees,
				graphiCalUId: note.graphiCalUId,
				meetingTitle: note.meetingTitle,
				meetingStartDate: note.meetingStartDate,
				tags: note.tags,
				updateNote: new Date(),
				createdOn: note.createdOn,
				updateOn: note.updateOn,
				accessRightType: note.accessRightType,
				accessRightCompanies: note.accessRightCompanies,
				archived: note.archived,
				projectId: data.project ? data.project.id : note.projectId,
			};

			dispatch(updateNotes({ noteId: note.id, body })).then((res) => {
				const newBody = res.payload as NotesContract;
				handleNote(newBody);
				closeModal();
			});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.titleWrapper}>
					<h3>
						<IconLink />
						{t('Connected project')}
					</h3>
					<div className={styles.closeButton}>
						<div
							className={styles.icon}
							onClick={() => {
								closeModal();
							}}>
							<IconClose />
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.dueDateAu}>
						{user && projects && note && (
							<Controller
								name='project'
								control={control}
								defaultValue={projects.find((project) => project.id === note.projectId)}
								render={({ field }) => (
									<Stack spacing={2} sx={{ width: '100%' }}>
										<Autocomplete
											id='project'
											disableClearable
											{...field}
											onChange={(event, newValue) => {
												field.onChange(newValue);
											}}
											options={projects.map((project) => project)}
											getOptionLabel={(project: ProjectContract) =>
												`${project.projectName} (${getDateFormatL(project.createdOn)})`
											}
											renderInput={(param) => (
												<TextField
													placeholder={t<string>('Connected project')}
													sx={{
														'& .MuiInputBase-root': {
															padding: 0,
															margin: 0,
														},
														'& .MuiOutlinedInput-root': {
															border: 'none',
															paddingLeft: '14px',
															paddingBlock: '12px',
															fontSize: '14px',
														},
														'& .MuiOutlinedInput-notchedOutline': {
															border: 'none',
														},
														'& .MuiSvgIcon-fontSizeMedium': {
															padding: 0,
														},
														'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
															{
																padding: 0,
																margin: 0,
															},
														'borderRadius': '6px',
														'& .MuiSvgIcon-root': {
															'height': '100%',
															'padding': 0,
															'margin': 0,
															'color': 'black',
															'transform': 'scale(1.3)',
															'&:hover': {
																backgroundColor: 'white',
															},
														},
														'border': 'transparent',
														'& input::placeholder': {
															opacity: 1,
															fontStyle: 'italic',
															color: '#707070',
														},
													}}
													{...param}
												/>
											)}
										/>
									</Stack>
								)}
							/>
						)}
					</div>
					<div className={styles.btnContainer}>
						<button className={styles.addBtn}>{t('Save')}</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default NotesProjectConnect;
