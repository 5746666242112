import { PlainExtension } from 'remirror';
import { CommandFunction } from '@remirror/core';

import * as Y from 'yjs';
interface YjsSyncExtensionOptions {
	doc: Y.Doc;
}

export class YjsSyncExtension extends PlainExtension<YjsSyncExtensionOptions> {
	private doc: Y.Doc;
	constructor(options: YjsSyncExtensionOptions) {
		super(options);
		this.doc = options.doc;
	}

	get name() {
		return 'yjsSync' as const;
	}

	createCommands() {
		return {
			syncYjs:
				(): CommandFunction =>
				({ state, dispatch }) => {
					if (!this.doc) {
						console.error('Yjs document is undefined');
						return false;
					}

					if (dispatch) {
						try {
							// Find a safe position at the start of the document
							const firstTextBlock = state.doc.firstChild;
							if (!firstTextBlock) return false;

							const pos = 1; // Position after the first node start

							// Create a transaction for insertion of zero-width space
							const tr = state.tr.insertText('\u200B', pos);
							dispatch(tr);
							// console.log('Sync triggered');
							return true;
						} catch (error) {
							console.info('Error during sync:', error);
							return false;
						}
					}
					return true;
				},
		};
	}
}
