import React from 'react';
import styles from './tags-form.modal.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TagContract } from '../../../store/meetings/types';
import { useId } from '@fluentui/react-hooks';
import { SwatchColorPicker } from '@fluentui/react/lib/SwatchColorPicker';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TodosContract } from '../../../store/todos/type';
import { updateUserTags } from '../../../store/user/slice';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import { ProjectContract } from '../../../store/project/type';

export interface TagsModalProps {
	close: (tag: string, color: string) => void;
	handleSimpleClose: () => void;
	type: string;
	todo?: TodosContract;
	project?: ProjectContract;
}

const colorCells = [
	{ id: '#D50101', label: 'Red', color: '#D50101' },
	{ id: '#3F45EA', label: 'Blue', color: '#3F45EA' },
	{ id: '#F4511E', label: 'Orange', color: '#F4511E' },
	{ id: '#F6BF25', label: 'Yellow', color: '#F6BF25' },
	{ id: '#33B679', label: 'Light green', color: '#33B679' },
	{ id: '#0A8043', label: 'Green', color: '#0A8043' },
	{ id: '#7886CB', label: 'Light purple', color: '#7886CB' },
	{ id: '#6F00FF', label: 'Purple', color: '#6F00FF' },
];

const TagsFormModal: React.FC<TagsModalProps> = ({ close, handleSimpleClose, type, todo, project }) => {
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { note } = useAppSelector((state) => state.notes);
	const dispatch = useAppDispatch();

	type FormData = {
		text: string;
		previewColor: string;
	};

	const dataSchema = yup.object().shape({
		text: yup.string().required(),
		previewColor: yup.string(),
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const modalRef = React.useRef<HTMLDivElement>(null);
	const modalRefColor = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleSimpleClose();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleSimpleClose]);

	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);

	// ----- color swatch

	const baseId = useId('colorpicker');
	const [colorPick, setColorPick] = React.useState<string | undefined>('#D50101');
	const [showPickColors, setShowPickColors] = React.useState(false);

	const handleShowColors = () => {
		setShowPickColors(!showPickColors);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefColor.current && !modalRefColor.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowColors();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowColors]);

	// ------

	const handleCloseWithAlreadyUsed = (textI: string, colorI: string) => {
		close(textI, colorI);
	};

	const onSubmit = (data: FormData) => {
		if (data.previewColor) {
			close(data.text, data.previewColor);
		} else {
			close(data.text, '#D50101');
		}
		if (user && selectedMeeting && type === 'meetings') {
			const newTag = new TagContract(
				undefined,
				data.text,
				data.previewColor ? data.previewColor : '#D50101',
				user!.graphUserId,
				[selectedMeeting.iCalUId],
				[],
				[],
				[],
			);

			dispatch(updateUserTags(newTag));
		} else if (user && type === 'newMeeting') {
			const newTag = new TagContract(
				undefined,
				data.text,
				data.previewColor ? data.previewColor : '#D50101',
				user!.graphUserId,
				[],
				[],
				[],
				[],
			);

			dispatch(updateUserTags(newTag));
		} else if (user && note && type === 'notes') {
			const newTag = new TagContract(
				undefined,
				data.text,
				data.previewColor ? data.previewColor : '#D50101',
				user!.graphUserId,
				[],
				[note.id],
				[],
				[],
			);

			dispatch(updateUserTags(newTag));
		} else if (user && todo && type === 'todos') {
			// const newTag = new TagContract(
			// 	undefined,
			// 	data.text,
			// 	data.previewColor ? data.previewColor : '#D50101',
			// 	user!.graphUserId,
			// 	[],
			// 	[],
			// 	[todo.id],
			// 	[],
			// );
			// dispatch(updateUserTags(newTag));
		}
	};

	return (
		<div className={styles.container} ref={modalRef}>
			<h3>{t('Select tags')}</h3>
			<form>
				<div className={styles.input}>
					<input {...register('text')} />
					<div className={styles.pickerColorWrapper}>
						<div className={styles.pickColor} style={{ cursor: 'pointer' }}>
							<div
								style={{
									backgroundColor: colorPick,
									width: '20px',
									height: '20px',
									borderRadius: '50%',
									position: 'relative',
									zIndex: 100,
									transition: '300ms',
									boxShadow: showPickColors ? '1px 1px 2px grey' : 'none',
								}}
								onClick={() => {
									handleShowColors();
								}}></div>
							<div></div>
						</div>

						{showPickColors && (
							<div className={styles.colors} ref={modalRefColor}>
								<Controller
									name='previewColor'
									control={control}
									render={({ field }) => (
										<SwatchColorPicker
											columnCount={colorCells.length}
											cellShape={'circle'}
											colorCells={colorCells}
											aria-labelledby={`${baseId}-circle`}
											onChange={(id, color) => {
												field.onChange(color);
												setColorPick(color);
												handleShowColors();
											}}
											selectedId={field.value}
										/>
									)}
								/>
							</div>
						)}
						<button type='button' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
							{t('Confirm')}
						</button>
					</div>
				</div>
				<p>{t('Already used')}</p>

				<div className={styles.tags}>
					{type === 'meetings' &&
						user?.tags
							.filter((tag: TagContract) => !selectedMeeting?.tags.some((mt) => mt.title === tag.title))
							.map((tag: TagContract, index) => (
								<div key={index}>
									<div
										onClick={() => {
											handleCloseWithAlreadyUsed(tag.title, tag.color);
										}}
										className={styles.tag}
										style={{
											backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
											border: `1px solid ${tag.color}`,
										}}>
										<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
									</div>
								</div>
							))}

					{type === 'newMeeting' &&
						user?.tags.map((tag: TagContract, index) => (
							<div key={index}>
								<div
									onClick={() => {
										handleCloseWithAlreadyUsed(tag.title, tag.color);
									}}
									className={styles.tag}
									style={{
										backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
										border: `1px solid ${tag.color}`,
									}}>
									<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
								</div>
							</div>
						))}

					{type === 'notes' &&
						user?.tags
							.filter((tag: TagContract) => !note?.tags.some((mt) => mt.title === tag.title))
							.map((tag: TagContract, index) => (
								<div key={index}>
									<div
										onClick={() => {
											handleCloseWithAlreadyUsed(tag.title, tag.color);
										}}
										className={styles.tag}
										style={{
											backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
											border: `1px solid ${tag.color}`,
										}}>
										<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
									</div>
								</div>
							))}
					{type === 'todos' &&
						user?.tags
							.filter((tag: TagContract) => !todo?.tags.some((mt) => mt.title === tag.title))
							.map((tag: TagContract, index) => (
								<div key={index}>
									<div
										onClick={() => {
											handleCloseWithAlreadyUsed(tag.title, tag.color);
										}}
										className={styles.tag}
										style={{
											backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
											border: `1px solid ${tag.color}`,
										}}>
										<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
									</div>
								</div>
							))}
					{type === 'project' &&
						user?.tags
							.filter((tag: TagContract) => !project?.tags.some((mt) => mt.title === tag.title))
							.map((tag: TagContract, index) => (
								<div key={index}>
									<div
										onClick={() => {
											handleCloseWithAlreadyUsed(tag.title, tag.color);
										}}
										className={styles.tag}
										style={{
											backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
											border: `1px solid ${tag.color}`,
										}}>
										<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
									</div>
								</div>
							))}

					{type === 'newProject' &&
						user?.tags.map((tag: TagContract, index) => (
							<div key={index}>
								<div
									onClick={() => {
										handleCloseWithAlreadyUsed(tag.title, tag.color);
									}}
									className={styles.tag}
									style={{
										backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
										border: `1px solid ${tag.color}`,
									}}>
									<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
								</div>
							</div>
						))}
				</div>
			</form>
		</div>
	);
};

export default TagsFormModal;
