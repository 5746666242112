import React from 'react';
import styles from '../date-details.component.module.scss';
import ShimmerText from '../../../../../shared/components/shimmers/shimmer-text.component';

const DateDetailsShimmer = () => {
    return (
        <div
            className={styles.meeting}>
            <div>
                <ShimmerText height={20} width={90} />
            </div>
            <p className={styles.title}><ShimmerText height={20} width={90} /></p>
        </div>
    );
};

export default DateDetailsShimmer;