/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { TodosContract, TodosStates } from '../../../store/todos/type';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import styles from './todos.form.modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { IconClose, IconEdit, IconMeetings, IconSearch, IconSimplePlus, IconTodos } from '../../../shared/utils/icon';
import { getDateFormatL, getDateFormatWithYear } from '../../../shared/utils/date.util';
import {
	addTags,
	addTodosAssignee,
	createTodos,
	deleteTagsFromTodo,
	deleteTodo,
	deleteTodosAssignee,
	updateTodoAssignee,
	updateTodoNotes,
	updateTodoSelectMeeting,
	updateTodoSelectMeetingPrev,
	updateTodos,
} from '../../../store/todos/slice';
import { MeetingContract, TagContract } from '../../../store/meetings/types';
import TagsFormModal from '../../meetings/modals/tags-form.modal';
import { addLastMeetings, getLastMeetings, getMeetingsForSelectOption } from '../../../store/meetings/slice';
import {
	updateTodoMeetingPrevShared,
	updateTodoMeetingShared,
	updateTodoNotesShared,
	updateTodosShared,
	updateTodoTitle,
	updateTodoTitleNoteShared,
} from '../../../store/sharing/slice';
import { Avatar } from '../../../shared/utils/avatar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { RouteUrls } from '../../../routes/routes-config';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import SimpleEditor from '../../../shared/utils/simple-editor';
import { ContactsGraphContract } from '../../../store/user/type';
import { SendNotificationsProject, updateTodoProject } from '../../../store/project/slice';
import { NotificationsProjectType, ProjectContract } from '../../../store/project/type';
import { NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';
import Input, { Items } from '../../../shared/components/input/input.component';
import MeetingFormDatePickerModal from '../../meetings/modals/meeting-form-date-picker.modal';
import FiveDaysBtn from '../../../shared/components/buttons/fiveDaysBtn/five-days-btn.component';
import OutlinedButton from '../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import AttendeesInputTodo from '../../../shared/components/input/attendees-input-todo.component';
import { PersonaSize } from '@fluentui/react';
import { Attendees } from '../../meetings/modals/meeting-create.modal';

interface Props {
	todo: TodosContract;
	userId: string;
	handleToggle: () => void;
	handleClose: () => void;
	attendees?: { name: string; email: string }[];
	syncYjs?: () => void;
}

const TodosFormComponent: React.FC<Props> = ({ todo, userId, handleToggle, handleClose, attendees, syncYjs }) => {
	const location = useLocation();
	const { contacts, user } = useAppSelector((state) => state.user);
	const { meetingsOption } = useAppSelector((state) => state.meetings);
	const { projects } = useAppSelector((state) => state.project);
	const [updatedTodo, setUpdatedTodo] = React.useState(todo);
	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);
	const [newTagsC, setNewTagsC] = React.useState<Array<{ title: string; color: string }>>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const modalFormRef = useRef<HTMLDivElement | null>(null);

	const submitTodoUpdate = () => {
		if (user) {
			dispatch(updateTodos({ body: updatedTodo, todoId: updatedTodo.id })).then(() => {
				if (updatedTodo.noteId) {
					dispatch(updateTodoTitle({ todoId: updatedTodo.id, newTitle: updatedTodo.title }));
					syncYjs && syncYjs();
				}
				handleClose();
			});
		} else {
			dispatch(updateTodosShared({ body: updatedTodo, todoId: updatedTodo.id })).then(() => {
				if (updatedTodo.noteId) {
					dispatch(updateTodoTitle({ todoId: updatedTodo.id, newTitle: updatedTodo.title }));
					syncYjs && syncYjs();
				}
				handleClose();
			});
		}
	};

	const stateTitles = {
		[TodosStates.TODO]: t('To do') as string,
		[TodosStates.DOING]: t('Doing') as string,
		[TodosStates.BLOCKED]: t('Blocked') as string,
		[TodosStates.DONE]: t('Done') as string,
	};

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	const [meetingTodo, setMeetingTodo] = React.useState<MeetingContract>();

	const close = () => {
		if (location.search.includes('?create')) {
			dispatch(deleteTodo(updatedTodo.id)).then(() => {
				dispatch(deleteTodosAssignee(updatedTodo.id));
			});
		}
		handleClose();
	};

	React.useEffect(() => {
		dispatch(getLastMeetings()).then((res) => {
			const m = res.payload as MeetingContract[];

			dispatch(addLastMeetings(m));
		});
		if (updatedTodo && updatedTodo.graphiCalUId !== null) {
			const body = {
				graphEventId: updatedTodo.meetingGraphId,
				iCalUId: updatedTodo.graphiCalUId,
				subject: updatedTodo.meetingName,
				start: updatedTodo.meetingStartDate,
			};

			setMeetingTodo(body as MeetingContract);
		}
	}, []);

	// --------------

	const [timeType, setTimeType] = React.useState('minutes');

	type FormData = {
		name: string;
		assignee: string;
		assigneeName: string;
		description: string;
		dueDate: Date;
		meetingId: string;
		duration: number;
		timeType: string;
		meetingName: string;
		meetingStartDate: string;
		meeting: MeetingContract;
		state: TodosStates;
		project: ProjectContract;
	};

	const dataSchema = yup.object().shape({});

	const { register, handleSubmit, control, setValue, trigger } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	// -------------

	const onSubmit = (data: FormData) => {
		if (updatedTodo && user) {
			if (location.search.includes('?create')) {
				const attendeeEmails = data.meeting
					? data.meeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
					: updatedTodo.meetingAttendees;
				const body = {
					id: undefined,
					title: data.name,
					text: data.description,
					state: data.state,
					dueDate: new Date(data.dueDate).toUTCString(),
					duration: timeType === 'minutes' ? data.duration : data.duration / 60,
					graphUserId: updatedTodo.graphUserId,
					noteId: undefined,
					assigneeDisplayName: data.assignee ? data.assignee : updatedTodo.assigneeDisplayName,
					graphiCalUId: data.meeting ? data.meeting.iCalUId : updatedTodo.graphiCalUId,
					meetingGraphId: data.meeting ? data.meeting.graphEventId : updatedTodo.meetingGraphId,
					meetingName: data.meeting ? data.meeting.subject : updatedTodo.meetingName,
					meetingStartDate: data.meeting ? data.meeting.start : updatedTodo.meetingStartDate,
					createdOn: new Date(),
					tags: undefined,
					assigneeName: data.assigneeName ? data.assigneeName : updatedTodo.assigneeName,
					archived: updatedTodo.archived,
					projectId: data.project?.id || '',
					meetingAttendees: attendeeEmails,
					columnUserId: null,
					columnProjectId: null,
				};

				dispatch(createTodos(body)).then((res) => {
					const todoN = res.payload as any;
					if (user) {
						const newTodo = {
							id: todoN.id,
							title: todoN.title,
							text: todoN.text,
							state: todoN.state,
							dueDate: todoN.dueDate,
							duration: todoN.duration,
							graphUserId: todoN.graphUserId,
							noteId: todoN.noteId,
							assigneeDisplayName: todoN.assigneeDisplayName,
							graphiCalUId: todoN.graphiCalUId,
							meetingGraphId: todoN.meetingGraphId,
							meetingName: todoN.meetingName,
							meetingStartDate: todoN.meetingStartDate,
							createdOn: new Date().toISOString(),
							tags: newTagsC,
							assigneeName: todoN.assigneeName,
							archived: todoN.archived,
							projectId: todoN.projectId,
							meetingAttendees: todoN.meetingAttendees,
							columnUserId: null,
							columnProjectId: null,
						};
						dispatch(addTodosAssignee(newTodo));

						if (todoN.assigneeDisplayName !== user.userName) {
							const bodyNotification: any = {
								id: undefined,
								userFromName: user.displayName,
								userFromMail: user.userName,
								userToName: todoN.assigneeName,
								userToMail: todoN.assigneeDisplayName,
								type: NotificationsType.NEW_TODO,
								noteId: undefined,
								todoId: todoN.id,
								graphiCalUId: undefined,
								meetingStartDate: undefined,
								itemTitle: todoN.title,
								visible: true,
								meetingName: undefined,
							};

							dispatch(SendNotifications(bodyNotification));
						}

						if (newTags.length !== 0) {
							newTags.forEach((tag) => {
								const bodyTag = {
									tag: tag.tag,
									color: tag.color,
									todoId: todoN.id,
								};

								dispatch(addTags(bodyTag));
							});
						}

						navigate(RouteUrls.Todos);
					}
				});
			} else {
				const attendeeEmails = data.meeting
					? data.meeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
					: updatedTodo.meetingAttendees;

				const body: TodosContract = {
					...updatedTodo,
					id: updatedTodo.id,
					title: data.name,
					text: data.description,
					state: data.state,
					dueDate: new Date(data.dueDate).toUTCString(),
					duration: timeType === 'minutes' ? data.duration : data.duration / 60,
					graphUserId: updatedTodo.graphUserId,
					noteId: updatedTodo.noteId,
					assigneeDisplayName: data.assignee,
					graphiCalUId: data.meeting ? data.meeting.iCalUId : updatedTodo.graphiCalUId,
					meetingGraphId: data.meeting ? data.meeting.graphEventId : updatedTodo.meetingGraphId,
					meetingName: data.meeting ? data.meeting.subject : updatedTodo.meetingName,
					meetingStartDate: data.meeting ? data.meeting.start : updatedTodo.meetingStartDate,
					createdOn: new Date(),
					tags: updatedTodo.tags,
					assigneeName: data.assigneeName,
					archived: updatedTodo.archived,
					projectId: data.project?.id || '',
					meetingAttendees: attendeeEmails,
				};

				// Mettre à jour le state local avant l'envoi
				setUpdatedTodo(body);

				dispatch(updateTodos({ body, todoId: updatedTodo.id })).then((res) => {
					if (res.meta.requestStatus === 'fulfilled') {
						const newTodo = res.payload as TodosContract;

						dispatch(updateTodoAssignee({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoSelectMeeting({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoSelectMeetingPrev({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoNotes({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoProject({ todoId: newTodo.id, newTodo }));

						// Trigger sync après toutes les mises à jour
						if (newTodo.noteId && syncYjs) {
							syncYjs();
						}

						if (data.project?.id && newTodo.projectId !== todo.projectId) {
							const bodyNotification = {
								id: '',
								userFromName: user.name,
								userFromMail: user.email,
								type: NotificationsProjectType.NEW_TODO,
								noteId: '',
								todoId: updatedTodo.id,
								todoDueDate: new Date(data.dueDate).toUTCString(),
								itemTitle: data.name,
								graphiCalUId: '',
								meetingStartDate: '',
								meetingEndDate: '',
								meetingName: '',
								meetingAttendees: [],
								projectId: data.project.id,
								userSeenNotif: [],
								todoAssignee: data.assignee,
							};

							dispatch(SendNotificationsProject(bodyNotification));
						}

						if (location.search.includes('?view')) {
							navigate(RouteUrls.Todos);
						}
					}
					handleClose();
				});
			}
		} else if (storedAccessMail) {
			const body = {
				id: updatedTodo.id,
				title: data.name,
				text: data.description,
				state: data.state,
				dueDate: new Date(data.dueDate).toUTCString(),
				duration: timeType === 'minutes' ? data.duration : data.duration / 60,
				graphUserId: updatedTodo.graphUserId,
				noteId: updatedTodo.noteId,
				assigneeDisplayName: data.assignee,
				graphiCalUId: updatedTodo.graphiCalUId,
				meetingGraphId: updatedTodo.meetingGraphId,
				meetingName: updatedTodo.meetingName,
				meetingStartDate: updatedTodo.meetingStartDate,
				createdOn: updatedTodo.createdOn,
				tags: [],
				assigneeName: data.assigneeName,
				archived: updatedTodo.archived,
				projectId: updatedTodo.projectId,
				meetingAttendees: updatedTodo.meetingAttendees,
			};

			// Mettre à jour le state local avant l'envoi
			setUpdatedTodo(body);

			dispatch(updateTodosShared({ body, todoId: updatedTodo.id })).then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const newTodo = res.payload as TodosContract;

					dispatch(updateTodoMeetingShared({ todoId: newTodo.id, newTodo }));
					dispatch(updateTodoMeetingPrevShared({ todoId: newTodo.id, newTodo }));
					dispatch(updateTodoNotesShared({ todoId: newTodo.id, newTodo }));

					// Trigger sync après toutes les mises à jour
					if (newTodo.noteId && syncYjs) {
						syncYjs();
					}
				}
				handleClose();
			});
		}
	};

	let timeoutId: any;

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	// -----

	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);

	const deleteTag = (tag: TagContract) => {
		if (tag) {
			dispatch(deleteTagsFromTodo({ todoId: updatedTodo.id, tagsId: tag.id as string })).then(() => {
				setUpdatedTodo((prevTodo) => {
					const updatedTags = prevTodo.tags.filter((td) => td.id !== tag.id);
					return { ...prevTodo, tags: updatedTags };
				});
			});

			const updatesTags = newTags.filter((tagToDelete) => tagToDelete.tag !== tag.title);
			setNewTags(updatesTags);
			const updatesTagsC = newTagsC.filter((tagToDelete) => tagToDelete.title !== tag.title);
			setNewTagsC(updatesTagsC);
		}
	};

	const closeTagsModal = (tag: string, color: string) => {
		if (updatedTodo) {
			const body = {
				tag,
				color,
				todoId: updatedTodo.id,
			};

			dispatch(addTags(body)).then((res) => {
				setUpdatedTodo((prevTodo) => {
					const updatedTags = [...prevTodo.tags, res.payload as TagContract];
					return { ...prevTodo, tags: updatedTags };
				});
				setTagsModalOpen(false);
			});

			const newTagTB = { tag, color };
			const newTagTBC = { title: tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			const updatedTagsToBeCreatedC = [...newTagsC, newTagTBC];
			setNewTags(updatedTagsToBeCreated);
			setNewTagsC(updatedTagsToBeCreatedC);
		}
	};

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};
	// ----

	const onChangeHandlerMeeting = (filter: string) => {
		const clearFilter = filter.replace(/\(\d{2}\/\d{2}\/\d{4}\)|[éè\\/]/g, '');

		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getMeetingsForSelectOption(clearFilter));
		}, 200);
	};

	const handleTextChange = (content: string) => {
		setValue('description', content);
		trigger('description');
	};

	useEffect(() => {
		setValue('name', updatedTodo.title);
	}, []);

	const [datePicker, setDatePicker] = React.useState(false);

	const handleDatePicker = () => {
		setDatePicker(!datePicker);
	};

	const [date, setDate] = React.useState(todo.dueDate ? new Date(todo.dueDate) : undefined);

	const handleDateSelect = (dateSelectPicker: Date) => {
		setDate(dateSelectPicker);
		setValue('dueDate', dateSelectPicker);
		trigger('dueDate');
		handleDatePicker();
	};

	const handleChangeMeeting = (item: Items | undefined) => {
		const selectedMeeting = meetingsOption?.find((itemMeeting) => itemMeeting.iCalUId === item?.id);

		if (selectedMeeting) {
			setValue('meeting', selectedMeeting);
		}
	};

	const handleAttendee = (item: Items | undefined) => {
		setUpdatedTodo({
			...updatedTodo,
			assigneeDisplayName: item?.subTitle ?? '',
			assigneeName: item?.title ?? '',
		});
		setValue('assignee', item?.subTitle ?? '');
		setValue('assigneeName', item?.title ?? '');
	};

	const handleStateValue = (item: Items | undefined) => {
		if (item) setValue('state', item?.id as TodosStates);
	};

	const handleChangeProject = (item: Items | undefined) => {
		const selectedProject = projects?.find((project) => project.id === item?.id);

		if (selectedProject) {
			setValue('project', selectedProject);
		}
	};

	const currentPath = window.location.pathname;
	const todosPagePath = RouteUrls.Todos;
	const renderTodos = currentPath === todosPagePath || currentPath === todosPagePath + '/';

	return (
		<>
			<div ref={modalFormRef}>
				{userId === updatedTodo.graphUserId ? (
					<h3>
						<span>
							<IconTodos />
						</span>

						{location.search.includes('?create') ? (
							<> {t('Create your todo')}</>
						) : (
							<>{t('Update your todo')}</>
						)}
					</h3>
				) : user?.userName === updatedTodo.assigneeDisplayName ? (
					<h3>
						<span>
							<IconTodos />
						</span>

						{location.search.includes('?create') ? (
							<> {t('Create your todo')}</>
						) : (
							<>{t('Update your todo')}</>
						)}
					</h3>
				) : userId === updatedTodo.assigneeDisplayName ? (
					<h3>
						<span>
							<IconTodos />
						</span>

						{location.search.includes('?create') ? (
							<> {t('Create your todo')}</>
						) : (
							<>{t('Update your todo')}</>
						)}
					</h3>
				) : (
					<h3>
						<span>
							<IconTodos />
						</span>
						{t('Todos')}
					</h3>
				)}

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.inputWrapper}>
						<Input
							placeHolder={t('Title')}
							register={register('name')}
							defaultValue={{ title: updatedTodo.title }}
						/>
					</div>
					<div>
						<AttendeesInputTodo
							attendee={{ title: updatedTodo.assigneeName, subTitle: updatedTodo.assigneeDisplayName }}
							renderModal={(attendees ?? []).map((attendee, index) => ({
								leading: (
									<Avatar
										size={PersonaSize.size32}
										name={attendee.name}
										mail={attendee.email}
										index={index}
									/>
								),
								id: attendee.email,
								title: attendee.name,
								subTitle: attendee.email,
							}))}
							getValue={handleAttendee}
						/>
					</div>
					<input
						style={{ visibility: 'hidden', display: 'none' }}
						type='text'
						defaultValue={updatedTodo ? updatedTodo.dueDate : undefined}
						{...register('dueDate')}
					/>
					<div>
						<Input
							placeHolder={t<string>('Meeting')}
							defaultValue={{ title: updatedTodo.meetingName ?? '' }}
							getValue={handleChangeMeeting}
							onChange={(e) => onChangeHandlerMeeting(e.currentTarget.value)}
							renderModal={meetingsOption?.map((itemMeeting) => ({
								id: itemMeeting.iCalUId,
								title: `${itemMeeting.subject} (${getDateFormatL(itemMeeting.createdDateTime)})`,
							}))}
						/>
					</div>
					<div>
						<Input
							placeHolder={t<string>('Project')}
							defaultValue={{
								title:
									projects?.find((project) => project.id === updatedTodo.projectId)?.projectName ??
									'',
							}}
							getValue={handleChangeProject}
							renderModal={projects?.map((project) => ({
								id: project.id,
								title: `${project.projectName} (${getDateFormatL(project.createdOn)})`,
							}))}
						/>
					</div>
					<div className={styles.dueDate}>
						<div className={styles.timeslot}>
							<div className={styles.startDate}>
								<div className={styles.startDateButton} onClick={() => handleDatePicker()}>
									<div>
										<span className={styles.titleLabel}>{t('Due date')}</span>

										<div className={styles.datePickerTime}>
											<span>
												{date !== null && date !== undefined
													? getDateFormatWithYear(date.toString())
													: t('No due date')}
											</span>
										</div>
									</div>

									<div className={styles.icon}>
										<IconMeetings />
									</div>
								</div>

								{datePicker && (
									<MeetingFormDatePickerModal
										date={date ? date : new Date()}
										handleDatePicker={handleDateSelect}
										close={handleDatePicker}
										type='start'
									/>
								)}
							</div>
						</div>
					</div>
					<div className={styles.statusAndTimeContainer}>
						<Input
							placeHolder={t('Duration')}
							type='number'
							defaultValue={{ title: updatedTodo.duration ? updatedTodo.duration.toString() : '' }}
							register={register('duration')}
							style={{ paddingRight: '50%' }}
						/>

						<select
							className={styles.durationSelect}
							{...register('timeType')}
							onChange={(e) => {
								setTimeType(e.target.value);
							}}>
							<option value='minutes'>minutes</option>
							<option value='hours'>heures</option>
						</select>
						<Input
							placeHolder={t('State')}
							clickableField={true}
							getValue={handleStateValue}
							defaultValue={{
								id: updatedTodo.state ? updatedTodo.state : TodosStates.TODO,
								title: updatedTodo.state ? stateTitles[updatedTodo.state] : (t('To do') as string),
							}}
							renderModal={[
								{ id: TodosStates.TODO, title: t('To do') },
								{ id: TodosStates.DOING, title: t('Doing') },
								{ id: TodosStates.BLOCKED, title: t('Blocked') },
								{ id: TodosStates.DONE, title: t('Done') },
							]}
						/>
					</div>
					<div>
						<div className={styles.containerTags}>
							<div
								className={styles.buttonTags}
								onClick={() => {
									if (user) {
										openTagsModal();
									}
								}}>
								<div className={styles.tagsWrapper}>
									<p>{t('Tags')}</p>
									<div className={styles.tags}>
										{updatedTodo?.tags.map((tag) => (
											<div
												key={tag.id}
												className={styles.tag}
												style={{
													border: `1px solid ${tag.color}`,
													backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
												}}>
												<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
												{user?.graphUserId === updatedTodo.graphUserId && (
													<div
														style={{ color: tag.color, fontWeight: '600' }}
														className={styles.iconDelete}
														onClick={() => {
															deleteTag(tag);
														}}>
														<IconClose />
													</div>
												)}
											</div>
										))}
									</div>
								</div>
								{user && (
									<div className={styles.icon}>
										<IconSimplePlus />
									</div>
								)}
							</div>

							{tagsModalOpen && (
								<TagsFormModal
									close={closeTagsModal}
									handleSimpleClose={openTagsModal}
									todo={updatedTodo}
									type='todos'
								/>
							)}
						</div>
					</div>
					<div className={styles.description}>
						<input
							style={{ display: 'none' }}
							type='text'
							defaultValue={todo.text}
							{...register('description')}
						/>
						<SimpleEditor content={todo.text} onChangeHandlerText={handleTextChange} />
					</div>
					{userId === updatedTodo.graphUserId ? (
						<div className={styles.btnContainer}>
							<OutlinedButton onClick={() => close()}>{t('Cancel')}</OutlinedButton>
							<FiveDaysBtn type='submit'>
								<IconSimplePlus />{' '}
								{location.search.includes('?create') ? <> {t('Create')}</> : <>{t('Save')}</>}
							</FiveDaysBtn>
						</div>
					) : userId === updatedTodo.assigneeDisplayName ? (
						<div className={styles.btnContainer}>
							<OutlinedButton onClick={() => close()}>{t('Cancel')}</OutlinedButton>
							<FiveDaysBtn type='submit'>
								<IconSimplePlus />{' '}
								{location.search.includes('?create') ? <> {t('Create')}</> : <>{t('Save')}</>}
							</FiveDaysBtn>
						</div>
					) : user?.userName === updatedTodo.assigneeDisplayName ? (
						<div className={styles.btnContainer}>
							<OutlinedButton onClick={() => close()}>{t('Cancel')}</OutlinedButton>
							<FiveDaysBtn type='submit'>
								<IconSimplePlus />{' '}
								{location.search.includes('?create') ? <> {t('Create')}</> : <>{t('Save')}</>}
							</FiveDaysBtn>
						</div>
					) : (
						<></>
					)}
				</form>
			</div>
		</>
	);
};

export default TodosFormComponent;
