export function randomColor(index: number) {
	const colors = [
		'#FF6633',
		'#FFB399',
		'#FF33FF',
		'#FFFF99',
		'#00B3E6',
		'#E6B333',
		'#3366E6',
		'#999966',
		'#99FF99',
		'#B34D4D',
		'#80B300',
		'#809900',
		'#E6B3B3',
		'#6680B3',
		'#66991A',
		'#FF99E6',
		'#CCFF1A',
		'#FF1A66',
		'#E6331A',
		'#33FFCC',
		'#66994D',
		'#B366CC',
		'#4D8000',
		'#B33300',
		'#CC80CC',
		'#66664D',
		'#991AFF',
		'#E666FF',
		'#4DB3FF',
		'#1AB399',
		'#E666B3',
		'#33991A',
		'#CC9999',
		'#B3B31A',
		'#00E680',
		'#4D8066',
		'#809980',
		'#E6FF80',
		'#1AFF33',
		'#999933',
		'#FF3380',
		'#CCCC00',
		'#66E64D',
		'#4D80CC',
		'#9900B3',
		'#E64D66',
		'#4DB380',
		'#FF4D4D',
		'#99E6E6',
		'#6666FF',
		'#FFCCCC',
		'#669999',
		'#00FFCC',
		'#9999FF',
		'#CC99FF',
		'#00CCCC',
		'#336633',
		'#33FF33',
		'#CCFFFF',
		'#66FF66',
		'#99CC00',
		'#FF9900',
		'#CCCCFF',
		'#CC6699',
		'#FF9966',
		'#9999CC',
		'#FF6600',
		'#FF66CC',
		'#66FFFF',
		'#9966FF',
		'#66CCFF',
		'#999900',
		'#CC6600',
		'#9933FF',
		'#FFFF00',
		'#993333',
		'#CC6600',
		'#66CC66',
		'#FF6666',
		'#00CC99',
		'#666600',
		'#FF33CC',
		'#00FF99',
		'#99FF00',
		'#993300',
		'#FF0000',
		'#CCFF00',
		'#FFCC00',
		'#00FF00',
		'#00CC00',
		'#FF00FF',
		'#FF00CC',
		'#FFCC99',
		'#FF99CC',
		'#99CCFF',
		'#FF9900',
		'#99CC33',
		'#FF6699',
		'#FFFF66',
		'#33FF66',
		'#00FFCC',
		'#FFFF33',
		'#FF9933',
		'#33CC33',
		'#00FF33',
		'#CC6600',
		'#FF6699',
		'#FF9900',
		'#33FFCC',
		'#3366FF',
		'#666600',
		'#FFCC66',
		'#FF9966',
		'#FF66FF',
		'#FFFF00',
		'#CC99FF',
		'#FF9999',
		'#33FF33',
		'#00FF00',
		'#00CC66',
		'#FF33FF',
		'#66FF33',
	];
	const randomIndex = Math.floor(Math.random() * colors.length);
	return colors[index];
}
