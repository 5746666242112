import { SerializedError } from '@reduxjs/toolkit';

export default class ErrorUtil {
	public static getErrorMessage(serializedError: SerializedError): string | undefined {
		let message: string | undefined;
		if (serializedError) {
			message = 'Undefined Error';
			if (serializedError.message) {
				message = serializedError.message;
			}
		}
		return message;
	}
}
