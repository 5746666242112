/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { RequestStatusType } from '../../../../../../store/shared/types';
import Loader from '../../../../../../shared/components/loader/loader.component';
import MeetingsDetailsTodosFormComponents from './meetings-details-todos-form.components';
import styles from './meetings-details-todos.module.scss';
import {
	IconDateTime,
	IconList,
	IconMeetings,
	IconSimplePlus,
	IconStatus,
	IconTag,
} from '../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import TodosViewSimple from '../../../../../todos/components/todos-view-simple';
import {
	deleteTodosSelectedMeeting,
	getSelectMeetingTodos,
	updateTodoSelectMeeting,
} from '../../../../../../store/todos/slice';
import { TodoAttendee, TodosContract } from '../../../../../../store/todos/type';
import OutlinedButton from '../../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import { getSelectMeetingTodosForShared } from '../../../../../../store/sharing/slice';
import { TodosColumns } from '../../../../../../shared/hooks/use-todos-colums.hook';
import DateCell from '../../../../../todos/components/date-cell.component';
import StateCell from '../../../../../todos/components/state-cell.component';
import TagsCell from '../../../../../todos/components/tags-cell.component';
import AttendeeCell from '../../../../../todos/components/attendee-cell.component';
import TodoFilters, { filterConfigurations } from '../../../../../todos/components/todo-filters.component';
import { SectionType } from '../../../../../../shared/hooks/use-todos-mutations.hook';

interface Props {
	storedAccessMail?: string;
}

const MeetingsDetailsTodos: React.FC<Props> = ({ storedAccessMail }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const { selectMeetingTodos, selectMeetingTodosRequestStatus } = useAppSelector((state) => state.todos);
	const { shareTodos, shareTodosRequestStatus } = useAppSelector((state) => state.sharing);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const [filteredTodosList, setFilteredTodosList] = useState<TodosContract[]>(selectMeetingTodos ?? []);

	const storedView = localStorage.getItem('viewTodoMeetings');
	const [view, setView] = React.useState(storedView ? storedView : 'Simple');

	const handleFilteredTodo = (todos: TodosContract[]) => {
		setFilteredTodosList(todos);
	};

	React.useEffect(() => {
		if (filterConfigurations.every((filter) => !filter.isActive)) {
			setFilteredTodosList(selectMeetingTodos ?? []);
		}
	}, [selectMeetingTodos, filterConfigurations]);

	const [todosColumns] = useState<TodosColumns[]>([
		{
			icon: IconMeetings,
			title: t('Due date'),
			width: 200,
			isActive: true,
			component: (todo) => (
				<DateCell updateFn={updateTodoSelectMeeting} todo={todo} sectionType={SectionType.meeting} />
			),
		},
		{
			icon: IconMeetings,
			title: t('Assignee'),
			width: 200,
			isActive: true,
			component: (todo) => (
				<AttendeeCell
					updateFn={updateTodoSelectMeeting}
					todo={todo}
					attendees={selectedMeeting?.attendees.map(
						(attendee) => new TodoAttendee(attendee.emailAddress.address, attendee.emailAddress.name),
					)}
				/>
			),
		},
		{
			icon: IconStatus,
			title: t('State'),
			width: 200,
			isActive: true,
			component: (todo) => (
				<StateCell updateFn={updateTodoSelectMeeting} todo={todo} sectionType={SectionType.meeting} />
			),
		},
		{
			icon: IconTag,
			title: t('Tags'),
			width: 200,
			isActive: true,
			component: (todo) => (
				<TagsCell sectionType={SectionType.meeting} updateFn={updateTodoSelectMeeting} todo={todo} />
			),
		},
	]);

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedMeeting) {
				if (user) {
					await dispatch(getSelectMeetingTodos(selectedMeeting.iCalUId));
				} else {
					await dispatch(getSelectMeetingTodosForShared(selectedMeeting.iCalUId));
				}
			}
		};
		fetchData();
	}, [selectedMeeting]);

	const handleGroupChange = (val: string) => {
		localStorage.setItem('viewTodoMeetings', val);
		setView(val);
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	const [showFilterModal, setShowFilterModal] = React.useState(false);
	const handleShowFilterModal = () => {
		setShowFilterModal(!showFilterModal);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	return (
		<>
			{user ? (
				<div>
					<div className={styles.over}>
						<div className={styles.top}>
							<div className={styles.flexButton}>
								<OutlinedButton onClick={() => handleMenu()}>
									<IconSimplePlus /> {t('Add new todo')}
								</OutlinedButton>
							</div>
						</div>
						<TodoFilters
							todos={selectMeetingTodos ?? []}
							handleGroupChange={handleGroupChange}
							view={view}
							handleFilteredTodos={handleFilteredTodo}
							sectionType={SectionType.meeting}
						/>
						<div className={styles.overview}>
							{view === 'Simple' && selectMeetingTodos && (
								<>
									<div className={styles.mainTodoPage}>
										<TodosViewSimple
											deleteTodoFn={deleteTodosSelectedMeeting}
											updateTitleFn={updateTodoSelectMeeting}
											updateTodoFn={updateTodoSelectMeeting}
											sectionType={SectionType.meeting}
											todosColumns={todosColumns}
											height='calc(100vh - 412px)'
											attendees={selectedMeeting?.attendees.map((attendee) => ({
												name: attendee.emailAddress.name,
												email: attendee.emailAddress.address,
											}))}
											todosAssignee={[...filteredTodosList].sort(
												(a: TodosContract, b: TodosContract) =>
													Date.parse(b.createdOn.toString()) -
													Date.parse(a.createdOn.toString()),
											)}
										/>
									</div>
								</>
							)}
						</div>
						{menu && (
							<div ref={modalRef}>
								<MeetingsDetailsTodosFormComponents close={handleMenu} />
							</div>
						)}
					</div>
					{selectMeetingTodosRequestStatus.type === RequestStatusType.Failed && (
						<div>{selectMeetingTodosRequestStatus.errorMessage}</div>
					)}
				</div>
			) : (
				<div className={styles.over}>
					{shareTodosRequestStatus.type === RequestStatusType.InProgress && (
						<div>
							<Loader></Loader>
						</div>
					)}

					{shareTodosRequestStatus.type === RequestStatusType.Success && (
						<div className={styles.over}>
							<div className={styles.top}>
								<button className={styles.cancel} onClick={() => handleMenu()}>
									<IconSimplePlus />
									{t('Add new todo')}
								</button>
								<div className={styles.flex}>
									<div className={styles.icons}>
										<div
											className={view === 'Simple' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('Simple');
											}}>
											<IconList />
										</div>
										<div
											className={view === 'List' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('List');
											}}>
											<div className={styles.childIcon}>
												<IconDateTime />
											</div>
										</div>
										<div
											className={view === 'Meetings' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('Meetings');
											}}>
											<div className={styles.childIcon}>
												<IconMeetings />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className={styles.overview}>
								{view === 'Simple' && shareTodos && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewSimple
												deleteTodoFn={deleteTodosSelectedMeeting}
												updateTitleFn={updateTodoSelectMeeting}
												updateTodoFn={updateTodoSelectMeeting}
												sectionType={SectionType.meeting}
												todosColumns={todosColumns}
												todosAssignee={shareTodos}
											/>
										</div>
									</>
								)}
							</div>
							{menu && (
								<div ref={modalRef}>
									<MeetingsDetailsTodosFormComponents
										close={handleMenu}
										storedAccessMail={storedAccessMail}
									/>
								</div>
							)}
						</div>
					)}

					{shareTodosRequestStatus.type === RequestStatusType.Failed && (
						<div>{shareTodosRequestStatus.errorMessage}</div>
					)}
				</div>
			)}
		</>
	);
};

export default MeetingsDetailsTodos;
