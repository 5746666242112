import React from 'react';
import { MeetingContract } from '../../../../../../store/meetings/types';
import styles from './meeting-collaboration.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { AddCollaboratorShare, getMeetingsCollaborators, shareMeeting } from '../../../../../../store/sharing/slice';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../../../../../shared/utils/avatar';
import { IconSearch } from '../../../../../../shared/utils/icon';

interface Props {
	onClose: () => void;
	meeting: MeetingContract;
	inviteSend: () => void;
	users: { type: string; emailAddress: { name: string; address: string } }[];
}

const MeetingCollaborationSharingModal: React.FC<Props> = ({ onClose, meeting, inviteSend, users }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { shareCollaborators } = useAppSelector((state) => state.sharing);
	const [searchValue, setSearchValue] = React.useState('');
	const [link, setLink] = React.useState('');
	const [selectedAttendees, setSelectedAttendees] = React.useState<string[]>([]);

	const handleShareMeeting = () => {
		selectedAttendees.forEach((mail) => {
			dispatch(shareMeeting({ eventId: meeting.graphEventId, mail })).then((res) => {
				dispatch(AddCollaboratorShare(mail));
				// toast.show({
				// 	id: user?.graphUserId,
				// 	title: t('Invitation send') as string,
				// 	duration: 10000,
				// 	type: 'success',
				// });
				setLink(res.payload as string);
				inviteSend();
			});
		});
		dispatch(getMeetingsCollaborators(meeting.iCalUId));
		onClose();
	};

	const handleCheckboxChange = (mail: string) => {
		setSelectedAttendees((prevSelected) => {
			return prevSelected.includes(mail) ? prevSelected.filter((item) => item !== mail) : [...prevSelected, mail];
		});
	};

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const userEmails = new Set(users.map((attendee) => attendee.emailAddress.address));

	const filterAttendeesUsers = meeting.attendees.filter((attendee) => !userEmails.has(attendee.emailAddress.address));

	const filteredAttendees = filterAttendeesUsers.filter((attendee) =>
		attendee.emailAddress.name.toLowerCase().includes(searchValue.toLowerCase()),
	);

	return (
		<div className={styles.containerSharing}>
			<div className={styles.search_input}>
				<IconSearch />{' '}
				<input
					type='text'
					name='company'
					id='company'
					placeholder={styles.Search}
					onChange={handleSearchInputChange}
				/>
			</div>
			<div className={styles.send}>
				{filteredAttendees.some(
					(attendee) =>
						attendee.emailAddress.address !== user?.email &&
						!shareCollaborators?.includes(attendee.emailAddress.address),
				) && (
					<div className={styles.mails}>
						<input
							type='checkbox'
							checked={selectedAttendees.length === meeting.attendees.length}
							onChange={() => {
								if (selectedAttendees.length === meeting.attendees.length) {
									setSelectedAttendees([]);
								} else {
									setSelectedAttendees(
										meeting.attendees.map((attendee) => attendee.emailAddress.address),
									);
								}
							}}
						/>
						<div className={styles.mail}>
							<span>{t('All')}</span>
						</div>
					</div>
				)}

				{filteredAttendees
					.filter(
						(attendee) =>
							attendee.emailAddress.address !== user?.email &&
							!shareCollaborators?.includes(attendee.emailAddress.address),
					)
					.map((attendee, index) => (
						<div className={styles.mails} key={index}>
							<input
								type='checkbox'
								checked={selectedAttendees.includes(attendee.emailAddress.address)}
								onChange={() => handleCheckboxChange(attendee.emailAddress.address)}
							/>
							<div className={styles.mail}>
								<Avatar
									name={attendee.emailAddress.name}
									mail={attendee.emailAddress.address}
									index={index}
								/>
								<span>{attendee.emailAddress.name}</span>
							</div>
						</div>
					))}
			</div>
			<button onClick={handleShareMeeting}>{t('Confirm')}</button>
		</div>
	);
};

export default MeetingCollaborationSharingModal;
