import React, { useEffect } from 'react';

import styles from './home.module.scss';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { useAppDispatch } from '../../store/hooks';

const HomePage = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
	}, [dispatch]);

	return (
		<div className={styles.container}>
			<div>Home Page</div>
		</div>
	);
};

export default HomePage;
