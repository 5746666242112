import HttpClient from '../shared/http/http-client';
import { ApiUrls, UrlParamBuilder } from './api-urls';
import { TodosContract, TodosContractComplete, TodosContractCreate } from '../store/todos/type';
import { updateTags } from '../store/notes/type';
import { TagContract } from '../store/meetings/types';

export default class TodosAPI {
	public static async createTodo(body: TodosContractCreate): Promise<TodosContract> {
		const { data } = await HttpClient.post<TodosContractCreate, TodosContract>(ApiUrls.PostTodo, body);
		return data;
	}

	public static async getCurrentUserTodos(): Promise<TodosContract[]> {
		const { data } = await HttpClient.get<TodosContract[]>(ApiUrls.GetCurrentUserTodos);
		return data;
	}

	public static async getCurrentUserTodosAssignee(): Promise<TodosContract[]> {
		const { data } = await HttpClient.get<TodosContract[]>(ApiUrls.GetCurrentUserTodosAssignee);
		return data;
	}

	public static async getSelectMeetingTodos(eventId: string): Promise<TodosContract[]> {
		const { data } = await HttpClient.get<TodosContract[]>(
			ApiUrls.GetSelectMeetingTodos,
			new UrlParamBuilder().addEventId(eventId).build(),
		);
		return data;
	}

	public static async getNotesTodos(notesId: string): Promise<TodosContract[]> {
		const { data } = await HttpClient.get<TodosContract[]>(
			ApiUrls.GetNoteTodos,
			new UrlParamBuilder().addNotesId(notesId).build(),
		);
		return data;
	}

	public static async updateTodo(body: TodosContractCreate, todoId: string): Promise<TodosContractCreate> {
		const { data } = await HttpClient.patch<TodosContractCreate, TodosContract>(
			ApiUrls.UpdateTodo,
			body,
			new UrlParamBuilder().addtodoId(todoId).build(),
		);
		return data;
	}

	public static async addTagsForTodos(body: updateTags): Promise<TagContract> {
		const { data } = await HttpClient.post<updateTags, TagContract>(ApiUrls.AddTagsForTodo, body);
		return data;
	}

	public static async deleteTagsFromTodo(todoId: string, tagsId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteTagsFromTodo,
			new UrlParamBuilder().addtodoId(todoId).addtagsId(tagsId).build(),
		);
		return data;
	}

	public static async deleteTodo(todoId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteTodo,
			new UrlParamBuilder().addtodoId(todoId).build(),
		);
		return data;
	}

	public static async completeTodo(body: TodosContractComplete, todoId: string): Promise<TodosContractComplete> {
		const { data } = await HttpClient.patch<TodosContractComplete, TodosContract>(
			ApiUrls.CompleteTodo,
			body,
			new UrlParamBuilder().addtodoId(todoId).build(),
		);
		return data;
	}

	public static async getOneTodo(todoId: string): Promise<TodosContract> {
		const { data } = await HttpClient.get<TodosContract>(
			ApiUrls.getOneTodo,
			new UrlParamBuilder().addtodoId(todoId).build(),
		);
		return data;
	}
}
