import React from 'react';
import styles from './project-filter.module.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

interface Props {
	showArchived: boolean;
	handleShowArchived: (show: boolean) => void;
	showPersonalProject: boolean;
	handleShowPersoProject: (show: boolean) => void;
	showSharedProject: boolean;
	handleShowSharedProject: (show: boolean) => void;
	showPublicProject: boolean;
	handleShowPublicProject: (show: boolean) => void;
}

const ProjectFilterModal: React.FC<Props> = ({
	showArchived,
	handleShowArchived,
	showPersonalProject,
	handleShowPersoProject,
	showSharedProject,
	handleShowSharedProject,
	showPublicProject,
	handleShowPublicProject,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={styles.container}>
				<div className={styles.list}>
					{/* <h2>{t('Select filters')}</h2> */}

					<div
						className={styles.row}
						onClick={() => handleShowPersoProject(showPersonalProject === true ? false : true)}>
						<p>{t('My projects')}</p>
						<input
							className={styles.filterCheckbox}
							type='checkbox'
							checked={showPersonalProject === true ? true : false}
							onChange={(e) => handleShowPersoProject(e.target.checked)}
						/>
					</div>

					<div
						className={styles.row}
						onClick={() => handleShowSharedProject(showSharedProject === true ? false : true)}>
						<p>{t('Projects shared with me')}</p>
						<input
							type='checkbox'
							checked={showSharedProject === true ? true : false}
							onChange={(e) => handleShowSharedProject(e.target.checked)}
						/>
					</div>

					<div
						className={styles.row}
						onClick={() => handleShowPublicProject(showSharedProject === true ? false : true)}>
						<p>{t('Public projects')}</p>
						<input
							type='checkbox'
							checked={showPublicProject === true ? true : false}
							onChange={(e) => handleShowPublicProject(e.target.checked)}
						/>
					</div>

					<div
						className={styles.row}
						onClick={() => handleShowArchived(showArchived === true ? false : true)}>
						<p>{t('Projects archived')}</p>
						<input
							type='checkbox'
							checked={showArchived === true ? true : false}
							onChange={(e) => handleShowArchived(e.target.checked)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectFilterModal;
