import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, RequestStatusType, createRequestStatus } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import { CreateMeetingSeriesContract, MeetingForSeriesContract } from './type';
import SeriesAPI from '../../api/series-api';

interface SeriesState {
	seriesMeetings?: MeetingForSeriesContract[];
	seriesMeetingsRequestStatus: RequestStatus;
}

const initialState: SeriesState = {
	seriesMeetings: undefined,
	seriesMeetingsRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const meetingsForSeries = createAsyncThunk(
	'series/meetings',
	async (icalUid: string): Promise<MeetingForSeriesContract[]> => {
		return SeriesAPI.getMeetingsForASerie(icalUid);
	},
);

export const postMeetingsForSeries = createAsyncThunk(
	'series/post/meeting',
	async (body: CreateMeetingSeriesContract): Promise<CreateMeetingSeriesContract> => {
		return SeriesAPI.CreateMeetingsForASerie(body);
	},
);

const slice = createSlice({
	name: 'series',
	initialState,
	reducers: {},
	extraReducers(builder): void {
		builder.addCase(meetingsForSeries.pending, (state) => {
			state.seriesMeetingsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(meetingsForSeries.fulfilled, (state, action) => {
			state.seriesMeetingsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.seriesMeetings = action.payload;
		});
		builder.addCase(meetingsForSeries.rejected, (state, action) => {
			state.seriesMeetingsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { reducer } = slice;
export default reducer;
