/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import useWebSocketConnections from '../utils/websocket';
import NotesForm from '../components/notes.form.component';

interface Props {
	toggle: () => void;
	noteId: string;
	unmount: boolean;
}

const NotesBridgeForm: React.FC<Props> = ({ toggle, noteId, unmount }) => {
	// ---
	const { getConnection } = useWebSocketConnections();
	const connection = getConnection(noteId);

	if (!connection || typeof connection === 'function') {
		return <NotesForm toggle={toggle} provider={undefined} doc={undefined} noteId={noteId} unmount={unmount} />;
	}

	const { doc, provider } = connection;

	// ---

	return (
		<>
			<NotesForm toggle={toggle} provider={provider} doc={doc} noteId={noteId} unmount={unmount} />
		</>
	);
};

export default NotesBridgeForm;
