import React from 'react';
import Toast, { ToastProps } from './toast';
import { createRoot, Root } from 'react-dom/client';

interface ToastOptions {
	id?: string;
	title: string;
	duration?: number;
	type: string;
}

export class ToastManager {
	private containerRef: HTMLDivElement;
	private toasts: ToastProps[] = [];
	private root?: Root;

	constructor() {
		const body = document.querySelectorAll('body')[0] as HTMLBodyElement;
		const toastContainer = document.createElement('div') as HTMLDivElement;
		body.appendChild(toastContainer);
		this.containerRef = toastContainer;
	}

	public show(options: ToastOptions): void {
		const toastId = Math.random().toString(36).slice(2, 9);
		const toast: ToastProps = {
			id: toastId,
			...options,
			destroy: () => this.destroy(options.id ?? toastId),
		};

		this.toasts = [toast, ...this.toasts];
		this.render();
	}

	public destroy(id: string): void {
		this.toasts = this.toasts.filter((toast: ToastProps) => toast.id !== id);
		this.render();
	}

	private render(): void {
		const toastsList = this.toasts.map((toastProps: ToastProps, index) => <Toast key={index} {...toastProps} />);
		const container = this.containerRef;
		if (container) {
			if (!this.root) {
				this.root = createRoot(container);
			}
			this.root.render(<>{toastsList}</>);
		}
	}
}

export const toast = new ToastManager();
