import React, { MouseEvent, useRef, useState } from 'react';
import styles from '../todos.module.scss';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import { getDateFormatL } from '../../../shared/utils/date.util';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { TodosContract, UpdateTodo } from '../../../store/todos/type';
import Input, { Items } from '../../../shared/components/input/input.component';
import { SectionType, useUpdateTodoMutation } from '../../../shared/hooks/use-todos-mutations.hook';
import { IconClose } from '../../../shared/utils/icon';
import { PayloadAction } from '@reduxjs/toolkit';

interface Props {
    todo: TodosContract,
    sectionType: SectionType,
    updateFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>,
}

const ProjectCell: React.FC<Props> = ({ todo, sectionType, updateFn }) => {
    const [openProjectMenu, setOpenProjectMenu] = useState<boolean>(false);
    const { projects } = useAppSelector((state) => state.project);
    const navigate = useNavigate();
    const updateTodoProjectMutation = useUpdateTodoMutation();

    const projectModalRef = useRef<HTMLDivElement | null>(null);

    useClickOutside(projectModalRef, () => setOpenProjectMenu(false));

    const handleRemoveProject = (e: MouseEvent) => {
        e.stopPropagation();

        const body: any = {
            ...todo,
            projectId: null,
        };

        updateTodoProjectMutation.mutate({ id: todo.id, newTodo: body, oldTodo: todo, updateFn });

        setOpenProjectMenu(false);
    };

    const handleProjectValue = (item: Items | undefined) => {
        const selectedProject = projects?.find((project) => project.id === item?.id);

        const body: any = {
            ...todo,
            projectId: selectedProject?.id,
        };

        updateTodoProjectMutation.mutate({ id: todo.id, newTodo: body, oldTodo: todo, updateFn });

        setOpenProjectMenu(false);
    };

    return (
        <div className={styles.todoRowColumn} ref={projectModalRef}>
            <div className={styles.todoRowColumnContent} onClick={() => setOpenProjectMenu(true)}>
                <span
                    className={styles.linkMeeting}
                    onClick={(e) => {
                        e.stopPropagation();

                        const params: any = {};
                        params[RouteSearchParams.ProjectId] = todo.projectId;
                        navigate(`${RouteUrls.Project}?${new URLSearchParams(params).toString()}`);
                    }}>
                    {projects?.find((project) => project.id === todo.projectId)?.projectName}
                </span>
                {todo.projectId && (
                    <div className={styles.removeMeetingOrProject} onClick={handleRemoveProject}>
                        <IconClose />
                    </div>
                )}
            </div>
            {openProjectMenu && (
                <ModalLayout top='0'>
                    <div className={styles.meetingModal}>
                        <Input
                            placeHolder=''
                            defaultValue={{
                                title:
                                    projects?.find((project) => project.id === todo.projectId)?.projectName ??
                                    '',
                            }}
                            getValue={handleProjectValue}
                            renderPlaceHolder={false}
                            renderModal={projects?.map((project) => ({
                                id: project.id,
                                title: `${project.projectName} (${getDateFormatL(project.createdOn)})`,
                            }))}
                        />
                    </div>
                </ModalLayout>
            )}
        </div>
    );
};

export default ProjectCell;