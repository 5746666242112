/* eslint-disable unicorn/no-null */
import React from 'react';
import { TodosContract, TodosStates, UpdateTodo } from '../../../store/todos/type';
import { useLocation } from 'react-router-dom';
import TodosRowComponent from './todos-row.component';
import styles from '../todos.module.scss';
import TodosFormModal from '../modals/todos.form.modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RouteUrls } from '../../../routes/routes-config';
import { IconDown, IconText, IconUp } from '../../../shared/utils/icon';
import { t } from 'i18next';
import { reverseTodos } from '../../../store/todos/slice';
import { reverseTodosProject } from '../../../store/project/slice';
import { TodosColumns } from '../../../shared/hooks/use-todos-colums.hook';
import { SectionType } from '../../../shared/hooks/use-todos-mutations.hook';
import { PayloadAction } from '@reduxjs/toolkit';

interface Props {
	todosAssignee: TodosContract[];
	height?: string,
	attendees?: { name: string, email: string, }[];
	todosColumns: TodosColumns[],
	sectionType: SectionType,
	updateTitleFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>,
	deleteTodoFn: (value: string) => PayloadAction<string>,
	updateTodoFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>,
}

const TodosViewSimple: React.FC<Props> = ({ todosAssignee, height, attendees, todosColumns, sectionType, updateTitleFn, deleteTodoFn, updateTodoFn }) => {
	const location = useLocation();
	const dispatch = useAppDispatch();

	const [openUpdate, setOpenUpdate] = React.useState(false);
	const [todoToUpdate, setTodoToUpdate] = React.useState<TodosContract | undefined>(undefined);

	const handleUpdate = (NoteTD: TodosContract) => {
		setTodoToUpdate(NoteTD);
		setOpenUpdate(!openUpdate);
	};

	const toggleUpdate = () => {
		setOpenUpdate(!openUpdate);
	};

	const [arrow, setArrow] = React.useState(false);

	const handleArrow = () => {
		setArrow(!arrow);
		dispatch(reverseTodos());
		dispatch(reverseTodosProject());
	};

	React.useEffect(() => {
		if (location.search.includes('?view')) {
			const id = location.search.split('/')[1];

			const todo = todosAssignee && todosAssignee.find((todoA) => todoA.id === id);

			if (todo) {
				handleUpdate(todo);
			}
		}
	}, [location]);

	return (
		<div className={styles.listViewContainer} style={{ height }}>
			<div className={styles.headTitle} style={{ gridTemplateColumns: `450px repeat(${todosColumns.length}, 1fr) 30px` }}>
				<div className={styles.flexHeader}>
					<div className={styles.title} style={{ color: '#2E4552' }}>
						<IconText /> {t('Todos')}
					</div>
					<div>
						{arrow === false && (
							<span
								style={{ cursor: 'pointer' }}
								onClick={() => {
									handleArrow();
								}}>
								<IconUp />
							</span>
						)}
						{arrow === true && (
							<span
								style={{ cursor: 'pointer' }}
								onClick={() => {
									handleArrow();
								}}>
								<IconDown />
							</span>
						)}
					</div>
				</div>

				{todosColumns.map((column) => (
					column.isActive && (<div key={column.title} className={styles.titleHeader}>
						<div className={styles.title}>
							<column.icon />
							{column.title}
						</div>
					</div>)

				))}

				<div className={styles.endColumn}></div>
			</div>
			<div className={styles.todoRowList}>
				{todosAssignee.map((todo: TodosContract) => (
					<TodosRowComponent
						key={todo.createdOn.toString()}
						singleTodo={todo}
						handleUpdate={handleUpdate}
						todosColumns={todosColumns}
						sectionType={sectionType}
						updateTitleFn={updateTitleFn}
						deleteTodoFn={deleteTodoFn}
						updateTodoFn={updateTodoFn}
					/>
				))}

				{openUpdate && todoToUpdate && (
					<TodosFormModal toggle={toggleUpdate} attendees={attendees} handleToggle={toggleUpdate} todo={todoToUpdate} />
				)}
			</div>
		</div>
	);
};

export default TodosViewSimple;
