import HttpClient from '../shared/http/http-client';
import { ApiUrls, UrlParamBuilder } from './api-urls';
import { CompanyContract, CompanyUpdateDomain } from '../store/settings/type';

export default class SettingsFivedaysAPI {
	public static async getAllCompanies(): Promise<CompanyContract[]> {
		const { data } = await HttpClient.get<CompanyContract[]>(ApiUrls.GetAllOrganization);
		return data;
	}

	public static async deleteCompany(companyId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.deleteOranization,
			new UrlParamBuilder().addCompanyId(companyId).build(),
		);
		return data;
	}

	public static async updateCompanyDomains(body: CompanyUpdateDomain): Promise<CompanyContract> {
		const { data } = await HttpClient.post<CompanyUpdateDomain, CompanyContract>(
			ApiUrls.UpdateDomainsOrganization,
			body,
		);
		return data;
	}
}
