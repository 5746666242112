import React from 'react';
import styles from './header.component.module.scss';
import { useTranslation } from 'react-i18next';
import LogoFivedays from '../../../../images/logo/Logo.png';
import { IconLeftChev } from '../../../shared/utils/icon';

interface Props {
	returnInvite: () => void;
}

const HeaderInviteComponent: React.FC<Props> = ({ returnInvite }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.headerInvite}>
			<div></div>
			<div className={styles.return} onClick={() => returnInvite()}>
				<IconLeftChev />
				{t('Return to the invitation')}
			</div>
			<div className={styles.right}>
				<img src={LogoFivedays} alt='' />
			</div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default HeaderInviteComponent;
