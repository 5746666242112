import React from 'react';
import ShimmerAvatar from '../shimmer-avatar.component';
import ShimmerText from '../shimmer-text.component';
import styles from './shimmer-header.module.scss';

const ShimmerHeader = () => {
    return (
        <div className={styles.headerShimmer}>
            <ShimmerText height={20} width={150} />
            <div className={styles.shimmerAvatarContainer}>
                <ShimmerAvatar />
                {Array.from({ length: 2 }).map((_, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            left: index === 0 ? '27px' : `${(index + 1) * 25}px`,
                            zIndex: `${10 - (index + 1) * 1}`,
                        }}>
                        <ShimmerAvatar />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ShimmerHeader;