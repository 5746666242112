import React from 'react';
import styles from './meetings-files.component.module.scss';
import { useAppSelector } from '../../../../../../store/hooks';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { extractDomain } from '../../../../../../shared/utils/domainext';
import { useParams } from 'react-router-dom';
import MeetingsFilesListComponent from './meetings-files-list.component';

initializeFileTypeIcons();

const MeetingsFilesComponent = () => {
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const { shareFiles } = useAppSelector((state) => state.sharing);

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	// -------

	return (
		<div className={styles.over}>
			<div className={styles.meetingsFiles}>
				{user && selectedMeeting && (
					<MeetingsFilesListComponent
						mail={user.email}
						userId={user.graphUserId}
						selectedMeeting={selectedMeeting}
						userCompagnyName={user.companyName}
						files={selectedMeeting.files}
					/>
				)}
				{!user && shareFiles && storedAccessMail && selectedMeeting && (
					<MeetingsFilesListComponent
						mail={storedAccessMail}
						userId={storedAccessMail}
						selectedMeeting={selectedMeeting}
						userCompagnyName={extractDomain(storedAccessMail)}
						files={shareFiles}
					/>
				)}
			</div>
		</div>
	);
};

export default MeetingsFilesComponent;
