import React, { ErrorInfo, ReactNode } from 'react';
import { Environments } from '../../types/environments';
import { RuntimeConfig } from '../../../config/runtime-config';
import * as Sentry from '@sentry/react';

class ErrorBoundaryInner extends React.Component<OwnProps, OwnState> {
	constructor(props: OwnProps) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidUpdate(prevProps: OwnProps) {
		if (!this.props.hasError && prevProps.hasError) {
			this.setState({ hasError: false });
		}
	}

	public componentDidCatch(error: Error, info: ErrorInfo): void {
		this.handleError(error, info);
	}

	private handleError(error: Error, info: ErrorInfo): void {
		this.setState({ hasError: true });
		if (
			RuntimeConfig.getEnvironment() === Environments.LOCAL ||
			RuntimeConfig.getEnvironment() === Environments.DEV
		) {
			console.log(error, info);
		}

		Sentry.captureException(error, {
			extra: {
				info,
			},
		});

		if (this.props.setHasError) {
			this.props.setHasError(true);
		}
	}

	public render(): React.ReactNode {
		return this.props.children;
	}
}

interface OwnProps {
	children?: ReactNode;
	hasError: boolean;
	setHasError?: (value: boolean) => void;
}

interface OwnState {
	hasError: boolean;
}

export default ErrorBoundaryInner;
