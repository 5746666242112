import React, { useState } from 'react';
import { NotesContract } from '../../../store/notes/type';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AccessRightType } from '../../../store/meetings/types';
import styles from './note-form-access-update.module.scss';
import { addPeopleShare, deleteNotesPeople, postPeopleShareNote, updateNotes } from '../../../store/notes/slice';
import { deletePeopleShareNote, updateNotesShared } from '../../../store/sharing/slice';
import { SendNotifications } from '../../../store/notifications/slice';
import { NotificationsType } from '../../../store/notifications/type';
import { extractDomainWithExt } from '../../../shared/utils/domainext';
import { IconClose, IconContact, IconEdit, IconGlobe, IconLock, IconMeetingsPage } from '../../../shared/utils/icon';
import AttendeesInput, { Items } from '../../../shared/components/input/attendees-input.component';
import { Avatar } from '../../../shared/utils/avatar';
import { PersonaSize } from '@fluentui/react';
import { Attendees } from '../../meetings/modals/meeting-create.modal';
import useMergeAttendees from '../../../shared/hooks/use-merge-attendees.hook';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { ContactsGraphContract } from '../../../store/user/type';
import { AttendeeMerge, attendeeMergeMapper } from '../../../shared/utils/mappers/attendee-merge.mapper';

interface Props {
	note: NotesContract;
	close: (note: NotesContract) => void;
	storedAccessMail?: string;
}

const NotesFormUpdateAccessComponent: React.FC<Props> = ({ storedAccessMail, note, close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = (body: NotesContract) => {
		setAnimation(true);
		setTimeout(() => {
			close(body);
			setAnimation(false);
		}, 300);
	};
	const { user, contacts } = useAppSelector((state) => state.user);

	const domain = extractDomainWithExt(user ? user.email : storedAccessMail ? storedAccessMail : '');

	const [selectedAccessType, setSelectedAccessType] = React.useState<AccessRightType>(note.accessRightType);
	const { sharedPeopleNote } = useAppSelector((state) => state.notes);

	const handleCheckboxChange = (accessData: AccessRightType) => {
		const value = accessData;
		setSelectedAccessType(value);
	};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedAddresses, setSelectedAddresses] = React.useState<Attendees[]>([
		...(sharedPeopleNote?.map((sharedPeoples) => ({
			type: 'required',
			emailAddress: {
				name: sharedPeoples.username ?? '',
				address: sharedPeoples.mail ?? '',
			},
		})) ?? []),
		{
			type: 'required',
			emailAddress: {
				name: user?.displayName ? user.displayName : '',
				address: user?.email ? user.email : '',
			},
		},
	]);
	const [mergedAttendees, setMergedAttendees] = useMergeAttendees({
		userCompany: user?.userCompany,
		contacts,
	});

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if (AccessRightType) {
			event.preventDefault();

			if (note) {
				const body = {
					id: note.id,
					title: note.title,
					text: note.text,
					graphUserId: note.graphUserId,
					graphUserName: note.graphUserName,
					meetingAttendees: note.meetingAttendees,
					graphiCalUId: note.graphiCalUId,
					meetingTitle: note.meetingTitle,
					meetingStartDate: note.meetingStartDate,
					tags: note.tags,
					updateNote: new Date(),
					createdOn: note.createdOn,
					updateOn: note.updateOn,
					accessRightType: selectedAccessType,
					accessRightCompanies: domain ? [domain] : [],
					archived: note.archived,
					projectId: note.projectId,
				};

				if (user) {
					if (selectedAccessType === AccessRightType.SHARED) {
						selectedAddresses.filter((sharedPeople) => sharedPeople.emailAddress.address !== user.email).forEach((address) => {
							const bodySharedPeople = {
								id: '1',
								username: address.emailAddress.name,
								mail: address.emailAddress.address,
							};

							dispatch(postPeopleShareNote({ body: bodySharedPeople, noteId: note.id })).then(() => {
								dispatch(addPeopleShare(bodySharedPeople));
							});
						});

					}

					dispatch(updateNotes({ noteId: note.id, body })).then(() => {
						closeModal(body);

						if (selectedAccessType === AccessRightType.EVERYONE) {
							const attendees = note.meetingAttendees.filter(
								(attendee) => attendee.toLowerCase() !== user.email.toLowerCase(),
							);

							attendees?.forEach((attendee) => {
								const bodyNotification = {
									id: undefined,
									userFromName: user.displayName,
									userFromMail: user.email,
									userToName: attendee,
									userToMail: attendee,
									type: NotificationsType.NEW_NOTE,
									noteId: note.id,
									todoId: undefined,
									graphiCalUId: note.graphiCalUId,
									projectId: undefined,
									meetingStartDate: note.meetingStartDate,
									itemTitle: body.title,
									visible: true,
									meetingName: note.meetingTitle,
								};

								dispatch(SendNotifications(bodyNotification));
							});
						}
					});
				} else {
					dispatch(updateNotesShared({ noteId: note.id, body })).then(() => {
						close(body);

						if (storedAccessMail && selectedAccessType === AccessRightType.EVERYONE) {
							const attendees = note.meetingAttendees.filter(
								(attendee) => attendee.toLowerCase() !== storedAccessMail.toLowerCase(),
							);

							attendees?.forEach((attendee) => {
								const bodyNotification = {
									id: undefined,
									userFromName: storedAccessMail,
									userFromMail: storedAccessMail,
									userToName: attendee,
									userToMail: attendee,
									type: NotificationsType.NEW_NOTE,
									noteId: note.id,
									todoId: undefined,
									graphiCalUId: note.graphiCalUId,
									projectId: undefined,
									meetingStartDate: note.meetingStartDate,
									itemTitle: body.title,
									visible: true,
									meetingName: note.meetingTitle,
								};

								dispatch(SendNotifications(bodyNotification));
							});
						}
					});
				}
			}
		}
	};

	const handleAttendee = (selectedContact: Items | undefined) => {
		if (selectedContact) {
			const newAttendee = {
				type: 'required',
				emailAddress: {
					name: selectedContact.title,
					address: selectedContact.id ?? '',
				},
			};
			setSelectedAddresses([...selectedAddresses, newAttendee]);
		}
	};

	const handleDeletePeopleShare = (mail: string) => {
		const removedSelectedAdress = selectedAddresses.filter((address) => address.emailAddress.address !== mail);
		setSelectedAddresses(removedSelectedAdress);

		dispatch(deletePeopleShareNote({ noteId: note.id, mail })).then(() => {
			dispatch(deleteNotesPeople(mail));
		});
	};

	let timeoutId: any;

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const onChangeHandler = (filter: string) => {
		setIsLoading(true);
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getCurrentUserContacts(filter)).then((res) => {
				const response = res.payload as ContactsGraphContract[];
				const newContactPR: AttendeeMerge = attendeeMergeMapper(filter, filter);
				setIsLoading(false);

				if (response?.length < 1 && isEmail(filter)) {
					setMergedAttendees([newContactPR]);
				}
			});
		}, 30);
	};

	return (
		<>
			<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
				<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
					<div className={styles.titleWrapper}>
						<h3>
							<IconEdit />
							{t('Update access')}
						</h3>
						<div className={styles.closeButton}>
							<div
								className={styles.icon}
								onClick={() => {
									closeModal(note);
								}}>
								<IconClose />
							</div>
						</div>
					</div>
					<form onSubmit={handleSubmit}>
						<div className={styles.flexAccess}>
							<div
								className={
									selectedAccessType === AccessRightType.EVERYONE
										? styles.selectAccess
										: styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.EVERYONE)}>
								<IconGlobe />
								{t('Public')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.INTERN ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.INTERN)}>
								<IconContact height='19' width='19' />
								{t('Intern')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.SHARED ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.SHARED)}>
								<IconMeetingsPage />
								{t('Shared')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.ONLYME ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.ONLYME)}>
								<IconLock />
								{t('Private')}
							</div>
						</div>

						{selectedAccessType === AccessRightType.SHARED && (
							<AttendeesInput
								isLoading={isLoading}
								placeHolder={t('Invite people') as string}
								attendees={selectedAddresses.filter(
									(attendee) => attendee.emailAddress.address !== user?.email,
								)}
								resetFieldOnSelect={true}
								getValue={handleAttendee}
								onChange={(e) => onChangeHandler(e.currentTarget.value)}
								handleRemoveAttendee={handleDeletePeopleShare}
								renderModal={mergedAttendees.filter((attendee) => !sharedPeopleNote?.some((sharedPeople) => sharedPeople.mail === attendee.email)).filter((mergedAttendee) => !selectedAddresses.some((attendee) => attendee.emailAddress.address === mergedAttendee.email)).map((attendee, index) => ({
									leading: (
										<Avatar size={PersonaSize.size32} name={attendee.name} mail={attendee.email} index={index} />
									),
									id: attendee.email,
									title: attendee.name,
									subTitle: attendee.email,
								}))}
							/>
						)}
						<button className={styles.confirmBtn} type='submit'>{t('Confirm')}</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default NotesFormUpdateAccessComponent;
