import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RegisterAPI from '../../api/register-api';
import { UserContract } from '../user/type';
import { AdminRegisterContract, UserRegisterContract } from './type';
import { RequestStatus, RequestStatusType, createRequestStatus } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import { RequestContract } from '../settings/type';
import { InvitationDataContract } from '../invitations/type';

interface RegisterState {
	registerAdmin?: UserContract;
	registerRequestStatus: RequestStatus;
	request?: RequestContract;
	requestRequestStatus: RequestStatus;
}

const initialState: RegisterState = {
	registerAdmin: undefined,
	registerRequestStatus: createRequestStatus(RequestStatusType.New),
	request: undefined,
	requestRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const registerAdminUser = createAsyncThunk(
	'register/users/admin',
	async (body: AdminRegisterContract): Promise<UserContract> => {
		return RegisterAPI.registerAdmin(body);
	},
);

export const registerUser = createAsyncThunk(
	'register/users/existingCompany',
	async (body: UserRegisterContract): Promise<UserContract> => {
		return RegisterAPI.registerUser(body);
	},
);

export const checkExistingCompany = createAsyncThunk('register/check/company', async (domain: string): Promise<any> => {
	return RegisterAPI.checkExistingCompany(domain);
});

export const subscribeForUpdate = createAsyncThunk(
	'register/subscribe/noMicrosoft',
	async (mail: string): Promise<any> => {
		return RegisterAPI.subscribeForUpdateNoMicrosoft(mail);
	},
);

export const checkRequestStatus = createAsyncThunk(
	'register/check/request',
	async (mail: string): Promise<RequestContract> => {
		return RegisterAPI.checkForRequestStatus(mail);
	},
);

export const sendRequest = createAsyncThunk(
	'register/send/request',
	async (body: RequestContract): Promise<RequestContract> => {
		return RegisterAPI.sendRequest(body);
	},
);

export const checkUserFromFivedaysRegister = createAsyncThunk(
	'register/check/user/fivedays',
	async (mail: string): Promise<boolean> => {
		return RegisterAPI.checkUserRegister(mail);
	},
);

export const getInvitationDataPreRegister = createAsyncThunk(
	'register/invitation/check/pre',
	async (invitationId: string): Promise<InvitationDataContract> => {
		return RegisterAPI.getInvitationDataPreRegister(invitationId);
	},
);

const slice = createSlice({
	name: 'register',
	initialState,
	reducers: {},
	extraReducers(builder): void {
		builder.addCase(registerAdminUser.pending, (state) => {
			state.registerRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(registerAdminUser.fulfilled, (state, action) => {
			state.registerRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.registerAdmin = action.payload;
		});
		builder.addCase(registerAdminUser.rejected, (state, action) => {
			state.registerRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(checkRequestStatus.pending, (state) => {
			state.requestRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(checkRequestStatus.fulfilled, (state, action) => {
			state.requestRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.request = action.payload;
		});
		builder.addCase(checkRequestStatus.rejected, (state, action) => {
			state.requestRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { reducer } = slice;
export default reducer;
