import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './tooltip-attendees.module.scss';
import { PersonaSize } from '@fluentui/react';
import { Avatar } from '../../utils/avatar';
import { useTranslation } from 'react-i18next';

interface Props {
	address: string;
	name: string;
	jobTitle: string | undefined;
	url: string;
	indexAt: number;
}

const TooltipAttendees: React.FC<Props> = ({ address, name, jobTitle, indexAt, url }) => {
	const { t } = useTranslation();

	return (
		<Tooltip
			noArrow
			place='bottom'
			id={address}
			style={{
				border: '1px solid #D7D7D7',
				borderRadius: '12px',
				backgroundColor: '#f7f7f7',
				color: 'black',
				opacity: 1,
				boxShadow: '1px 1px 2px rgba(128, 128, 128, .1)',
			}}>
			<div className={styles.tooltip}>
				<div className={styles.tooltipAvatarContainer}>
					<div className={styles.domainTooltip}>
						<img style={{ width: '100%' }} src={url} alt='favico' />
					</div>
					<div className={styles.avatarTolltip}>
						<Avatar size={PersonaSize.size28} name={name} mail={address} index={indexAt} />
					</div>
				</div>
				<div className={styles.tooltipContentContainer}>
					<p className={styles.tooltipContent}>{name}</p>
					<p className={styles.jobTitleTooltip}>{jobTitle ?? t('Extern')}</p>
				</div>
			</div>
		</Tooltip>
	);
};

export default TooltipAttendees;
