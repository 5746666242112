import React, { ReactElement } from 'react';
import { BotStatus } from '../../store/recall/type';
import {
	IconAnalysis,
	IconAnalysisDone,
	IconNotRecording,
	IconRecording,
	IconTJoining,
	IconWaiting,
} from '../utils/icon';

export interface Status {
	icon: ReactElement;
	text: string;
}

const useBotStatus = (botStatus: string | undefined): Status | undefined => {
	switch (botStatus) {
		case BotStatus.ready:
			return {
				icon: <IconTJoining />,
				text: 'Ready',
			};
		case BotStatus.joining_call:
			return {
				icon: <IconTJoining />,
				text: 'Joining the call',
			};
		case BotStatus.in_waiting_room:
			return {
				icon: <IconWaiting />,
				text: 'In waiting room',
			};
		case BotStatus.in_call_recording:
			return {
				icon: <IconRecording />,
				text: 'Recording',
			};
		case BotStatus.in_call_not_recording:
			return {
				icon: <IconNotRecording />,
				text: 'Not recording',
			};
		case BotStatus.call_ended:
			return {
				icon: <IconAnalysis />,
				text: 'Call ended',
			};
		case BotStatus.done:
			return {
				icon: <IconAnalysis />,
				text: 'Analysis in progress',
			};
		case BotStatus.analysis_failed:
			return {
				icon: <IconNotRecording />,
				text: 'Analysis failed',
			};
		case BotStatus.analysis_done:
			return {
				icon: <IconAnalysisDone />,
				text: 'Analysis done',
			};

		case BotStatus.media_expired:
			return {
				icon: <IconNotRecording />,
				text: 'Media expired',
			};

		case BotStatus.participant_in_waiting_room:
			return {
				icon: <IconRecording />,
				text: 'participant in waiting room',
			};

		case BotStatus.recording_done:
			return {
				icon: <IconAnalysis />,
				text: 'Recording done',
			};
	}
};

export default useBotStatus;
