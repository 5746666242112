import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { randomColor } from './random-color';
import styles from './utils.module.scss';
import { getAvatarForUserByEmail, getUsersAvatarFromGoogle } from '../../store/avatars/slice';
import { AvatarData } from '../../store/avatars/type';
import { checkUserFromFivedaysShared } from '../../store/sharing/slice';
import { AuthenticationProvider } from '../../store/user/type';

interface AvatarProps {
	name: string;
	mail: string;
	index: number;
	showBorder?: boolean;
	size?: PersonaSize;
}

// ---------------------------------

const initializeColor = (mail: string, index: number): string => {
	const storedIndex = localStorage.getItem(`storedMailIndex/${mail}`);
	if (storedIndex) {
		return randomColor(+storedIndex);
	} else {
		localStorage.setItem(`storedMailIndex/${mail}`, index.toString());
		return randomColor(index);
	}
};

export const Avatar: React.FC<AvatarProps> = ({ name, mail, index, showBorder = true, size }) => {
	const dispatch = useAppDispatch();
	const { usersAvatars } = useAppSelector((state) => state.avatars);
	const existingAvatar = usersAvatars[mail];
	const isFiveDays = usersAvatars[mail];
	const [avatar, setAvatar] = React.useState<string | undefined>(existingAvatar ? existingAvatar.avatar : undefined);
	const [color] = React.useState(() => initializeColor(mail, index));
	const [isLoading, setIsLoading] = React.useState(false);
	const [isFromFivedays, setIsFromFivedays] = React.useState(isFiveDays ? isFiveDays : false);
	const { user } = useAppSelector((state) => state.user);

	React.useEffect(() => {
		if (!existingAvatar) {
			if (mail && name) {
				if (user) {
					if (user.provider === AuthenticationProvider.Microsoft) {
						dispatch(getAvatarForUserByEmail(mail)).then((response) => {
							const avatarData = response.payload as AvatarData;
							if (response.payload !== undefined) {
								setAvatar(avatarData.avatar);
							} else {
								setAvatar(undefined);
							}

							setIsFromFivedays(avatarData.isFiveDays ? avatarData.isFiveDays : false);

							setIsLoading(false);
						});
					} else if (user.provider === AuthenticationProvider.Google) {
						if (user.userName.toLocaleLowerCase() === mail.toLocaleLowerCase()) {
							setAvatar(user.picture);
							setIsFromFivedays(true);
						} else {
							dispatch(getUsersAvatarFromGoogle(mail)).then((response) => {
								const avatarData = response.payload as AvatarData;
								if (response.payload !== undefined) {
									setAvatar(avatarData.avatar);
								} else {
									setAvatar(undefined);
								}

								setIsFromFivedays(avatarData.isFiveDays ? avatarData.isFiveDays : false);

								setIsLoading(false);
							});
						}
					}
				} else {
					setAvatar(undefined);
					setIsLoading(false);
					dispatch(checkUserFromFivedaysShared(mail)).then((res) => {
						const isUser = res.payload as boolean;
						setIsFromFivedays(isUser);
					});
				}
			} else {
				setAvatar(undefined);
				setIsLoading(false);
			}
		} else {
			setAvatar(existingAvatar.avatar);
			setIsFromFivedays(existingAvatar.isFiveDays);
		}
	}, [mail, name, index, dispatch, user, usersAvatars]);

	return (
		<div style={{ display: 'flex', alignItems: 'center' }} key={index}>

				<>
					<div
						key={mail}
						className={isFiveDays ? isFiveDays.isFiveDays ? styles.fivedays : styles.nonFivedays : undefined}
						style={{ border: showBorder ? '' : 'none' }}>
						<Persona
							showInitialsUntilImageLoads
							imageShouldFadeIn={false}
							text={name}
							secondaryText={mail}
							size={size ?? PersonaSize.size24}
							initialsColor={color}
							hidePersonaDetails={true}
							imageUrl={avatar}
							className={styles.avatarImage}
						/>
					</div>
				</>
		
		</div>
	);
};
