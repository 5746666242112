import {
	MeetingContract,
	FileContract,
	TagContract,
	InformationsContract,
	MeetingCalendarContract,
} from '../store/meetings/types';
import HttpClient from '../shared/http/http-client';
import { ApiUrls, UrlParamBuilder } from './api-urls';
import { UpdateAndCreateGraphEventContract } from '../shared/types/meetings-graph-interface';
import { UpdateMeetingFilesAccess, UpdateMeetingTagsContract } from '../store/shared/types';

export default class MeetingsAPI {
	public static async getMeetings(startDate: Date, endDate: Date): Promise<MeetingContract[]> {
		const searchParams = new URLSearchParams([
			['startDate', startDate.toISOString()],
			['endDate', endDate.toISOString()],
		]);
		const { data } = await HttpClient.get<MeetingContract[]>(ApiUrls.GetMeetings, undefined, searchParams);
		return data;
	}

	public static async getMeetingsCalendar(startDate: Date, endDate: Date): Promise<MeetingCalendarContract[]> {
		const searchParams = new URLSearchParams([
			['startDate', startDate.toISOString()],
			['endDate', endDate.toISOString()],
		]);
		const { data } = await HttpClient.get<MeetingCalendarContract[]>(
			ApiUrls.GetMeetingsCalendar,
			undefined,
			searchParams,
		);
		return data;
	}

	public static async getMeetingsForReccurence(
		startDate: Date,
		endDate: Date,
		seriesId: string,
	): Promise<MeetingContract[]> {
		const searchParams = new URLSearchParams([
			['startDate', startDate.toISOString()],
			['endDate', endDate.toISOString()],
		]);
		const { data } = await HttpClient.get<MeetingContract[]>(
			ApiUrls.GetMeetingsForReccurence,
			new UrlParamBuilder().addSeriesId(seriesId).build(),
			searchParams,
		);
		return data;
	}

	public static async getMeetingsForOption(filter: string): Promise<MeetingContract[]> {
		const { data } = await HttpClient.get<MeetingContract[]>(
			ApiUrls.GetMeetingForSelectOption,
			new UrlParamBuilder().addFilter(filter).build(),
		);
		return data;
	}

	public static async getMeetingsDescription(iCalUId: string): Promise<InformationsContract> {
		const { data } = await HttpClient.get<InformationsContract>(
			ApiUrls.getDescriptionforTheMeeting,
			new UrlParamBuilder().addEventiCalUId(iCalUId).build(),
		);
		return data;
	}

	public static async getLastMeetings(): Promise<MeetingContract[]> {
		const { data } = await HttpClient.get<MeetingContract[]>(ApiUrls.getLastMeetings);
		return data;
	}

	public static async getMeeting(eventId: string): Promise<MeetingContract> {
		const { data } = await HttpClient.get<MeetingContract>(
			ApiUrls.GetMeeting,
			new UrlParamBuilder().addEventId(eventId).build(),
		);
		return data;
	}

	public static async createMeeting(meeting: UpdateAndCreateGraphEventContract): Promise<MeetingContract> {
		const { data } = await HttpClient.post<UpdateAndCreateGraphEventContract, MeetingContract>(
			ApiUrls.CreateMeeting,
			meeting,
		);
		return data;
	}

	public static async updateMeeting(
		body: UpdateAndCreateGraphEventContract,
		eventId: string,
	): Promise<MeetingContract> {
		const { data } = await HttpClient.patch<UpdateAndCreateGraphEventContract, MeetingContract>(
			ApiUrls.PatchMeetings,
			body,
			new UrlParamBuilder().addEventId(eventId).build(),
		);
		return data;
	}

	public static async deleteMeeting(eventId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteMeeting,
			new UrlParamBuilder().addEventId(eventId).build(),
		);
		return data;
	}

	public static async addFileToMeeting(body: FormData, selectedMeetingID: string): Promise<FormData> {
		const { data } = await HttpClient.postFile<FormData, FormData>(
			ApiUrls.AddMeetingsFile,
			body,
			new UrlParamBuilder().addEventiCalUId(selectedMeetingID).build(),
		);
		return data;
	}

	public static async deleteFileToMeeting(selectedMeetingID: string, fileId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteMeetingFile,
			new UrlParamBuilder().addEventId(selectedMeetingID).addFileId(fileId).build(),
		);
		return data;
	}

	public static async updateFileToMeetingAccess(
		body: UpdateMeetingFilesAccess,
		selectedMeetingID: string,
		fileId: string,
	): Promise<FileContract> {
		const { data } = await HttpClient.patch<UpdateMeetingFilesAccess, FileContract>(
			ApiUrls.UpdateMeetingFile,
			body,
			new UrlParamBuilder().addEventId(selectedMeetingID).addFileId(fileId).build(),
		);
		return data;
	}

	public static async updateTagsMeeting(tags: TagContract[], selectedMeetingID: string): Promise<TagContract[]> {
		const { data } = await HttpClient.patch<UpdateMeetingTagsContract, TagContract[]>(
			ApiUrls.PatchMeetingsTags,
			{ tags },
			new UrlParamBuilder().addEventiCalUId(selectedMeetingID).build(),
		);
		return data;
	}

	public static async downloadFileMeeting(selectedMeetingID: string, fileId: string): Promise<Blob> {
		const { data } = await HttpClient.getFile<Blob>(
			ApiUrls.DownloadMeetingFile,
			new UrlParamBuilder().addEventiCalUId(selectedMeetingID).addFileId(fileId).build(),
		);
		return data;
	}
}
