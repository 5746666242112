import HttpClient from '../shared/http/http-client';
import { NotesContract, People, updateTags } from '../store/notes/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';
import { TagContract } from '../store/meetings/types';

export default class NotesAPI {
	public static async getOneNotes(noteId: string): Promise<NotesContract> {
		const { data } = await HttpClient.get<NotesContract>(
			ApiUrls.GetOneNote,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}

	public static async getAllNotes(): Promise<NotesContract[]> {
		const { data } = await HttpClient.get<NotesContract[]>(ApiUrls.GetAllNote);
		return data;
	}

	public static async getAllNotesForAMeeting(iCalUId: string): Promise<NotesContract[]> {
		const { data } = await HttpClient.get<NotesContract[]>(
			ApiUrls.getNotesForASelectedMeeting,
			new UrlParamBuilder().addEventiCalUId(iCalUId).build(),
		);
		return data;
	}

	public static async createNotes(body: NotesContract): Promise<NotesContract> {
		const { data } = await HttpClient.post<NotesContract, NotesContract>(ApiUrls.PostNotes, body);
		return data;
	}

	public static async deleteNote(noteId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteNote,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}

	public static async updateNote(noteId: string, body: NotesContract): Promise<NotesContract> {
		const { data } = await HttpClient.patch<NotesContract, NotesContract>(
			ApiUrls.UpdateNote,
			body,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}

	public static async addTags(body: updateTags): Promise<TagContract> {
		const { data } = await HttpClient.post<updateTags, TagContract>(ApiUrls.AddTagsToNote, body);
		return data;
	}

	public static async deleteTagsFromNote(noteId: string, tagsId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteTagsFromNote,
			new UrlParamBuilder().addNotesId(noteId).addtagsId(tagsId).build(),
		);
		return data;
	}

	public static async getPeopleShare(noteId: string): Promise<People[]> {
		const { data } = await HttpClient.get<People[]>(
			ApiUrls.GetPeopleShare,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}

	public static async deleteAPeopleShare(noteId: string, mail: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeletePeopleShare,
			new UrlParamBuilder().addNotesId(noteId).addUserMail(mail).build(),
		);
		return data;
	}

	public static async addPeopleShare(body: People, noteId: string): Promise<People> {
		const { data } = await HttpClient.post<People, People>(
			ApiUrls.PostPeopleShare,
			body,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}
}
