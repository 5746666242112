export enum ConfirmMailSteps {
	CONFIRM = 'CONFIRM',
	CHECKTOKEN = 'CHECKTOKEN',
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
}

export enum SharingMeetingInvalidReason {
	INCORRECT_USER = 'INCORRECT_USER',
	NOT_FOUND = 'NOT_FOUND',
	FIVEDAYS_USER = 'FIVEDAYS_USER',
	NOT_ATTENDEE = 'NOT_ATTENDEE',
}

export class SharingDataContract {
	constructor(public success: boolean, public invalidReason?: SharingMeetingInvalidReason) {}
}

export enum CheckTokenInvalidReason {
	WRONG_CODE = 'WRONG_CODE',
	TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export class CheckTokenContract {
	constructor(public success: boolean, public invalidReason?: CheckTokenInvalidReason) {}
}
