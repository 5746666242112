import { useEffect, useRef } from 'react';
import * as Y from 'yjs';
import { WebsocketProvider } from 'y-websocket';
import { RuntimeConfig } from '../../../config/runtime-config';

interface Connection {
	doc: Y.Doc;
	provider: WebsocketProvider;
	lastActive: number;
}

const RECONNECT_MAX_ATTEMPS = 60;

const useWebSocketConnections = () => {
	const connections = useRef(new Map<string, Connection>());

	useEffect(() => {
		const intervalId = setInterval(() => {
			const now = Date.now();
			const timeout = 60000; // 1 minute

			connections.current.forEach((connection, noteId) => {
				if (now - connection.lastActive > timeout) {
					connection.provider.disconnect();
					connections.current.delete(noteId);
				}
			});
		}, 10000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const connectWithRetry = (noteId: string) => {
		const newDoc = new Y.Doc();
		const newProvider = new WebsocketProvider(RuntimeConfig.getWebSocketURL(), noteId, newDoc);

		const reconnectWithBackoff = (attempt = 1) => {
			const maxDelay = 3000;
			const delay = Math.min(500 * attempt, maxDelay);

			setTimeout(() => {
				if (attempt < RECONNECT_MAX_ATTEMPS) {
					newProvider.connect();
					attempt += 1;
					reconnectWithBackoff(attempt);
				}
			}, delay);
		};

		let attempt = 0;

		const handleStatus = (event: { status: string }) => {
			if (event.status === 'disconnected' && attempt < RECONNECT_MAX_ATTEMPS) {
				attempt += 1;
				reconnectWithBackoff(attempt);
			}
		};

		newProvider.on('status', handleStatus);
		newProvider.connect();

		connections.current.set(noteId, { doc: newDoc, provider: newProvider, lastActive: Date.now() });

		return () => {
			newProvider.off('status', handleStatus);
			if (newProvider.ws?.readyState === WebSocket.OPEN) {
				newProvider.disconnect();
			}
		};
	};

	const getConnection = (noteId: string) => {
		if (!connections.current.has(noteId)) {
			return connectWithRetry(noteId);
		}

		const connection = connections.current.get(noteId);
		if (connection) {
			connection.lastActive = Date.now();
			return connection;
		}
	};

	return { getConnection };
};

export default useWebSocketConnections;
