/* eslint-disable unicorn/prefer-add-event-listener */
/* eslint-disable unicorn/prefer-number-properties */

import JSZip from 'jszip';

export const handlePowerPointPreview = (fileBlob: Blob) => {
	const reader = new FileReader();
	reader.onload = async (e) => {
		const arrayBuffer = e.target?.result as ArrayBuffer;
		const zip = await JSZip.loadAsync(arrayBuffer);

		const slides = Object.keys(zip.files).filter((fileName) => fileName.startsWith('ppt/slides/slide'));

		const previewWindow = window.open('', '_blank', 'popup');
		if (previewWindow) {
			previewWindow.document.write('<html><head><title>PowerPoint Preview</title><style>');
			previewWindow.document.write(`
				body { font-family: Arial, sans-serif; }
				.slide { margin: 0 auto; padding: 20px; border: 1px solid black; width: 80%; height: 600px; position: relative; overflow: hidden; background-color: #f9f9f9; text-align: center }
				.title { font-size: 24px; font-weight: bold; margin-bottom: 10px; }
				.subtitle { font-size: 20px; font-weight: bold; margin-bottom: 10px; }
				.textbox { font-size: 16px; margin-bottom: 10px; }
				.list-item { margin-left: 30px; list-style-type: disc; }
				.img { position: absolute; max-width: 100%; height: auto; }
			`);
			previewWindow.document.write('</style></head><body>');

			const DRAWING_NAMESPACE = 'http://schemas.openxmlformats.org/drawingml/2006/main';
			const SLIDE_NAMESPACE = 'http://schemas.openxmlformats.org/presentationml/2006/main';

			for (const slidePath of slides) {
				const slideXml = await zip.files[slidePath].async('text');
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(slideXml, 'application/xml');

				previewWindow.document.write('<div class="slide">');

				const shapeElements = xmlDoc.getElementsByTagNameNS(SLIDE_NAMESPACE, 'sp');

				for (const shapeElement of shapeElements) {
					const placeholder = shapeElement.getElementsByTagNameNS(SLIDE_NAMESPACE, 'ph')[0];

					let className = 'textbox';
					if (placeholder) {
						const type = placeholder.getAttribute('type');
						if (type === 'ctrTitle') className = 'title';
						if (type === 'subTitle') className = 'subtitle';
					}

					const textElements = shapeElement.getElementsByTagNameNS(DRAWING_NAMESPACE, 't');
					for (const textElement of textElements) {
						const textContent = textElement.textContent || '';

						const bulletPoint = shapeElement.getElementsByTagNameNS(DRAWING_NAMESPACE, 'buChar')[0];
						if (bulletPoint) {
							className = 'list-item';
						}

						previewWindow.document.write(`<div class="${className}">${textContent}</div>`);
					}
				}

				const blipElements = xmlDoc.getElementsByTagNameNS(DRAWING_NAMESPACE, 'blip');
				for (const blipElement of blipElements) {
					const rId = blipElement.getAttribute('r:embed');
					if (!rId) continue;

					const relPath = `ppt/slides/_rels/${slidePath.split('/').pop()}.rels`;
					const relFile = zip.files[relPath];

					if (relFile) {
						const relXml = await relFile.async('text');
						const relDoc = parser.parseFromString(relXml, 'application/xml');
						const imagePath = relDoc.querySelector(`Relationship[Id="${rId}"]`)?.getAttribute('Target');

						if (imagePath) {
							const imageFullPath = `ppt/${imagePath.replace('../', '')}`;
							const imageBlob = await zip.files[imageFullPath].async('blob');
							const imageUrl = URL.createObjectURL(imageBlob);

							previewWindow.document.write(
								`<img src="${imageUrl}" class="img" style="left: 100px; top: 100px;">`,
							);
						}
					}
				}

				previewWindow.document.write('</div>');
			}

			previewWindow.document.write('</body></html>');
			previewWindow.document.close();
		}
	};
	reader.readAsArrayBuffer(fileBlob);
};
