import React from 'react';
import styles from './my-preferences.component.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { days } from '../../../shared/utils/days';
import { updateCurrentUser, getCurrentUser } from '../../../store/user/slice';
import { UpdateCurrentUserContract, WorkingHoursContract } from '../../../store/user/type';
import FormWorkHours from './form-work-hours';
import { toast } from '../../../shared/components/modals/toast/toast-manager';
import { UserLanguage } from '../../../store/shared/types';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function MyPreferences() {
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [from] = React.useState('');
	const [to] = React.useState('');
	const [workingHours, setWorkingHours] = React.useState<Array<WorkingHoursContract>>([]);

	type FormData = {
		language: string;
	};

	const dataSchema = yup.object().shape({
		language: yup.string().required(),
	});

	const {
		register,
		handleSubmit,
		formState: { isValid },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	React.useEffect(() => {
		user && setWorkingHours(user.preferences.workingHours.map((w) => w));
	}, [user]);

	const handleWorkingHoursDelete = (day: number) => {
		user &&
			setWorkingHours((prevState) => {
				const newArray = prevState.filter((w) => w.weekday !== day);
				return newArray;
			});
	};

	const handleWorkingHoursChange = (day: number, fromTime: string, toTime: string) => {
		user &&
			setWorkingHours((prevState) => {
				const oldArray = [...prevState];
				const weekDay = oldArray.findIndex((w) => w.weekday === day);
				if (weekDay !== -1) {
					oldArray[weekDay] = new WorkingHoursContract(day, fromTime, toTime);
				} else {
					oldArray.push(new WorkingHoursContract(day, fromTime, toTime));
				}
				return oldArray;
			});
	};

	const onSave = (data: FormData) => {
		if (user) {
			const preferences = Object.assign({}, user.preferences, {
				workingHours,
				language: data.language,
			});

			dispatch(updateCurrentUser(new UpdateCurrentUserContract(preferences, user.userId))).then(() => {
				dispatch(getCurrentUser());

				toast.show({
					id: user?.graphUserId,
					title: t('Preferences update success') as string,
					duration: 10000,
					type: 'success',
				});
			});
		}
	};

	return (
		<div className={styles.container}>
			<form onSubmit={handleSubmit(onSave)}>
				<h2>{t('Working hours')}</h2>
				<p>{t('Choose your working hours when you usually work and are available')}</p>
				{days.map((day, index) => (
					<FormWorkHours
						dayChecked={day}
						from={from}
						to={to}
						onChange={(fromTime, toTime) => handleWorkingHoursChange(day, fromTime, toTime)}
						onUnChecked={() => handleWorkingHoursDelete(day)}
						key={index}
					/>
				))}
				<h2>{t('Language')}</h2>
				<p>{t('Choose the language in which your Fivedays app will run')}</p>
				<div className={styles.lang}>
					<label>{t('Language')}</label>
					<br />
					<select defaultValue={user?.preferences.language} {...register('language')}>
						<option value={UserLanguage.EN}>{t('English')}</option>
						<option value={UserLanguage.FR}>{t('French')}</option>
						<option value={UserLanguage.NL}>{t('Dutch')}</option>
						<option value={UserLanguage.DE}>{t('German')}</option>
					</select>
				</div>
				<button type='submit' disabled={!isValid}>
					{t('Save')}
				</button>
				<button className={styles.cancel} type='button'>
					{t('Cancel')}
				</button>
			</form>
		</div>
	);
}

export default MyPreferences;
