import React from 'react';
import styles from '../../settings/components/user-management/settings-user-management.component.module.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { extractDomain } from '../../../shared/utils/domainext';
import {
	getCompanyForUser,
	downloadCompanyLogo,
	updateCompany,
	deleteCompanyLogo,
	getAllCompanies,
	updateCompanies,
	updateBilling,
} from '../../../store/settings/slice';
import { toast } from '../../../shared/components/modals/toast/toast-manager';
import { IconClose, IconSimplePlus, IconMeetings } from '../../../shared/utils/icon';
import { UserRole, UserRoleFivedays } from '../../../store/user/type';
import { countries } from '../../../shared/utils/countries';

import { getDateFormatL, getDateFormatWithYear } from '../../../shared/utils/date.util';

import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CompanyContract } from '../../../store/settings/type';
import TodoDateSelect from '../../todos/components/date-select';
import { TodosContract } from '../../../store/todos/type';
import { BillingPlan, BillingContract } from '../../../store/billing/type';
import { getBillingInfoForAUser } from '../../../store/billing/slice';

interface Props {
	close: () => void;
	companyId?: string;
	company?: CompanyContract;
}

const FivedaysUserEdit: React.FC<Props> = ({ close, companyId, company }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const [userRole, setUserRole] = React.useState<string[]>(['User']);
	const [openUploadLogo, setOpenUploadLogo] = React.useState(false);
	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const { billingInfo } = useAppSelector((state) => state.billing);

	type FormData = {
		name: string;
		scale: string;
		industry: string;
		street: string;
		city: string;
		logo: FileList;
		country: string;
		postalCode: string;
		vatNumber: string;
		billingPlan: BillingPlan;
		dueDate: string;
	};

	const dataSchema = yup.object().shape({
		name: yup.string().required(),
		scale: yup.string(),
		industry: yup.string(),
		street: yup.string(),
		city: yup.string(),
		postalCode: yup.string(),
		vatNumber: yup
			.string()
			.optional()
			.matches(/^[A-Z]{2}[0-9A-Z]{8,12}$/i, { excludeEmptyString: true }),
		dueDate: yup.string(),
	});

	const {
		register,
		handleSubmit,
		control,
		getValues,
		formState: { isValid },
		setValue,
		trigger,
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const [animation, setAnimation] = React.useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			close();
			setAnimation(false);
		}, 300);
	};

	const onSubmit = (data: FormData) => {
		if (user && company) {
			const formData = new FormData();

			formData.append('companyId', company.id);
			formData.append('name', data.name.toLowerCase());

			if (data.logo) {
				formData.append('logoName', data.logo[0].name);
				formData.append('file', data.logo[0]);
			}

			if (data.country) {
				formData.append('country', data.country);
			} else if (company.country) {
				formData.append('country', company.country);
			}

			if (data.street) {
				formData.append('street', data.street);
			} else {
				formData.append('street', company.street);
			}

			if (data.city) {
				formData.append('city', data.city);
			} else {
				formData.append('city', company.city);
			}

			if (data.vatNumber) {
				formData.append('vatNumber', data.vatNumber);
			} else {
				formData.append('vatNumber', company.vatNumber);
			}

			if (data.scale) {
				formData.append('companyScale', data.scale);
			} else {
				formData.append('companyScale', company.companyScale);
			}

			if (data.industry) {
				formData.append('industry', data.industry);
			} else {
				formData.append('industry', company.industry);
			}

			if (data.postalCode) {
				formData.append('postCode', data.postalCode);
			} else {
				formData.append('postCode', company.postCode);
			}

			if (data.billingPlan) {
				formData.append('billingPlan', data.billingPlan);
			} else {
				formData.append('billingPlan', company.billingPlan.plan);
			}

			if (data.billingPlan) {
				formData.append('dueDate', data.dueDate);
			} else {
				formData.append('dueDate', company.billingPlan.endDateTrial);
			}

			if (data.logo) {
				if (
					data.logo[0].type === 'image/png' ||
					data.logo[0].type === 'image/jpeg' ||
					data.logo[0].type === 'image/jpg' ||
					data.logo[0].type === 'image/svg' ||
					data.logo[0].type === 'image/webp'
				) {
					dispatch(updateCompany(formData)).then((res) => {
						const newBody = res.payload as CompanyContract;

						const billingPlan = formData.get('billingPlan') as string;
						const dueDatePlan = formData.get('dueDate') as string;

						let billingPlanEnum: BillingPlan;
						switch (billingPlan) {
							case BillingPlan.FREEBETA:
								billingPlanEnum = BillingPlan.FREEBETA;
								break;
							case BillingPlan.TRIAL:
								billingPlanEnum = BillingPlan.TRIAL;
								break;
							case BillingPlan.PERUSER:
								billingPlanEnum = BillingPlan.PERUSER;
								break;
							case BillingPlan.UNLIMITED:
								billingPlanEnum = BillingPlan.UNLIMITED;
								break;
							default:
								console.error('Invalid billing plan:', billingPlan);
								return;
						}

						dispatch(
							updateBilling({ companyId: company.id, newPlan: billingPlanEnum, dueDate: dueDatePlan }),
						);
						dispatch(updateCompanies(newBody));

						toast.show({
							id: user?.graphUserId,
							title: t('Company details update success') as string,
							duration: 10000,
							type: 'success',
						});

						closeModal();
					});
				} else {
					toast.show({
						id: user?.graphUserId,
						title: t('Please, provide an image type file as logo') as string,
						duration: 10000,
						type: 'failed',
					});
				}
			} else {
				dispatch(updateCompany(formData)).then((res) => {
					const newBody = res.payload as CompanyContract;

					const billingPlan = formData.get('billingPlan') as string;
					const dueDatePlan = formData.get('dueDate') as string;

					let billingPlanEnum: BillingPlan;
					switch (billingPlan) {
						case BillingPlan.FREEBETA:
							billingPlanEnum = BillingPlan.FREEBETA;
							break;
						case BillingPlan.TRIAL:
							billingPlanEnum = BillingPlan.TRIAL;
							break;
						case BillingPlan.PERUSER:
							billingPlanEnum = BillingPlan.PERUSER;
							break;
						case BillingPlan.UNLIMITED:
							billingPlanEnum = BillingPlan.UNLIMITED;
							break;
						default:
							console.error('Invalid billing plan:', billingPlan);
							return;
					}

					dispatch(updateBilling({ companyId: company.id, newPlan: billingPlanEnum, dueDate: dueDatePlan }));
					dispatch(updateCompanies(newBody));

					toast.show({
						id: user?.graphUserId,
						title: t('Company details update success') as string,
						duration: 10000,
						type: 'success',
					});

					closeModal();
				});
			}
		}
	};

	const modalRef = React.useRef<HTMLDivElement>(null);
	const handleUploadFile = () => {
		setOpenUploadLogo(!openUploadLogo);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleUploadFile();
				}, 200);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleUploadFile]);

	const handleDeleteConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleDeleteFile = () => {
		user &&
			dispatch(deleteCompanyLogo()).then(() => {
				dispatch(getCompanyForUser());
				handleDeleteConfirmBox();

				toast.show({
					id: user?.graphUserId,
					title: t('Logo delete success') as string,
					duration: 10000,
					type: 'success',
				});
			});
	};

	const logo: FileList = getValues('logo') as unknown as FileList;
	if (logo && logo.length === 1 && openUploadLogo) {
		setOpenUploadLogo(false);
	}

	const [userDomain, setUserDomain] = React.useState<string>('');

	React.useEffect(() => {
		if (user) {
			const domain = extractDomain(user.email);
			setUserDomain(domain);
		}
	}, [user, setUserDomain]);

	const [datePicker, setDatePicker] = React.useState(false);

	const handleDatePicker = () => {
		setDatePicker(!datePicker);
	};

	const [date, setDate] = React.useState<Date>(
		company?.billingPlan.endDateTrial ? new Date(company.billingPlan.endDateTrial) : new Date(),
	);

	const handleDateSelect = (dateSelectPicker: Date) => {
		setDate(dateSelectPicker);
		setValue('dueDate', dateSelectPicker.toString());
		trigger('dueDate');
		handleDatePicker();
	};

	const [LogoCompany, setLogoCompany] = React.useState<string | undefined>(undefined);

	React.useEffect(() => {
		dispatch(getBillingInfoForAUser());
	}, [dispatch]);

	React.useEffect(() => {
		if (company?.companyLogo) {
			dispatch(downloadCompanyLogo()).then((response) => {
				if (response.payload !== undefined) {
					setLogoCompany(response.payload as string);
				} else {
					setLogoCompany(undefined);
				}
			});
		}
	}, [company]);

	return (
		<div className={`${styles.update} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div
					className={styles.icon}
					onClick={() => {
						closeModal();
					}}>
					<IconClose />
				</div>
				<h1>{t('Edit company')}</h1>
				<form onSubmit={handleSubmit(onSubmit)}>
					{UserRoleFivedays.SUPERADMIN && (
						<>
							<div className={styles.inputWrapper}>
								<label>{t('Plan')}</label>
								<select defaultValue={company?.billingPlan.plan} {...register('billingPlan')}>
									<option value='' disabled hidden>
										{t('Which plan') as string}
									</option>
									<option value={BillingPlan.FREEBETA}>{t(BillingPlan.FREEBETA)}</option>
									<option value={BillingPlan.TRIAL}>{t(BillingPlan.TRIAL)}</option>
									<option value={BillingPlan.PERUSER}>{t(BillingPlan.PERUSER)}</option>
									<option value={BillingPlan.UNLIMITED}>{t(BillingPlan.UNLIMITED)}</option>
								</select>{' '}
							</div>
							<input
								style={{ visibility: 'hidden', display: 'none' }}
								type='text'
								defaultValue={company?.billingPlan.endDateTrial}
								{...register('dueDate')}
							/>
							<div className={styles.startDate}>
								<div className={styles.startDateButton} onClick={() => handleDatePicker()}>
									<div>
										<span className={styles.titleLabel}>{t('Due date')}</span>

										<div className={styles.datePickerTime}>
											<span>
												{date !== null && date !== undefined
													? getDateFormatWithYear(date.toString())
													: t('No due date')}
											</span>
										</div>
									</div>

									<div className={styles.icon}>
										<IconMeetings />
									</div>
								</div>

								{datePicker && (
									<TodoDateSelect
										date={date ? date : new Date()}
										handleDatePicker={handleDateSelect}
										close={handleDatePicker}
									/>
								)}
							</div>
						</>
					)}
					<div className={styles.inputWrapper}>
						<label>{t('Company name')}</label>
						<input defaultValue={company?.name} {...register('name')} />
					</div>
					<div className={styles.inputWrapper}>
						<label>{t('Size of the company')}</label>
						<select defaultValue={company?.companyScale} {...register('scale')}>
							<option value='' disabled hidden>
								{t('How many people are in your company') as string}
							</option>
							<option value='1 to 5'>{t('1 to 5')}</option>
							<option value='6 to 20'>{t('6 to 20')}</option>
							<option value='21 to 50'>{t('21 to 50')}</option>
							<option value='51 to 100'>{t('51 to 100')}</option>
							<option value='100+'>{t('100+')}</option>
						</select>
					</div>
					<div className={styles.inputWrapper}>
						<label>{t('Industry')}</label>
						<input defaultValue={company?.industry} {...register('industry')} />
					</div>
					<div className={styles.inputWrapper}>
						<label>{t('Company logo')}</label>
						{company?.companyLogo ? (
							<div className={styles.addLogo}>
								<img className={styles.logo} src={LogoCompany} alt='company logo' />
								<div className={styles.iconClose} onClick={() => handleDeleteConfirmBox()}>
									<IconClose />
								</div>
							</div>
						) : (
							<div className={styles.addLogo}>
								<div className={styles.icon} onClick={() => handleUploadFile()}>
									<IconSimplePlus />
								</div>
								{logo && logo.length === 1 ? (
									<p>{logo[0].name}</p>
								) : (
									<p style={{ cursor: 'pointer' }} onClick={() => handleUploadFile()}>
										{t('Upload logo')}
									</p>
								)}
								{openUploadLogo && (
									<div className={styles.containerLogo} ref={modalRef}>
										<input type={'file'} {...register('logo')} />
									</div>
								)}
							</div>
						)}
					</div>
					{openConfirmBox && (
						<div className={styles.confirmBox}>
							<div>
								<p>{t('Are you sure you want to delete this logo?')}</p>
								<button onClick={() => handleDeleteFile()}>{t('Yes')}</button>
								<button
									onClick={() => {
										handleDeleteConfirmBox();
									}}>
									{t('Cancel')}
								</button>
							</div>
						</div>
					)}
					<div className={styles.inputWrapper}>
						<label>{t('Country')}</label>
						{company && (
							<Controller
								{...register('country')}
								name='country'
								control={control}
								defaultValue={t(company.country) as string}
								render={({ field }) => (
									<Stack spacing={2}>
										<Autocomplete
											id='country'
											disableClearable
											freeSolo
											{...field}
											onChange={(event, newValue) => {
												field.onChange(newValue);
											}}
											options={countries.map((country: any) => t(country) as string)}
											renderInput={(param) => (
												<TextField
													className={styles.textArea}
													sx={{
														'& .MuiOutlinedInput-root': {
															border: 'none',
														},
														'& .MuiOutlinedInput-notchedOutline': {
															border: 'none',
														},
														'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
															{
																padding: 0,
															},
														'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
															{
																paddingTop: 0,
																paddingBottom: 0,
																paddingLeft: 0,
																paddingRight: 0,
															},
														'backgroundColor': 'white',
														'marginTop': '15px',
													}}
													{...param}
												/>
											)}
										/>
									</Stack>
								)}
							/>
						)}
					</div>
					<div className={styles.inputWrapper}>
						<label>{t('City')}</label>
						<input defaultValue={company?.city} {...register('city')} />
					</div>
					<div className={styles.inputWrapper}>
						<label>{t('Street')}</label>
						<input defaultValue={company?.street} {...register('street')} />
					</div>
					<div className={styles.inputWrapper}>
						<label>{t('Postal code')}</label>
						<input defaultValue={company?.postCode} {...register('postalCode')} />
					</div>
					<div className={styles.inputWrapper}>
						<label>{t('VAT number')}</label>
						<input defaultValue={company?.vatNumber} {...register('vatNumber')} />
					</div>
					<br />
					<button type='submit'>{t('Save')}</button>
					<button
						className={styles.cancel}
						type='button'
						onClick={() => {
							closeModal();
						}}>
						{t('Cancel')}
					</button>
				</form>
			</div>
		</div>
	);
};

export default FivedaysUserEdit;
