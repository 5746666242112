import React from 'react';
import styles from './shimmers-todos-styles.module.scss';
import ShimmerText from '../../../../../../../shared/components/shimmers/shimmer-text.component';
import ShimmerAvatar from '../../../../../../../shared/components/shimmers/shimmer-avatar.component';

const CopilotTranscriptShimmer = () => {
	return (
		<div>
			<div className={styles.text}>
				<div className={styles.flex}>
					<ShimmerAvatar col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
				</div>
				<br />
				<div className={styles.flex}>
					<ShimmerAvatar col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
				</div>
				<br />
				<div className={styles.flex}>
					<ShimmerAvatar col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
				</div>
				<br />
				<div className={styles.flex}>
					<ShimmerAvatar col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
				</div>
				<br />
				<div className={styles.flex}>
					<ShimmerAvatar col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
				</div>
				<br />
				<div className={styles.flex}>
					<ShimmerAvatar col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
				</div>
				<br />
			</div>
		</div>
	);
};

export default CopilotTranscriptShimmer;
