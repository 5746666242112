import React, { useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import ModalLayout from '../../modalLayout/modal-layout.component';
import styles from './tag-modal.module.scss';
import { SwatchColorPicker } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { TagContract } from '../../../../store/meetings/types';
import Tags from '../../tags/tags.component';
import { IconRightChev } from '../../../utils/icon';
import { hexToRgb } from '../../../utils/hextorgb';
import { SectionType } from '../../../hooks/use-todos-mutations.hook';

interface Props {
    tags: TagContract[],
    handleTags: (tag: string, color: string) => void;
    handleDeleteTag: (tag: TagContract) => void,
    sectionType: SectionType,
}

const colorCells = [
    { id: '#D50101', label: 'Red', color: '#D50101' },
    { id: '#3F45EA', label: 'Blue', color: '#3F45EA' },
    { id: '#F4511E', label: 'Orange', color: '#F4511E' },
    { id: '#F6BF25', label: 'Yellow', color: '#F6BF25' },
    { id: '#33B679', label: 'Light green', color: '#33B679' },
    { id: '#0A8043', label: 'Green', color: '#0A8043' },
    { id: '#7886CB', label: 'Light purple', color: '#7886CB' },
    { id: '#6F00FF', label: 'Purple', color: '#6F00FF' },
];

const TagModal: React.FC<Props> = ({ tags, handleTags, handleDeleteTag, sectionType }) => {
    const [colorPick, setColorPick] = React.useState<string>('#D50101');
    const [showPickColors, setShowPickColors] = React.useState(false);
    const [inputValue, setInputValue] = useState<string>('');
    const { t } = useTranslation();

    const modalRefColor = React.useRef<HTMLDivElement>(null);

    const baseId = useId('colorpicker');

    const handleSubmit = (color: string) => {
        handleTags(inputValue, color);
    };

    return (
        <div className={styles.tagModalContainer}>
            <div className={styles.tagsEditContainer}>
                {tags.map((tag, index) => (
                    <Tags deleteTag={handleDeleteTag} key={index} tag={tag} />
                ))}
                <input onInput={(e) => setInputValue(e.currentTarget.value)} />
            </div>

            <div className={styles.pickerColorWrapper}>
                {inputValue.length === 0 && (
                    <p>Aucun tag</p>
                )}
                {inputValue.length > 0 && (
                    <div onMouseOver={() => setShowPickColors(true)} className={styles.tagCreationContainer}>
                        <div style={{
                            backgroundColor: `rgba(${hexToRgb(colorPick)}, 0.1)`,
                            border: `1px solid ${colorPick}`,
                            color: colorPick,
                        }}
                            className={styles.createTag}
                        >
                            {inputValue}
                        </div>
                        <div>
                            <IconRightChev height='14' width='14' />
                        </div>
                        {showPickColors && (
                            <ModalLayout top='0' left={sectionType === SectionType.todos ? '100%' : '-95%'}>
                                <div className={styles.pickerColorModal}>
                                    {colorCells.map((color, index) => (
                                        <div onClick={() => handleSubmit(color.color)} onMouseOver={() => setColorPick(color.color)} className={styles.pickerModalRow} key={index}>
                                            <div style={{ backgroundColor: `${color.color}` }} className={styles.colorContainer} />
                                            <div>{color.label}</div>
                                        </div>
                                    ))}
                                </div>
                            </ModalLayout>
                        )}
                    </div>
                )}
                {/* <div className={styles.pickColor} style={{ cursor: 'pointer' }}>
                    <div
                        style={{
                            backgroundColor: colorPick,
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            position: 'relative',
                            zIndex: 100,
                            transition: '300ms',
                            boxShadow: showPickColors ? '1px 1px 2px grey' : 'none',
                        }}
                        onClick={() => {
                            setShowPickColors(!showPickColors);;
                        }}></div>
                    <div></div>
                </div> */}

                {/* {showPickColors && (
                    <div className={styles.colors} ref={modalRefColor}>
                        <SwatchColorPicker
                            columnCount={colorCells.length}
                            cellShape={'circle'}
                            colorCells={colorCells}
                            aria-labelledby={`${baseId}-circle`}
                            onChange={(id, color) => {
                                setColorPick(color ?? '#D50101');
                                setShowPickColors(!showPickColors);
                            }}
                        />
                    </div>
                )} */}
                {/* <button type='button' onClick={handleSubmit}>
                    {t('Confirm')}
                </button> */}
            </div>
        </div>
    );
};

export default TagModal;