/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './project-notes.module.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NotesContract } from '../../../../../store/notes/type';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
	deleteCommentsForANote,
	postCommentsForANote,
	updateCommentsForANote,
} from '../../../../../store/comments/slice';
import {
	addNoteComments,
	getNotesCommentsProject,
	SendNotificationsProject,
	updateCommentNote,
} from '../../../../../store/project/slice';
import { CommentsContract } from '../../../../../store/comments/type';
import {
	IconDelete,
	IconDownChev,
	IconEdit,
	IconSimplePlus,
	IconUpChev,
	IconCheck,
} from '../../../../../shared/utils/icon';
import { Avatar } from '../../../../../shared/utils/avatar';
import { SuggestionDataItem } from 'react-mentions';
import { SendNotifications } from '../../../../../store/notifications/slice';
import { NotificationsType } from '../../../../../store/notifications/type';
import { NotificationsProjectType } from '../../../../../store/project/type';
import MentionComment from '../../../../notes/components/mention-comment.component';

interface Props {
	note?: NotesContract;
}

const DetailsNotesComments: React.FC<Props> = ({ note }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { selectProject, projectsNotesComments } = useAppSelector((state) => state.project);
	const modalRef = React.useRef<HTMLDivElement>(null);

	const [textValue, setTextValue] = React.useState<string>('');
	const [showComment, setShowComment] = React.useState(false);

	const handleComment = () => {
		setShowComment(!showComment);
	};

	const mentionSuggestor = () => {
		return user?.userCompany.map((users) => {
			return {
				id: users.userName,
				display: users.name,
			};
		});
	};

	const renderSuggestion = (
		suggestion: SuggestionDataItem,
		search: string,
		highlightedDisplay: React.ReactNode,
		index: number,
		focused: boolean,
	): React.ReactNode => {
		return (
			<div className={`suggestion-item ${focused ? 'focused' : ''}`} key={suggestion.id}>
				{suggestion.display}
			</div>
		);
	};

	// ADD COMMENT //

	type FormData = {
		text: string;
	};

	const dataSchema = yup.object().shape({
		text: yup.string().required(),
	});

	const { register, handleSubmit, reset } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const addComment = () => {
		if (note && user) {
			const body = {
				id: '1',
				noteId: note.id,
				userId: user.email,
				userName: user.displayName,
				textComment: textValue,
			};
			dispatch(postCommentsForANote({ body, notesId: note.id })).then((res) => {
				const bodyComment = res.payload as CommentsContract;

				const regex = /\(user:\{([^}]+)\}\)/g;
				const matches = [...textValue.matchAll(regex)];
				matches.forEach((mention) => {
					const bodyNotification: any = {
						userFromName: user.displayName,
						userFromMail: user.userName,
						userToName: mention[1],
						userToMail: mention[1],
						type: NotificationsType.NEW_COMMENT,
						noteId: note.id,
						itemTitle: note.title,
						visible: true,
					};

					dispatch(SendNotifications(bodyNotification));
				});

				if (note.projectId) {
					const bodyNotificationComment = {
						id: '',
						userFromName: user.name,
						userFromMail: user.email,
						type: NotificationsProjectType.NEW_MENTION_COMMENT,
						noteId: note.id,
						todoId: '',
						todoDueDate: '',
						itemTitle: note.title,
						graphiCalUId: '',
						meetingStartDate: '',
						meetingEndDate: '',
						meetingName: '',
						meetingAttendees: [],
						projectId: note.projectId,
						userSeenNotif: [],
						todoAssignee: '',
					};

					dispatch(SendNotificationsProject(bodyNotificationComment));
				}
				dispatch(addNoteComments(bodyComment));
				setTextValue('');
			});
		}
	};

	// DELETE

	const handleDeleteComment = (commentId: string) => {
		dispatch(deleteCommentsForANote(commentId)).then(() => {
			if (selectProject) dispatch(getNotesCommentsProject(selectProject.id));
		});
	};

	// EDIT

	const [isEditing, setEditingIndex] = React.useState(-1);

	const handleEditing = (index: number) => {
		if (index === isEditing) {
			setEditingIndex(-1);
		} else {
			setEditingIndex(index);
		}
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleEditing(-1);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleEditing]);

	let timeoutId: any;

	const onChangeHandlerComment = (comment: CommentsContract, newText: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			const body = {
				id: comment.id,
				noteId: comment.noteId,
				userId: comment.userId,
				userName: comment.userName,
				textComment: newText,
			};
			dispatch(updateCommentsForANote({ body, commentId: comment.id })).then(() => {
				dispatch(updateCommentNote({ id: comment.id, newText }));
			});
		}, 30);
	};

	// --

	const displayComment = (comment: string) => {
		const regex = /@\[(.*?)\]\(user:\{[^}]+\}\)/g;
		const commentWithBreaks = comment
			.replace(/\n/g, '<br />')
			.replace(regex, (_, p1) => `<span style="color: #00ad56; font-weight: 500;">@${p1}</span>`);
		return { __html: commentWithBreaks };
	};

	return (
		<div className={styles.commentSection}>
			{note && projectsNotesComments && projectsNotesComments.some((comment) => comment.noteId === note.id) ? (
				<div className={styles.count} onClick={() => handleComment()}>
					<span>
						{projectsNotesComments?.filter((comment) => comment.noteId === note.id).length} {t('replies')}
					</span>

					{showComment ? <IconUpChev /> : <IconDownChev />}
				</div>
			) : (
				<div className={showComment ? styles.countActive : styles.count} onClick={() => handleComment()}>
					<span>{t('Add some replies')}</span>

					{showComment ? <IconUpChev /> : <IconDownChev />}
				</div>
			)}
			{showComment && (
				<>
					{note &&
						projectsNotesComments
							?.filter((comment) => comment.noteId === note.id)
							.map((comment, index) => (
								<div className={styles.commentNote} key={comment.id} ref={modalRef}>
									<div className={styles.name}>
										<Avatar name={comment.userName} mail={comment.userId} index={index} />
										<span>{comment.userName}</span>
									</div>
									<div className={styles.commentText}>
										{isEditing === index ? (
											<MentionComment
												textValue={comment.textComment}
												onChange={(e) => onChangeHandlerComment(comment, e.target.value)}
												mentionSuggestor={mentionSuggestor}
											/>
										) : (
											<div
												className={styles.commentTextDisplay}
												dangerouslySetInnerHTML={displayComment(comment.textComment)}
											/>
										)}

										{user && comment.userId === user.email && (
											<div className={styles.icons}>
												<div className={styles.icon} onClick={() => handleEditing(index)}>
													{isEditing === index ? <IconCheck /> : <IconEdit />}
												</div>
												<div
													className={styles.icon}
													onClick={() => handleDeleteComment(comment.id)}>
													<IconDelete />
												</div>
											</div>
										)}
									</div>
								</div>
							))}

					<div className={styles.formComment}>
						<div className={styles.comment}>
							<Avatar name={user ? user.displayName : ''} mail={user ? user.email : ''} index={1} />
							<MentionComment
								textValue={textValue}
								onChange={(e) => setTextValue(e.target.value)}
								mentionSuggestor={mentionSuggestor}
							/>
						</div>
						<div className={styles.buttonComment}>
							<button onClick={addComment}>
								{' '}
								<IconSimplePlus /> {t('Create')}
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default DetailsNotesComments;
