import React, { FormEvent, useEffect, useRef, useState, ReactNode } from 'react';
import styles from './input.module.scss';
import useClickOutside from '../../hooks/use-click-outside.hook';
import { IconClose, IconEdit, IconSimplePlus } from '../../utils/icon';
import { Attendees } from '../../../pages/meetings/modals/meeting-create.modal';
import { ShimmerDiv } from 'shimmer-effects-react';
import { Avatar } from '../../utils/avatar';
import { PersonaSize } from '@fluentui/react';

export interface Items {
    id?: string;
    leading?: ReactNode;
    title: string;
    subTitle?: string;
    trailing?: string;
}

interface Props {
    attendee?: Items;
    getValue?: (item: Items | undefined) => void;
    renderModal?: Items[];
}

const AttendeesInputTodo: React.FC<Props> = ({
    getValue,
    renderModal,
    attendee,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);

    const handleClick = (item: Items) => {
        setIsModalOpen(false);

        if (getValue) getValue(item);
    };

    useClickOutside(modalRef, () => {
        setIsModalOpen(false);
    });

    return (
        <div className={styles.inputContainer} style={{ cursor: 'pointer' }} onClick={() => setIsModalOpen(true)}>
            <div className={styles.inputAttendeeTodoWrapper}>
                <Avatar
                    name={attendee?.title ?? ''}
                    mail={attendee?.subTitle ?? ''}
                    index={0}
                    size={PersonaSize.size32}
                />
                <div>
                    <div>{attendee?.title}</div>
                    <div className={styles.inputWrapperSubtitle}>{attendee?.subTitle}</div>
                </div>
            </div>

            {renderModal && (
                <div className={styles.inputIcon}>
                    <IconEdit />
                </div>
            )}
            {renderModal && isModalOpen && (
                <div className={styles.inputModal} ref={modalRef}>
                    {renderModal
                        .map((item) => (
                            <div
                                className={styles.inputModalItems}
                                style={{ paddingBlock: '8px' }}
                                key={item.id}
                                onClick={() => handleClick(item)}>
                                {item.leading && <div>{item.leading}</div>}
                                <div>
                                    <div>{item.title}</div>
                                    <div className={styles.modalSubTitle}>{item.subTitle}</div>
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default AttendeesInputTodo;
