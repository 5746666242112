/* eslint-disable unicorn/prefer-add-event-listener */
export const handleVideoPreview = (previewUrl: string) => {
	const previewWindow = window.open('', '_blank', 'popup');
	if (previewWindow) {
		previewWindow.document.write(`
			<html>
				<head>
					<title>Video Preview</title>
					<style>
						body {
							margin: 0;
							padding: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							height: 100vh;
							background-color: #000;
						}
						video {
							max-width: 100%;
							max-height: 100%;
						}
					</style>
				</head>
				<body></body>
			</html>
		`);
		previewWindow.document.close();

		previewWindow.onload = () => {
			const video = previewWindow.document.createElement('video');
			video.src = previewUrl;
			video.controls = true;
			video.autoplay = true;
			previewWindow.document.body.appendChild(video);
			video.onended = () => {
				URL.revokeObjectURL(previewUrl);
			};
		};
	}
};
