import HttpClient from '../shared/http/http-client';
import { ColumnCustomContract } from '../store/column/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';

export default class ColumnAPI {
	public static async getUserColumn(userId: string): Promise<ColumnCustomContract[]> {
		const { data } = await HttpClient.get<ColumnCustomContract[]>(
			ApiUrls.GetUserCustomColumn,
			new UrlParamBuilder().addUserId(userId).build(),
		);
		return data;
	}

	public static async getProjectColumn(projectId: string): Promise<ColumnCustomContract[]> {
		const { data } = await HttpClient.get<ColumnCustomContract[]>(
			ApiUrls.GetProjectCustomColumn,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async createCustomColumn(body: ColumnCustomContract): Promise<ColumnCustomContract> {
		const { data } = await HttpClient.post<ColumnCustomContract, ColumnCustomContract>(
			ApiUrls.createCustomColumn,
			body,
		);
		return data;
	}

	public static async updateCustomColumn(body: ColumnCustomContract): Promise<ColumnCustomContract> {
		const { data } = await HttpClient.patch<ColumnCustomContract, ColumnCustomContract>(
			ApiUrls.updateCustomColumn,
			body,
		);
		return data;
	}

	public static async deleteCustomColumn(columnId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.deleteCustomColumn,
			new UrlParamBuilder().addColmunId(columnId).build(),
		);
		return data;
	}
}
