/* eslint-disable unicorn/prefer-add-event-listener */

export const handleAudioPreview = (previewUrl: string) => {
	const previewWindow = window.open('', '_blank', 'popup');
	if (previewWindow) {
		previewWindow.document.write(`
			<html>
				<head>
					<title>Audio Preview</title>
					<style>
						body {
							margin: 0;
							padding: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							height: 100vh;
							background-color: #000;
						}
						audio {
							max-width: 100%;
						}
					</style>
				</head>
				<body></body>
			</html>
		`);
		previewWindow.document.close();

		previewWindow.onload = () => {
			const audio = previewWindow.document.createElement('audio');
			audio.src = previewUrl;
			audio.controls = true;
			audio.autoplay = true;
			previewWindow.document.body.appendChild(audio);
			audio.onended = () => {
				URL.revokeObjectURL(previewUrl);
			};
		};
	}
};
