import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, RequestStatusType, createRequestStatus } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import { ColumnCustomContract } from './type';
import ColumnAPI from '../../api/column-api';

interface ColumnsState {
	customColumnUser?: ColumnCustomContract[];
	customColumnUserRequestStatus: RequestStatus;

	customColumnProject?: ColumnCustomContract[];
	customColumnProjectRequestStatus: RequestStatus;
}

const initialState: ColumnsState = {
	customColumnUser: undefined,
	customColumnUserRequestStatus: createRequestStatus(RequestStatusType.New),

	customColumnProject: undefined,
	customColumnProjectRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getCustomColumnForAUser = createAsyncThunk(
	'column/user',
	async (userId: string): Promise<ColumnCustomContract[]> => {
		return ColumnAPI.getUserColumn(userId);
	},
);

export const getCustomColumnForAProject = createAsyncThunk(
	'column/project',
	async (projectId: string): Promise<ColumnCustomContract[]> => {
		return ColumnAPI.getProjectColumn(projectId);
	},
);

export const createCustomColumn = createAsyncThunk(
	'column/create',
	async (body: ColumnCustomContract): Promise<ColumnCustomContract> => {
		return ColumnAPI.createCustomColumn(body);
	},
);

export const updateCustomColumn = createAsyncThunk(
	'column/update',
	async (body: ColumnCustomContract): Promise<ColumnCustomContract> => {
		return ColumnAPI.updateCustomColumn(body);
	},
);

export const deleteCustomColumn = createAsyncThunk('column/delete', async (columnId: string): Promise<undefined> => {
	return ColumnAPI.deleteCustomColumn(columnId);
});

const slice = createSlice({
	name: 'column',
	initialState,
	reducers: {
		addUserColumn: (state, action: PayloadAction<ColumnCustomContract>) => {
			const newColProject = action.payload;
			if (state.customColumnUser) {
				state.customColumnUser.push(newColProject);
			}
		},

		addProjectColumn: (state, action: PayloadAction<ColumnCustomContract>) => {
			const newColProject = action.payload;
			if (state.customColumnProject) {
				state.customColumnProject.push(newColProject);
			}
		},

		deleteUserColumn: (state, action: PayloadAction<string>) => {
			const id = action.payload;
			if (state.customColumnUser) {
				state.customColumnUser = state.customColumnUser.filter((col) => col.id !== id);
			}
		},

		deleteProjectColumn: (state, action: PayloadAction<string>) => {
			const id = action.payload;
			if (state.customColumnProject) {
				state.customColumnProject = state.customColumnProject.filter((col) => col.id !== id);
			}
		},

		updateUserColumnTitle: (state, action: PayloadAction<ColumnCustomContract>) => {
			const UpCol = action.payload;
			if (state.customColumnUser) {
				const colToUp = state.customColumnUser.find((col) => col.id === UpCol.id);

				if (colToUp) {
					colToUp.title = UpCol.title;
				}
			}
		},

		updateProjectColumnTitle: (state, action: PayloadAction<ColumnCustomContract>) => {
			const UpCol = action.payload;
			if (state.customColumnProject) {
				const colToUp = state.customColumnProject.find((col) => col.id === UpCol.id);

				if (colToUp) {
					colToUp.title = UpCol.title;
				}
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getCustomColumnForAUser.pending, (state) => {
			state.customColumnUserRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCustomColumnForAUser.fulfilled, (state, action) => {
			state.customColumnUserRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.customColumnUser = action.payload;
		});
		builder.addCase(getCustomColumnForAUser.rejected, (state, action) => {
			state.customColumnUserRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getCustomColumnForAProject.pending, (state) => {
			state.customColumnProjectRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCustomColumnForAProject.fulfilled, (state, action) => {
			state.customColumnProjectRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.customColumnProject = action.payload;
		});
		builder.addCase(getCustomColumnForAProject.rejected, (state, action) => {
			state.customColumnProjectRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const {
	addProjectColumn,
	addUserColumn,
	deleteProjectColumn,
	deleteUserColumn,
	updateUserColumnTitle,
	updateProjectColumnTitle,
} = actions;
export default reducer;
