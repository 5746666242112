/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { NotificationProjectContract } from '../../../../../../store/project/type';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import styles from './update-meeting.module.scss';
import {
	getDateFormatWithYear,
	getDayPastOneDay,
	getStartOfDay,
	getStartOfDayDate,
	getTimeFormat,
} from '../../../../../../shared/utils/date.util';
import { Avatar } from '../../../../../../shared/utils/avatar';
import { useNavigate } from 'react-router-dom';
import { RouteSearchParams, RouteUrls } from '../../../../../../routes/routes-config';
import { getMeetings } from '../../../../../../store/meetings/slice';
import { IconSeen } from '../../../../../../shared/utils/icon';
import { SeeNotificationsProject, seeNotification } from '../../../../../../store/project/slice';
import { PersonaSize } from '@fluentui/react';

interface Props {
	notification: NotificationProjectContract;
}

const UpdateMeetingComponent: React.FC<Props> = ({ notification }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { selectProject } = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);

	const handleSeenNotif = () => {
		if (selectProject && user) {
			dispatch(SeeNotificationsProject(notification)).then(() => {
				dispatch(seeNotification({ notificationId: notification.id, user: user.userName }));
			});
		}
	};

	return (
		<>
			<div
				className={styles.notif}
				style={{ opacity: notification.userSeenNotif.includes(user ? user.userName : '') ? 0.5 : 1 }}>
				<div>
					<h3>{t('New meeting')}</h3>
					<p className={styles.date}>{getDateFormatWithYear(notification.meetingStartDate)}</p>
					<p className={styles.hours}>
						{getTimeFormat(notification.meetingStartDate, notification.meetingEndDate)}
					</p>
					<p
						onClick={(e) => {
							e.stopPropagation();
							if (user && notification.meetingAttendees.includes(user.userName)) {
								const startDate = getStartOfDayDate(new Date(notification.meetingStartDate));
								const endDate = getDayPastOneDay(new Date(notification.meetingStartDate));
								const dateRange = { startDate, endDate };
								dispatch(getMeetings(dateRange)).then(() => {
									const params: any = {};
									params[RouteSearchParams.Date] = getStartOfDay(notification.meetingStartDate);
									params[RouteSearchParams.MeetingId] = notification.graphiCalUId;
									navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
								});
							}
						}}
						className={styles.link}
						style={{
							cursor:
								user && notification.meetingAttendees.includes(user.userName) ? 'pointer' : 'default',
						}}>
						{notification.meetingName}
					</p>
					<div className={styles.attendees}>
						{notification.meetingAttendees.slice(0, 3).map((attendee, index) => (
							<Avatar size={PersonaSize.size32} name={attendee} mail={attendee} index={index} key={index} />
						))}
					</div>
				</div>
				<div className={styles.icon} onClick={() => handleSeenNotif()}>
					<IconSeen />
				</div>
			</div>
		</>
	);
};

export default UpdateMeetingComponent;
