import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import styles from '../todos.module.scss';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import { IconClose, IconFilter, IconSearch, IconSettings, IconSimplePlus, IconSort } from '../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import useTodoColumns from '../../../shared/hooks/use-todos-colums.hook';
import { TodosContract, TodosStates } from '../../../store/todos/type';
import Toggle from '../../../shared/components/toggle/toggle.component';
import ViewSwitcher from '../../../shared/components/viewSwitcher/view-switcher.component';
import { SectionType } from '../../../shared/hooks/use-todos-mutations.hook';

interface Props {
    todos: TodosContract[],
    handleGroupChange: (val: string) => void,
    view: string,
    handleFilteredTodos: (todos: TodosContract[]) => void,
    sectionType: SectionType,
}

export const filterConfigurations = [
    {
        key: 'showTodo',
        label: 'Tâches à faire',
        state: TodosStates.TODO,
        isActive: false,
    },
    {
        key: 'showDoing',
        label: 'Tâches en cours',
        state: TodosStates.DOING,
        isActive: false,
    },
    {
        key: 'showDone',
        label: 'Tâches terminées',
        state: TodosStates.DONE,
        isActive: false,
    },
    {
        key: 'showBlocked',
        label: 'Tâches bloquées',
        state: TodosStates.BLOCKED,
        isActive: false,
    },
    {
        key: 'showArchived',
        label: 'Tâches archivées',
        state: 'archived',
        isActive: false,
    },
];

const TodoFilters: React.FC<Props> = ({ handleGroupChange, view, handleFilteredTodos, todos, sectionType }) => {
    const { t } = useTranslation();

    const [columnSettingsMenu, setColumSettingsMenu] = React.useState<boolean>(false);
    const [filterModal, setFilterModal] = React.useState<boolean>(false);
    const [quickSearchFilter, setQuickSearchFilter] = React.useState<boolean>(false);
    const [sortModal, setSortModal] = React.useState<boolean>(false);
    const [showInput, setShowInput] = React.useState<boolean>(false);
    const { todosColumns, setTodosColumns } = useTodoColumns();

    const sortModalRef = React.useRef<HTMLDivElement | null>(null);
    const filterModalRef = React.useRef<HTMLDivElement | null>(null);
    const settingsModalRef = useRef<HTMLDivElement | null>(null);
    const quickSearchModalRef = useRef<HTMLDivElement | null>(null);
    const inputSearchRef = useRef<HTMLInputElement | null>(null);

    useClickOutside(quickSearchModalRef, () => {
        setQuickSearchFilter(false);
        setFilterModal(false);
    });
    useClickOutside(settingsModalRef, () => setColumSettingsMenu(false));
    useClickOutside(filterModalRef, () => setFilterModal(false));
    useClickOutside(sortModalRef, () => setSortModal(false));
    useClickOutside(inputSearchRef, () => setShowInput(false));

    const [filterQuery, setFilterQuery] = React.useState<string>('');

    const handleToggleColumn = (name: string) => {
        const isActiveColumn = todosColumns.map((column) =>
            column.title === name ? { ...column, isActive: !column.isActive } : column
        );
        setTodosColumns(isActiveColumn);
    };



    const [filterConfigs, setFilterConfigs] = React.useState(filterConfigurations);

    const applyFilters = (query: string, filters: typeof filterConfigs) => {
        const filteredTodos = todos.filter((todo) => {
            const searchMatch = todo.title.toLowerCase().includes(query);

            const filtersMatch = filters.some((filter) => {
                if (!filter.isActive) return false;
                if (filter.state === 'archived') return filter.isActive === true;
                return todo.state === filter.state;
            });

            return searchMatch && (filtersMatch || filters.every((filter) => !filter.isActive));
        });

        handleFilteredTodos(filteredTodos);
    };

    const toggleFilter = (key: string) => {
        const updatedFilters = filterConfigs.map((filter) =>
            filter.key === key ? { ...filter, isActive: !filter.isActive } : filter
        );

        setFilterConfigs(updatedFilters);
        applyFilters(filterQuery, updatedFilters);
    };

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value.toLowerCase();
        setFilterQuery(value);
        applyFilters(value, filterConfigs);
    };

    return (
        <div className={styles.filterContainer}>
            <div className={styles.leftFilterContainer}>
                <div ref={inputSearchRef} className={styles.searchBarContainer}>
                    <div className={styles.filterIcon} onClick={() => setShowInput(!showInput)}>
                        <IconSearch />
                    </div>
                    <div className={`${styles.searchBar} ${showInput ? styles.active : ''}`}>
                        <input
                            onInput={handleInput}
                            placeholder={`${t('Search')}`}
                            className={styles.inputSearchBar}
                            type='text'
                        />
                    </div>
                </div>
                <div ref={quickSearchModalRef} className={styles.filterIcon} onClick={() => setQuickSearchFilter(!quickSearchFilter)}>
                    <IconFilter />
                    <p>Filtrer</p>
                    {quickSearchFilter && (
                        <ModalLayout left='0' padding='20px'>
                            <p className={styles.filterModalTitle}>Filtres</p>
                            <div className={styles.quickSearchContainer}>
                                {filterConfigs.map((filter) => (
                                    <div
                                        onClick={() => toggleFilter(filter.key)}
                                        key={filter.label}
                                        className={`${styles.quickSearchItem} ${filter.isActive ? styles.active : ''}`}
                                    >
                                        {filter.label}
                                    </div>
                                ))}
                            </div>
                            {/* <div className={styles.moreFiltersContainer}>
                                <div ref={filterModalRef} className={styles.moreFiltersBtn} onClick={(e) => {
                                    e.stopPropagation();
                                    setFilterModal(!filterModal);
                                }}>
                                    <IconSimplePlus />
                                    <div className={styles.moreFilterBtnTitle}>Plus de filtres</div>
                                    {filterModal && (
                                        <ModalLayout left='0'>
                                            {todosColumns.map((setting, index) => (
                                                <div className={styles.filterListContainer} key={index}>
                                                    <div className={styles.filterListRow}>
                                                        <setting.icon />
                                                        <p>{setting.title}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </ModalLayout>
                                    )}
                                </div>
                            </div> */}
                        </ModalLayout>
                    )}
                </div>
                {/* <div ref={sortModalRef} className={styles.filterIcon} onClick={() => setSortModal(!sortModal)}>
                    <IconSort />
                    <p>Trier</p>
                    {sortModal && (
                        <ModalLayout left='0'>
                            {todosColumns.map((setting, index) => (
                                <FilterListItem setting={setting} key={index} />
                            ))}
                        </ModalLayout>
                    )}
                </div> */}
                <div className={styles.filterChoiceContainer}>
                    {filterConfigs.filter((filterConfig) => filterConfig.isActive).map((element) => (
                        <div key={element.key} className={styles.filterChoiceItemContainer}>
                            <div className={styles.filterChoiceItemTitle}>{element.label}</div>
                            <div onClick={() => toggleFilter(element.key)} className={styles.filterChoiceItemIcon}><IconClose /></div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.rightFilterContainer}>
                {/* {view === 'Simple' && (
                    <div ref={settingsModalRef} className={styles.filterIcon}>
                        <div onClick={() => setColumSettingsMenu(!columnSettingsMenu)}>
                            <IconSettings />
                        </div>
                        {columnSettingsMenu && (
                            <ModalLayout right='0'>
                                {todosColumns.map((column, index) => (
                                    <div className={styles.settingsModalRow} key={index}>
                                        <div className={styles.contentSettingModal}>
                                            <column.icon />
                                            <div>{column.title}</div>
                                        </div>
                                        <Toggle value={column.isActive} onClick={() => handleToggleColumn(column.title)} />
                                    </div>
                                ))}
                            </ModalLayout>
                        )}
                    </div>
                )} */}
                {sectionType !== SectionType.meeting && (
                    <ViewSwitcher
                        handleGroupChange={handleGroupChange}
                        view={view}
                    />
                )}
            </div>
        </div>
    );
};

export default TodoFilters;