import { t } from 'i18next';
import { IconMeetings, IconMeetingsPage, IconStatus, IconTag } from '../utils/icon';
import React, { ReactElement, ReactNode, useState } from 'react';
import DateCell from '../../pages/todos/components/date-cell.component';
import { TodosContract, UpdateTodo } from '../../store/todos/type';
import StateCell from '../../pages/todos/components/state-cell.component';
import TagsCell from '../../pages/todos/components/tags-cell.component';
import MeetingCell from '../../pages/todos/components/meeting-cell.component';
import ProjectCell from '../../pages/todos/components/project-cell.component';
import { PayloadAction } from '@reduxjs/toolkit';

export interface TodosColumns {
    icon: () => ReactElement,
    title: string,
    width: number,
    isActive: boolean,
    component: (todo: TodosContract, updateFn?: UpdateTodo) => ReactElement,
    updateFn?: (value: UpdateTodo) => PayloadAction<UpdateTodo>,
}

export interface FiltersTodoColumn {
    icon: () => ReactElement,
    title: string,
    width: number,
    isActive: boolean,
}

const useTodoColumns = () => {
    const [todosColumns, setTodosColumns] = useState<FiltersTodoColumn[]>([
        {
            icon: IconMeetings,
            title: t('Due date'),
            width: 200,
            isActive: true,
        },
        {
            icon: IconStatus,
            title: t('State'),
            width: 200,
            isActive: true,
        },
        {
            icon: IconTag,
            title: t('Tags'),
            width: 200,
            isActive: true,
        },
        {
            icon: IconMeetingsPage,
            title: t('Connected meeting'),
            width: 200,
            isActive: true,
        },
        {
            icon: IconMeetingsPage,
            title: t('Connected project'),
            width: 200,
            isActive: true,
        }
    ]);

    return { todosColumns, setTodosColumns };
};

export default useTodoColumns;