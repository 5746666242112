import HttpClient from '../shared/http/http-client';
import { ApiUrls, UrlParamBuilder } from './api-urls';
import { UpdateCurrentUserContract, UserContract } from '../store/user/type';
import { AdminRegisterContract, UserRegisterContract } from '../store/register/type';
import { SendInvitationsContract } from '../store/shared/types';
import { InvitationDataContract } from '../store/invitations/type';
import { CompanyContract, RequestContract } from '../store/settings/type';

export default class RegisterAPI {
	public static async registerAdmin(body: AdminRegisterContract): Promise<UserContract> {
		const { data } = await HttpClient.post<AdminRegisterContract, UserContract>(ApiUrls.RegisterAdmin, body);
		return data;
	}

	public static async registerUser(body: UserRegisterContract): Promise<UserContract> {
		const { data } = await HttpClient.post<UserRegisterContract, UserContract>(ApiUrls.RegisterUser, body);
		return data;
	}

	public static async sendInvitation(body: SendInvitationsContract): Promise<UserContract> {
		const { data } = await HttpClient.post<SendInvitationsContract, UserContract>(
			ApiUrls.RegisterSendInvitation,
			body,
		);
		return data;
	}

	public static async registerInvite(body: UpdateCurrentUserContract): Promise<UserContract> {
		const { data } = await HttpClient.patch<UpdateCurrentUserContract, UserContract>(
			ApiUrls.CompleteInvitation,
			body,
		);
		return data;
	}

	public static async getInvitationData(inviteId: string): Promise<InvitationDataContract> {
		const { data } = await HttpClient.get<InvitationDataContract>(
			ApiUrls.GetInvitationData,
			new UrlParamBuilder().addInvitationId(inviteId).build(),
		);
		return data;
	}

	public static async checkExistingCompany(domain: string): Promise<any> {
		const { data } = await HttpClient.get<any>(
			ApiUrls.checkExistingCompany,
			new UrlParamBuilder().addDomain(domain).build(),
		);
		return data;
	}

	public static async subscribeForUpdateNoMicrosoft(mail: string): Promise<any> {
		const { data } = await HttpClient.get<any>(
			ApiUrls.subscribeForUpdateWithoutMicrosoft,
			new UrlParamBuilder().addUserMail(mail).build(),
		);
		return data;
	}

	public static async checkForRequestStatus(mail: string): Promise<RequestContract> {
		const { data } = await HttpClient.get<RequestContract>(
			ApiUrls.checkForRequestStatus,
			new UrlParamBuilder().addUserMail(mail).build(),
		);
		return data;
	}

	public static async sendRequest(body: RequestContract): Promise<RequestContract> {
		const { data } = await HttpClient.post<RequestContract, RequestContract>(ApiUrls.sendRequest, body);
		return data;
	}

	public static async checkUserRegister(userMail: string): Promise<boolean> {
		const { data } = await HttpClient.get<boolean>(
			ApiUrls.checkForUserExist,
			new UrlParamBuilder().addUserMail(userMail).build(),
		);
		return data;
	}

	public static async getInvitationDataPreRegister(inviteId: string): Promise<InvitationDataContract> {
		const { data } = await HttpClient.get<InvitationDataContract>(
			ApiUrls.checkForUserInviteData,
			new UrlParamBuilder().addInvitationId(inviteId).build(),
		);
		return data;
	}
}
