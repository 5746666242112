/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TableExtension } from '@remirror/extension-react-tables';
import {
	BoldExtension,
	ItalicExtension,
	HeadingExtension,
	DropCursorExtension,
	ImageExtension,
	LinkExtension,
	OrderedListExtension,
	BulletListExtension,
	UnderlineExtension,
	FontSizeExtension,
	StrikeExtension,
	TextColorExtension,
	ColumnsExtension,
	BlockquoteExtension,
	HorizontalRuleExtension,
	NodeFormattingExtension,
	CodeBlockExtension,
	CodeExtension,
	TextHighlightExtension,
	HistoryExtension,
	AnnotationExtension,
	MentionAtomExtension,
} from 'remirror/extensions';
import { useRemirror, Remirror } from '@remirror/react';
import { htmlToProsemirrorNode } from 'remirror';
import css from 'refractor/lang/css.js';
import javascript from 'refractor/lang/javascript.js';
import json from 'refractor/lang/json.js';
import markdown from 'refractor/lang/markdown.js';
import typescript from 'refractor/lang/typescript.js';

import { languages } from '@codemirror/language-data';
import { oneDark } from '@codemirror/theme-one-dark';
import { CodeMirrorExtension } from '@remirror/extension-codemirror6';
import 'remirror/styles/all.css';
import { TodosNodeExtension } from '../../../../../notes/components/editor menu/todos-extension.component';
import { NotesContract } from '../../../../../../store/notes/type';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { getNotesTodos } from '../../../../../../store/todos/slice';
import { getPeopleShareNote } from '../../../../../../store/sharing/slice';

export const usercolors = ['#30bced', '#6eeb83', '#ffbc42', '#ecd444', '#ee6352', '#9ac2c9', '#8acb88', '#1be7ff'];

interface Props {
	content: string;
	title: string;
	note: NotesContract;
}

const MeetingNotesSimpleEditor: React.FC<Props> = ({ content, title, note }) => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	React.useEffect(() => {
		if (user) {
			dispatch(getNotesTodos(note?.id));
		} else {
			dispatch(getPeopleShareNote(note?.id));
		}
	}, [dispatch, note]);

	const linkExtension = new LinkExtension({ autoLink: true, selectTextOnClick: true });

	linkExtension.addHandler('onClick', (_, data) => {
		const newWindow = window.open(data.href, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = undefined;
		return true;
	});

	const { manager, state } = useRemirror({
		extensions: () => [
			linkExtension,
			new BoldExtension({}),
			new ItalicExtension(),
			new HeadingExtension({}),
			new ImageExtension({ enableResizing: true }),
			new DropCursorExtension({}),
			new BulletListExtension({ enableSpine: true }),
			new OrderedListExtension(),
			new HeadingExtension({}),
			new UnderlineExtension(),
			new FontSizeExtension({ defaultSize: '16', unit: 'px' }),
			new StrikeExtension(),
			new TextColorExtension({}),
			new MentionAtomExtension({
				matchers: [{ name: 'at', char: '@' }],
			}),
			new ColumnsExtension({}),
			new TextColorExtension({}),
			new ColumnsExtension({}),
			new BlockquoteExtension(),
			new TableExtension({}),
			new HorizontalRuleExtension({}),
			new NodeFormattingExtension({}),
			new CodeBlockExtension({
				supportedLanguages: [css, javascript, json, markdown, typescript],
			}),
			new CodeExtension(),
			new TextHighlightExtension({}),
			new HistoryExtension({}),
			new CodeMirrorExtension({ languages, extensions: [oneDark] }),
			new AnnotationExtension({}),
			new TodosNodeExtension({ disableExtraAttributes: true }),
		],
		content: note.text.startsWith('{')
			? JSON.parse(note.text)
			: note.text.startsWith('<')
			? note.text
			: note.text === ''
			? undefined
			: { type: 'doc', content: [{ type: 'paragraph', content: [{ type: 'text', text: note.text }] }] },
		stringHandler: htmlToProsemirrorNode,
	});

	// ____

	return (
		<div>
			<div className='remirror-theme'>
				{title !== '' && (
					<h2
						style={{
							fontSize: '20px',
							fontWeight: '700',
							padding: '0',
							margin: '0',
						}}>
						{title}
					</h2>
				)}

				<Remirror manager={manager} initialContent={state} editable={false}></Remirror>
			</div>
		</div>
	);
};

export default MeetingNotesSimpleEditor;
