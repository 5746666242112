import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import styles from './form-confirm-mail.component.module.scss';
import { SharingContractForMap } from '../../../store/sharing/type';
import {
	CheckTokenContract,
	CheckTokenInvalidReason,
	ConfirmMailSteps,
	SharingDataContract,
	SharingMeetingInvalidReason,
} from '../types/confirm-mail-step';
import { useAppDispatch } from '../../../store/hooks';
import { sendToken, checkToken, getNewToken, getShareDetails } from '../../../store/sharing/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteUrls } from '../../../routes/routes-config';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../config/msal-auth-config.';

interface Props {
	shareDetails: SharingContractForMap | undefined;
}

const FormConfirmMailComponent: React.FC<Props> = ({ shareDetails }) => {
	const { instance } = useMsal();
	const handleLoginRedirect = () => {
		instance.loginRedirect(loginRequest);
	};

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [ConfirmStep, setConfirmStep] = React.useState<ConfirmMailSteps>(ConfirmMailSteps.CONFIRM);
	const [errorType, setErrorType] = React.useState<SharingMeetingInvalidReason>();
	const [errorTokenType, setErrorTokenType] = React.useState<CheckTokenInvalidReason>();

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	// --- mail form

	type ShareFormValues = {
		mail: string;
	};

	const shareFormSchema = yup.object().shape({
		mail: yup.string().required(),
	});

	const {
		register: registerShareForm,
		handleSubmit: handleSubmitShareForm,

		formState: { isValid: isShareFormValid },
	} = useForm<ShareFormValues>({
		resolver: yupResolver(shareFormSchema),
	});

	const handleShareMeeting = (data: ShareFormValues) => {
		shareDetails &&
			dispatch(sendToken({ shareId: shareDetails.id, mail: data.mail })).then((result) => {
				if ((result.payload as SharingDataContract).success) {
					setConfirmStep(ConfirmMailSteps.CHECKTOKEN);
					localStorage.setItem(`accessMail/${shareDetails.id}`, data.mail);
				} else {
					setConfirmStep(ConfirmMailSteps.ERROR);
					setErrorType((result.payload as SharingDataContract).invalidReason);
				}
			});
	};

	// --- confirm Token part

	type TokenFormValues = {
		token: string;
	};

	const tokenFormSchema = yup.object().shape({
		token: yup.string(),
	});

	const { register: TokenForm, handleSubmit: handleSubmitTokenForm } = useForm<TokenFormValues>({
		resolver: yupResolver(tokenFormSchema),
	});

	const handleTokenConfirm = (data: TokenFormValues) => {
		shareDetails &&
			dispatch(checkToken({ shareId: shareDetails.id, code: data.token })).then((response) => {
				if ((response.payload as CheckTokenContract).success) {
					dispatch(getShareDetails(shareDetails.id)).then((resultDetails) => {
						localStorage.setItem(`shareId/${shareDetails.id}`, shareDetails.id);
						localStorage.setItem(
							`accessTokenShareId/${shareDetails.id}`,
							(resultDetails.payload as SharingContractForMap).accessToken,
						);
						navigate(RouteUrls.PublicMeeting.replace(':shareID', shareDetails.id));
					});
				} else {
					setErrorTokenType((response.payload as CheckTokenContract).invalidReason);
				}
			});
	};

	// ---------

	const resquestNewToken = () => {
		shareDetails &&
			storedAccessMail &&
			dispatch(getNewToken({ shareId: shareDetails.id, mail: storedAccessMail })).then(() => {
				setErrorTokenType(undefined);
			});
	};

	return (
		<div>
			<div className={styles.containerForm}>
				{ConfirmStep === ConfirmMailSteps.CONFIRM ? (
					<div>
						<div className={styles.stepOne}>
							<div className={styles.one}></div>
							<div className={styles.two}></div>
							<div className={styles.three}></div>
						</div>
						<p className={styles.firstLine}>{t('Enter your email address')}</p>
						<p className={styles.secondLine}>{t('To ensure that it is really you')}</p>
						<form onSubmit={handleSubmitShareForm(handleShareMeeting)}>
							<div className={styles.validEmailContainer}>
								<p className={styles.validEmailPlaceholder}>Adresse email</p>
								<input
									placeholder='youremail@exemple.com'
									className={styles.validEmail}
									type='email'
									{...registerShareForm('mail')}
									defaultValue={''}
								/>
							</div>
							<button type='submit' disabled={!isShareFormValid}>
								{t('Confirm')}
							</button>
						</form>
					</div>
				) : ConfirmStep === ConfirmMailSteps.CHECKTOKEN ? (
					<div className={styles.stepTwoContainer}>
						<div className={styles.stepTwo}>
							<div className={styles.one}></div>
							<div className={styles.two}></div>
							<div className={styles.three}></div>
						</div>

						<h1>{t('Enter the verification code')}</h1>
						<div className={styles.sendCode}>
							<p>{t('We have sent a verification code to ')}</p>
							<p>
								<strong>{storedAccessMail}</strong>
							</p>
						</div>

						<div className={styles.info}>
							{t('The code has been sent successfully. Please wait, this may take a few moments.')}
						</div>
						<form onSubmit={handleSubmitTokenForm(handleTokenConfirm)}>
							<input type='password' {...TokenForm('token')} />
							<center>
								<button className={styles.checkButton} type='submit'>
									{t('Confirm')}
								</button>
								<br />
								<button
									className={styles.checkButtonCancel}
									type='button'
									onClick={() => {
										setConfirmStep(ConfirmMailSteps.CONFIRM);
									}}>
									{t('Back to the previous step')}
								</button>
							</center>
						</form>

						{errorTokenType === CheckTokenInvalidReason.WRONG_CODE && (
							<>
								<p className={styles.failed}>
									{t('Failed to valid your security code, please check your email and try again')}
								</p>
							</>
						)}

						{errorTokenType === CheckTokenInvalidReason.TOKEN_EXPIRED && (
							<>
								<p className={styles.failed}>
									{t(
										'Your token has expired, please request a new one if you want to access this meeting',
									)}
								</p>
								<center>
									<button
										type='button'
										onClick={() => {
											resquestNewToken();
										}}
										className={styles.requestButton}>
										{t('Request a new token')}
									</button>
								</center>
							</>
						)}
					</div>
				) : ConfirmStep === ConfirmMailSteps.ERROR ? (
					<div>
						{errorType === SharingMeetingInvalidReason.FIVEDAYS_USER ? (
							<>
								<div>
									<div className={styles.stepOne}>
										<div className={styles.one}></div>
										<div className={styles.two}></div>
										<div className={styles.three}></div>
									</div>
									<p className={styles.firstLine}>{t('Enter your email address')}</p>
									<p className={styles.secondLine}>{t('To ensure that it is really you')}</p>
									<form onSubmit={handleSubmitShareForm(handleShareMeeting)}>
										<input type='email' {...registerShareForm('mail')} defaultValue={''} />
										<p className={styles.existEmail}>
											{t('Ah! It seems that this email address already exists. Please log in.')}
										</p>
										<button onClick={handleLoginRedirect}>{t('Log in')}</button>
									</form>
								</div>
							</>
						) : errorType === SharingMeetingInvalidReason.INCORRECT_USER ? (
							<>
								<div>
									<div className={styles.stepOne}>
										<div className={styles.one}></div>
										<div className={styles.two}></div>
										<div className={styles.three}></div>
									</div>
									<p className={styles.firstLine}>{t('Enter your email address')}</p>
									<p className={styles.secondLine}>{t('To ensure that it is really you')}</p>
									<form onSubmit={handleSubmitShareForm(handleShareMeeting)}>
										<input type='email' {...registerShareForm('mail')} defaultValue={''} />
										<p>{t('The email address you provides does not match the email expected.')}</p>
									</form>
								</div>
							</>
						) : errorType === SharingMeetingInvalidReason.NOT_ATTENDEE ? (
							<>
								<div>
									<div className={styles.stepOne}>
										<div className={styles.one}></div>
										<div className={styles.two}></div>
										<div className={styles.three}></div>
									</div>
									<p className={styles.firstLine}>{t('Enter your email address')}</p>
									<p className={styles.secondLine}>{t('To ensure that it is really you')}</p>
									<form onSubmit={handleSubmitShareForm(handleShareMeeting)}>
										<input type='email' {...registerShareForm('mail')} defaultValue={''} />
										<p className={styles.errorEmail}>
											{t(
												'Your email does not match the attendees for this meeting, please contact your admin.',
											)}
										</p>
									</form>
								</div>
							</>
						) : errorType === SharingMeetingInvalidReason.NOT_FOUND ? (
							<>
								<div>
									<div className={styles.stepOne}>
										<div className={styles.one}></div>
										<div className={styles.two}></div>
										<div className={styles.three}></div>
									</div>
									<p className={styles.firstLine}>{t('Enter your email address')}</p>
									<p className={styles.secondLine}>{t('To ensure that it is really you')}</p>
									<form onSubmit={handleSubmitShareForm(handleShareMeeting)}>
										<input type='email' {...registerShareForm('mail')} defaultValue={''} />
										<p>{t('Your invitation is not valid, please contact your admin.')}</p>
									</form>
								</div>
							</>
						) : (
							<></>
						)}
						<center>
							<p
								className={styles.checkButton}
								onClick={() => {
									setConfirmStep(ConfirmMailSteps.CONFIRM);
								}}>
								{t('Return to the previous step')}
							</p>
						</center>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default FormConfirmMailComponent;
