import moment from 'moment-timezone';

// ___ TIME

export function getStartOfDay(dateString: string | Date) {
	return `${moment(dateString).startOf('day')}`;
}

export function getDateString(date: Date) {
	return `${moment(date).toString()}`;
}

export function getStartOfDaypastOne() {
	return `${moment().add('1', 'day').startOf('day')}`;
}

export function getStartOfDayLessOne() {
	return `${moment().subtract('1', 'day').startOf('day')}`;
}

export function getStartOfDayNow() {
	return `${moment().startOf('day')}`;
}

export function getStartOfTheDayDate(): Date {
	return moment().startOf('day').toDate();
}

export function getTime(dateString: string) {
	return moment.utc(dateString);
}

export function getNowTime() {
	return moment();
}

export function getMoment(date: Date) {
	return moment(date);
}

export function getHour(date: string | Date): number {
	return moment(date).hour();
}

export function getMin(date: string | Date): number {
	return moment(date).minute();
}

export function getNowTimeLessOneDay(): Date {
	return moment().subtract('1', 'day').toDate();
}

export function getStartOfDayDate(date: Date): Date {
	return moment(date).startOf('day').toDate();
}

export function getDateLessOneMonths(date: Date): Date {
	return moment(date).subtract('1', 'month').toDate();
}

export function getDateAddOneYear(date: Date): Date {
	return moment(date).add('1', 'year').toDate();
}

export function getDateLessOneYear(date: Date): Date {
	return moment(date).subtract('1', 'year').toDate();
}

export function getStartOfDayForAllDay(date: Date) {
	const startOfDay = moment.utc(date).startOf('day');
	return startOfDay.format('YYYY-MM-DD');
}

export function getDayPastOneDay(date: Date): Date {
	return moment(date).add(1, 'day').subtract(1, 'second').toDate();
}

export function getTimeDuration(meetingStart: string, meetingEnd: string) {
	return `${moment.utc(moment(meetingEnd).diff(moment(meetingStart))).format('HH:mm')}`;
}

export function getStartOfWeek(selectedMeetingDate: Date) {
	return moment(selectedMeetingDate).startOf('isoWeek');
}

export function getStartOfWeekDate(selectedMeetingDate: Date): Date {
	return moment(selectedMeetingDate).startOf('isoWeek').toDate();
}

export function getStartOfMonth(selectedMeetingDate: Date): Date {
	return moment(selectedMeetingDate).startOf('month').toDate();
}

export function getStartOfWeekToDate(selectedMeetingDate: Date): Date {
	return moment(selectedMeetingDate).startOf('isoWeek').toDate();
}

export function getDayPastSevenDays(date: Date): Date {
	return moment(date).add('7', 'day').toDate();
}

export function getDayPastOneWeek(date: Date): Date {
	return moment(date).add('1', 'weeks').toDate();
}

export function getDayLessOneWeek(date: Date): Date {
	return moment(date).subtract('1', 'weeks').toDate();
}

export function getDayPastOneMonth(date: Date): Date {
	return moment(date).add('1', 'months').toDate();
}

export function getDayLessOneMonth(date: Date): Date {
	return moment(date).subtract('1', 'months').toDate();
}

export function getDayPastOneDayStart(date: Date): Date {
	return moment(date).startOf('day').add('1', 'day').toDate();
}

export function getDayLessOneDayStart(date: Date): Date {
	return moment(date).startOf('day').subtract('1', 'day').toDate();
}

// ____ FORMAT

export function getDateFormat(date: Date) {
	return moment(date).format('dddd Do MMMM');
}

export function getDateFormatHeader(date: Date) {
	return moment(date).format('Do MMMM');
}

export function getDateFormatL(date: Date | string) {
	return `${moment(date).format('L')}`;
}

export function getDateDifference(endDate: Date) {
	return `${Math.round((endDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24))}`;
}

export function getDateEnFormat(date: Date) {
	return moment(date).format('dddd MMMM Do');
}

export function getDateFormatWithYear(date: string) {
	return `${moment(date).format('dddd Do MMMM YYYY')}`;
}

export function getDateEnFormatWithYear(date: string) {
	return `${moment(date).format('dddd MMMM Do YYYY')}`;
}

export function DateTimeFormat(date: Date | string) {
	return `${
		moment.tz(moment.utc(date).format('YYYY-MM-DDTHH:mm:ssZ'), moment.tz.guess()).format('DD/MM/YYYY HH:mm') +
		' ' +
		moment.tz(moment.utc(date).format('YYYY-MM-DDTHH:mm:ssZ'), moment.tz.guess()).zoneAbbr()
	}`;
}

export function getTimeFormat(selectedMeetingStart: string, selectedMeetingEnd: string) {
	return `${
		moment.tz(moment.utc(selectedMeetingStart).format('YYYY-MM-DDTHH:mm:ssZ'), moment.tz.guess()).format('HH:mm') +
		' - ' +
		moment.tz(moment.utc(selectedMeetingEnd).format('YYYY-MM-DDTHH:mm:ssZ'), moment.tz.guess()).format('HH:mm') +
		' ' +
		moment.tz(moment.utc(selectedMeetingStart).format('YYYY-MM-DDTHH:mm:ssZ'), moment.tz.guess()).zoneAbbr()
	}`;
}

export function getHourFormat(date: string | Date) {
	return `${moment(date).format('HH:mm')}`;
}

export function getFullDateFormatTZ(date: Date) {
	return moment.tz(moment.utc(date).format('YYYY-MM-DDTHH:mm:ssZ'), moment.tz.guess()).zoneAbbr();
}

export function getInputDateTimeFormat(date: string | Date) {
	return moment.tz(date, moment.tz.guess()).format('YYYY-MM-DDTHH:mm');
}

export function getMonthYearFormat(date: Date) {
	return `${moment(date).format('MMMM YYYY')}`;
}

export function getDayFormat(date: Date) {
	return `${moment(date).format('dddd D')}`;
}

export function getDayCalendarFormat(date: Date) {
	return `${moment(date).format('D')}`;
}

export function getDayofTheWeek(date: Date) {
	return `${moment(date).format('dddd')}`;
}

export function formatedDateTZ(date: Date) {
	return moment(date).tz('UTC').format();
}

export function formatedDateTime(date: string) {
	return `${moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS')}`;
}

// __ CALC

export function getRightTimeSlot(date: Date, hour: number, min: number): Date {
	return moment(date).add(hour, 'hours').add(min, 'minutes').toDate();
}

export function getRightTimeZoneForCreate(date: Date) {
	return `${moment.tz(date, moment.tz.guess()).format('YYYY-MM-DDTHH:mm')}`;
}

export function getDiffInMinutes(meetingStart: string, meetingEnd: string): number {
	return moment(meetingEnd).diff(moment(meetingStart), 'minutes');
}

export function getDiff(meetingStart: string): number {
	return moment(meetingStart).diff(moment(), 'minutes');
}

export function getMinutesPastDay(): number {
	return moment().diff(moment().startOf('day'), 'minutes');
}

export function getMinutesPastDayAgenda(date: Date | string): number {
	return moment().diff(moment(date).startOf('day'), 'minutes');
}

export function getDiffInMinutesStartOfDay(meetingStart: string | Date, date: Date): number {
	return moment(meetingStart).diff(moment(date).startOf('day'), 'minutes');
}

export function getMeetingTime(meetinStart: string) {
	return moment.utc(meetinStart, 'YYYY-MM-DD HH:mm').utcOffset(new Date().getTimezoneOffset());
}

// ___ DURATION

export function getDuration() {
	return moment.duration();
}

export function getDurationDiff(meetingTime: moment.Moment, currentTime: moment.Moment) {
	return moment.duration(meetingTime.diff(currentTime));
}

// __ ISSAME/ETC

export function isSame(meetingTime: moment.Moment) {
	return meetingTime.isSame(moment(), 'day');
}

export function isAfter(meetingTime: moment.Moment) {
	return meetingTime.isAfter(moment());
}

export function isSameOrAfter(meetingTime: moment.Moment) {
	return meetingTime.isSameOrAfter(moment(), 'day');
}

export function isSameOrBefore(meetingTime: moment.Moment) {
	return meetingTime.isSameOrBefore(moment(), 'day');
}

export function isBetween(meetingStart: string, day: Date) {
	return moment(meetingStart).isBetween(moment(day).startOf('day'), moment(day).endOf('day'), undefined, '[]');
}

export function formatTime(minutes: number) {
	if (minutes < 60) {
		return minutes + ' m';
	} else {
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
		return hours + 'h' + (remainingMinutes < 10 ? '0' : '') + remainingMinutes;
	}
}

export function setToMidnight(date: Date): Date {
	return moment.utc(date).add('2', 'hour').startOf('day').toDate();
}

export function setToMidnightAddOneDay(date: Date): Date {
	return moment.utc(date).add('1', 'day').startOf('day').toDate();
}
