import React from 'react';
import { IconContact, IconFilter, IconLock, IconMeetingsPage } from '../../../shared/utils/icon';
import styles from './project-list.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks';
import ProjectCreateModal from '../modal/project-create.modal';
import { AccessRightType } from '../../../store/meetings/types';
import ProjectFilterModal from '../modal/project-filter.modal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import RoundedButton from '../../../shared/components/buttons/roundedBtn/rounded-btn.component';

interface Props {
	showList: () => void;
}

const ProjetcList: React.FC<Props> = ({ showList }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { projects, selectProject } = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);

	const [createModal, setCreateModal] = React.useState(false);
	const handleSetCreateModal = () => {
		setCreateModal(!createModal);
	};

	// ----------

	const [showFilters, setShowFilters] = React.useState(false);

	const handleShowFilterModal = () => {
		setShowFilters(!showFilters);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	// React.useEffect(() => {
	// 	const handleClickOutside = (event: MouseEvent) => {
	// 		if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
	// 			setTimeout(() => {
	// 				handleShowFilterModal();
	// 			}, 150);
	// 		}
	// 	};

	// 	document.addEventListener('mousedown', handleClickOutside);

	// 	return () => {
	// 		document.removeEventListener('mousedown', handleClickOutside);
	// 	};
	// }, [handleShowFilterModal]);

	const [showArchived, setShowArchived] = React.useState<boolean>(false);
	const [showUserProject, setShowUserProject] = React.useState<boolean>(true);
	const [showShareProject, setShowShareProject] = React.useState<boolean>(true);
	const [showPublicProject, setShowPublicProject] = React.useState<boolean>(true);

	const handleShowArchived = (show: boolean) => {
		setShowArchived(show);
	};

	const handleShowUserProject = (show: boolean) => {
		setShowUserProject(show);
	};

	const handleShowShareProject = (show: boolean) => {
		setShowShareProject(show);
	};

	const handleShowPublicProject = (show: boolean) => {
		setShowPublicProject(show);
	};

	const trueCount = [showArchived, showUserProject, showShareProject, showPublicProject].filter(Boolean).length;

	return (
		<div className={styles.listContainer}>
			<div className={styles.list}>
				<div className={styles.title}>
					<h1>{t('Projects')}</h1>
					<RoundedButton onClick={() => handleSetCreateModal()} />
				</div>

				<div className={styles.filters}>
					<div
						className={showFilters ? styles.activeButton : styles.buttonFilter}
						onClick={() => handleShowFilterModal()}>
						<IconFilter />
						{t('Filters')}
						<div>{trueCount !== 0 ? trueCount : ''}</div>
					</div>

					<div
						style={{ maxHeight: showFilters ? '350px' : '0px' }}
						className={styles.modalFilterWrapper}
						ref={modalRefFilter}>
						<ProjectFilterModal
							showArchived={showArchived}
							handleShowArchived={handleShowArchived}
							showPersonalProject={showUserProject}
							handleShowPersoProject={handleShowUserProject}
							showSharedProject={showShareProject}
							handleShowSharedProject={handleShowShareProject}
							showPublicProject={showPublicProject}
							handleShowPublicProject={handleShowPublicProject}
						/>
					</div>
				</div>

				<div className={styles.projects}>
					{projects &&
						projects
							.filter((project) => {
								if (!showArchived && project.archived) {
									return false;
								}

								if (!showUserProject && project.graphUserId === user?.graphUserId) {
									return false;
								}

								if (!showShareProject && project.graphUserId !== user?.graphUserId) {
									return false;
								}

								if (
									!showPublicProject &&
									project.accessRightType === AccessRightType.EVERYONE &&
									project.graphUserId !== user?.graphUserId
								) {
									return false;
								}

								return true;
							})
							?.map((project) => (
								<div
									className={`
										${styles.project}
										${selectProject && selectProject.id === project.id ? styles.active : ''}
									`}
									key={project.id}
									onClick={() => {
										navigate(RouteUrls.Project);
										searchParams.set(RouteSearchParams.ProjectId, project.id);
										setSearchParams(searchParams, {});
										showList();
									}}>
									<div className={styles.projectTitle}>
										<span
											className={styles.projectTitleIcon}
											dangerouslySetInnerHTML={{ __html: project.emoticon }}></span>
										<p>{project.projectName}</p>
									</div>
									{project?.accessRightType === AccessRightType.INTERN ? (
										<div className={styles.accessButton}>
											<IconContact />
										</div>
									) : project.accessRightType === AccessRightType.ONLYME ? (
										<div className={styles.accessButton}>
											<IconLock />
										</div>
									) : (
										<div className={styles.accessButton}>
											<IconMeetingsPage />
										</div>
									)}
								</div>
							))}
				</div>
			</div>

			{createModal && <ProjectCreateModal close={handleSetCreateModal} />}
		</div>
	);
};

export default ProjetcList;
