import React from 'react';
import { ShimmerDiv } from 'shimmer-effects-react';
import styles from './meeting-details-shimmer.module.scss';
import ShimmerText from '../../../../../shared/components/shimmers/shimmer-text.component';
import ShimmerAvatar from '../../../../../shared/components/shimmers/shimmer-avatar.component';

const MeetingDetailsShimmer = () => {
	return (
		<div>
			<div className={styles.headerShimmer}>
				<ShimmerText height={20} width={150} />
				<div className={styles.shimmerAvatarContainer}>
					<ShimmerAvatar />
					{Array.from({ length: 2 }).map((_, index) => (
						<div
							key={index}
							style={{
								position: 'absolute',
								top: '50%',
								transform: 'translateY(-50%)',
								left: index === 0 ? '27px' : `${(index + 1) * 25}px`,
								zIndex: `${10 - (index + 1) * 1}`,
							}}>
							<ShimmerAvatar />
						</div>
					))}
				</div>
			</div>
			<div className={styles.shimmerMenu}>
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={72} />
			</div>
			<div className={styles.bodyShimmer}>
				{Array.from({ length: 8 }).map((_, index) => (
					<div key={index} className={styles.shimmerBodyRow}>
						<div className={styles.shimmerDescRow}>
							<ShimmerText
								height={24}
								width={24}
								rounded={0.5}
							/>
							<ShimmerText height={13} width={142} />
						</div>
						<ShimmerText height={13} width={102} />
					</div>
				))}
			</div>
		</div>
	);
};

export default MeetingDetailsShimmer;
