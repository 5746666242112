/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styles from './project-create.module.scss';
import {
	IconClose,
	IconContact,
	IconLock,
	IconMeetingsPage,
	IconProjectModal,
	IconSimplePlus,
} from '../../../shared/utils/icon';
import { AccessRightType, TagContract } from '../../../store/meetings/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { ContactsGraphContract } from '../../../store/user/type';
import { Avatar } from '../../../shared/utils/avatar';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import TagsFormModal from '../../meetings/modals/tags-form.modal';
import SimpleEditor from '../../../shared/utils/simple-editor';
import { ProjectContract } from '../../../store/project/type';
import {
	addTagsProject,
	deletePeopleShareProject,
	deleteTagsFromProject,
	getPeopleShareProject,
	postPeopleShareProject,
	setSelectedProject,
	updateProjectState,
	updateProjects,
} from '../../../store/project/slice';
import { People } from '../../../store/notes/type';
import { emoticonsUT8 } from '../../../shared/utils/ut8-emoticon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import useMergeAttendees from '../../../shared/hooks/use-merge-attendees.hook';
import Input, { Items } from '../../../shared/components/input/input.component';
import AttendeesInput from '../../../shared/components/input/attendees-input.component';
import { attendeeMergeMapper } from '../../../shared/utils/mappers/attendee-merge.mapper';
import { PersonaSize } from '@fluentui/react';
import { getLayout, PlatformType } from '../../../shared/components/layouts/layout-factory';
import { FormType } from '../../../shared/components/layouts/web/form-layout.component';

interface Props {
	close: () => void;
}

const ProjectUpdateModal: React.FC<Props> = ({ close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { user, contacts } = useAppSelector((state) => state.user);
	const { selectProject, projectsSharePeople } = useAppSelector((state) => state.project);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [mergedAttendees, setMergedAttendees] = useMergeAttendees({
		userCompany: user?.userCompany,
		contacts,
	});

	// STATE

	const [title, setTitle] = React.useState(selectProject ? selectProject.projectName : '');
	const [access, setAccess] = React.useState(
		selectProject ? selectProject.accessRightType : AccessRightType.EVERYONE,
	);
	const [openMenu, setOpenMenu] = React.useState(false);
	const [emoticon, setEmoticon] = React.useState(selectProject ? selectProject.emoticon : '');
	const [menuEmoticon, setMenuEmoticon] = React.useState(false);
	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);
	const [text, setText] = React.useState(selectProject ? selectProject.text : '');

	const [tags, setTags] = React.useState<TagContract[]>(selectProject ? selectProject.tags : []);
	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);

	const [people, setPeople] = React.useState<People[]>(projectsSharePeople ? projectsSharePeople : []);
	const [selectedAddresses, setSelectedAddresses] = React.useState<People[]>([]);
	const FormLayout = getLayout(window.api && window.api.isElectron ? PlatformType.desktop : PlatformType.web);
	// ----

	const handleMenu = () => {
		setOpenMenu(!openMenu);
	};

	const handleMenuEmoticon = () => {
		setMenuEmoticon(!menuEmoticon);
	};

	useEffect(() => {
		setSelectedAddresses(people);
	}, []);

	const handleCheckboxChange = (attendee: People) => {
		setSelectedAddresses([...selectedAddresses, attendee]);
		setPeople([...people, attendee]);
	};

	const handleDeletePeople = (email: string) => {
		setSelectedAddresses(selectedAddresses.filter((address) => address.mail !== email));
		setPeople(people.filter((address) => address.mail !== email));

		if (selectProject) {
			dispatch(deletePeopleShareProject({ projectId: selectProject.id, mail: email }));
		}
	};

	const handleAttendee = (selectedContact: Items | undefined) => {
		if (selectedContact) {
			const newAttendee = {
				id: selectedContact.id,
				username: selectedContact.title,
				mail: selectedContact.id,
			};
			setSelectedAddresses([...selectedAddresses, newAttendee as People]);
		}
	};

	const handleTextChange = (content: string) => {
		setText(content);
	};
	// --- PEOPLE

	let timeoutId: any;

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const onChangeHandler = (filter: string) => {
		setIsLoading(true);
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getCurrentUserContacts(filter)).then((res) => {
				setIsLoading(false);
				const response = res.payload as ContactsGraphContract[];
				const newContactPR = attendeeMergeMapper(filter, filter);

				if (response?.length < 1 && isEmail(filter)) {
					setMergedAttendees([newContactPR]);
				}
			});
		}, 200);
	};

	// TAG

	const deleteTag = (tag: TagContract) => {
		if (newTags) {
			const updatedTags = tags?.filter((tagsD) => tagsD !== tag);
			setTags(updatedTags);
			const updatesTags = newTags.filter((tagToDelete) => tagToDelete.tag !== tag.title);
			setNewTags(updatesTags);
		}
		if (selectProject && tag && tag.id) {
			dispatch(deleteTagsFromProject({ projectId: selectProject.id, tagsId: tag.id }));
		}
	};

	const closeTagsModal = (tag: string, color: string) => {
		if (tags) {
			const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [], [], [], []);
			const updatedTags = [...tags, newTag];
			setTags(updatedTags);
			setTagsModalOpen(false);

			const newTagTB = { tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			setNewTags(updatedTagsToBeCreated);
		} else {
			const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [], [], [], []);
			const updatedTags = [newTag];
			setTags(updatedTags);
			setTagsModalOpen(false);

			const newTagTB = { tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			setNewTags(updatedTagsToBeCreated);
		}
	};

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};

	// ---

	const updateProject = (e: any) => {
		e.preventDefault();
		if (user && selectProject) {
			const body: ProjectContract = {
				id: selectProject.id,
				projectName: title,
				accessRightType: access,
				graphUserId: selectProject.graphUserId,
				text,
				tags: selectProject.tags,
				emoticon,
				archived: selectProject.archived,
				todoCol: selectProject.todoCol,
				doingCol: selectProject.doingCol,
				blockedCol: selectProject.blockedCol,
				doneCol: selectProject.doneCol,
				createdOn: selectProject.createdOn,
			};

			dispatch(updateProjects(body)).then((res) => {
				const newProject = res.payload as ProjectContract;
				if (newTags) {
					newTags.forEach((tag) => {
						const bodyTag = {
							tag: tag.tag,
							color: tag.color,
							projectId: newProject.id,
						};

						dispatch(addTagsProject(bodyTag));
					});
				}

				if (access === AccessRightType.SHARED && selectedAddresses.length !== 0) {
					selectedAddresses.forEach((attendee) => {
						const bodyContact = {
							id: '1',
							username: attendee.username,
							mail: attendee.mail,
						};

						dispatch(postPeopleShareProject({ body: bodyContact, projectId: newProject.id }));
					});
				}

				const newBody: ProjectContract = {
					id: newProject.id,
					projectName: newProject.projectName,
					accessRightType: newProject.accessRightType,
					graphUserId: newProject.graphUserId,
					text: newProject.text,
					tags: tags ? tags : [],
					emoticon: newProject.emoticon,
					archived: newProject.archived,
					todoCol: selectProject.todoCol,
					doingCol: selectProject.doingCol,
					blockedCol: selectProject.blockedCol,
					doneCol: selectProject.doneCol,
					createdOn: newProject.createdOn,
				};

				dispatch(updateProjectState(newBody));
				getPeopleShareProject(newBody.id);
				navigate(RouteUrls.Project);
				searchParams.set(RouteSearchParams.ProjectId, newBody.id);
				dispatch(setSelectedProject(newBody));
				setSearchParams(searchParams, {});
			});
		}
	};

	// --

	const modalRef = React.useRef<HTMLDivElement>(null);
	const modalRefEmoticon = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefEmoticon.current && !modalRefEmoticon.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenuEmoticon();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenuEmoticon]);

	return (
		<FormLayout
			formTitle={{
				icon: <IconProjectModal />,
				title: t('Update your project'),
			}}
			handleClose={close}
			handleSubmit={updateProject}
			type={FormType.save}>
			<div className={styles.flexAccess}>
				<div
					className={access === AccessRightType.INTERN ? styles.selectAccess : styles.access}
					onClick={() => setAccess(AccessRightType.INTERN)}>
					<IconContact />
					{t('Intern')}
				</div>
				<div
					className={access === AccessRightType.SHARED ? styles.selectAccess : styles.access}
					onClick={() => setAccess(AccessRightType.SHARED)}>
					<IconMeetingsPage />
					{t('Shared')}
				</div>
				<div
					className={access === AccessRightType.ONLYME ? styles.selectAccess : styles.access}
					onClick={() => setAccess(AccessRightType.ONLYME)}>
					<IconLock />
					{t('Private')}
				</div>
			</div>

			<div className={styles.iconZone}>
				{emoticon === '' ? (
					<div className={styles.addEmoticone} onClick={() => handleMenuEmoticon()}>
						<div className={styles.iconPlus}>
							<IconSimplePlus />
						</div>

						{emoticon === '' && <>{t('Select an emoticon')}</>}
					</div>
				) : (
					<div className={styles.iconSelect} onClick={() => handleMenuEmoticon()}>
						<span dangerouslySetInnerHTML={{ __html: emoticon }}></span>
					</div>
				)}

				{menuEmoticon && (
					<div className={styles.modalPeople} ref={modalRefEmoticon}>
						<div className={styles.emoticonBlock}>
							{emoticonsUT8.map((emoticonUT, index) => (
								<div
									key={index}
									className={styles.emoticon}
									onClick={() => {
										setEmoticon(emoticonUT.code);
										handleMenuEmoticon();
									}}>
									{emoticonUT.smiley}
								</div>
							))}
						</div>
					</div>
				)}
			</div>

			<div>
				<Input
					type='text'
					placeHolder={t('Write your title here') as string}
					defaultValue={{ title }}
					onChange={(e) => setTitle(e.currentTarget.value)}
				/>
			</div>

			{access === AccessRightType.SHARED && (
				<>
					{/* {people.length > 0 && (
								<div className={styles.invited}>
									{people.length !== 0 &&
										people
											.filter((p) => p.mail !== user?.email)
											.map((contact, index) => (
												<>
													<div key={index} className={styles.avatar}>
														<div className={styles.avatarWrapper}>
															<Avatar
																name={contact.username}
																mail={contact.mail}
																index={index}
															/>
															<p>{contact.username}</p>
														</div>
														<div
															className={styles.icon}
															onClick={() => handleDeletePeople(contact.mail)}>
															<IconClose />
														</div>
													</div>
													<br />
												</>
											))}
								</div>
							)} */}
					<div className={styles.attendees}>
						<AttendeesInput
							isLoading={isLoading}
							handleRemoveAttendee={handleDeletePeople}
							attendees={selectedAddresses
								.filter((attendee: People) => attendee.mail !== user?.email)
								.map((attendee: People) => ({
									type: 'required',
									emailAddress: {
										name: attendee.username,
										address: attendee.mail,
									},
								}))}
							resetFieldOnSelect={true}
							placeHolder={t('Invite people') as string}
							onChange={(e) => onChangeHandler(e.currentTarget.value)}
							getValue={handleAttendee}
							renderModal={mergedAttendees
								.filter(
									(mergedAttendee) =>
										!selectedAddresses.some((attendee) => attendee.mail === mergedAttendee.email),
								)
								.map((attendee, index) => ({
									leading: (
										<Avatar
											size={PersonaSize.size32}
											name={attendee.name}
											mail={attendee.email}
											index={index}
										/>
									),
									id: attendee.email,
									title: attendee.name,
									subTitle: attendee.email,
								}))}
						/>
					</div>
				</>
			)}

			<div className={styles.containerTags}>
				<div className={styles.buttonTags} onClick={() => openTagsModal()}>
					<div className={styles.tagsWrapper}>
						<p>{t('Tags')}</p>
						<div className={styles.tags}>
							{tags?.map((tag) => (
								<div
									key={tag.title}
									className={styles.tag}
									style={{
										backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
										border: `1px solid ${tag.color}`,
									}}>
									<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>

									<div
										style={{ color: tag.color, fontWeight: '600' }}
										className={styles.iconDelete}
										onClick={() => deleteTag(tag)}>
										<IconClose />
									</div>
								</div>
							))}
						</div>
					</div>
					<div className={styles.icon}>
						<IconSimplePlus />
					</div>
				</div>

				{tagsModalOpen && (
					<TagsFormModal close={closeTagsModal} handleSimpleClose={openTagsModal} type={'newProject'} />
				)}
			</div>
			<div className={styles.textEditor}>
				<SimpleEditor content={text} onChangeHandlerText={handleTextChange} />
			</div>
		</FormLayout>
	);
};

export default ProjectUpdateModal;
