import { AuthContextProps } from 'react-oidc-context';
import { IMsalContext } from '@azure/msal-react/dist/MsalContext';
import { AuthenticationProvider } from './types/authentication-provider';

export class AuthInstance {
	public authProvider: AuthenticationProvider = AuthenticationProvider.Microsoft;
	private _googleAuthInstance?: AuthContextProps;
	private _microsoftAuthInstance?: IMsalContext;

	public initialize(googleAuthInstance: AuthContextProps, microsoftAuthInstance: IMsalContext) {
		this._googleAuthInstance = googleAuthInstance;
		this._microsoftAuthInstance = microsoftAuthInstance;

		if (microsoftAuthInstance.accounts.length > 0) {
			this.authProvider = AuthenticationProvider.Microsoft;
		} else if (googleAuthInstance.user) {
			this.authProvider = AuthenticationProvider.Google;
		}
	}

	public googleAuthInstance(): AuthContextProps {
		if (this._googleAuthInstance) {
			return this._googleAuthInstance!;
		} else {
			throw new Error('Google Auth Instance has not been set');
		}
	}

	public microsoftAuthInstance(): IMsalContext {
		if (this._microsoftAuthInstance) {
			return this._microsoftAuthInstance!;
		} else {
			throw new Error('Microsoft Auth Instance has not been set');
		}
	}
}
