import { CompanyContract } from '../settings/type';

export class InvitationContract {
	constructor(
		public id: string,
		public userId: string,
		public expirationDate: Date,
		public status: InvitationStatus,
	) {}
}

export enum InvitationInvalidReason {
	INCORRECT_USER = 'INCORRECT_USER',
	EXPIRED = 'EXPIRED',
	NOT_FOUND = 'NOT_FOUND',
	ALREADY_COMPLETE = 'ALREADY_COMPLETE',
}

export enum InvitationSendInvalidReason {
	EXISTING_USER = 'EXISTING_USER',
}

export class InvitationDataContract {
	constructor(
		public success: boolean,
		public invitation?: InvitationContract,
		public invalidReason?: InvitationInvalidReason,
		public company?: CompanyContract,
	) {}
}

export enum InvitationStatus {
	PENDING = 'Pending',
	COMPLETE = 'Complete',
}

export class InvitationSendContract {
	constructor(public success: boolean, public invalidReason?: InvitationSendInvalidReason) {}
}
