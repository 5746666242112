import HttpClient from '../shared/http/http-client';
import {
	UserContract,
	UpdateCurrentUserContract,
	ContactsGraphContract,
	TourUpdate,
	UpdateCurrentUserColContract,
	UsersCompanyContract,
} from '../store/user/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';

export default class UsersAPI {
	public static async getCurrentUser(): Promise<UserContract> {
		const { data } = await HttpClient.get<UserContract>(ApiUrls.GetCurrentUser);
		return data;
	}

	public static async updateCurrentUser(body: UpdateCurrentUserContract): Promise<UserContract> {
		const { data } = await HttpClient.patch<UpdateCurrentUserContract, UserContract>(
			ApiUrls.UpdateCurrentUser,
			body,
		);
		return data;
	}

	public static async updateCurrentUserTour(body: TourUpdate): Promise<UserContract> {
		const { data } = await HttpClient.patch<TourUpdate, UserContract>(ApiUrls.UpdateCurrentUserTour, body);
		return data;
	}

	public static async getUserByID(userMail: string): Promise<UserContract> {
		const { data } = await HttpClient.get<UserContract>(
			ApiUrls.GetUserByID,
			new UrlParamBuilder().addUserMail(userMail).build(),
		);
		return data;
	}

	public static async getUserAvatar(): Promise<ArrayBuffer | Blob> {
		const { data } = await HttpClient.getImage<ArrayBuffer | Blob>(ApiUrls.GetAvatar);
		return data;
	}

	public static async getUsersAvatar(userMail: string): Promise<ArrayBuffer | Blob> {
		const { data } = await HttpClient.getImage<ArrayBuffer | Blob>(
			ApiUrls.GetAvatarForAttendees,
			new UrlParamBuilder().addUserMail(userMail).build(),
		);
		return data;
	}

	public static async getUsersAvatarGoogle(userMail: string): Promise<string> {
		const { data } = await HttpClient.get<string>(
			ApiUrls.GetAvatarForAttendeesGoogle,
			new UrlParamBuilder().addUserMail(userMail).build(),
		);
		return data;
	}

	public static async getCurrentUserContacts(filter: string): Promise<ContactsGraphContract[]> {
		const { data } = await HttpClient.get<ContactsGraphContract[]>(
			ApiUrls.GetCurrentUserContact,
			new UrlParamBuilder().addFilter(filter).build(),
		);
		return data;
	}

	public static async checkUser(userMail: string): Promise<boolean> {
		const { data } = await HttpClient.get<boolean>(
			ApiUrls.checkUser,
			new UrlParamBuilder().addUserMail(userMail).build(),
		);
		return data;
	}

	public static async updateCurrentUserCol(body: UpdateCurrentUserColContract): Promise<UserContract> {
		const { data } = await HttpClient.patch<UpdateCurrentUserColContract, UserContract>(
			ApiUrls.UpdateCurrentUserCol,
			body,
		);
		return data;
	}

	public static async getUsersJobList(): Promise<UsersCompanyContract[]> {
		const { data } = await HttpClient.get<UsersCompanyContract[]>(ApiUrls.GetAllUsersJobList);
		return data;
	}
}
