export const emoticonsUT8 = [
	{ code: '&#128512;', smiley: '😀' },
	{ code: '&#128519;', smiley: '😇' },
	{ code: '&#128520;', smiley: '😈' },
	{ code: '&#128525;', smiley: '😍' },
	{ code: '&#128526;', smiley: '😎' },
	{ code: '&#129395;', smiley: '🥳' },
	{ code: '&#128568;', smiley: '😸' },
	{ code: '&#128188;', smiley: '💼' },
	{ code: '&#128204;', smiley: '📌' },
	{ code: '&#128214;', smiley: '📖' },
	{ code: '&#128222;', smiley: '📞' },
	{ code: '&#128267;', smiley: '🔋' },
	{ code: '&#128241;', smiley: '📱' },
	{ code: '&#128246;', smiley: '📶' },
	{ code: '&#128386;', smiley: '🖂' },
	{ code: '&#9889;', smiley: '⚡' },
	{ code: '&#11088;', smiley: '⭐' },
	{ code: '&#9749;', smiley: '☕' },
	{ code: '&#8987;', smiley: '⌛' },
	{ code: '&#127942;', smiley: '🏆' },
	{ code: '&#127919;', smiley: '🎯' },
	{ code: '&#129513;', smiley: '🧩' },
	{ code: '&#127757;', smiley: '🌍' },
	{ code: '&#127760;', smiley: '🌐' },
	{ code: '&#127909;', smiley: '🎥' },
	{ code: '&#128226;', smiley: '📢' },
	{ code: '&#128507;', smiley: '🗻' },
	{ code: '&#128508;', smiley: '🗼' },
	{ code: '&#128640;', smiley: '🚀' },
	{ code: '&#128681;', smiley: '🚩' },
	{ code: '&#128735;', smiley: '🛟' },
	{ code: '&#128736;', smiley: '🛠' },
	{ code: '&#127793;', smiley: '🌱' },
	{ code: '&#127809;', smiley: '🍁' },
	{ code: '&#127800;', smiley: '🌸' },
	{ code: '&#128270;', smiley: '🔎' },
	{ code: '&#128273;', smiley: '🔑' },
	{ code: '&#128294;', smiley: '🔦' },
	{ code: '&#128295;', smiley: '🔧' },
	{ code: '&#129527;', smiley: '🧷' },
];
