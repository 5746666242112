import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import ProjetcList from './components/project-list';
import styles from './project.module.scss';
import {
	getNotificationsProject,
	getPeopleShareProject,
	getProjects,
	setSelectedProject,
} from '../../store/project/slice';
import ProjectDetails from './components/project-details';
import { useSearchParams } from 'react-router-dom';
import { RouteSearchParams } from '../../routes/routes-config';
import { ProjectContract } from '../../store/project/type';
import { Tab } from '../../shared/types/component-interface';
import { useTranslation } from 'react-i18next';

const ProjectPage = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { selectProject, projects } = useAppSelector((state) => state.project);
	// const [activeTabIndex, setActiveTabIndex] = React.useState(1);
	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setIsLoading] = React.useState(true);

	const [tabs] = React.useState<Tab[]>([
		{
			label: t('Description'),
			component: <></>,
		},
		{ label: t('Last infos'), component: <></> },
		{
			label: t('Meetings'),
			component: <></>,
		},
		{
			label: t('Notes'),
			component: <></>,
		},
		{
			label: t('Todos'),
			component: <></>,
		},
		{
			label: t('Files'),
			component: <></>,
		},
	]);

	const [activeTabIndex, setActiveTabIndex] = React.useState(() => {
		const tabKey = searchParams.get('tab');

		const initialIndex = tabs.findIndex((tab) => tab.label.toLowerCase().replace(/\s+/g, '-') === tabKey);
		return initialIndex !== -1 ? initialIndex : 1;
	});

	const [projectId, setProjectId] = React.useState('');

	React.useEffect(() => {
		const Id = searchParams.get(RouteSearchParams.ProjectId);
		setProjectId(
			Id ? Id : selectProject ? selectProject.id : projects && projects.length > 0 ? projects[0].id : '',
		);
	}, [searchParams, projects]);

	useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
		dispatch(getProjects()).then((res) => {});

		const Id = searchParams.get(RouteSearchParams.ProjectId);
		const proj = projects;
		if (!Id) {
			const projId = selectProject ? selectProject.id : proj && proj.length > 0 ? proj[0].id : '';
			searchParams.set(RouteSearchParams.ProjectId, projId);
			setSearchParams(searchParams, {});
			const projectToBeShow = proj?.find((projectSelect) => projectSelect.id === projId);
			dispatch(setSelectedProject(projectToBeShow));
		} else {
			searchParams.set(RouteSearchParams.ProjectId, Id);
			setSearchParams(searchParams, {});
			const projectToBeShow = proj?.find((projectSelect) => projectSelect.id === Id);
			dispatch(setSelectedProject(projectToBeShow));
		}
	}, [dispatch]);

	useEffect(() => {
		if (projectId) {
			const projectToBeShow = projects?.find((projectSelect) => projectSelect.id === projectId);
			if (projectToBeShow) {
				dispatch(setSelectedProject(projectToBeShow));
				dispatch(getPeopleShareProject(projectToBeShow.id));
				dispatch(getNotificationsProject(projectToBeShow.id));
			}
		}
	}, [projectId]);

	useEffect(() => {
		if (selectProject) {
			dispatch(getPeopleShareProject(selectProject.id));
			dispatch(getNotificationsProject(selectProject.id));
		}
	}, [selectProject]);

	useEffect(() => {
		const fetchData = async () => {
			if (selectProject) {
				setIsLoading(true);
				dispatch(getPeopleShareProject(selectProject.id));
				dispatch(getNotificationsProject(selectProject.id));
				setActiveTabIndex(1);
			}
			setIsLoading(false);
		};
		fetchData();
	}, []);

	const [openList, setOpenList] = React.useState(false);
	const [isMobile, setIsMobile] = React.useState(false);
	const mediaQuery = window.matchMedia('(max-width: 500px)');

	function updateIsMobile(event: MediaQueryListEvent): void {
		if (isMobile !== event.matches) {
			setIsMobile(event.matches);
		}
	}

	mediaQuery.addEventListener('change', updateIsMobile);

	const handleOpenList = () => {
		setOpenList(!openList);
	};

	return (
		<div className={styles.mainProjects}>
			{!isMobile && (
				<>
					<ProjetcList showList={handleOpenList} />
					<ProjectDetails
						showList={handleOpenList}
						isLoading={isLoading}
						activeTabIndex={activeTabIndex}
						setActiveTabIndex={setActiveTabIndex}
					/>
				</>
			)}
			{isMobile && (
				<>
					{openList ? (
						<ProjetcList showList={handleOpenList} />
					) : (
						<ProjectDetails
							showList={handleOpenList}
							isLoading={isLoading}
							activeTabIndex={activeTabIndex}
							setActiveTabIndex={setActiveTabIndex}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default ProjectPage;
