import HttpClient from '../shared/http/http-client';
import { CommentsContract } from '../store/comments/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';

export default class CommentsAPI {
	public static async getCommentForANote(notesId: string): Promise<CommentsContract[]> {
		const { data } = await HttpClient.get<CommentsContract[]>(
			ApiUrls.getCommentToNote,
			new UrlParamBuilder().addNotesId(notesId).build(),
		);
		return data;
	}

	public static async getCommentForANoteForMeeting(body: string[]): Promise<CommentsContract[]> {
		const { data } = await HttpClient.post<string[], CommentsContract[]>(
			ApiUrls.getCommentToNoteForMeeting,
			body
		);
		return data;
	}

	public static async postCommentForANote(body: CommentsContract, notesId: string): Promise<CommentsContract> {
		const { data } = await HttpClient.post<CommentsContract, CommentsContract>(
			ApiUrls.postCommentToNote,
			body,
			new UrlParamBuilder().addNotesId(notesId).build(),
		);
		return data;
	}

	public static async updateCommentForANote(body: CommentsContract, commentId: string): Promise<CommentsContract> {
		const { data } = await HttpClient.patch<CommentsContract, CommentsContract>(
			ApiUrls.updateCommentFromANote,
			body,
			new UrlParamBuilder().addCommentId(commentId).build(),
		);
		return data;
	}

	public static async deleteCommentForANote(commentId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.deleteCommentFromANote,
			new UrlParamBuilder().addCommentId(commentId).build(),
		);
		return data;
	}
}
