/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable unicorn/no-null */
import React, { useState } from 'react';
import styles from './todos.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { deleteTodosAssignee, getCurrentUserTodos, updateTodoAssignee } from '../../store/todos/slice';
import { RequestStatusType } from '../../store/shared/types';
import { TodosContract, TodosStates } from '../../store/todos/type';
import TodosViewSimple from './components/todos-view-simple';
import TodosViewBoard from './components/todo-view-board';
import { getCustomColumnForAUser } from '../../store/column/slice';
import RoundedButton from '../../shared/components/buttons/roundedBtn/rounded-btn.component';
import { SectionType, useCreateTodoMutation } from '../../shared/hooks/use-todos-mutations.hook';
import TodoFilters, { filterConfigurations } from './components/todo-filters.component';
import { IconMeetings, IconStatus, IconTag, IconMeetingsPage } from '../../shared/utils/icon';
import DateCell from './components/date-cell.component';
import MeetingCell from './components/meeting-cell.component';
import ProjectCell from './components/project-cell.component';
import StateCell from './components/state-cell.component';
import TagsCell from './components/tags-cell.component';
import { TodosColumns } from '../../shared/hooks/use-todos-colums.hook';

const TodosPage = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { todos, todosRequestStatus } = useAppSelector((state) => state.todos);
	const { user } = useAppSelector((state) => state.user);
	const storedView = localStorage.getItem('viewTodo');
	const [view, setView] = React.useState(storedView ? storedView : 'Simple');
	const [filteredTodosList, setFilteredTodosList] = useState<TodosContract[]>(todos ?? []);
	const createTodoMutation = useCreateTodoMutation();

	React.useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
		const fetchTodos = async () => {
			await dispatch(getCurrentUserTodos());
		};

		fetchTodos();
		if (user) dispatch(getCustomColumnForAUser(user.graphUserId));
	}, []);

	React.useEffect(() => {
		if (filterConfigurations.every((filter) => !filter.isActive)) {
			setFilteredTodosList(todos ?? []);
		}
	}, [todos, filterConfigurations]);

	const [todosColumns] = useState<TodosColumns[]>([
		{
			icon: IconMeetings,
			title: t('Due date'),
			width: 200,
			isActive: true,
			component: (todo) => <DateCell updateFn={updateTodoAssignee} todo={todo} sectionType={SectionType.todos} />,
		},
		{
			icon: IconStatus,
			title: t('State'),
			width: 200,
			isActive: true,
			component: (todo) => <StateCell todo={todo} updateFn={updateTodoAssignee} sectionType={SectionType.todos} />,
		},
		{
			icon: IconTag,
			title: t('Tags'),
			width: 200,
			isActive: true,
			component: (todo) => <TagsCell sectionType={SectionType.todos} todo={todo} updateFn={updateTodoAssignee} />,
		},
		{
			icon: IconMeetingsPage,
			title: t('Connected meeting'),
			width: 200,
			isActive: true,
			component: (todo) => <MeetingCell updateFn={updateTodoAssignee} todo={todo} sectionType={SectionType.todos} />,
		},
		{
			icon: IconMeetingsPage,
			title: t('Connected project'),
			width: 200,
			isActive: true,
			component: (todo) => <ProjectCell updateFn={updateTodoAssignee} todo={todo} sectionType={SectionType.todos} />,
		}
	]);

	const handleGroupChange = (val: string) => {
		localStorage.setItem('viewTodo', val);
		setView(val);
	};

	const handleFilteredTodo = (todos: TodosContract[]) => {
		setFilteredTodosList(todos);
	};

	const createTodo = async () => {
		const newTodo: any = {
			title: '',
			text: '',
			state: TodosStates.TODO,
			dueDate: '',
			duration: 0,
			graphUserId: user ? user.graphUserId : '',
			noteId: '',
			assigneeDisplayName: user ? user.email : '',
			assigneeName: user ? user.name : '',
			graphiCalUId: undefined,
			meetingGraphId: undefined,
			meetingName: undefined,
			meetingStartDate: undefined,
			createdOn: new Date(),
			tags: [],
			archived: false,
			projectId: '',
		};

		createTodoMutation.mutate({ ...newTodo, createdOn: new Date() });
	};

	return (
		<>
			<div className={styles.overviewList}>
				<div className={styles.viewHeader} style={{ boxShadow: view === 'Board' ? '0px 2px 4px rgba(0, 0, 0, 0.05)' : '' }}>
					<div className={styles.top}>
						<h1>{t('My tasks')}</h1>
						<div className={styles.flex}>
							<RoundedButton onClick={() => createTodo()} />
						</div>
					</div>

					<TodoFilters
						todos={todos ?? []}
						handleGroupChange={handleGroupChange}
						view={view}
						handleFilteredTodos={handleFilteredTodo}
						sectionType={SectionType.todos}
					/>
				</div>
				<div className={styles.main}>
					<div>
						{todosRequestStatus.type === RequestStatusType.Success && view === 'Simple' && todos && (
							<>
								<div className={styles.mainTodoPage}>
									<div className={styles.overview}>
										<TodosViewSimple
											deleteTodoFn={deleteTodosAssignee}
											sectionType={SectionType.todos}
											todosColumns={todosColumns}
											height='calc(100vh - 198px)'
											updateTitleFn={updateTodoAssignee}
											updateTodoFn={updateTodoAssignee}
											todosAssignee={[...filteredTodosList].sort((a: TodosContract, b: TodosContract) => Date.parse(b.createdOn.toString()) - Date.parse(a.createdOn.toString()))}
										/>
									</div>
								</div>
							</>
						)}

						{todosRequestStatus.type === RequestStatusType.Success && view === 'Board' && todos && (
							<>
								<div className={styles.mainTodoPage}>
									<TodosViewBoard
										h='calc(100vh - 200px)'
										w='100%'
										todosAssignee={todos}
									/>
								</div>
							</>
						)}

						{todosRequestStatus.type === RequestStatusType.Failed && (
							<tr>{todosRequestStatus.errorMessage}</tr>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default TodosPage;
