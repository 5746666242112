/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styles from './meetings-details-agenda.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AgendaContract } from '../../../../../../store/agenda/type';
import { IconClose } from '../../../../../../shared/utils/icon';
import { Avatar } from '../../../../../../shared/utils/avatar';
import { selectTime } from '../../../../../../shared/utils/hours';
import { updateAgendaItem, updateAgendaItemState } from '../../../../../../store/agenda/slice';
import { updateAgendaItemShared, updateAgendaItemStateShared } from '../../../../../../store/sharing/slice';
import 'react-tooltip/dist/react-tooltip.css';

interface Props {
	agenda: AgendaContract;
	close: () => void;
}

export const MeetingsDetailsAgendaUpdate: React.FC<Props> = ({ agenda, close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const { shareMeeting } = useAppSelector((state) => state.sharing);
	const [mail, setMail] = useState(agenda.userMail);

	type UpdateFormValues = {
		duration: string;
		assignee: string;
		description: string;
	};

	const updateFormSchema = yup.object().shape({
		duration: yup.string().required(),
		assignee: yup.string(),
		description: yup.string(),
	});

	const {
		register: registerUpdateForm,
		handleSubmit: handleSubmitUpdateForm,
		setValue: updateSetValue,
		reset: updateReset,
	} = useForm<UpdateFormValues>({
		resolver: yupResolver(updateFormSchema),
	});

	const updateAgendaItems = (data: UpdateFormValues) => {
		close();
		updateReset();
		if (user && selectedMeeting && agenda) {
			const body = {
				id: agenda.id,
				userCreatorId: agenda.userCreatorId,
				userMail: data.assignee,
				duration: data.duration,
				description: data.description,
				graphiCalUId: agenda.graphiCalUId,
				orderNumber: agenda.orderNumber,
			};

			dispatch(updateAgendaItem(body)).then((res) => {
				const newAgenda = res.payload as AgendaContract;
				dispatch(updateAgendaItemState({ agendaId: agenda.id, newData: newAgenda }));
				updateReset();
			});
		} else if (shareMeeting && agenda) {
			const body = {
				id: agenda.id,
				userCreatorId: agenda.userCreatorId,
				userMail: data.assignee,
				duration: data.duration,
				description: data.description,
				graphiCalUId: agenda.graphiCalUId,
				orderNumber: agenda.orderNumber,
			};

			dispatch(updateAgendaItemShared(body)).then(() => {
				dispatch(updateAgendaItemStateShared({ agendaId: agenda.id, newData: body }));
				updateReset();
			});
		}
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [attendeeMenu, setAttendeeMenu] = React.useState(false);

	const handleMenu = () => {
		setAttendeeMenu(!attendeeMenu);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	return (
		<>
			<div className={styles.update}>
				<div className={styles.main}>
					<div className={styles.closeButton}>
						<div
							className={styles.icon}
							onClick={() => {
								close();
								updateReset();
							}}>
							<IconClose />
						</div>
					</div>
					<form onSubmit={handleSubmitUpdateForm(updateAgendaItems)}>
						<label>{t('Duration')}</label>
						<br />
						<br />
						<select defaultValue={agenda.duration} {...registerUpdateForm('duration')}>
							{selectTime.map((time, index) => (
								<option key={index} value={time}>
									{time}
								</option>
							))}
						</select>
						<br />
						<br />
						<label>{t('Assignee')}</label>
						<br />
						<br />
						<div className={styles.flex} onClick={() => handleMenu()}>
							<Avatar name={mail} mail={mail} index={8} />
							<span>{mail}</span>
						</div>
						{attendeeMenu && (
							<>
								<div className={styles.modalAttendee} ref={modalRef}>
									<div className={styles.containerList}>
										{selectedMeeting?.attendees
											.filter(
												(contact) =>
													contact.emailAddress.address !== '' ||
													contact.emailAddress.name !== '',
											)
											.map((contact, index) => (
												<div
													className={styles.list}
													key={index}
													onClick={() => {
														updateSetValue('assignee', contact.emailAddress.address);
														setMail(contact.emailAddress.address);
														handleMenu();
													}}>
													<div className={styles.name}>
														<Avatar
															name={contact.emailAddress.name}
															mail={contact.emailAddress.address}
															index={index}
														/>
														<span>{contact.emailAddress.name}</span>
													</div>
												</div>
											))}
									</div>
								</div>
							</>
						)}
						<br />
						<label>{t('Description')}</label>
						<br />
						<br />
						<textarea
							{...registerUpdateForm('description')}
							defaultValue={agenda.description}
							placeholder={t('Write something here...') as string}></textarea>
						<br />
						<br />
						<div className={styles.flexButton}>
							<button type='submit'>{t('Save')}</button>
							<button
								type='button'
								className={styles.cancel}
								onClick={() => {
									close();
									updateReset();
								}}>
								{t('Cancel')}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
