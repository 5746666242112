import React from 'react';
import styles from './meeting-update-create.modal.module.scss';
import { useTranslation } from 'react-i18next';
import { getHour, getMin, getRightTimeSlot, getStartOfDayDate } from '../../../shared/utils/date.util';
import { MeetingContract } from '../../../store/meetings/types';
import { hoursSimple, minSimple } from '../../../shared/utils/hours';

interface Props {
	date: Date;
	handleDatePicker: (date: Date) => void;
	close: () => void;
	meeting?: MeetingContract;
	type: string;
}

const MeetingFormHoursPickerModal: React.FC<Props> = ({ date, handleDatePicker, close, meeting, type }) => {
	const { t } = useTranslation();
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [newDate, setnewDate] = React.useState(date);
	const [hour, setHour] = React.useState(getHour(date));
	const [min, setMin] = React.useState(getMin(date));

	const handleDateSelect = (dateSelect: Date) => {
		setnewDate(dateSelect);
	};

	const confirmNewDate = () => {
		handleDatePicker(getRightTimeSlot(getStartOfDayDate(newDate), hour, min));
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					close();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close]);

	return (
		<>
			<div className={styles.hourPicker} ref={modalRef}>
				<div className={styles.hoursPicker}>
					<select defaultValue={hour} onChange={(e) => setHour(Number.parseInt(e.target.value))}>
						{hoursSimple.map((hours) => (
							<option value={hours} key={hours}>
								{hours}
							</option>
						))}
					</select>{' '}
					:{' '}
					<select defaultValue={min} onChange={(e) => setMin(Number.parseInt(e.target.value))}>
						{minSimple.map((mins) => (
							<option value={mins} key={mins}>
								{mins}
							</option>
						))}
					</select>
				</div>
				<button onClick={() => confirmNewDate()} className={styles.confirm}>
					{t('Confirm')}
				</button>
			</div>
		</>
	);
};

export default MeetingFormHoursPickerModal;
