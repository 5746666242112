import React from 'react';
import styles from './terms-and-conditions.component.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
	handleAcceptTheRights: (value: boolean) => void;
	hasAcceptTheRights: boolean;
}

const TermsAndConditions: React.FC<Props> = ({ handleAcceptTheRights, hasAcceptTheRights }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.checkboxFlex}>
			<input
				type='checkbox'
				checked={hasAcceptTheRights}
				onChange={(e) => handleAcceptTheRights(e.target.checked)}
			/>
			<span>
				{t('I read and accept')}{' '}
				<a href='https://fr.getfivedays.com/privacy-and-terms' target='_blank' rel='noreferrer'>
					{t('general terms and conditions of use')}
				</a>
			</span>
		</div>
	);
};

export default TermsAndConditions;
