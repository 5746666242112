import React from 'react';
import styles from './draggable-desktop-area.module.scss';

const DraggableDesktopArea = () => {
    const startDrag = (e: React.MouseEvent) => {
        const { ipcRenderer } = window.electron;
        console.log(ipcRenderer);
    
        const initialX = e.clientX;
        const initialY = e.clientY;
    
        const mouseMoveHandler = (event: MouseEvent) => {
          const deltaX = event.clientX - initialX;
          const deltaY = event.clientY - initialY;
    
          ipcRenderer.send('move-window', deltaX, deltaY);
        };
    
        const mouseUpHandler = () => {
          document.removeEventListener('mousemove', mouseMoveHandler);
          document.removeEventListener('mouseup', mouseUpHandler);
        };
    
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

    return (
        <div onMouseDown={startDrag} className={styles.draggableArea}></div>
    );
};

export default DraggableDesktopArea;