import React, { useRef, useState } from 'react';
import styles from './custom-select.component.module.scss';
import { IconCheck, IconDownChev } from '../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';

interface Props {
	options: Array<string>;
	checkbox?: boolean;
	value: Array<string>;
	handleValue: (value: string) => void;
	children: string;
}

const CustomSelect: React.FC<Props> = ({ options, checkbox = false, handleValue, value, children }) => {
	const { t } = useTranslation();
	const [openMenu, setOpenMenu] = useState<boolean>(false);

	const dropDownMenu = useRef<HTMLDivElement>(null);

	const handleMenu = () => {
		setOpenMenu(!openMenu);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropDownMenu.current && !dropDownMenu.current.contains(event.target as Node)) {
				setOpenMenu(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	const handleOptionValue = (el: string) => {
		handleValue(el);

		if (!Number.isNaN(Number(el[0]))) {
			setOpenMenu(false);
		}

		if (el[0] === 'A' || el[0] === 'U') {
			setOpenMenu(false);
		}
	};

	return (
		<div className={styles.inputWrapper}>
			<div onClick={() => handleMenu()}>
				<label>{t(children)}</label>
				<div className={styles.selectIcon}>
					<IconDownChev />
				</div>
				<div className={styles.selectChoiceWrapper}>
					<div className={styles.selectChoice}>
						{value.map((val) => val + (value.length > 1 ? ', ' : ''))}
					</div>
				</div>
				<p className={styles.counter}>
					{value.length > 2 ? '+' : ''}
					{value.length > 2 ? value.length - 2 : ''}
				</p>
			</div>
			<div ref={dropDownMenu}>
				<ul className={styles.customOptionsContainer} style={{ display: openMenu ? 'block' : 'none' }}>
					{options.map((el, index) => (
						<li
							key={index}
							onClick={() => handleOptionValue(el)}
							style={{
								backgroundColor: value.includes(el) ? 'rgba(5, 173, 86, 0.10)' : '',
								color: value.includes(el) ? '#09AB57' : '',
							}}>
							<div
								className={styles.checkbox}
								style={{
									display: checkbox ? 'block' : 'none',
									color: value.includes(el) ? 'white' : '',
									backgroundColor: value.includes(el) ? '#09AB57' : '',
									border: value.includes(el) ? 'none' : '',
								}}>
								<div style={{ display: value.includes(el) ? 'block' : 'none' }}>
									<IconCheck />
								</div>
							</div>
							{el}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default CustomSelect;
