import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, RequestStatusType, createRequestStatus } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import { BotNotesContract, DataMeetingBotContract, MistralCall, MistralTodoUpdate } from './type';
import RecallAPI from '../../api/recall-api';
import { TodosContract } from '../todos/type';

interface RecallState {
	dataMeetingBot?: DataMeetingBotContract;
	dataMeetingBotRequestStatus: RequestStatus;

	dataMeetingBotTranscript?: BotNotesContract;
	dataMeetingBotRequestStatusTranscript: RequestStatus;

	mistralSummary?: string;
	mistralSummaryRequestStatus: RequestStatus;

	mistralTodosRequestStatus: RequestStatus;

	todosMistral?: TodosContract[];
	todosMistralRequestStatus: RequestStatus;
}

const initialState: RecallState = {
	dataMeetingBot: undefined,
	dataMeetingBotRequestStatus: createRequestStatus(RequestStatusType.New),

	dataMeetingBotTranscript: undefined,
	dataMeetingBotRequestStatusTranscript: createRequestStatus(RequestStatusType.New),

	mistralSummary: undefined,
	mistralSummaryRequestStatus: createRequestStatus(RequestStatusType.New),

	mistralTodosRequestStatus: createRequestStatus(RequestStatusType.New),

	todosMistral: undefined,
	todosMistralRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getDataMeetingBot = createAsyncThunk(
	'/recall/get/data',
	async (iCalUId: string): Promise<DataMeetingBotContract> => {
		return RecallAPI.getMeetingsDataBot(iCalUId);
	},
);

export const getDataMeetingBotTranscript = createAsyncThunk(
	'/recall/get/data/transcript',
	async (botId: string): Promise<BotNotesContract> => {
		return RecallAPI.getMeetingsDataBotTranscript(botId);
	},
);

export const runBotAnaalyze = createAsyncThunk('/recall/run/analyse/bot', async (botId: string): Promise<boolean> => {
	return RecallAPI.runBotAnalyze(botId);
});

export const deleteDataMeetingBot = createAsyncThunk(
	'/recall/delete/data',
	async (iCalUId: string): Promise<undefined> => {
		return RecallAPI.deleteMeetingsDataBot(iCalUId);
	},
);

export const createDataMeetingBot = createAsyncThunk(
	'/recall/create/data',
	async (body: DataMeetingBotContract): Promise<DataMeetingBotContract> => {
		return RecallAPI.createMeetingsDataBot(body);
	},
);

export const generateMistralSummary = createAsyncThunk(
	'/recall/get/mistral/summary',
	async (body: MistralCall): Promise<string> => {
		return RecallAPI.generateMistralSummary(body);
	},
);

export const generateMistralTodos = createAsyncThunk(
	'/recall/get/mistral/todos',
	async (body: MistralCall): Promise<TodosContract[]> => {
		return RecallAPI.GenerateMistralTodos(body);
	},
);

const slice = createSlice({
	name: 'recall',
	initialState,
	reducers: {
		updateSelectMeetingDataBot: (state, action: PayloadAction<DataMeetingBotContract | undefined>) => {
			const newData = action.payload;
			if (state.dataMeetingBot) {
				state.dataMeetingBot = newData;
			}
		},

		updateSelectMeetingDataNoteBot: (state, action: PayloadAction<BotNotesContract | undefined>) => {
			const newData = action.payload;
			if (state.dataMeetingBotTranscript) {
				state.dataMeetingBotTranscript = newData;
			}
		},

		setTodosMistral: (state, action: PayloadAction<TodosContract[] | undefined>) => {
			state.todosMistral = action.payload;
		},

		updateTodoMistral: (state, action: PayloadAction<MistralTodoUpdate>) => {
			const { id, newAttName, newAttMail, newTitle, projectId } = action.payload;
			if (state.todosMistral) {
				const todo = state.todosMistral.find((todoNT) => todoNT.id === id);
				if (todo) {
					todo.title = newTitle;
					todo.assigneeDisplayName = newAttMail;
					todo.assigneeName = newAttName;
					todo.projectId = projectId;
				}
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getDataMeetingBot.pending, (state) => {
			state.dataMeetingBotRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getDataMeetingBot.fulfilled, (state, action) => {
			state.dataMeetingBotRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.dataMeetingBot = action.payload;
		});
		builder.addCase(getDataMeetingBot.rejected, (state, action) => {
			state.dataMeetingBotRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getDataMeetingBotTranscript.pending, (state) => {
			state.dataMeetingBotRequestStatusTranscript = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getDataMeetingBotTranscript.fulfilled, (state, action) => {
			state.dataMeetingBotRequestStatusTranscript = createRequestStatus(RequestStatusType.Success);
			state.dataMeetingBotTranscript = action.payload;
		});
		builder.addCase(getDataMeetingBotTranscript.rejected, (state, action) => {
			state.dataMeetingBotRequestStatusTranscript = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(generateMistralSummary.pending, (state) => {
			state.mistralSummaryRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(generateMistralSummary.fulfilled, (state, action) => {
			state.mistralSummaryRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.mistralSummary = action.payload;
		});
		builder.addCase(generateMistralSummary.rejected, (state, action) => {
			state.mistralSummaryRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(generateMistralTodos.pending, (state) => {
			state.mistralTodosRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(generateMistralTodos.fulfilled, (state, action) => {
			state.mistralTodosRequestStatus = createRequestStatus(RequestStatusType.Success);
		});
		builder.addCase(generateMistralTodos.rejected, (state, action) => {
			state.mistralTodosRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const { updateSelectMeetingDataBot, updateSelectMeetingDataNoteBot, setTodosMistral, updateTodoMistral } =
	actions;
export default reducer;
