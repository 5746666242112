import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, RequestStatusType, createRequestStatus } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import { CommentsContract, UpdateStatePayload } from './type';
import CommentsAPI from '../../api/comments-api';

interface CommentsState {
	commentsForANote?: CommentsContract[];
	commentsForANoteRequestStatus: RequestStatus;

	commentsForANoteMeeting?: CommentsContract[];
	commentsForANoteMeetingRequestStatus: RequestStatus;
}

const initialState: CommentsState = {
	commentsForANote: undefined,
	commentsForANoteRequestStatus: createRequestStatus(RequestStatusType.New),

	commentsForANoteMeeting: undefined,
	commentsForANoteMeetingRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getCommentsForANote = createAsyncThunk(
	'comments/note/get',
	async (notesId: string): Promise<CommentsContract[]> => {
		return CommentsAPI.getCommentForANote(notesId);
	},
);

export const getCommentsForANoteForMeetings = createAsyncThunk(
	'comments/note/get/meeting',
	async (iCalUId: string[]): Promise<CommentsContract[]> => {
		return CommentsAPI.getCommentForANoteForMeeting(iCalUId);
	},
);

export const postCommentsForANote = createAsyncThunk(
	'comments/note/post',
	async ({ body, notesId }: { body: CommentsContract; notesId: string }): Promise<CommentsContract> => {
		return CommentsAPI.postCommentForANote(body, notesId);
	},
);

export const updateCommentsForANote = createAsyncThunk(
	'comments/note/update',
	async ({ body, commentId }: { body: CommentsContract; commentId: string }): Promise<CommentsContract> => {
		return CommentsAPI.updateCommentForANote(body, commentId);
	},
);

export const deleteCommentsForANote = createAsyncThunk(
	'comments/note/delete',
	async (commentId: string): Promise<undefined> => {
		return CommentsAPI.deleteCommentForANote(commentId);
	},
);

const slice = createSlice({
	name: 'comments',
	initialState,
	reducers: {
		deleteCommentMeeting: (state, action: PayloadAction<string>) => {
			const id = action.payload;
			if (state.commentsForANoteMeeting) {
				state.commentsForANoteMeeting = state.commentsForANoteMeeting.filter((comment) => comment.id !== id);
			}
		},

		updateCommentMeeting: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { commentId, newText } = action.payload;
			if (state.commentsForANoteMeeting) {
				const comment = state.commentsForANoteMeeting.find((commentNT) => commentNT.id === commentId);
				if (comment) {
					comment.textComment = newText;
				}
			}
		},

		deleteCommentNote: (state, action: PayloadAction<string>) => {
			const id = action.payload;
			if (state.commentsForANote) {
				state.commentsForANote = state.commentsForANote.filter((comment) => comment.id !== id);
			}
		},

		updateCommentNote: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { commentId, newText } = action.payload;
			if (state.commentsForANote) {
				const comment = state.commentsForANote.find((commentNT) => commentNT.id === commentId);
				if (comment) {
					comment.textComment = newText;
				}
			}
		},

		addMeetingsNoteComments: (state, action: PayloadAction<CommentsContract>) => {
			const newComment = action.payload;
			if (state.commentsForANoteMeeting) {
				state.commentsForANoteMeeting.push(newComment);
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getCommentsForANote.pending, (state) => {
			state.commentsForANoteRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCommentsForANote.fulfilled, (state, action) => {
			state.commentsForANoteRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.commentsForANote = action.payload;
		});
		builder.addCase(getCommentsForANote.rejected, (state, action) => {
			state.commentsForANoteRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getCommentsForANoteForMeetings.pending, (state) => {
			state.commentsForANoteMeetingRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCommentsForANoteForMeetings.fulfilled, (state, action) => {
			state.commentsForANoteMeetingRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.commentsForANoteMeeting = action.payload;
		});
		builder.addCase(getCommentsForANoteForMeetings.rejected, (state, action) => {
			state.commentsForANoteMeetingRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const { deleteCommentMeeting, updateCommentMeeting, deleteCommentNote, updateCommentNote, addMeetingsNoteComments } = actions;
export default reducer;
