import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import styles from './meeting-details-header-tags.module.scss';
import TagsFormModal from '../../../../modals/tags-form.modal';
import { MeetingContract, TagContract } from '../../../../../../store/meetings/types';
import { IconClose, IconSimplePlus } from '../../../../../../shared/utils/icon';
import { hexToRgb } from '../../../../../../shared/utils/hextorgb';

export interface MeetingDetailsTagsProps {
	meeting: MeetingContract;
	handleTagsChanged: (tags: TagContract[]) => void;
}

const MeetingDetailsTags = ({ meeting, handleTagsChanged }: MeetingDetailsTagsProps) => {
	const { user } = useAppSelector((state) => state.user);
	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);

	const [tagsMeeting, setTagsMeeting] = React.useState<TagContract[]>();

	React.useEffect(() => {
		setTagsMeeting(meeting.tags);
	}, [meeting]);

	const deleteTag = (tag: TagContract) => {
		const tags = meeting.tags.filter((t) => t !== tag);
		handleTagsChanged(tags);
		const updatedTags = tagsMeeting?.filter((t) => t !== tag);
		setTagsMeeting(updatedTags);
	};

	const closeTagsModal = (tag: string, color: string) => {
		if (tagsMeeting) {
			const tags = [
				...meeting.tags,
				new TagContract(undefined, tag, color, user!.graphUserId, [meeting.iCalUId], [], [], []),
			];
			const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [meeting.iCalUId], [], [], []);
			const updatedTags = [...tagsMeeting, newTag];
			setTagsMeeting(updatedTags);
			handleTagsChanged(tags);
			setTagsModalOpen(false);
		}
	};

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};

	return (
		<>
			<div>
				<div className={styles.tags}>
					{tagsMeeting?.map((tag, index) => (
						<div
							key={index}
							className={styles.tag}
							style={{
								backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
								border: `1px solid ${tag.color}`,
							}}>
							<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
							{meeting.isOrganizer === true && user && (
								<div
									style={{ color: tag.color, fontWeight: '600' }}
									className={styles.iconDelete}
									onClick={() => {
										deleteTag(tag);
									}}>
									<IconClose />
								</div>
							)}
						</div>
					))}

					{meeting.isOrganizer === true && user ? (
						<>
							<div className={styles.icon} onClick={() => openTagsModal()}>
								<IconSimplePlus />
							</div>
							{tagsModalOpen && (
								<TagsFormModal
									close={closeTagsModal}
									handleSimpleClose={openTagsModal}
									type='meetings'
								/>
							)}
						</>
					) : (
						<></>
					)}
				</div>
			</div>
		</>
	);
};

export default MeetingDetailsTags;
