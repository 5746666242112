/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { GroupAttendeesByOrg } from '../../../shared/types/meetings-graph-interface';
import { updateSelectedMeeting, updateMeeting, updateSelectedMeetingForMeetings } from '../../../store/meetings/slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { PropsModalsMeeting } from '../../../shared/types/component-interface';
import 'react-quill/dist/quill.snow.css';
import { RequestStatusType } from '../../../store/shared/types';
import { toast } from '../../../shared/components/modals/toast/toast-manager';
import { useSearchParams } from 'react-router-dom';
import { MeetingContract } from '../../../store/meetings/types';
import { RouteSearchParams } from '../../../routes/routes-config';
import {
	getDiffInMinutes,
	getStartOfDay,
	setToMidnight,
	setToMidnightAddOneDay,
} from '../../../shared/utils/date.util';
import { extractDomain } from '../../../shared/utils/domainext';
import MeetingFormComponent, { FormData } from './meeting-form.component';
import { postMeetingsForSeries } from '../../../store/series/slice';
import { MeetingsProjectContract, NotificationsProjectType } from '../../../store/project/type';
import { SendNotificationsProject, linkMeetingProject } from '../../../store/project/slice';
import { deleteDataMeetingBot, updateSelectMeetingDataBot } from '../../../store/recall/slice';
import { FormType } from '../../../shared/components/layouts/web/form-layout.component';

const MeetingDetailsUpdateModal: React.FC<PropsModalsMeeting> = ({ handleToggle, meeting }) => {
	const { updateMeetingsRequestStatus } = useAppSelector((state) => state.meetings);
	const { dataMeetingBot } = useAppSelector((state) => state.recall);
	const { user } = useAppSelector((state) => state.user);

	const [timeType, setTimeType] = React.useState('minutes');
	const [isAllDay, setIsAllDay] = React.useState(false);

	const handleAllDay = (allDay: boolean) => {
		setIsAllDay(allDay);
	};

	const handleTimeType = (time: string) => {
		setTimeType(time);
	};

	const [searchParams, setSearchParams] = useSearchParams();

	React.useEffect(() => {
		if (getDiffInMinutes(meeting.start, meeting.end) === 1440) {
			setIsAllDay(true);
		}
	}, [meeting]);

	// -------

	const handleClose = () => {
		handleToggle();
	};

	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	// --------------

	const [attendees, setAttendees] = React.useState<
		Array<{
			type: string;
			emailAddress: {
				name: string;
				address: string;
			};
		}>
	>(meeting.attendees);

	const handleNewAttendees = (
		newAt: Array<{
			type: string;
			emailAddress: {
				name: string;
				address: string;
			};
		}>,
	) => {
		setAttendees(newAt);
	};

	// -------------

	const onSubmit = async (data: FormData) => {
		handleToggle();

		if (data.bodyContent !== undefined) {
			const eventId = meeting.graphEventId;

			const startDateUP = isAllDay === false ? new Date(data.eventStart) : setToMidnight(data.eventStart);
			const startDateUTC = startDateUP.toISOString();

			const endDateUP = isAllDay === false ? new Date(data.eventEnd) : setToMidnightAddOneDay(data.eventEnd);
			const endDateUTC = endDateUP.toISOString();

			const newAttendees = attendees;

			const newGroup: object = newAttendees.reduce((groups: GroupAttendeesByOrg, attendee: any) => {
				const domain = extractDomain(attendee.emailAddress.address);
				if (!groups[domain]) {
					groups[domain] = [];
				}
				groups[domain].push(attendee);
				return groups;
			}, {});

			const html = data.bodyContent;

			const body = {
				eventBody: {
					subject: data.eventSubject,
					start: {
						dateTime: startDateUTC,
						timeZone: 'UTC',
					},
					end: {
						dateTime: endDateUTC,
						timeZone: 'UTC',
					},
					location: data.eventLocation,
					attendees: newAttendees,
					isOnlineMeeting: data.isOnline ? data.isOnline : meeting.isOnlineMeeting,
					onlineMeetingProvider: 'teamsForBusiness',
					content: html,
					isAllDay,
					projectId: data.project ? data.project.id : meeting.projectId,
					iCalUId: meeting.iCalUId,
				},
			};

			const result = await dispatch(updateMeeting({ body, eventId }));

			const newMeeting = result.payload as MeetingContract;

			const val: any = {
				subject: data.eventSubject,
				start: startDateUTC,
				end: endDateUTC,
				location: data.eventLocation,
				attendees: newAttendees,
				group: newGroup,
				isOnlineMeeting: data.isOnline ? data.isOnline : meeting.isOnlineMeeting,
				onlineMeetingProvider: 'teamsForBusiness',
				bodyContent: meeting.bodyContent,
				isAllDay,
				projectId: newMeeting.projectId,
				IcalUid: meeting.iCalUId,
				onlineMeeting: newMeeting.onlineMeeting,
				description: newMeeting.description,
			};

			if (dataMeetingBot && data.isBot === false) {
				dispatch(deleteDataMeetingBot(newMeeting.iCalUId)).then(() => {
					dispatch(updateSelectMeetingDataBot(undefined));
				});
			}

			if (data.project && user) {
				const attendeeEmails = newMeeting
					? newMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
					: [];

				const bodyMeetingProject: MeetingsProjectContract = {
					id: '',
					projectId: data.project.id,
					graphiCalUId: newMeeting.iCalUId,
					meetingName: newMeeting.subject,
					meetingStartDate: newMeeting.start,
					meetingEndDate: newMeeting.end,
					meetingAttendees: attendeeEmails,
					tags: [],
				};
				await dispatch(linkMeetingProject({ body: bodyMeetingProject, projectId: data.project.id }));

				const bodyNotification = {
					id: '',
					userFromName: user.name,
					userFromMail: user.email,
					type: NotificationsProjectType.NEW_MEETING,
					noteId: '',
					todoId: '',
					todoDueDate: '',
					itemTitle: '',
					graphiCalUId: newMeeting.iCalUId,
					meetingStartDate: newMeeting.start,
					meetingEndDate: newMeeting.end,
					meetingName: newMeeting.subject,
					meetingAttendees: attendeeEmails,
					projectId: data.project.id,
					userSeenNotif: [],
					todoAssignee: '',
				};

				await dispatch(SendNotificationsProject(bodyNotification));
			}

			if (data.meeting && data.serial === true) {
				const bodySerie = {
					prevGraphiCalUId: data.meeting.iCalUId,
					prevMeetingStartDate: data.meeting.start,
					prevMeetingName: data.meeting.subject,
					nextGraphiCalUId: (result.payload as MeetingContract).iCalUId,
					nextMeetingStartDate: (result.payload as MeetingContract).start,
					nextMeetingName: (result.payload as MeetingContract).subject,
				};
				await dispatch(postMeetingsForSeries(bodySerie)).then(() => {
					dispatch(updateSelectedMeeting(val));
					// dispatch(getMeetings(dateRange));
					dispatch(updateSelectedMeetingForMeetings(val));

					const ID = (result.payload as MeetingContract).graphEventId;
					const dateEvent = (result.payload as MeetingContract).start;

					const params: any = {};
					params[RouteSearchParams.Date] = getStartOfDay(dateEvent);
					params[RouteSearchParams.MeetingId] = ID;
					setSearchParams(params);

					toast.show({
						id: user?.graphUserId,
						title: t('Meeting update success') as string,
						duration: 10000,
						type: 'success',
					});

					if (updateMeetingsRequestStatus.type === RequestStatusType.Failed) {
						toast.show({
							id: user?.graphUserId,
							title: t('Meeting update failed') as string,
							duration: 10000,
							type: 'failed',
						});
					}
				});
			} else {
				dispatch(updateSelectedMeeting(val));

				// dispatch(getMeetings(dateRange));
				dispatch(updateSelectedMeetingForMeetings(val));

				const ID = (result.payload as MeetingContract).graphEventId;
				const dateEvent = (result.payload as MeetingContract).start;

				const params: any = {};
				params[RouteSearchParams.Date] = getStartOfDay(dateEvent);
				params[RouteSearchParams.MeetingId] = ID;
				setSearchParams(params);

				toast.show({
					id: user?.graphUserId,
					title: t('Meeting update success') as string,
					duration: 10000,
					type: 'success',
				});

				if (updateMeetingsRequestStatus.type === RequestStatusType.Failed) {
					toast.show({
						id: user?.graphUserId,
						title: t('Meeting update failed') as string,
						duration: 10000,
						type: 'failed',
					});
				}
			}
		}
	};

	return (
		<>
			<MeetingFormComponent
				meeting={meeting}
				onSubmit={onSubmit}
				handleClose={handleClose}
				handleNewAttendees={handleNewAttendees}
				type={FormType.save}
				handleNewTags={() => {}}
			/>
		</>
	);
};

export default MeetingDetailsUpdateModal;
