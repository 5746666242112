import styles from './note-editor.module.scss';

import React, { useEffect, useState } from 'react';
import { FloatingWrapper, MentionAtomNodeAttributes, useMentionAtom } from '@remirror/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { NotificationsSendContract, NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';
import { AccessRightType, MeetingContract } from '../../../store/meetings/types';
import { NotesContract } from '../../../store/notes/type';
import { Avatar } from '../../../shared/utils/avatar';
import { SendNotificationsProject } from '../../../store/project/slice';
import { NotificationsProjectType } from '../../../store/project/type';

interface Props {
	noteId: string;
	noteTitle: string;
	meeting?: MeetingContract;
	note: NotesContract;
}

const MentionSuggestor: React.FC<Props> = ({ noteId, noteTitle, meeting, note }) => {
	const { user } = useAppSelector((state) => state.user);
	const { projectsSharePeople } = useAppSelector((state) => state.project);
	const { sharedPeopleNote } = useAppSelector((state) => state.notes);
	const dispatch = useAppDispatch();
	const [options, setOptions] = useState<MentionAtomNodeAttributes[]>([]);
	const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } = useMentionAtom({
		items: options,
	});

	const mentionsUsers = () => {
		if (note.graphiCalUId) {
			switch (note.accessRightType) {
				case AccessRightType.EVERYONE:
					return note.meetingAttendees.map((attendee) => {
						const isInCompany = user?.userCompany.find((userC) => userC.userName === attendee);
						return {
							id: attendee,
							label: isInCompany ? `${isInCompany.name}` : attendee,
						};
					});
				case AccessRightType.INTERN: {
					const internUserSharingMeeting = user?.userCompany.filter((userCompany) =>
						note.meetingAttendees.includes(userCompany.userName),
					);
					return internUserSharingMeeting?.map((attendee) => {
						return {
							id: attendee.userName,
							label: `${attendee.name}`,
						};
					});
				}
				case AccessRightType.SHARED: {
					return sharedPeopleNote !== undefined
						? sharedPeopleNote?.map((attendee) => {
								return {
									id: attendee.mail,
									label: `${attendee.username}`,
								};
						  })
						: [];
				}
			}
		}
		if (note.projectId) {
			switch (note.accessRightType) {
				case AccessRightType.EVERYONE:
					return projectsSharePeople
						? projectsSharePeople.map((attendee) => {
								return {
									id: attendee.mail,
									label: `${attendee.username}`,
								};
						  })
						: [];
				case AccessRightType.INTERN: {
					const internUserSharingMeeting = user?.userCompany.filter((userCompany) =>
						projectsSharePeople?.filter((sharePeople) => sharePeople.mail === userCompany.userName),
					);
					return internUserSharingMeeting?.map((attendee) => {
						return {
							id: attendee.userName,
							label: `${attendee.name}`,
						};
					});
				}
				case AccessRightType.SHARED: {
					return sharedPeopleNote !== undefined
						? sharedPeopleNote?.map((attendee) => {
								return {
									id: attendee.mail,
									label: `${attendee.username}`,
								};
						  })
						: [];
				}
			}
		}

		switch (note.accessRightType) {
			case AccessRightType.INTERN:
				return user?.userCompany.map((userC) => {
					return {
						id: userC.userName,
						label: `${userC.name}`,
					};
				});
			case AccessRightType.SHARED: {
				return sharedPeopleNote !== undefined
					? sharedPeopleNote?.map((attendee) => {
							return {
								id: attendee.mail,
								label: `${attendee.username}`,
							};
					  })
					: [];
			}
		}
	};

	useEffect(() => {
		if (!state) {
			return;
		}

		const searchTerm = state.query.full.toLowerCase();

		const users = mentionsUsers() ?? [];

		const filteredOptions = users
			.filter((userC) => userC.label.toLowerCase().includes(searchTerm))
			.sort()
			.slice(0, 5);

		setOptions(filteredOptions);
	}, [state]);

	const handleClick = (email: string, name: string) => {
		const body: NotificationsSendContract = {
			userFromName: user?.name,
			userFromMail: user?.email,
			userToName: name.slice(1),
			userToMail: email,
			type: NotificationsType.MENTION,
			noteId: noteId,
			id: undefined,
			todoId: undefined,
			graphiCalUId: undefined,
			projectId: undefined,
			meetingStartDate: undefined,
			itemTitle: noteTitle,
			visible: true,
			meetingName: undefined,
		};

		dispatch(SendNotifications(body));

		if (user) {
			const bodyNotification = {
				id: '',
				userFromName: user.name,
				userFromMail: user.email,
				type: NotificationsProjectType.NEW_MENTION,
				noteId: note.id,
				todoId: '',
				todoDueDate: '',
				itemTitle: note.title,
				graphiCalUId: '',
				meetingStartDate: '',
				meetingEndDate: '',
				meetingName: '',
				meetingAttendees: [],
				projectId: note.projectId,
				userSeenNotif: [],
				todoAssignee: '',
			};

			dispatch(SendNotificationsProject(bodyNotification));
		}
	};

	const enabled = Boolean(state);

	return (
		<FloatingWrapper positioner='cursor' enabled={enabled} placement='bottom-start'>
			<div {...getMenuProps()} className={styles.suggestor}>
				{enabled &&
					options.map((userC, index) => {
						const isHighlighted = indexIsSelected(index);
						const isHovered = indexIsHovered(index);

						return (
							<div
								key={userC.id}
								className={styles.mentionsSuggestor}
								{...getItemProps({
									item: userC,
									index,
								})}
								onMouseDown={() => handleClick(userC.id, userC.label)}>
								<Avatar name={userC.label} mail={userC.id} index={index} />
								<div>{userC.label}</div>
							</div>
						);
					})}
			</div>
		</FloatingWrapper>
	);
};

export default MentionSuggestor;
