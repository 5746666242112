import HttpClient from '../shared/http/http-client';
import { ApiUrls, UrlParamBuilder } from './api-urls';
import { MeetingsProjectContract, NotificationProjectContract, ProjectContract } from '../store/project/type';
import { NotesContract, People, updateTags } from '../store/notes/type';
import { FileContract, TagContract } from '../store/meetings/types';
import { TodosContract } from '../store/todos/type';
import { CommentsContract } from '../store/comments/type';
import { UpdateMeetingFilesAccess } from '../store/shared/types';

export default class ProjectsAPI {
	public static async getCurrentUserProjects(): Promise<ProjectContract[]> {
		const { data } = await HttpClient.get<ProjectContract[]>(ApiUrls.GetUserProjects);
		return data;
	}

	public static async getOneProject(projectId: string): Promise<ProjectContract> {
		const { data } = await HttpClient.get<ProjectContract>(
			ApiUrls.GetOneProject,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async createProject(body: ProjectContract): Promise<ProjectContract> {
		const { data } = await HttpClient.post<ProjectContract, ProjectContract>(ApiUrls.PostProjects, body);
		return data;
	}

	public static async updateProject(body: ProjectContract): Promise<ProjectContract> {
		const { data } = await HttpClient.patch<ProjectContract, ProjectContract>(ApiUrls.UpdateProjects, body);
		return data;
	}

	public static async deleteProject(projectId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteProject,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async addTagsProject(body: updateTags): Promise<TagContract> {
		const { data } = await HttpClient.post<updateTags, TagContract>(ApiUrls.AddTagsToProject, body);
		return data;
	}

	public static async deleteTagsFromProject(projectId: string, tagsId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteTagsFromProject,
			new UrlParamBuilder().addProjectId(projectId).addtagsId(tagsId).build(),
		);
		return data;
	}

	public static async getPeopleShareProject(projectId: string): Promise<People[]> {
		const { data } = await HttpClient.get<People[]>(
			ApiUrls.GetPeopleShareProject,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async deleteAPeopleShareProject(projectId: string, mail: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeletePeopleShareProject,
			new UrlParamBuilder().addProjectId(projectId).addUserMail(mail).build(),
		);
		return data;
	}

	public static async addPeopleShareProject(body: People, projectId: string): Promise<People> {
		const { data } = await HttpClient.post<People, People>(
			ApiUrls.PostPeopleShareProject,
			body,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async getNotesProjects(projectId: string): Promise<NotesContract[]> {
		const { data } = await HttpClient.get<NotesContract[]>(
			ApiUrls.GetNotesProject,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async getTodosProjects(projectId: string): Promise<TodosContract[]> {
		const { data } = await HttpClient.get<TodosContract[]>(
			ApiUrls.GetTodosProject,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async getMeetingsProjects(projectId: string): Promise<MeetingsProjectContract[]> {
		const { data } = await HttpClient.get<MeetingsProjectContract[]>(
			ApiUrls.GetMeetingsProject,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async getNotesCommentsProjects(projectId: string): Promise<CommentsContract[]> {
		const { data } = await HttpClient.get<CommentsContract[]>(
			ApiUrls.GetNotesCommentsProject,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async postMeetingProjects(
		body: MeetingsProjectContract,
		projectId: string,
	): Promise<MeetingsProjectContract> {
		const { data } = await HttpClient.post<MeetingsProjectContract, MeetingsProjectContract>(
			ApiUrls.PostMeetingsProject,
			body,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async getNotificationsProjects(projectId: string): Promise<NotificationProjectContract[]> {
		const { data } = await HttpClient.get<NotificationProjectContract[]>(
			ApiUrls.GetNotificationsProject,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async SeeNotificationsProjects(
		notification: NotificationProjectContract,
	): Promise<NotificationProjectContract> {
		const { data } = await HttpClient.patch<NotificationProjectContract, NotificationProjectContract>(
			ApiUrls.SeeNotificationProject,
			notification,
		);
		return data;
	}

	public static async postNotificationProjects(
		body: NotificationProjectContract,
	): Promise<NotificationProjectContract> {
		const { data } = await HttpClient.post<NotificationProjectContract, NotificationProjectContract>(
			ApiUrls.PostNotificationProject,
			body,
		);
		return data;
	}

	public static async addFileToProject(body: FormData, projectId: string): Promise<FormData> {
		const { data } = await HttpClient.postFile<FormData, FormData>(
			ApiUrls.AddProjectFile,
			body,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async deleteFileToProject(projectId: string, fileId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteProjectFile,
			new UrlParamBuilder().addProjectId(projectId).addFileId(fileId).build(),
		);
		return data;
	}

	public static async updateFileToProjectAccess(
		body: UpdateMeetingFilesAccess,
		projectId: string,
		fileId: string,
	): Promise<FileContract> {
		const { data } = await HttpClient.patch<UpdateMeetingFilesAccess, FileContract>(
			ApiUrls.UpdateProjectFile,
			body,
			new UrlParamBuilder().addProjectId(projectId).addFileId(fileId).build(),
		);
		return data;
	}

	public static async downloadFileProject(projectId: string, fileId: string): Promise<Blob> {
		const { data } = await HttpClient.getFile<Blob>(
			ApiUrls.DownloadProjectFile,
			new UrlParamBuilder().addProjectId(projectId).addFileId(fileId).build(),
		);
		return data;
	}

	public static async getFileProject(projectId: string): Promise<FileContract[]> {
		const { data } = await HttpClient.get<FileContract[]>(
			ApiUrls.GetProjectFile,
			new UrlParamBuilder().addProjectId(projectId).build(),
		);
		return data;
	}

	public static async deleteAPeopleShareFile(fileId: string, mail: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeletePeopleShareFile,
			new UrlParamBuilder().addFileId(fileId).addUserMail(mail).build(),
		);
		return data;
	}

	public static async addPeopleShareFile(body: People, fileId: string): Promise<People> {
		const { data } = await HttpClient.post<People, People>(
			ApiUrls.PostPeopleShareFile,
			body,
			new UrlParamBuilder().addFileId(fileId).build(),
		);
		return data;
	}

	public static async getPeopleShareFile(fileId: string): Promise<People[]> {
		const { data } = await HttpClient.get<People[]>(
			ApiUrls.GetPeopleShareFile,
			new UrlParamBuilder().addFileId(fileId).build(),
		);
		return data;
	}
}
