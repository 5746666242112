import React, { useEffect } from 'react';
import styles from './seetings.module.scss';
import { useTranslation } from 'react-i18next';
import { Tab } from '../../shared/types/component-interface';
import SettingsGeneralComponent from './components/general/settings-general.component';
import SettingsUserManagementComponent from './components/user-management/settings-user-management.component';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { getAllRequestForACompany, getCompanyForUser } from '../../store/settings/slice';

function SettingsPage() {
	const { t } = useTranslation();

	const { user } = useAppSelector((state) => state.user);
	const { company } = useAppSelector((state) => state.settings);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (user) {
			dispatch(getCompanyForUser());
			dispatch(getAllRequestForACompany());
		}
	}, [dispatch, user]);

	useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
	}, [dispatch]);

	const [activeTabIndex, setActiveTabIndex] = React.useState(0);

	const [tabs] = React.useState<Tab[]>([
		{ label: t('General'), component: <SettingsGeneralComponent /> },
		{
			label: t('User management'),
			component: <SettingsUserManagementComponent />,
		},
	]);

	return (
		<div className={styles.container}>
			<div>
				<h1>{t('Settings')}</h1>
				<div className={styles.tabs}>
					{tabs.map((tab, index) => (
						<a
							key={index}
							onClick={() => setActiveTabIndex(index)}
							className={index === activeTabIndex ? styles.activeTab : ''}>
							{tab.label}
						</a>
					))}
				</div>
			</div>
			<div className={styles.tabContainer}>
				{tabs[activeTabIndex].component && company && tabs[activeTabIndex].component}
			</div>
		</div>
	);
}

export default SettingsPage;
