/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { NotesContract } from '../../../store/notes/type';
import { AccessRightType, MeetingContract, TagContract } from '../../../store/meetings/types';
import styles from './notes.form.component.module.scss';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import {
	IconClose,
	IconContact,
	IconEdit,
	IconGlobe,
	IconGroupS,
	IconLock,
	IconMeetings,
	IconMeetingsPage,
	IconProject,
	IconSimplePlus,
	IconTag,
} from '../../../shared/utils/icon';
import TagsFormModal from '../../meetings/modals/tags-form.modal';
import {
	addTags,
	deleteTagsFromNote,
	getOneNote,
	getPeopleShareNote,
	updateNotePageShareAccess,
} from '../../../store/notes/slice';
import NotesFormUpdateAccessComponent from './notes.form-update-access.component';
import { useTranslation } from 'react-i18next';
import {
	getDateFormatWithYear,
	getDayPastOneDay,
	getStartOfDay,
	getStartOfDayDate,
} from '../../../shared/utils/date.util';
import { Avatar } from '../../../shared/utils/avatar';
import { getMeetings, setSelectedMeeting } from '../../../store/meetings/slice';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NotesProjectConnect from '../modal/note-connect-project.modal';
import NotesMeetingConnect from '../modal/note-connect-meeting.modal';

interface Props {
	noteId: string;
	handleNoteData: (note: NotesContract) => void;
}

const NotesInformations: React.FC<Props> = ({ noteId, handleNoteData }) => {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const { projects } = useAppSelector((state) => state.project);
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);

	const [note, setNote] = React.useState<NotesContract>();

	const handleNote = (data: NotesContract) => {
		setNote(data);
	};

	React.useEffect(() => {
		if (noteId) {
			dispatch(getOneNote(noteId)).then((result) => {
				setNote(result.payload as NotesContract);
			});

			dispatch(getPeopleShareNote(noteId));
		}
	}, [noteId]);

	const deleteTag = (tag: TagContract) => {
		if (tag && note) {
			dispatch(deleteTagsFromNote({ notesId: note.id, tagsId: tag.id as string })).then(() => {
				const updatedTags = note.tags.filter((tagToDelete) => tag !== tagToDelete);
				handleNoteData({ ...note, tags: updatedTags });
				setNote({ ...note, tags: updatedTags });
			});
		}
	};

	const closeTagsModal = (tag: string, color: string) => {
		if (note) {
			const body = {
				tag,
				color,
				noteId: note.id,
			};

			dispatch(addTags(body)).then((res) => {
				const newTag = res.payload as TagContract;

				const updatedTags = [...note.tags, newTag];

				handleNoteData({ ...note, tags: updatedTags });
				setNote({ ...note, tags: updatedTags });
				setTagsModalOpen(false);
			});
		}
	};

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};

	const [openAccessMenu, setOpenAccessMenu] = React.useState(false);

	const handleAccessMenu = () => {
		setOpenAccessMenu(!openAccessMenu);
	};

	const handleAccessMenuUpdate = (noteAccess: NotesContract) => {
		setOpenAccessMenu(!openAccessMenu);
		if (note) {
			const updatedNoteData: NotesContract = {
				...note,
				accessRightType: noteAccess.accessRightType,
			};
			handleNoteData(updatedNoteData);
			setNote(updatedNoteData);
			dispatch(updateNotePageShareAccess({ id: noteAccess.id, accessRightType: noteAccess.accessRightType }));
		}
	};

	// ____

	const [projectConnect, setHantleProjectConnect] = React.useState(false);
	const [meetingConnect, setMeetingConnect] = React.useState(false);

	const handleProjectConnect = () => {
		setHantleProjectConnect(!projectConnect);
	};

	const handleMeetingConnect = () => {
		setMeetingConnect(!meetingConnect);
	};

	return (
		<>
			{note && (
				<div className={styles.mainNoteInformation}>
					<div className={styles.row}>
						<div className={styles.title}>
							<IconMeetings />
							<span>{t('Creation date')}</span>
						</div>
						<div>{getDateFormatWithYear(note.createdOn.toString())}</div>
					</div>
					<div className={styles.row}>
						<div className={styles.title}>
							<IconEdit />
							<span>{t('Last update')}</span>
						</div>
						<div>{getDateFormatWithYear(note.updateOn.toString())}</div>
					</div>
					<div className={styles.row}>
						<div className={styles.title}>
							<IconContact />
							<span>{t('Create by')}</span>
						</div>
						<div className={styles.avatar}>
							<Avatar name={note.graphUserName} mail={note.graphUserId} index={1} />
							{note.graphUserName}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.title}>
							<IconTag />
							<span>{t('Tags')}</span>
						</div>
						<div className={styles.tags}>
							{note?.tags.map((tag, index) => (
								<div
									key={index}
									className={styles.tag}
									style={{
										backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
										border: `1px solid ${tag.color}`,
									}}>
									<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
									{user?.graphUserId === note.graphUserId && (
										<div
											className={styles.iconDelete}
											style={{ color: tag.color, fontWeight: '600' }}
											onClick={() => {
												deleteTag(tag);
											}}>
											<IconClose />
										</div>
									)}
								</div>
							))}
							{user?.graphUserId === note?.graphUserId && (
								<div className={styles.icon} onClick={() => openTagsModal()}>
									<IconSimplePlus />
								</div>
							)}
							{tagsModalOpen && (
								<TagsFormModal close={closeTagsModal} handleSimpleClose={openTagsModal} type='notes' />
							)}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.title}>
							<IconGroupS width='18' height='18' />
							<span>{t('Connected meeting')}</span>
						</div>
						<div
							onClick={() => {
								const startDate = getStartOfDayDate(new Date(note.meetingStartDate));
								const endDate = getDayPastOneDay(new Date(note.meetingStartDate));
								const dateRange = { startDate, endDate };
								dispatch(getMeetings(dateRange)).then((res) => {
									const meetingsSelect = (res.payload as MeetingContract[]).find(
										(m) => m.iCalUId === note.graphiCalUId,
									);
									dispatch(setSelectedMeeting(meetingsSelect));
									const params: any = {};
									params[RouteSearchParams.Date] = getStartOfDay(note.meetingStartDate);
									params[RouteSearchParams.MeetingId] = note.graphiCalUId;
									navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
								});
							}}
							className={styles.link}>
							{note.meetingTitle}
						</div>
						{user && user.graphUserId === note.graphUserId && (
							<div onClick={() => handleMeetingConnect()} className={styles.icon}>
								<IconEdit />
							</div>
						)}
						{meetingConnect && (
							<NotesMeetingConnect
								handleClose={handleMeetingConnect}
								note={note}
								handleNote={handleNote}
							/>
						)}
					</div>
					<div className={styles.row}>
						<div className={styles.title}>
							<IconProject height='18' width='18' />
							<span>{t('Connected project')}</span>
						</div>
						<div
							onClick={() => {
								const params: any = {};
								params[RouteSearchParams.ProjectId] = note.projectId;
								navigate(`${RouteUrls.Project}?${new URLSearchParams(params).toString()}`);
							}}
							className={styles.link}>
							{projects?.find((project) => project.id === note.projectId)?.projectName}
						</div>
						{user && note.graphUserId === user.graphUserId && (
							<div onClick={() => handleProjectConnect()} className={styles.icon}>
								<IconEdit />
							</div>
						)}

						{projectConnect && (
							<NotesProjectConnect
								handleClose={handleProjectConnect}
								note={note}
								handleNote={handleNote}
							/>
						)}
					</div>
					<div className={styles.row}>
						<div className={styles.title}>
							<IconContact />
							<span>{t('Access')}</span>
						</div>

						<div className={styles.access}>
							{note?.accessRightType === AccessRightType.EVERYONE ? (
								<div className={styles.accessButton}>
									<IconGlobe />
									<span>{t('Public note')}</span>
								</div>
							) : note.accessRightType === AccessRightType.ONLYME ? (
								<div className={styles.accessButton}>
									<IconLock />
									<span>{t('Private note')}</span>
								</div>
							) : note.accessRightType === AccessRightType.SHARED ? (
								<div className={styles.accessButton}>
									<IconMeetingsPage />
									<span>{t('Shared note')}</span>
								</div>
							) : (
								<div className={styles.accessButton}>
									<IconContact />
									<span>{t('Internal note')}</span>
								</div>
							)}
							{user?.graphUserId === note?.graphUserId && (
								<div className={styles.icon} onClick={() => handleAccessMenu()}>
									<IconEdit />
								</div>
							)}

							{openAccessMenu && note && (
								<NotesFormUpdateAccessComponent note={note} close={handleAccessMenuUpdate} />
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default NotesInformations;
