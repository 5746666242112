import React, { ReactNode, MouseEventHandler } from 'react';
import styles from './outlined-btn.module.scss';

interface Props {
    children: ReactNode,
    onClick?: MouseEventHandler,
    type?: 'button' | 'submit' | 'reset' | undefined,
}

const OutlinedButton: React.FC<Props> = ({
    children, 
    onClick,
    type = 'button',
}) => {
    return (
        <button className={styles.outlinedBtn} type={type} onClick={onClick}>
			{children}
		</button>
    );
    
};

export default OutlinedButton;