import React from 'react';
import styles from './login-sign-in.component.module.scss';
import { CompanyContract } from '../../../store/settings/type';
import { useTranslation } from 'react-i18next';

interface Props {
	company: CompanyContract | undefined;
	children: string;
}

const AccessRequestMessage: React.FC<Props> = ({ company, children }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.fadeRight}>
			<h2>{t(children)}</h2>
			<p className={styles.notifier} style={{ marginTop: '32px' }}>
				{t('If you are a member of')} {company?.name},{' '}
				{t('an admin will validate your account. Then you will have access using this address.')}
			</p>
		</div>
	);
};

export default AccessRequestMessage;
