import React, { useState } from 'react';
import styles from './meetings-details-agenda.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { addAgendaItem, createAgendaItem } from '../../../../../../store/agenda/slice';
import { addAgendaSelectedMeetingShare, createAgendaItemShared } from '../../../../../../store/sharing/slice';
import { Attendees } from '../../../../../../shared/types/meetings-graph-interface';
import { AgendaContract } from '../../../../../../store/agenda/type';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IconDownArrow, IconEdit, IconSimplePlus } from '../../../../../../shared/utils/icon';
import { selectTime } from '../../../../../../shared/utils/hours';
import { Avatar } from '../../../../../../shared/utils/avatar';
import { updateSelectedMeetingCount } from '../../../../../../store/meetings/slice';

interface props {
	close: () => void;
}

const MeetingsDetailsAgendaForm: React.FC<React.PropsWithChildren<props>> = ({ close }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const { agendaItems } = useAppSelector((state) => state.agenda);
	const { shareAgenda, shareMeeting } = useAppSelector((state) => state.sharing);

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	type FormData = {
		duration: string;
		description: string;
		assignee: string;
	};

	const dataSchema = yup.object().shape({
		duration: yup.string().required(),
		description: yup.string(),
		assignee: yup.string(),
	});

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const [dropdown, setDropdown] = React.useState(false);

	const handleDropDown = () => {
		setDropdown(!dropdown);
	};

	const [assignee, setAssignee] = React.useState(user ? user.name : storedAccessMail);
	const [assigneeEmail, setAssigneeEmail] = useState<string>('');

	React.useEffect(() => {
		if (user) {
			setValue('assignee', user.email);
		} else {
			setValue('assignee', storedAccessMail ? storedAccessMail : '');
		}
	}, [agendaItems]);

	const handleNewAssignee = (data: Attendees) => {
		setAssigneeEmail(data.emailAddress.address);
		setAssignee(data.emailAddress.name);
		setValue('assignee', data.emailAddress.address);
		handleDropDown();
	};

	const [dropdownTime, setDropdownTime] = React.useState(false);

	const handleDropDownTime = () => {
		setDropdownTime(!dropdownTime);
	};

	const [timeData, setTime] = React.useState(t('How long?') as string);

	const handleNewTime = (data: string) => {
		setTime(data);
		setValue('duration', data);
		handleDropDownTime();
	};

	const createAgendaItems = (data: FormData) => {
		if (user && selectedMeeting) {
			const body = {
				id: '',
				userCreatorId: data.assignee,
				userMail: data.assignee,
				duration: data.duration,
				description: data.description,
				graphiCalUId: selectedMeeting.iCalUId,
				orderNumber: agendaItems ? (agendaItems.length + 1).toString() : '1',
			};

			const bodyUpCount = {
				iCalUId: selectedMeeting.iCalUId,
				noteCount: selectedMeeting.countNotes ? +selectedMeeting.countNotes : 0,
				agendaCount: selectedMeeting.countAgenda ? +selectedMeeting.countAgenda + 1 : 1,
				todoCount: selectedMeeting.countTodos ? +selectedMeeting.countTodos : 0,
			};

			dispatch(updateSelectedMeetingCount(bodyUpCount));

			dispatch(createAgendaItem(body)).then((res) => {
				const newData = res.payload as AgendaContract;
				const newBody = {
					id: newData.id,
					userCreatorId: newData.userCreatorId,
					userMail: newData.userMail,
					duration: newData.duration,
					description: newData.description,
					graphiCalUId: newData.graphiCalUId,
					orderNumber: newData.orderNumber,
				};
				dispatch(addAgendaItem(newBody));
				setAssignee(t('Who?') as string);
				setTime(t('How long?') as string);
				reset();
				setAssignee(user ? user.name : storedAccessMail);
				setValue('assignee', user.name);
				close();
			});
		} else if (selectedMeeting && shareMeeting && storedAccessMail) {
			const body = {
				id: '',
				userCreatorId: data.assignee,
				userMail: data.assignee,
				duration: data.duration,
				description: data.description,
				graphiCalUId: selectedMeeting.iCalUId,
				orderNumber: shareAgenda ? (shareAgenda.length + 1).toString() : '1',
			};
			dispatch(createAgendaItemShared(body)).then((res) => {
				const newData = res.payload as AgendaContract;
				const newBody = {
					id: newData.id,
					userCreatorId: newData.userCreatorId,
					userMail: newData.userMail,
					duration: newData.duration,
					description: newData.description,
					graphiCalUId: newData.graphiCalUId,
					orderNumber: newData.orderNumber,
				};
				dispatch(addAgendaSelectedMeetingShare(newBody));
				reset();

				setAssignee(storedAccessMail ? storedAccessMail : '');
				setValue('assignee', storedAccessMail ? storedAccessMail : '');
				close();
			});
		}
	};

	const modalRef = React.useRef<HTMLDivElement>(null);
	const modalRefTime = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					if (dropdown) handleDropDown();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleDropDown]);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					if (dropdownTime) handleDropDownTime();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleDropDownTime]);

	return (
		<div className={styles.form}>
			<p>
				<div className={styles.icon}>
					<IconEdit width='18' height='18' />
				</div>
				{t('Add a new point to the agenda')}
			</p>
			<form onSubmit={handleSubmit(createAgendaItems)}>
				<div className={styles.selectCustom} ref={modalRef}>
					<button type='button' onClick={() => handleDropDown()}>
						<div className={styles.buttonAttendee}>
							<Avatar name={assignee ?? ''} mail={assigneeEmail} index={0} />
							<p>{assignee}</p>
						</div>
						<IconDownArrow />
					</button>
					{dropdown && (
						<div className={styles.dropdown} ref={modalRefTime}>
							{selectedMeeting &&
								selectedMeeting.attendees.map((attendee, index) => (
									<p key={index} onClick={() => handleNewAssignee(attendee)}>
										<Avatar
											name={attendee.emailAddress.name}
											mail={attendee.emailAddress.address}
											index={index}
										/>
										{attendee.emailAddress.name}
									</p>
								))}
						</div>
					)}
				</div>
				<div className={styles.selectCustom}>
					<button type='button' onClick={() => handleDropDownTime()}>
						{timeData}
						<IconDownArrow />
					</button>
					{errors.duration && <p className={styles.error}>{t('Duration is required')}</p>}
					{dropdownTime && (
						<div className={styles.dropdown}>
							{selectTime.map((time, index) => (
								<p key={index} onClick={() => handleNewTime(time)}>
									{time}
								</p>
							))}
						</div>
					)}
				</div>
				<textarea {...register('description')} placeholder={t('Write your point here') as string}></textarea>
				<button type='submit' className={styles.formButton}>
					<IconSimplePlus /> {t('Create')}
				</button>
			</form>
		</div>
	);
};

export default MeetingsDetailsAgendaForm;
