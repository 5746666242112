import React from 'react';

export interface Section {
	title: string;
	content: string | string[];
}

export const parseTextToRemirrorJson = (text: string): string => {
	const lines = text.split('\n');
	const content: any[] = [];

	let currentList: any[] = [];

	lines.forEach((line) => {
		line = line.trim();
		if (line.startsWith('##')) {
			if (currentList.length > 0) {
				content.push({ type: 'bulletList', content: currentList });
				currentList = [];
			}
			content.push(
				{ type: 'paragraph', content: [] },
				{
					type: 'heading',
					attrs: { level: 2 },
					content: [{ type: 'text', text: line.replace('##', '').replace('**', '').trim() }],
				},
				{ type: 'paragraph', content: [] },
			);
		} else if (line.startsWith('#') && !line.startsWith('##')) {
			if (currentList.length > 0) {
				content.push({ type: 'bulletList', content: currentList });
				currentList = [];
			}
			content.push(
				{ type: 'paragraph', content: [] },
				{
					type: 'heading',
					attrs: { level: 1 },
					content: [{ type: 'text', text: line.replace('#', '').replace('**', '').trim() }],
				},
				{ type: 'paragraph', content: [] },
			);
		} else if (line.startsWith('**') && line.endsWith('**')) {
			if (currentList.length > 0) {
				content.push({ type: 'bulletList', content: currentList });
				currentList = [];
			}
			content.push(
				{ type: 'paragraph', content: [] },
				{
					type: 'heading',
					attrs: { level: 3 },
					content: [{ type: 'text', text: line.replace('**', '').trim() }],
				},
				{ type: 'paragraph', content: [] },
			);
		} else if ((line.startsWith('- **') && !line.endsWith(':')) || (line.startsWith('-') && !line.endsWith(':'))) {
			const cleanText = line
				.replace(/^- \*\*/, '')
				.replace(/\*\*\*$/, '')
				.replace('**', '')
				.replace('-', '')
				.trim();
			currentList.push({
				type: 'listItem',
				content: [
					{
						type: 'paragraph',
						content: [{ type: 'text', text: cleanText }],
					},
				],
			});
		} else if (line.endsWith(':')) {
			if (currentList.length > 0) {
				content.push({ type: 'bulletList', content: currentList });
				currentList = [];
			}
			content.push(
				{ type: 'paragraph', content: [] },
				{
					type: 'heading',
					attrs: { level: 4 },
					content: [{ type: 'text', text: line.replace(':', '').replace('-', '').replace('**', '').trim() }],
				},
				{ type: 'paragraph', content: [] },
			);
		} else if (line) {
			if (currentList.length > 0) {
				content.push({ type: 'bulletList', content: currentList });
				currentList = [];
			}
			content.push({
				type: 'paragraph',
				content: [{ type: 'text', text: line.trim() }],
			});
		}
	});

	if (currentList.length > 0) {
		content.push({ type: 'bulletList', content: currentList });
	}

	return JSON.stringify({ type: 'doc', content });
};
