/* eslint-disable unicorn/prefer-add-event-listener */
import * as XLSX from 'xlsx';

export const handleXlsxPreview = (fileBlob: Blob) => {
	const reader = new FileReader();
	reader.onload = (e) => {
		const arrayBuffer = e.target?.result as ArrayBuffer;
		const data = new Uint8Array(arrayBuffer);
		const workbook = XLSX.read(data, { type: 'array' });
		const sheetName = workbook.SheetNames[0];
		const sheet = workbook.Sheets[sheetName];
		const html = XLSX.utils.sheet_to_html(sheet);
		const previewWindow = window.open('', '_blank', 'popup');
		if (previewWindow) {
			previewWindow.document.write(`
				<html>
					<head>
						<title>Excel Preview</title>
					</head>
					<body>${html}</body>
				</html>
			`);
			previewWindow.document.close();
		}
	};
	reader.readAsArrayBuffer(fileBlob);
};
