/* eslint-disable unicorn/prefer-add-event-listener */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { addFileSuccess, addMeetingFile } from '../../../../../../store/meetings/slice';
import { FileContract } from '../../../../../../store/meetings/types';
import { toast } from '../../../../../../shared/components/modals/toast/toast-manager';
import { addMeetingFileShared, getFilesForAMeetingShared } from '../../../../../../store/sharing/slice';
import styles from './meetings-files.component.module.scss';
import { IconSimplePlus } from '../../../../../../shared/utils/icon';

interface props {
	userId: string;
	close: () => void;
}

const MeetingsFormFiles: React.FC<React.PropsWithChildren<props>> = ({ userId, close }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [filePreview, setFilePreview] = useState<string | null>(null);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			if (file.type.startsWith('image/')) {
				setFilePreview(URL.createObjectURL(file));
			} else {
				setFilePreview(null);
			}
		}
	};

	type FormData = {
		url: string;
		file: FileList;
	};

	const dataSchema = yup.object().shape({
		url: yup.string().url(t('Invalid URL') as string),
		file: yup.mixed(),
	});

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const handleInvalidUrl = errors.url && errors.url.message;

	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);

	if (selectedMeeting !== undefined) {
		const handleSubmitFiles = async (data: FormData) => {
			if (data.url !== '') {
				const formData = new FormData();

				formData.append('name', data.url);
				formData.append('location', data.url);
				formData.append('type', 'URL');
				formData.append('graphUserId', userId);

				if (user) {
					dispatch(
						addMeetingFile({
							body: formData,
							selectedMeetingID: selectedMeeting.iCalUId,
						}),
					).then((response) => {
						dispatch(addFileSuccess(response.payload as FileContract));
						reset();
						close();

						toast.show({
							id: userId,
							title: t('File upload success') as string,
							duration: 10000,
							type: 'success',
						});
					});
				} else {
					dispatch(
						addMeetingFileShared({
							body: formData,
							selectedMeetingID: selectedMeeting.iCalUId,
						}),
					).then(() => {
						dispatch(getFilesForAMeetingShared({ iCalUId: selectedMeeting.iCalUId, userId }));
						reset();
						close();
					});
				}
			} else {
				const formData = new FormData();

				formData.append('name', data.file[0].name);
				formData.append('type', 'FILE');
				formData.append('file', data.file[0]);
				formData.append('graphUserId', userId);

				if (user) {
					dispatch(
						addMeetingFile({
							body: formData,
							selectedMeetingID: selectedMeeting.iCalUId,
						}),
					).then((response) => {
						dispatch(addFileSuccess(response.payload as FileContract));

						toast.show({
							id: userId,
							title: t('File upload success') as string,
							duration: 10000,
							type: 'success',
						});
						close();
					});
				} else {
					dispatch(
						addMeetingFileShared({
							body: formData,
							selectedMeetingID: selectedMeeting.iCalUId,
						}),
					).then(() => {
						dispatch(getFilesForAMeetingShared({ iCalUId: selectedMeeting.iCalUId, userId }));
						close();
					});
				}
			}
		};

		return (
			<div className={styles.formFile}>
				<form onSubmit={handleSubmit(handleSubmitFiles)}>
					<div>
						<input {...register('url')} placeholder={t('Add a link') as string} />
					</div>
					{handleInvalidUrl && <p className={styles.error}>{handleInvalidUrl}</p>}
					<div className={styles.orForm}>
						<div className={styles.line} />
						<div>{t('OR')}</div>
						<div className={styles.line} />
					</div>
					<div>
						<input
							className={styles.inputFile}
							type={'file'}
							{...register('file')}
							onChange={handleFileChange}
						/>
					</div>
					{filePreview && filePreview.startsWith('blob:') && (
						<img src={filePreview} alt='File preview' style={{ maxWidth: '200px', marginTop: '10px' }} />
					)}

					{filePreview && !filePreview.startsWith('blob:') && (
						<pre style={{ marginTop: '10px', maxHeight: '200px', overflowY: 'auto' }}>{filePreview}</pre>
					)}
					<button type='submit'>
						<IconSimplePlus />
						{t('Create')}
					</button>
				</form>
			</div>
		);
	} else {
		return <></>;
	}
};

export default MeetingsFormFiles;
