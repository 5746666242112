import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { UserRoleFivedays } from '../../store/user/type';
import { getAllCompanies } from '../../store/settings/slice';
import FivedaysCompaniesComponent from './components/fivedays-companies.component';
import styles from './setting-fivedays.module.scss';
import { RequestStatusType } from '../../store/shared/types';
import TabsFilesShimmers from '../project/components/tabs/shimmers/shimmers-tabs-files';

const SettingsFivedaysPage = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { companies, companiesRequestStatus } = useAppSelector((state) => state.settings);

	React.useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
	}, []);

	React.useEffect(() => {
		if (user && user.roleFivedays === UserRoleFivedays.SUPERADMIN) {
			dispatch(getAllCompanies());
		}
	}, [user]);

	return (
		<div className={styles.container}>
			{companiesRequestStatus.type === RequestStatusType.InProgress ? (
				<div>
					<TabsFilesShimmers />
				</div>
			) : (
				<FivedaysCompaniesComponent />
			)}
		</div>
	);
};

export default SettingsFivedaysPage;
