import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, RequestStatusType, createRequestStatus } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import { BillingContract } from './type';
import BillingAPI from '../../api/billing-api';

interface BillingState {
	billingInfo?: BillingContract;
	billingInfoRequestStatus: RequestStatus;
}

const initialState: BillingState = {
	billingInfo: undefined,
	billingInfoRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getBillingInfoForAUser = createAsyncThunk('admin/billing/info', async (): Promise<BillingContract> => {
	return BillingAPI.getAdminBillingInfo();
});

const slice = createSlice({
	name: 'billing',
	initialState,
	reducers: {},
	extraReducers(builder): void {
		builder.addCase(getBillingInfoForAUser.pending, (state) => {
			state.billingInfoRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getBillingInfoForAUser.fulfilled, (state, action) => {
			state.billingInfoRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.billingInfo = action.payload;
		});
		builder.addCase(getBillingInfoForAUser.rejected, (state, action) => {
			state.billingInfoRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
// export const {} = actions;
export default reducer;
