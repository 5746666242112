/* eslint-disable unicorn/prefer-string-slice */
/* eslint-disable unicorn/no-null */
import React, { useState } from 'react';
import styles from './copilot-summary.module.scss';
import { IconLeft } from '../../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { getDateFormatL, getDateFormatWithYear, getTimeFormat } from '../../../../../../../shared/utils/date.util';
import { AccessRightType } from '../../../../../../../store/meetings/types';
import { extractDomainWithExt } from '../../../../../../../shared/utils/domainext';
import { createNotes, getAllNoteForAMeeting } from '../../../../../../../store/notes/slice';
import { RequestStatusType } from '../../../../../../../store/shared/types';
import CopilotSummaryShimmer from '../shimmers/shimers-summary';
import SimpleEditor from '../../../../../../../shared/utils/simple-editor';
import { parseTextToRemirrorJson } from '../../../../../../../shared/utils/remirror-convert';
import { updateSelectedMeetingCount } from '../../../../../../../store/meetings/slice';

export interface Props {
	handleClose: () => void;
}

const CopilotSummarytModal = ({ handleClose }: Props) => {
	const { t } = useTranslation();
	const [animation, setAnimation] = useState<boolean>(false);
	const { mistralSummary, mistralSummaryRequestStatus, dataMeetingBotRequestStatusTranscript } = useAppSelector(
		(state) => state.recall,
	);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const domain = extractDomainWithExt(user ? user.email : '');
	const [text, setText] = React.useState('');

	React.useEffect(() => {
		if (mistralSummaryRequestStatus.type === RequestStatusType.Success && mistralSummary) {
			const finalText = parseTextToRemirrorJson(mistralSummary);
			setText(finalText);
		}
	}, [mistralSummary]);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	const handleTextChange = (content: string) => {
		setText(content);
	};

	const createNote = () => {
		if (user && text) {
			const attendeeEmails = selectedMeeting
				? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];

			const body = {
				id: '',
				title: `${t('Bot Note')} ${selectedMeeting?.subject} (${getDateFormatL(new Date())})`,
				text,
				graphUserId: user ? user.graphUserId : '',
				graphUserName: user ? user.displayName : '',
				meetingAttendees: attendeeEmails.length > 0 ? attendeeEmails : [user.email],
				graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
				meetingTitle: selectedMeeting ? selectedMeeting.subject : '',
				meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
				tags: [],
				updateNote: new Date(),
				createdOn: new Date(),
				updateOn: new Date(),
				accessRightType: AccessRightType.EVERYONE,
				accessRightCompanies: domain ? [domain] : [],
				archived: false,
				projectId: '',
			};

			if (selectedMeeting) {
				const bodyUpCount = {
					iCalUId: selectedMeeting.iCalUId,
					noteCount: selectedMeeting.countNotes ? +selectedMeeting.countNotes + 1 : 1,
					agendaCount: selectedMeeting.countAgenda ? +selectedMeeting.countAgenda : 0,
					todoCount: selectedMeeting.countTodos ? +selectedMeeting.countTodos : 0,
				};

				dispatch(updateSelectedMeetingCount(bodyUpCount));
			}

			dispatch(createNotes(body)).then(() => {
				selectedMeeting &&
					dispatch(getAllNoteForAMeeting(selectedMeeting?.iCalUId)).then(() => {
						closeModal();
					});
			});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.close} onClick={() => closeModal()}>
					<IconLeft />
					{t('Back')}
				</div>
				{selectedMeeting && (
					<div className={styles.info}>
						<span>{t(getDateFormatWithYear(selectedMeeting.start))}</span>
						<br />
						<span>
							<span>{getTimeFormat(selectedMeeting.start, selectedMeeting.end)}</span>
						</span>
						<h3>{selectedMeeting.subject}</h3>
					</div>
				)}
				<div className={styles.note}>
					{dataMeetingBotRequestStatusTranscript.type === RequestStatusType.InProgress && (
						<div>
							<CopilotSummaryShimmer />
						</div>
					)}
					{mistralSummaryRequestStatus.type === RequestStatusType.InProgress && (
						<div>
							<CopilotSummaryShimmer />
						</div>
					)}
					{mistralSummaryRequestStatus.type === RequestStatusType.Failed && (
						<div>{mistralSummaryRequestStatus.errorMessage}</div>
					)}
					{mistralSummaryRequestStatus.type === RequestStatusType.Success &&
						dataMeetingBotRequestStatusTranscript.type === RequestStatusType.Success &&
						text && (
							<div className={styles.description}>
								<SimpleEditor content={text} onChangeHandlerText={handleTextChange} />
								<button className={styles.submit} onClick={() => createNote()}>
									{t('Save')}
								</button>
							</div>
						)}
				</div>
			</div>
		</div>
	);
};

export default CopilotSummarytModal;
