import React from 'react';
import styles from './settings-user-plan.module.scss';
import { useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getDateDifference } from '../../../../shared/utils/date.util';
import { BillingPlan } from '../../../../store/billing/type';
import { IconAddUser, IconContact } from '../../../../shared/utils/icon';

const SettingUserOffer = () => {
	const { t } = useTranslation();
	const { billingInfo } = useAppSelector((state) => state.billing);
	const { company } = useAppSelector((state) => state.settings);

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<p>{t('Your offer')}</p>
				<div className={styles.flex}>
					<h2>{t(billingInfo?.plan as string)}</h2>
					{billingInfo && billingInfo.plan !== BillingPlan.TRIAL && (
						<>
							<a
								href='https://appsource.microsoft.com/fr-fr/marketplace/apps?search=5days&page=1'
								target='_blank'
								rel='noreferrer'>
								<button className={styles.buttonCancel}>{t('Change offer')}</button>
							</a>
						</>
					)}
				</div>

				{billingInfo && billingInfo.plan === BillingPlan.TRIAL && (
					<div>
						<p>
							{t('Your trial plan expire in')}{' '}
							<span className={styles.bold}>
								{getDateDifference(new Date(billingInfo.endDateTrial))} {t('days')}.
							</span>
						</p>
						<a
							href='https://appsource.microsoft.com/fr-fr/product/web-apps/productivity5srl1685460155895.fivedaysperuser?tab=Overview'
							target='_blank'
							rel='noreferrer'>
							<button>{t('Subscribe to an offer')}</button>
						</a>
					</div>
				)}

				{billingInfo && billingInfo.plan === BillingPlan.UNLIMITED && (
					<div>
						<p>{t('Your subscription allows you to invite an unlimited number of seats')}</p>
					</div>
				)}

				{billingInfo && billingInfo.plan === BillingPlan.PERUSER && (
					<div>
						<p>{t('Buy seats for 8.64€/user/month')}</p>
						<a
							href='https://appsource.microsoft.com/fr-fr/product/web-apps/productivity5srl1685460155895.fivedaysperuser?tab=Overview'
							target='_blank'
							rel='noreferrer'>
							<button className={styles.buttonAdd}>
								<IconAddUser />
								{t('Add new users')}
							</button>
						</a>
					</div>
				)}
			</div>
			<div className={styles.right}>
				<div className={styles.places}>
					<IconContact />
					<span>
						{company?.user.length}
						{billingInfo && billingInfo.plan === BillingPlan.PERUSER && (
							<>/ {billingInfo && billingInfo.usersForPlan}</>
						)}
					</span>
				</div>
				<div className={styles.seats}>
					{company && billingInfo && billingInfo.plan === BillingPlan.PERUSER && (
						<span>
							{billingInfo.usersForPlan - company.user.length} {t('seat(s) available')}
						</span>
					)}

					{company && billingInfo && billingInfo.plan !== BillingPlan.PERUSER && (
						<span>{t('Unlimited seats')}</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default SettingUserOffer;
