/* eslint-disable unicorn/prefer-add-event-listener */
import mammoth from 'mammoth';

export const handleDocxPreview = (fileBlob: Blob) => {
	const reader = new FileReader();
	reader.onload = async (e) => {
		const arrayBuffer = e.target?.result as ArrayBuffer;
		const resultAB = await mammoth.convertToHtml({ arrayBuffer });
		const html = resultAB.value;
		const previewWindow = window.open('', '_blank', 'popup');
		if (previewWindow) {
			previewWindow.document.write(html);
		}
	};
	reader.readAsArrayBuffer(fileBlob);
};
