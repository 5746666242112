import React from 'react';
import styles from './register-form.component.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { extractDomain } from '../../../shared/utils/domainext';
import { useNavigate } from 'react-router-dom';
import { RouteUrls } from '../../../routes/routes-config';
import { CompanyContract } from '../../../store/settings/type';
import { transformLanguage } from '../../../shared/utils/language';
import { UserRole } from '../../../store/user/type';
import { registerUser } from '../../../store/register/slice';
import { getCurrentUser } from '../../../store/user/slice';
import { useMsal } from '@azure/msal-react';
import { jobTitles } from '../../../shared/utils/jobtitle';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

interface Props {
	company: CompanyContract;
}

const RegisterFormWithCompanyComponent: React.FC<React.PropsWithChildren<Props>> = ({ company }) => {
	const { t } = useTranslation();
	const { instance } = useMsal();
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [userDomain, setUserDomain] = React.useState<string>('');

	const [isCreating, setIsCreating] = React.useState(false);

	const storedInvitationId = localStorage.getItem('invite');

	const browserLang = window.navigator.language;

	React.useEffect(() => {
		if (user) {
			const domain = extractDomain(user.email);
			setUserDomain(domain);
		}
	}, [user, setUserDomain]);

	const handlePreviousStep = () => {
		instance.logoutRedirect({ postLogoutRedirectUri: '/' });
	};

	type FormData = {
		jobTitle: string;
	};

	const dataSchema = yup.object().shape({
		jobTitle: yup.string(),
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const onSubmit = (data: FormData) => {
		setIsCreating(true);

		const body = {
			graphId: user?.graphUserId,
			mail: user?.email,
			name: user?.name,
			role: UserRole.USER,
			companyId: company.id,
			language: transformLanguage(browserLang),
			jobTitle: data.jobTitle,
			invitationId: storedInvitationId ? storedInvitationId : undefined,
		};

		dispatch(registerUser(body)).then(() => {
			dispatch(getCurrentUser()).then(() => {
				localStorage.clear();
				setIsCreating(false);
				navigate(RouteUrls.Meetings);
			});
		});
	};

	return (
		<div className={styles.container}>
			<div className={`${styles.form} ${styles.fadeRight}`}>
				<h1>{t('Stay connected.')}</h1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.inputWrapper} style={{ opacity: '0.7' }}>
						<label>{t('Company name')}</label>
						<input value={company.name} disabled />
					</div>
					<div className={styles.inputWrapper} style={{ opacity: '0.7' }}>
						<label>{t('Professional email address')}</label>
						<input value={user?.email} disabled />
					</div>
					<div className={`${styles.country} ${styles.inputWrapper}`}>
						<label>{t('Role with the Organisation')}</label>
						<Controller
							name='jobTitle'
							control={control}
							render={({ field }) => (
								<Stack spacing={2}>
									<Autocomplete
										id='JobTitle'
										disableClearable
										freeSolo
										{...field}
										filterOptions={(options, state) =>
											state.inputValue.length > 0
												? options.filter((option) =>
														option
															.toLowerCase()
															.startsWith(state.inputValue.toLowerCase().trim()),
												  )
												: options
										}
										slotProps={{
											paper: {
												sx: {
													'& .MuiAutocomplete-listbox': {
														'padding': 0,
														'backgroundColor': 'white',
														'width': 'calc(100% + 20px)',
														'left': '-10px',
														'position': 'absolute',
														'top': '12px',
														'borderRadius': '6px',
														'boxShadow': '1px 1px 10px rgba(0, 0, 0, 0.2)',

														'&::-webkit-scrollbar': {
															width: '6px',
														},
														'&::-webkit-scrollbar-thumb': {
															background: '#888',
															borderRadius: '10px',
															marginBlock: '10px',
														},
														'& .MuiAutocomplete-option': {
															'fontSize': '14px',
															'borderRadius': '10px',
															'margin': '5px',
															'paddingInline': '8px',
															'paddingBlock': '12px',

															'&[aria-selected="true"]': {
																backgroundColor: 'rgba(5, 173, 86, 0.10)',
															},

															'&[data-focus="true"]': {
																backgroundColor: 'rgba(5, 173, 86, 0.10)',
															},

															'&:hover': {
																backgroundColor: 'rgba(128, 128, 128, 0.05)',
															},
														},
													},
												},
											},
										}}
										onChange={(event, newValue) => {
											if (newValue !== null && newValue !== undefined) {
												field.onChange(newValue);
											}
										}}
										onInputChange={(value) => {
											field.onChange(value);
										}}
										options={jobTitles.map((job) => t(job) as string)}
										renderInput={(param) => (
											<TextField
												placeholder={t('Enter your role') as string}
												sx={{
													'& .MuiOutlinedInput-root': {
														border: 'none',
														padding: 0,
														margin: 0,
													},
													'& .MuiOutlinedInput-notchedOutline': {
														border: 'none',
													},
													'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
														{
															paddingTop: 0,
															paddingBottom: 0,
															padding: 0,
															margin: 0,
															border: 'none',
														},
													'backgroundColor': '#fbfaf9',
													'& input': {
														margin: 0,
														padding: 0,
														fontSize: '14px',
														lineHeight: 1,
														zIndex: 5,
													},
													'border': 'transparent',
													'background-color': 'transparent',
													'paddingTop': '20px',
												}}
												{...param}
											/>
										)}
									/>
								</Stack>
							)}
						/>
					</div>
					<button type='submit' disabled={!isValid || isCreating}>
						{t('Next')}
					</button>
					<p onClick={handlePreviousStep} className={styles.stepBack}>
						{t('Previous step')}
					</p>
				</form>
			</div>
		</div>
	);
};

export default RegisterFormWithCompanyComponent;
