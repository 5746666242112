import HttpClient from '../shared/http/http-client';
import { AgendaContract } from '../store/agenda/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';

export default class AgendaAPI {
	public static async getAgendaItemsForAMeeting(iCalUId: string): Promise<AgendaContract[]> {
		const { data } = await HttpClient.get<AgendaContract[]>(
			ApiUrls.GetAgendaForMeeting,
			new UrlParamBuilder().addEventiCalUId(iCalUId).build(),
		);
		return data;
	}

	public static async getAgendaItems(): Promise<AgendaContract[]> {
		const { data } = await HttpClient.get<AgendaContract[]>(ApiUrls.getAllAgenda);
		return data;
	}

	public static async createAgendaItem(body: AgendaContract): Promise<AgendaContract> {
		const { data } = await HttpClient.post<AgendaContract, AgendaContract>(ApiUrls.PostItemAgenda, body);
		return data;
	}

	public static async deleteAgendaItem(itemId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeleteAgendaItem,
			new UrlParamBuilder().addItemId(itemId).build(),
		);
		return data;
	}

	public static async updateAgendaItem(body: AgendaContract): Promise<AgendaContract> {
		const { data } = await HttpClient.patch<AgendaContract, AgendaContract>(ApiUrls.UpdateItemAgenda, body);
		return data;
	}
}
