import React from 'react';
import styles from './notes.form.component.module.scss';
import { IconSearch } from '../../../shared/utils/icon';
import { MeetingContract } from '../../../store/meetings/types';
import { getDateFormatL } from '../../../shared/utils/date.util';
import { useAppSelector } from '../../../store/hooks';

interface Props {
	isSearching: boolean;
	onChangeHandler: (filter: string) => void;
	handleChangeMeeting: (meeting: MeetingContract) => void;
	close: () => void;
}

const NotesFormMeetingsModalComponent: React.FC<Props> = ({
	isSearching,
	onChangeHandler,
	handleChangeMeeting,
	close,
}) => {
	const modalRef = React.useRef<HTMLDivElement>(null);
	const { lastMeetings, meetingsOption } = useAppSelector((state) => state.meetings);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					close();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close]);

	return (
		<div className={styles.modalAttendee} ref={modalRef}>
			<div className={styles.searchInput}>
				<span>
					<IconSearch />
				</span>
				<input type='text' onChange={(e) => onChangeHandler(e.target.value)} />
			</div>
			<div className={styles.containerList}>
				{!isSearching &&
					lastMeetings?.map((meeting, index) => (
						<div onClick={() => handleChangeMeeting(meeting)} className={styles.slot} key={index}>
							<span>{`${meeting.subject} (${getDateFormatL(meeting.start)})`}</span>
						</div>
					))}
				{isSearching &&
					meetingsOption?.map((meeting, index) => (
						<div onClick={() => handleChangeMeeting(meeting)} className={styles.slot} key={index}>
							<span>{`${meeting.subject} (${getDateFormatL(meeting.start)})`}</span>
						</div>
					))}
			</div>
		</div>
	);
};

export default NotesFormMeetingsModalComponent;
