import React from 'react';
import styles from './shimmer-note.module.scss';
import ShimmerText from '../shimmer-text.component';


const ShimmerNote = () => {
    const lineNumber = Array.from({ length: 12 });

    return (
        <div className={styles.shimmerEditor}>
            <div className={styles.shimmerText}>
                <ShimmerText height={16} width={250} />
                <br />
                {lineNumber.map((_, index) => (
                    <ShimmerText height={12} width={420} key={index} />
                ))}
            </div>
            <div className={styles.shimmerComment}></div>
        </div>
    );
};

export default ShimmerNote;