import { t } from 'i18next';
import { NotificationsContract, NotificationsType } from '../../store/notifications/type';

type NotificationMessage = {
    title: string,
    message: string,
}

type Notifications = {
    [key in NotificationsType]: NotificationMessage;
}

export const getNotificationMessage = (notification: NotificationsContract): NotificationMessage => {
    const notifications: Notifications = {
        [NotificationsType.NEW_TODO]: {
            title: t('Todo'),
            message: `${t('You have a new todo ')}${notification.itemTitle}${t(' created by ')}${notification.userFromName}`
        },
        [NotificationsType.TODO_COMPLETE]: {
            title: t('Todo'),
            message: `${notification.userFromName}${t(' just finish the todo ')}${notification.itemTitle}${t(' you assigned to him/her ')}`,
        },
        [NotificationsType.NEW_MEETING_NOTE]: {
            title: t('Note'),
            message: `${t('A new note has been shared by ')}${notification.userFromName}${t(' on the meeting ')}${notification.meetingName}`,
        },
        [NotificationsType.NEW_PROJECT_NOTE]: {
            title: t('Note'),
            message: `${t('A new note has been shared by ')}${notification.userFromName}${t(' on the project ')}${notification.itemTitle}`,
        },
        [NotificationsType.NEW_PROJECT]: {
            title: t('Project'),
            message: `${notification.userFromName}${t(' vous a ajouté dans un nouveau projet ')}${notification.itemTitle}`,
        },
        [NotificationsType.NEW_MEETING]: {
            title: t('Meeting'),
            message: `${notification.userFromName}${t(' vous a ajouté dans une nouvelle réunion ')}${notification.itemTitle}`,
        },
        [NotificationsType.NEW_COMMENT]: {
            title: t('Comment'),
            message: `${notification.userFromName}${t(' vous a identifié dans un commentaire sous la note ')}${notification.itemTitle}`,
        },
        [NotificationsType.MENTION]: {
            title: t('Mention'),
            message: `${notification.userFromName}${t(' vous a identifié dans la note  ')}${notification.itemTitle}`,
        },
        [NotificationsType.NEW_NOTE]: {
            title: t('Note'),
            message: 'Not implemented',
        },
    };

    return notifications[notification.type];
};