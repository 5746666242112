import React, { MouseEventHandler } from 'react';
import styles from './filter-btn.module.scss';
import { IconFilter } from '../../../utils/icon';
import { t } from 'i18next';

interface Props {
    showFilterModal: boolean,
    trueCount: number,
    onClick: MouseEventHandler,
}

const FilterBtn: React.FC<Props> = ({ showFilterModal, trueCount, onClick }) => {
    
    return (
        <div
            className={`${styles.filterBtn} ${showFilterModal ? styles.active : ''}`}
            onClick={onClick}>
            <IconFilter />
            {t('Filters')}
            <div>{trueCount !== 0 ? trueCount : ''}</div>
        </div>
    );
};

export default FilterBtn;