import React, { ReactNode, useRef, useState } from 'react';
import styles from './form-layout.module.scss';
import { IconClose, IconSimplePlus } from '../../../utils/icon';
import { t } from 'i18next';
import FiveDaysBtn from '../../buttons/fiveDaysBtn/five-days-btn.component';
import useClickOutside from '../../../hooks/use-click-outside.hook';
import OutlinedButton from '../../buttons/outlinedBtn/outlined-btn.component';

export enum FormType {
    create,
    save,
}

interface FormLayoutTitle {
    icon: ReactNode,
    title: string,
}

interface Props {
    formTitle: FormLayoutTitle,
    children: ReactNode,
    handleClose: () => void,
    handleSubmit?: (e?: React.BaseSyntheticEvent) => void,
    type?: FormType,
}

const FormLayout = ({ children, handleClose, handleSubmit, type, formTitle }: Props) => {
    const modalFormRef = useRef<HTMLDivElement | null>(null);
    const [animation, setAnimation] = useState<boolean>(false);
    const formModal = useRef<HTMLDivElement | null>(null);


    const closeModal = () => {
        setAnimation(true);
        setTimeout(() => {
            handleClose();
            setAnimation(false);
        }, 300);
    };

    useClickOutside(modalFormRef, () => closeModal());

    return (
        <div ref={formModal} className={`${styles.formContainer} ${animation ? styles.fadeOut : ''}`}>
            <div ref={modalFormRef} className={`${styles.formModal} ${animation ? styles.slideOut : ''}`}>
                <div>
                    <div className={styles.titleWrapper}>
                        <h3>
                            {formTitle.icon}
                            {formTitle.title}
                        </h3>
                        <div className={styles.closeButton}>
                            <div
                                className={styles.icon}
                                onClick={() => {
                                    closeModal();
                                }}>
                                <IconClose />
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit ? handleSubmit : undefined}>
                        {children}
                        {handleSubmit && (
                            <div className={styles.btnContainer}>
                                <OutlinedButton
                                    onClick={() => {
                                        closeModal();
                                    }}
                                    type='button'>
                                    {t('Cancel')}
                                </OutlinedButton>
                                <FiveDaysBtn type={'submit'} onClick={closeModal}>
                                    {type === FormType.create ? (
                                        <>
                                            <IconSimplePlus /> {t('Create')}
                                        </>
                                    ) : (
                                        <>{t('Save')}</>
                                    )}
                                </FiveDaysBtn>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FormLayout;