import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ErrorUtil from '../../shared/utils/error.util';
import { createRequestStatus, RequestStatus, RequestStatusType } from '../shared/types';
import {
	NotesContract,
	People,
	UpdateStatePayload,
	updateNoteAccess,
	updateNoteArchive,
	updateNoteTags,
	updateTags,
} from './type';
import NotesAPI from '../../api/notes-api';
import { TagContract } from '../meetings/types';

interface NotesState {
	notes?: NotesContract[];
	notesRequestStatus: RequestStatus;
	note?: NotesContract;
	noteRequestStatus: RequestStatus;

	selectedMeetingNotes?: NotesContract[];
	selectMeetingNoteRequestStatus: RequestStatus;

	prevMeetingsNote?: NotesContract[];

	selectedMeetingPrevNotes?: NotesContract[];
	selectMeetingPrevNoteRequestStatus: RequestStatus;

	sharedPeopleNote?: People[];
	sharedPeopleNoteRequestStatus: RequestStatus;

	selectNote?: NotesContract;
	selectNoteRequestStatus: RequestStatus;
}

const initialState: NotesState = {
	notes: undefined,
	notesRequestStatus: createRequestStatus(RequestStatusType.New),
	note: undefined,
	noteRequestStatus: createRequestStatus(RequestStatusType.New),

	selectedMeetingNotes: undefined,
	selectMeetingNoteRequestStatus: createRequestStatus(RequestStatusType.New),

	prevMeetingsNote: undefined,

	selectedMeetingPrevNotes: undefined,
	selectMeetingPrevNoteRequestStatus: createRequestStatus(RequestStatusType.New),

	sharedPeopleNote: undefined,
	sharedPeopleNoteRequestStatus: createRequestStatus(RequestStatusType.New),

	selectNoteRequestStatus: createRequestStatus(RequestStatusType.New),
	selectNote: undefined,
};

export const getOneNote = createAsyncThunk('notes/getOne', async (noteId: string): Promise<NotesContract> => {
	return NotesAPI.getOneNotes(noteId);
});

export const getAllNote = createAsyncThunk('notes/getAll', async (): Promise<NotesContract[]> => {
	return NotesAPI.getAllNotes();
});

export const getAllNoteForAMeeting = createAsyncThunk(
	'notes/getAll/meeting',
	async (iCalUId: string): Promise<NotesContract[]> => {
		return NotesAPI.getAllNotesForAMeeting(iCalUId);
	},
);

export const getAllNoteForAPrecedentMeeting = createAsyncThunk(
	'notes/getAll/meeting/prev',
	async (iCalUId: string): Promise<NotesContract[]> => {
		return NotesAPI.getAllNotesForAMeeting(iCalUId);
	},
);

export const createNotes = createAsyncThunk('notes/create', async (body: NotesContract): Promise<NotesContract> => {
	return NotesAPI.createNotes(body);
});

export const deleteOneNote = createAsyncThunk('notes/delete', async (noteId: string): Promise<undefined> => {
	return NotesAPI.deleteNote(noteId);
});

export const updateNotes = createAsyncThunk(
	'notes/update',
	async ({ noteId, body }: { noteId: string; body: NotesContract }): Promise<NotesContract> => {
		return NotesAPI.updateNote(noteId, body);
	},
);

export const addTags = createAsyncThunk('notes/tags/add', async (body: updateTags): Promise<TagContract> => {
	return NotesAPI.addTags(body);
});

export const deleteTagsFromNote = createAsyncThunk(
	'notes/tags/delete',
	async ({ notesId, tagsId }: { notesId: string; tagsId: string }): Promise<undefined> => {
		return NotesAPI.deleteTagsFromNote(notesId, tagsId);
	},
);

export const postPeopleShareNote = createAsyncThunk(
	'notes/share/post',
	async ({ body, noteId }: { body: People; noteId: string }): Promise<People> => {
		return NotesAPI.addPeopleShare(body, noteId);
	},
);

export const deletePeopleShareNote = createAsyncThunk(
	'notes/share/delete',
	async ({ noteId, mail }: { noteId: string; mail: string }): Promise<undefined> => {
		return NotesAPI.deleteAPeopleShare(noteId, mail);
	},
);

export const getPeopleShareNote = createAsyncThunk('notes/share/get', async (noteId: string): Promise<People[]> => {
	return NotesAPI.getPeopleShare(noteId);
});

const slice = createSlice({
	name: 'notes',
	initialState,
	reducers: {
		deleteNotes: (state, action: PayloadAction<string>) => {
			const noteId = action.payload;
			if (state.notes) {
				state.notes = state.notes.filter((note) => note.id !== noteId);
			}
		},

		setSelectedNote: (state: NotesState, action: PayloadAction<NotesContract | undefined>) => {
			state.selectNote = action.payload;
		},

		setPrevMeetingsNotes: (state: NotesState, action: PayloadAction<NotesContract[] | undefined>) => {
			state.prevMeetingsNote = action.payload;
		},

		deleteNotesFromAMeeting: (state, action: PayloadAction<string>) => {
			const noteId = action.payload;
			if (state.selectedMeetingNotes) {
				state.selectedMeetingNotes = state.selectedMeetingNotes.filter((note) => note.id !== noteId);
			}
		},

		sortNotes: (state) => {
			if (state.notes) {
				state.notes.sort((b, a) => {
					return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
				});
			}
		},

		sortSelectMeetingNotes: (state) => {
			if (state.selectedMeetingNotes) {
				state.selectedMeetingNotes.sort((b, a) => {
					return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
				});
			}
		},

		sortSelectMeetingNotesPrev: (state) => {
			if (state.prevMeetingsNote) {
				state.prevMeetingsNote.sort((b, a) => {
					return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
				});
			}
		},

		addPeopleShare: (state, action: PayloadAction<any>) => {
			const newPeople = action.payload;
			if (state.sharedPeopleNote) {
				state.sharedPeopleNote.push(newPeople);
			}
		},

		deleteNotesPeople: (state, action: PayloadAction<string>) => {
			const mail = action.payload;
			if (state.sharedPeopleNote) {
				state.sharedPeopleNote = state.sharedPeopleNote.filter((note) => note.mail !== mail);
			}
		},

		addNotesSelectedMeeting: (state, action: PayloadAction<NotesContract>) => {
			const newNote = action.payload;
			if (state.selectedMeetingNotes) {
				state.selectedMeetingNotes = [newNote, ...state.selectedMeetingNotes];
			}
		},

		addNotes: (state, action: PayloadAction<NotesContract>) => {
			const newNote = action.payload;
			if (state.notes) {
				state.notes = [newNote, ...state.notes];
			}
		},

		updateNoteMeeting: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { noteId, newText } = action.payload;
			if (state.selectedMeetingNotes) {
				const note = state.selectedMeetingNotes.find((noteNT) => noteNT.id === noteId);
				if (note) {
					note.text = newText;
				}
			}
		},
		updateNoteMeetingPrev: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { noteId, newText } = action.payload;
			if (state.prevMeetingsNote) {
				const note = state.prevMeetingsNote.find((noteNT) => noteNT.id === noteId);
				if (note) {
					note.text = newText;
				}
			}
		},
		updateNoteTitle: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { noteId, newText } = action.payload;
			if (state.notes) {
				const note = state.notes.find((noteNT) => noteNT.id === noteId);
				if (note) {
					note.title = newText;
				}
			}
		},
		updateNoteMeetingTitle: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { noteId, newText } = action.payload;
			if (state.selectedMeetingNotes) {
				const note = state.selectedMeetingNotes.find((noteNT) => noteNT.id === noteId);
				if (note) {
					note.title = newText;
				}
			}
		},
		updateNoteMeetingTitlePrev: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { noteId, newText } = action.payload;
			if (state.prevMeetingsNote) {
				const note = state.prevMeetingsNote.find((noteNT) => noteNT.id === noteId);
				if (note) {
					note.title = newText;
				}
			}
		},
		updateNoteShareAccess: (state, action: PayloadAction<updateNoteAccess>) => {
			const { id, accessRightType } = action.payload;
			if (state.selectedMeetingNotes) {
				const note = state.selectedMeetingNotes.find((noteNT) => noteNT.id === id);
				if (note) {
					note.accessRightType = accessRightType;
				}
			}
		},

		updateNotePageShareAccess: (state, action: PayloadAction<updateNoteAccess>) => {
			const { id, accessRightType } = action.payload;
			if (state.notes) {
				const note = state.notes.find((noteNT) => noteNT.id === id);
				if (note) {
					note.accessRightType = accessRightType;
				}
			}
		},

		updateNoteArchiveState: (state, action: PayloadAction<updateNoteArchive>) => {
			const { id, archive } = action.payload;
			if (state.notes) {
				const note = state.notes.find((noteNT) => noteNT.id === id);
				if (note) {
					note.archived = archive;
				}
			}
		},

		updateNotesTags: (state, action: PayloadAction<updateNoteTags>) => {
			const { id, tags } = action.payload;
			if (state.notes) {
				const note = state.notes.find((noteNT) => noteNT.id === id);
				if (note) {
					note.tags.push(tags);
				}
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getAllNote.pending, (state) => {
			state.notesRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllNote.fulfilled, (state, action) => {
			state.notesRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.notes = action.payload;
		});
		builder.addCase(getAllNote.rejected, (state, action) => {
			state.notesRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAllNoteForAMeeting.pending, (state) => {
			state.selectMeetingNoteRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllNoteForAMeeting.fulfilled, (state, action) => {
			state.selectMeetingNoteRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.selectedMeetingNotes = action.payload;
		});
		builder.addCase(getAllNoteForAMeeting.rejected, (state, action) => {
			state.selectMeetingNoteRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAllNoteForAPrecedentMeeting.pending, (state) => {
			state.selectMeetingPrevNoteRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllNoteForAPrecedentMeeting.fulfilled, (state, action) => {
			state.selectMeetingPrevNoteRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.selectedMeetingPrevNotes = action.payload;
		});
		builder.addCase(getAllNoteForAPrecedentMeeting.rejected, (state, action) => {
			state.selectMeetingPrevNoteRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getOneNote.pending, (state) => {
			state.noteRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getOneNote.fulfilled, (state, action) => {
			state.noteRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.note = action.payload;
		});
		builder.addCase(getOneNote.rejected, (state, action) => {
			state.noteRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getPeopleShareNote.pending, (state) => {
			state.sharedPeopleNoteRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getPeopleShareNote.fulfilled, (state, action) => {
			state.sharedPeopleNoteRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.sharedPeopleNote = action.payload;
		});
		builder.addCase(getPeopleShareNote.rejected, (state, action) => {
			state.sharedPeopleNoteRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const {
	deleteNotes,
	deleteNotesFromAMeeting,
	sortNotes,
	addPeopleShare,
	deleteNotesPeople,
	addNotesSelectedMeeting,
	updateNoteMeeting,
	setSelectedNote,
	updateNoteShareAccess,
	updateNoteMeetingTitle,
	setPrevMeetingsNotes,
	updateNoteMeetingPrev,
	updateNoteMeetingTitlePrev,
	sortSelectMeetingNotes,
	sortSelectMeetingNotesPrev,
	updateNoteTitle,
	addNotes,
	updateNoteArchiveState,
	updateNotePageShareAccess,
	updateNotesTags,
} = actions;
export default reducer;
