import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ErrorUtil from '../../shared/utils/error.util';
import { createRequestStatus, RequestStatus, RequestStatusType, UpdateMeetingFilesAccess } from '../shared/types';
import { SharingContract, SharingContractForMap } from './type';
import SharingAPI from '../../api/sharing-api';
import { CheckTokenContract, SharingDataContract } from '../../pages/publicMeeting/types/confirm-mail-step';
import { TodosContract, TodosContractCreate, UpdateTitlePayload, UpdateTodo } from '../todos/type';
import { NotesContract, People, updateNoteAccess } from '../notes/type';
import { AgendaContract, UpdateAgendaItemPayload } from '../agenda/type';
import { FileContract, MeetingContract } from '../meetings/types';

interface SharingState {
	shareMeeting?: SharingContract;
	shareMeetingRequestStatus: RequestStatus;

	shareTodos?: TodosContract[];
	shareTodosRequestStatus: RequestStatus;

	shareTodosPrev?: TodosContract[];
	shareTodosPrevRequestStatus: RequestStatus;

	shareNotes?: NotesContract[];
	shareNotesRequestStatus: RequestStatus;

	shareNotesPrev?: NotesContract[];
	shareNotesPrevRequestStatus: RequestStatus;

	shareAgenda?: AgendaContract[];
	shareAgendaRequestStatus: RequestStatus;

	shareFiles?: FileContract[];
	shareFilesRequestStatus: RequestStatus;

	shareTodosNote?: TodosContract[];
	shareTodosRequestStatusNote: RequestStatus;

	shareCollaborators?: string[];
	shareCollaboratorsRequestStatusNote: RequestStatus;

	shareMeetingData?: MeetingContract;
	shareMeetingDataRequestStatus: RequestStatus;

	shareMeetingDataPrev?: MeetingContract[];
	shareMeetingDataPrevRequestStatus: RequestStatus;

	shareMeetingDataNext?: MeetingContract[];
	shareMeetingDataNextRequestStatus: RequestStatus;

	sharedPeopleNote?: People[];
	sharedPeopleNoteRequestStatus: RequestStatus;

	filesPeopleShare?: People[];
	filesPeopleRequestStatus: RequestStatus;
}

const initialState: SharingState = {
	shareMeeting: undefined,
	shareMeetingRequestStatus: createRequestStatus(RequestStatusType.New),

	shareTodos: undefined,
	shareTodosRequestStatus: createRequestStatus(RequestStatusType.New),

	shareTodosPrev: undefined,
	shareTodosPrevRequestStatus: createRequestStatus(RequestStatusType.New),

	shareNotes: undefined,
	shareNotesRequestStatus: createRequestStatus(RequestStatusType.New),

	shareNotesPrev: undefined,
	shareNotesPrevRequestStatus: createRequestStatus(RequestStatusType.New),

	shareAgenda: undefined,
	shareAgendaRequestStatus: createRequestStatus(RequestStatusType.New),

	shareFiles: undefined,
	shareFilesRequestStatus: createRequestStatus(RequestStatusType.New),

	shareTodosNote: undefined,
	shareTodosRequestStatusNote: createRequestStatus(RequestStatusType.New),

	shareCollaborators: undefined,
	shareCollaboratorsRequestStatusNote: createRequestStatus(RequestStatusType.New),

	shareMeetingData: undefined,
	shareMeetingDataRequestStatus: createRequestStatus(RequestStatusType.New),

	shareMeetingDataPrev: undefined,
	shareMeetingDataPrevRequestStatus: createRequestStatus(RequestStatusType.New),

	shareMeetingDataNext: undefined,
	shareMeetingDataNextRequestStatus: createRequestStatus(RequestStatusType.New),

	sharedPeopleNote: undefined,
	sharedPeopleNoteRequestStatus: createRequestStatus(RequestStatusType.New),

	filesPeopleShare: undefined,
	filesPeopleRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const shareMeeting = createAsyncThunk(
	'share/meeting',
	async ({ eventId, mail }: { eventId: string; mail: string }): Promise<SharingContract> => {
		return SharingAPI.ShareMeeting(eventId, mail);
	},
);

export const getShareDetails = createAsyncThunk(
	'share/meeting/details',
	async (shareId: string): Promise<SharingContractForMap> => {
		return SharingAPI.getShareDetails(shareId);
	},
);

export const sendToken = createAsyncThunk(
	'share/token',
	async ({ shareId, mail }: { shareId: string; mail: string }): Promise<SharingDataContract> => {
		return SharingAPI.sendToken(shareId, mail);
	},
);

export const checkToken = createAsyncThunk(
	'share/check',
	async ({ shareId, code }: { shareId: string; code: string }): Promise<CheckTokenContract> => {
		return SharingAPI.checkToken(shareId, code);
	},
);

export const getNewToken = createAsyncThunk(
	'share/getNewToken',
	async ({ shareId, mail }: { shareId: string; mail: string }): Promise<undefined> => {
		return SharingAPI.getNewToken(shareId, mail);
	},
);

export const getSharedMeeting = createAsyncThunk(
	'share/getMeeting/details',
	async ({ shareId }: { shareId: string }): Promise<undefined> => {
		return SharingAPI.getShareMeeting(shareId);
	},
);

export const getSelectMeetingTodosForShared = createAsyncThunk(
	'share/todos/event',
	async (eventId: string): Promise<TodosContract[]> => {
		return SharingAPI.getSelectMeetingTodos(eventId);
	},
);

export const getSelectMeetingTodosForSharedPrev = createAsyncThunk(
	'share/todos/event/prev',
	async (eventId: string): Promise<TodosContract[]> => {
		return SharingAPI.getSelectMeetingTodos(eventId);
	},
);

export const getAllNoteForAMeetingShared = createAsyncThunk(
	'notes/getAll/meeting',
	async ({ iCalUId, userId }: { iCalUId: string; userId: string }): Promise<NotesContract[]> => {
		return SharingAPI.getAllNotesForAMeeting(iCalUId, userId);
	},
);

export const getAllNoteForAMeetingSharedPrev = createAsyncThunk(
	'notes/getAll/meeting/prev',
	async ({ iCalUId, userId }: { iCalUId: string; userId: string }): Promise<NotesContract[]> => {
		return SharingAPI.getAllNotesForAMeeting(iCalUId, userId);
	},
);

export const getAgendaForAMeetingShared = createAsyncThunk(
	'agenda/get/meeting',
	async (iCalUId: string): Promise<AgendaContract[]> => {
		return SharingAPI.getAgendaItemsForAMeeting(iCalUId);
	},
);

export const getFilesForAMeetingShared = createAsyncThunk(
	'files/get/meeting',
	async ({ iCalUId, userId }: { iCalUId: string; userId: string }): Promise<FileContract[]> => {
		return SharingAPI.getFilesForAMeeting(iCalUId, userId);
	},
);

export const downloadMeetingFileShared = createAsyncThunk(
	'files/downloadFile',
	async ({ selectedMeetingID, fileId }: { selectedMeetingID: string; fileId: string }): Promise<Blob> => {
		return SharingAPI.downloadFileMeetingShare(selectedMeetingID, fileId);
	},
);

export const getNotesTodosShared = createAsyncThunk(
	'shared/todos/notes',
	async (notesId: string): Promise<TodosContract[]> => {
		return SharingAPI.getNotesTodosShare(notesId);
	},
);

export const createTodosShared = createAsyncThunk(
	'shared/todos/create',
	async ({
		bodyShared,
		sharedId,
	}: {
		bodyShared: TodosContractCreate;
		sharedId: string;
	}): Promise<TodosContractCreate> => {
		return SharingAPI.createTodo(bodyShared, sharedId);
	},
);

export const updateTodosShared = createAsyncThunk(
	'shared/todos/update',
	async ({ body, todoId }: { body: TodosContractCreate; todoId: string }): Promise<TodosContractCreate> => {
		return SharingAPI.updateTodo(body, todoId);
	},
);

export const deleteTodoShared = createAsyncThunk('shared/todos/delete', async (todoId: string): Promise<undefined> => {
	return SharingAPI.deleteTodo(todoId);
});

export const createAgendaItemShared = createAsyncThunk(
	'shared/agenda/item/create',
	async (body: AgendaContract): Promise<AgendaContract> => {
		return SharingAPI.createAgendaItem(body);
	},
);

export const deleteAgendaItemShared = createAsyncThunk(
	'shared/agenda/delete',
	async (itemId: string): Promise<undefined> => {
		return SharingAPI.deleteAgendaItem(itemId);
	},
);

export const updateAgendaItemShared = createAsyncThunk(
	'shared/agenda/item/update',
	async (body: AgendaContract): Promise<AgendaContract> => {
		return SharingAPI.updateAgendaItem(body);
	},
);

export const createNotesShared = createAsyncThunk(
	'shared/notes/create',
	async ({ body, shareId }: { body: NotesContract; shareId: string }): Promise<NotesContract> => {
		return SharingAPI.createNotes(body, shareId);
	},
);

export const deleteOneNoteShared = createAsyncThunk(
	'shared/notes/delete',
	async (noteId: string): Promise<undefined> => {
		return SharingAPI.deleteNote(noteId);
	},
);

export const updateNotesShared = createAsyncThunk(
	'shared/notes/update',
	async ({ noteId, body }: { noteId: string; body: NotesContract }): Promise<NotesContract> => {
		return SharingAPI.updateNote(noteId, body);
	},
);

export const addMeetingFileShared = createAsyncThunk(
	'shared/files/addFile',
	async ({ body, selectedMeetingID }: { body: FormData; selectedMeetingID: string }): Promise<FormData> => {
		return SharingAPI.addFileToMeeting(body, selectedMeetingID);
	},
);

export const deleteMeetingFileShared = createAsyncThunk(
	'shared/files/deleteFile',
	async ({
		selectedMeetingID,
		fileId,
		userId,
	}: {
		selectedMeetingID: string;
		fileId: string;
		userId: string;
	}): Promise<undefined> => {
		return SharingAPI.deleteFileToMeeting(selectedMeetingID, fileId, userId);
	},
);

export const updateMeetingFileShared = createAsyncThunk(
	'shared/files/updateAccess',
	async ({
		body,
		selectedMeetingID,
		fileId,
		userId,
	}: {
		body: UpdateMeetingFilesAccess;
		selectedMeetingID: string;
		fileId: string;
		userId: string;
	}): Promise<FileContract> => {
		return SharingAPI.updateFileToMeetingAccess(body, selectedMeetingID, fileId, userId);
	},
);

export const checkUserFromFivedaysShared = createAsyncThunk(
	'shared/user/check/fivedays',
	async (mail: string): Promise<boolean> => {
		return SharingAPI.checkUser(mail);
	},
);

export const getMeetingsCollaborators = createAsyncThunk(
	'meeting/share/collaborators',
	async (iCalUId: string): Promise<string[]> => {
		return SharingAPI.getMeetingCollaborators(iCalUId);
	},
);

export const deleteMeetingCollaborator = createAsyncThunk(
	'meeting/share/collaborators/delete',
	async ({ iCalUId, mail }: { iCalUId: string; mail: string }): Promise<any> => {
		return SharingAPI.deleteAMeetingCollaborator(iCalUId, mail);
	},
);

export const getSharedMeetingPrev = createAsyncThunk(
	'share/getMeeting/details/prev',
	async ({ shareId, token }: { shareId: string; token: string }): Promise<undefined> => {
		return SharingAPI.getShareMeetingPrev(shareId, token);
	},
);

export const getSharedMeetingNext = createAsyncThunk(
	'share/getMeeting/details/next',
	async ({ shareId, token }: { shareId: string; token: string }): Promise<undefined> => {
		return SharingAPI.getShareMeetingNext(shareId, token);
	},
);

export const postPeopleShareNoteShared = createAsyncThunk(
	'notes/share/post',
	async ({ body, noteId }: { body: People; noteId: string }): Promise<People> => {
		return SharingAPI.addPeopleShare(body, noteId);
	},
);

export const deletePeopleShareNote = createAsyncThunk(
	'notes/share/delete',
	async ({ noteId, mail }: { noteId: string; mail: string }): Promise<undefined> => {
		return SharingAPI.deleteAPeopleShare(noteId, mail);
	},
);

export const getPeopleShareNote = createAsyncThunk('notes/share/get', async (noteId: string): Promise<People[]> => {
	return SharingAPI.getPeopleShare(noteId);
});

export const postPeopleShareFileShare = createAsyncThunk(
	'/share/post/file/public',
	async ({ body, fileId }: { body: People; fileId: string }): Promise<People> => {
		return SharingAPI.addPeopleShareFileShare(body, fileId);
	},
);

export const deletePeopleShareFileShare = createAsyncThunk(
	'/share/delete/file/public',
	async ({ fileId, mail }: { fileId: string; mail: string }): Promise<undefined> => {
		return SharingAPI.deleteAPeopleShareFileShare(fileId, mail);
	},
);

export const getPeopleShareFileShare = createAsyncThunk(
	'/file/share/get/public',
	async (fileId: string): Promise<People[]> => {
		return SharingAPI.getPeopleShareFileShare(fileId);
	},
);

const slice = createSlice({
	name: 'sharing',
	initialState,
	reducers: {
		updateTodoTitle: (state, action: PayloadAction<UpdateTitlePayload>) => {
			const { todoId, newTitle } = action.payload;
			if (state.shareTodos) {
				const todo = state.shareTodos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.title = newTitle;
				}
			}
		},

		updateTodoTitleNoteShared: (state, action: PayloadAction<UpdateTitlePayload>) => {
			const { todoId, newTitle } = action.payload;
			if (state.shareTodosNote) {
				const todo = state.shareTodosNote.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.title = newTitle;
				}
			}
		},

		updateNoteSharedState: (state, action: PayloadAction<NotesContract>) => {
			const newNote = action.payload;
			if (state.shareNotes) {
				const note = state.shareNotes.find((noteNew) => noteNew.id === newNote.id);
				if (note) {
					note.title = newNote.title;
					note.text = newNote.text;
					note.updateOn = newNote.updateOn;
				}
			}
		},

		addTodosSelectedMeetingShare: (state, action: PayloadAction<TodosContract>) => {
			const newTodo = action.payload;
			if (state.shareTodos) {
				state.shareTodos.push(newTodo);
			}
		},

		addNotesSelectedMeetingShare: (state, action: PayloadAction<NotesContract>) => {
			const newTodo = action.payload;
			if (state.shareNotes) {
				state.shareNotes.push(newTodo);
			}
		},

		addAgendaSelectedMeetingShare: (state, action: PayloadAction<AgendaContract>) => {
			const newTodo = action.payload;
			if (state.shareAgenda) {
				state.shareAgenda.push(newTodo);
			}
		},
		updateTodoSharedState: (state, action: PayloadAction<TodosContract>) => {
			const newTodo = action.payload;
			if (state.shareTodos) {
				const todo = state.shareTodos.find((todoNew) => todoNew.id === newTodo.id);
				if (todo) {
					todo.title = newTodo.title;
					todo.text = newTodo.text;
					todo.duration = newTodo.duration;
					todo.dueDate = newTodo.dueDate;
					todo.meetingName = newTodo.meetingName;
					todo.assigneeDisplayName = newTodo.assigneeDisplayName;
					todo.meetingStartDate = newTodo.meetingStartDate;
					todo.meetingGraphId = newTodo.meetingGraphId;
					todo.state = newTodo.state;
				}
			}
		},

		updateTodoSharedStateNote: (state, action: PayloadAction<TodosContract>) => {
			const newTodo = action.payload;
			if (state.shareTodosNote) {
				const todo = state.shareTodosNote.find((todoNew) => todoNew.id === newTodo.id);
				if (todo) {
					todo.title = newTodo.title;
					todo.text = newTodo.text;
					todo.duration = newTodo.duration;
					todo.dueDate = newTodo.dueDate;
					todo.meetingName = newTodo.meetingName;
					todo.assigneeDisplayName = newTodo.assigneeDisplayName;
					todo.meetingStartDate = newTodo.meetingStartDate;
					todo.meetingGraphId = newTodo.meetingGraphId;
					todo.state = newTodo.state;
				}
			}
		},

		deleteNoteShare: (state, action: PayloadAction<string>) => {
			const noteId = action.payload;
			if (state.shareNotes) {
				state.shareNotes = state.shareNotes.filter((note) => note.id !== noteId);
			}
		},

		deleteAgendaShare: (state, action: PayloadAction<string>) => {
			const agendaId = action.payload;
			if (state.shareAgenda) {
				state.shareAgenda = state.shareAgenda.filter((agenda) => agenda.id !== agendaId);
			}
		},

		deleteTodosShare: (state, action: PayloadAction<string>) => {
			const todoId = action.payload;
			if (state.shareTodos) {
				state.shareTodos = state.shareTodos.filter((todo) => todo.id !== todoId);
			}
		},

		deleteTodosShareNote: (state, action: PayloadAction<string>) => {
			const todoId = action.payload;
			if (state.shareTodosNote) {
				state.shareTodosNote = state.shareTodosNote.filter((todo) => todo.id !== todoId);
			}
		},

		AddCollaboratorShare: (state, action: PayloadAction<string>) => {
			const newCollaborator = action.payload;
			if (state.shareCollaborators) {
				state.shareCollaborators.push(newCollaborator);
			}
		},
		sortAgendaShared: (state) => {
			if (state.shareAgenda) {
				state.shareAgenda.sort((a, b) => {
					const orderNumberA = Number.parseInt(a.orderNumber, 10);
					const orderNumberB = Number.parseInt(b.orderNumber, 10);

					return orderNumberA - orderNumberB;
				});
			}
		},
		updateNoteShareAccessShared: (state, action: PayloadAction<updateNoteAccess>) => {
			const { id, accessRightType } = action.payload;
			if (state.shareNotes) {
				const note = state.shareNotes.find((noteNT) => noteNT.id === id);
				if (note) {
					note.accessRightType = accessRightType;
				}
			}
		},

		updateTodoMeetingShared: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.shareTodos) {
				const todoIndex = state.shareTodos.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.shareTodos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.shareTodos = updatedTodos;
				}
			}
		},

		updateTodoMeetingPrevShared: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.shareTodosPrev) {
				const todoIndex = state.shareTodosPrev.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.shareTodosPrev];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.shareTodosPrev = updatedTodos;
				}
			}
		},

		updateTodoNotesShared: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.shareTodosNote) {
				const todoIndex = state.shareTodosNote.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.shareTodosNote];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.shareTodosNote = updatedTodos;
				}
			}
		},

		updateAgendaItemStateShared: (state, action: PayloadAction<UpdateAgendaItemPayload>) => {
			const { agendaId, newData } = action.payload;
			if (state.shareAgenda) {
				const agendaIndex = state.shareAgenda.findIndex((agenda) => agenda.id === agendaId);
				if (agendaIndex !== -1) {
					const updatedAgenda = [...state.shareAgenda];
					updatedAgenda[agendaIndex] = {
						...updatedAgenda[agendaIndex],
						...newData,
					};

					state.shareAgenda = updatedAgenda;
				}
			}
		},

		addPeopleShareFileShared: (state, action: PayloadAction<any>) => {
			const newPeople = action.payload;
			if (state.filesPeopleShare) {
				state.filesPeopleShare.push(newPeople);
			}
		},

		deletePeopleFileShared: (state, action: PayloadAction<string>) => {
			const mail = action.payload;
			if (state.filesPeopleShare) {
				state.filesPeopleShare = state.filesPeopleShare.filter((note) => note.mail !== mail);
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getShareDetails.pending, (state) => {
			state.shareMeetingRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getShareDetails.fulfilled, (state, action) => {
			state.shareMeetingRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareMeeting = action.payload;
		});
		builder.addCase(getShareDetails.rejected, (state, action) => {
			state.shareMeetingRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getSelectMeetingTodosForShared.pending, (state) => {
			state.shareTodosRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getSelectMeetingTodosForShared.fulfilled, (state, action) => {
			state.shareTodosRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareTodos = action.payload;
		});
		builder.addCase(getSelectMeetingTodosForShared.rejected, (state, action) => {
			state.shareTodosRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getSelectMeetingTodosForSharedPrev.pending, (state) => {
			state.shareTodosPrevRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getSelectMeetingTodosForSharedPrev.fulfilled, (state, action) => {
			state.shareTodosPrevRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareTodosPrev = action.payload;
		});
		builder.addCase(getSelectMeetingTodosForSharedPrev.rejected, (state, action) => {
			state.shareTodosPrevRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAllNoteForAMeetingShared.pending, (state) => {
			state.shareNotesRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllNoteForAMeetingShared.fulfilled, (state, action) => {
			state.shareNotesRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareNotes = action.payload;
		});
		builder.addCase(getAllNoteForAMeetingShared.rejected, (state, action) => {
			state.shareNotesRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAllNoteForAMeetingSharedPrev.pending, (state) => {
			state.shareNotesPrevRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllNoteForAMeetingSharedPrev.fulfilled, (state, action) => {
			state.shareNotesPrevRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareNotesPrev = action.payload;
		});
		builder.addCase(getAllNoteForAMeetingSharedPrev.rejected, (state, action) => {
			state.shareNotesPrevRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAgendaForAMeetingShared.pending, (state) => {
			state.shareAgendaRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAgendaForAMeetingShared.fulfilled, (state, action) => {
			state.shareAgendaRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareAgenda = action.payload;
		});
		builder.addCase(getAgendaForAMeetingShared.rejected, (state, action) => {
			state.shareAgendaRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getFilesForAMeetingShared.pending, (state) => {
			state.shareFilesRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getFilesForAMeetingShared.fulfilled, (state, action) => {
			state.shareFilesRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareFiles = action.payload;
		});
		builder.addCase(getFilesForAMeetingShared.rejected, (state, action) => {
			state.shareFilesRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getNotesTodosShared.pending, (state) => {
			state.shareTodosRequestStatusNote = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getNotesTodosShared.fulfilled, (state, action) => {
			state.shareTodosRequestStatusNote = createRequestStatus(RequestStatusType.Success);
			state.shareTodosNote = action.payload;
		});
		builder.addCase(getNotesTodosShared.rejected, (state, action) => {
			state.shareTodosRequestStatusNote = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getMeetingsCollaborators.pending, (state) => {
			state.shareCollaboratorsRequestStatusNote = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getMeetingsCollaborators.fulfilled, (state, action) => {
			state.shareCollaboratorsRequestStatusNote = createRequestStatus(RequestStatusType.Success);
			state.shareCollaborators = action.payload;
		});
		builder.addCase(getMeetingsCollaborators.rejected, (state, action) => {
			state.shareCollaboratorsRequestStatusNote = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getSharedMeetingPrev.pending, (state) => {
			state.shareMeetingDataPrevRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getSharedMeetingPrev.fulfilled, (state, action) => {
			state.shareMeetingDataPrevRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareMeetingDataPrev = action.payload;
		});
		builder.addCase(getSharedMeetingPrev.rejected, (state, action) => {
			state.shareMeetingDataPrevRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getSharedMeetingNext.pending, (state) => {
			state.shareMeetingDataNextRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getSharedMeetingNext.fulfilled, (state, action) => {
			state.shareMeetingDataNextRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareMeetingDataNext = action.payload;
		});
		builder.addCase(getSharedMeetingNext.rejected, (state, action) => {
			state.shareMeetingDataNextRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getSharedMeeting.pending, (state) => {
			state.shareMeetingDataRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getSharedMeeting.fulfilled, (state, action) => {
			state.shareMeetingDataRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.shareMeetingData = action.payload;
		});
		builder.addCase(getSharedMeeting.rejected, (state, action) => {
			state.shareMeetingDataRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getPeopleShareNote.pending, (state) => {
			state.sharedPeopleNoteRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getPeopleShareNote.fulfilled, (state, action) => {
			state.sharedPeopleNoteRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.sharedPeopleNote = action.payload;
		});
		builder.addCase(getPeopleShareNote.rejected, (state, action) => {
			state.sharedPeopleNoteRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getPeopleShareFileShare.pending, (state) => {
			state.filesPeopleRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getPeopleShareFileShare.fulfilled, (state, action) => {
			state.filesPeopleRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.filesPeopleShare = action.payload;
		});
		builder.addCase(getPeopleShareFileShare.rejected, (state, action) => {
			state.filesPeopleRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const {
	updateTodoTitle,
	updateTodoTitleNoteShared,
	updateNoteSharedState,
	addAgendaSelectedMeetingShare,
	addNotesSelectedMeetingShare,
	addTodosSelectedMeetingShare,
	updateTodoSharedState,
	deleteAgendaShare,
	deleteNoteShare,
	deleteTodosShare,
	deleteTodosShareNote,
	updateTodoSharedStateNote,
	AddCollaboratorShare,
	sortAgendaShared,
	updateNoteShareAccessShared,
	updateTodoMeetingPrevShared,
	updateTodoMeetingShared,
	updateTodoNotesShared,
	updateAgendaItemStateShared,
	addPeopleShareFileShared,
	deletePeopleFileShared,
} = actions;
export default reducer;
