import React from 'react';
import { Avatar } from '../../../../../shared/utils/avatar';
import { Domain } from '../../../../../shared/types/meetings-graph-interface';
import styles from './meeting-details-header-attendees.module.scss';
import { getFaviconUrl } from '../../../../../shared/utils/favicon';

interface props {
	emailAddresses?: {
		name: string;
		address: string;
		avatar?: string | undefined;
	}[];
	results?: {
		domain: string;
		emailAddresses: {
			name: string;
			address: string;
		}[];
	}[];
}

const AttendeesTab = ({ emailAddresses, results }: props) => {
	return (
		<div className={styles.attendeesList}>
			{emailAddresses &&
				emailAddresses.map((attendee: { name: string; address: string }, indexAt) => (
					<div key={indexAt} className={styles.flex}>
						<div style={{ width: '24px' }}>
							<Avatar name={attendee.name} mail={attendee.address} index={indexAt} />
						</div>

						<p>{attendee.name}</p>
					</div>
				))}

			{results &&
				results.map((result: Domain, index) => {
					const url = getFaviconUrl(result.emailAddresses[0].address);
					return (
						<div key={index}>
							<div>
								{result.emailAddresses.map((attendee: { name: string; address: string }, indexAt) => (
									<div className={styles.flex} key={indexAt}>
										<div style={{ width: '24px' }}>
											<div className={styles.domainModal}>
												<img src={url} alt='favico' />
											</div>
										</div>

										<p>{attendee.name}</p>
									</div>
								))}
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default AttendeesTab;
