import { People } from '../../store/notes/type';

export const extractDomain = (emailAddress: string) => {
	const domainRegex = /@([^.]+\.)*([^@.]+\.[^@.]+?)\.?$/;
	const matches = emailAddress.match(domainRegex);
	if (matches) {
		return matches[2].split('.')[0];
	} else {
		throw new Error('Invalid email address');
	}
};

export const extractDomainWithExt = (emailAddress: string) => {
	if (emailAddress) {
		const domainRegex = /@([^.]+\.)*([^@.]+\.[^@.]+?)\.?$/;

		const matches = emailAddress.match(domainRegex);

		if (matches && matches[2]) {
			return matches[2];
		} else {
			throw new Error('Invalid email address or no domain found');
		}
	}
};

export const sortByGroup = (peopleArray: People[]) => {
	return [...peopleArray].sort((a, b) => {
		const domainA = extractDomain(a.mail);
		const domainB = extractDomain(b.mail);

		if (domainA < domainB) {
			return -1;
		} else if (domainA > domainB) {
			return 1;
		} else {
			return 0;
		}
	});
};

export const groupByDomain = (peopleArray: People[]) => {
	const groupedPeople: { [key: string]: People[] } = {};

	peopleArray.forEach((person) => {
		const domain = extractDomain(person.mail);

		if (!groupedPeople[domain]) {
			groupedPeople[domain] = [];
		}

		groupedPeople[domain].push(person);
	});

	return groupedPeople;
};
