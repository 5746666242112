import React, { useRef, useState } from 'react';
import { TodosContract, TodosStates, UpdateTodo } from '../../../store/todos/type';
import styles from '../todos.module.scss';
import { useTranslation } from 'react-i18next';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import { SectionType, useUpdateTodoMutation } from '../../../shared/hooks/use-todos-mutations.hook';
import { PayloadAction } from '@reduxjs/toolkit';

interface Props {
    todo: TodosContract,
    sectionType: SectionType,
    updateFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>,
}

const StateCell: React.FC<Props> = ({ todo, sectionType, updateFn }) => {
    const { t } = useTranslation();

    const modalStateRef = useRef<HTMLDivElement | null>(null);
    const [openStatusMenu, setOpenStatusMenu] = useState<boolean>(false);
    const updateTodoStateMutation = useUpdateTodoMutation();

    useClickOutside(modalStateRef, () => setOpenStatusMenu(false));

    const handleStatusTodo = async (state: TodosStates) => {
        const body = {
            ...todo,
            state,
        };

        updateTodoStateMutation.mutate({ id: body.id, newTodo: body, oldTodo: todo, updateFn });
    };

    return (
        <div ref={modalStateRef} className={styles.todoRowColumn} onClick={(e) => {
            e.stopPropagation();
            setOpenStatusMenu(!openStatusMenu);
        }}
        >
            <div className={styles.todoRowColumnContent}>
                {todo.state === TodosStates.TODO && (
                    <div
                        className={`${styles.todoState} ${styles.todoLayout}`}
                    >
                        <div className={`${styles.todoStateIcon} ${styles.todoIcon}`} />
                        {t('À faire')}
                    </div>
                )}
                {todo.state === TodosStates.DOING && (
                    <div
                        className={`${styles.todoState} ${styles.doingLayout}`}
                    >
                        <div className={`${styles.todoStateIcon} ${styles.doingIcon}`} />
                        {t('En cours')}
                    </div>
                )}
                {todo.state === TodosStates.DONE && (
                    <div
                        className={`${styles.todoState} ${styles.doneLayout}`}
                    >
                        <div className={`${styles.todoStateIcon} ${styles.doneIcon}`} />
                        {t('Fini')}
                    </div>
                )}
                {todo.state === TodosStates.BLOCKED && (
                    <div
                        className={`${styles.todoState} ${styles.bloquedLayout}`}
                    >
                        <div className={`${styles.todoStateIcon} ${styles.bloquedIcon}`} />
                        {t('Bloqué')}
                    </div>
                )}
            </div>
            {openStatusMenu && (
                <ModalLayout>
                    <div className={styles.statusDropDownContainer}>
                        <div className={styles.statusDropDownItem} onClick={() => handleStatusTodo(TodosStates.TODO)}>
                            <div
                                className={`${styles.todoState} ${styles.todoLayout}`}
                            >
                                <div className={`${styles.todoStateIcon} ${styles.todoIcon}`} />
                                {t('À faire')}
                            </div>
                        </div>
                        <div className={styles.statusDropDownItem} onClick={() => handleStatusTodo(TodosStates.DOING)}>
                            <div
                                className={`${styles.todoState} ${styles.doingLayout}`}
                            >
                                <div className={`${styles.todoStateIcon} ${styles.doingIcon}`} />
                                {t('En cours')}
                            </div>
                        </div>
                        <div className={styles.statusDropDownItem} onClick={() => handleStatusTodo(TodosStates.DONE)}>
                            <div
                                className={`${styles.todoState} ${styles.doneLayout}`}
                            >
                                <div className={`${styles.todoStateIcon} ${styles.doneIcon}`} />
                                {t('Fini')}
                            </div>
                        </div>
                        <div className={styles.statusDropDownItem} onClick={() => handleStatusTodo(TodosStates.BLOCKED)}>
                            <div
                                className={`${styles.todoState} ${styles.bloquedLayout}`}
                            >
                                <div className={`${styles.todoStateIcon} ${styles.bloquedIcon}`} />
                                {t('Bloqué')}
                            </div>
                        </div>
                    </div>
                </ModalLayout>
            )}
        </div>
    );
};

export default StateCell;