import React, { useRef, useState } from 'react';
import { TodosContract, UpdateTodo } from '../../../store/todos/type';
import { getDateFormatL } from '../../../shared/utils/date.util';
import { t } from 'i18next';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import DateSelectorComponent from '../../meetings/modals/date-selector.component';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import styles from '../todos.module.scss';
import { SectionType, useUpdateTodoMutation } from '../../../shared/hooks/use-todos-mutations.hook';
import { PayloadAction } from '@reduxjs/toolkit';

interface Props {
    todo: TodosContract,
    sectionType: SectionType,
    updateFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>,
}

const DateCell: React.FC<Props> = ({ todo, sectionType, updateFn }) => {
    const [openDateMenu, setOpenDateMenu] = useState<boolean>(false);

    const dueDateRef = useRef<HTMLDivElement | null>(null);

    useClickOutside(dueDateRef, () => setOpenDateMenu(false));

    const updateDateTodoMutation = useUpdateTodoMutation();

    const handleDateSelect = (selectDate: Date, type: string | undefined) => {
        if (type === 'month') {
            return;
        }

        const body = {
            ...todo,
            dueDate: selectDate.toUTCString(),
        };

        updateDateTodoMutation.mutate({ id: body.id, newTodo: body, oldTodo: todo, updateFn });
        setOpenDateMenu(false);
    };

    return (
        <div ref={dueDateRef} className={styles.todoRowColumn}>
            <div className={styles.todoRowColumnContent} onClick={() => setOpenDateMenu(!openDateMenu)}>
                {todo.dueDate === 'select' ||
                    todo.dueDate === 'Invalid Date' ||
                    todo.dueDate === null ||
                    todo.dueDate === undefined ||
                    !todo.dueDate ? (
                    <span className={styles.noSelectedDate}>{t('No due date')}</span>
                ) : (
                    <>
                        <span>{getDateFormatL(todo.dueDate)}</span>
                    </>
                )}
            </div>
            {openDateMenu &&
                <ModalLayout>
                    <DateSelectorComponent selectedDate={todo.dueDate ? new Date(todo.dueDate) : new Date()} onDateSelect={handleDateSelect} type='agenda' />
                </ModalLayout>
            }
        </div>
    );
};

export default DateCell;