import { useEffect, useRef, useState } from 'react';
import { NotificationsContract } from '../../store/notifications/type';
import { getNotificationMessage } from '../utils/notifications-message';

export const useDesktopNotification = (notifications: NotificationsContract[] | undefined) => {
    const [_, setDisplayedNotification] = useState<string[] | undefined>(undefined);
    const hasBeenMounted = useRef(false);

    const sendDesktopNotification = () => {
        if (!notifications) return;

        notifications?.forEach((notification) => {
            setDisplayedNotification((prev) => {
                if (!prev?.includes(notification.id)) {
                    const notificationMessage = getNotificationMessage(notification);
                    const { ipcRenderer } = window.electron;
                    ipcRenderer.send('show-notification', { title: notificationMessage.title, body: notificationMessage.message });

                    return [...(prev ?? []), notification.id];
                }

                return prev;
            });
        });

    };

    useEffect(() => {
        if (!window.electron) {
            return;
        }

        if (!hasBeenMounted.current) {
            hasBeenMounted.current = true;
            return;
        }

        sendDesktopNotification();
    }, [notifications]);

    return {
        sendDesktopNotification,
    };
};