import React from 'react';
import styles from './meeting-details-header-attendees.module.scss';
import { Attendees, Domain } from '../../../../../shared/types/meetings-graph-interface';
import { MeetingDetailsProps, Tab } from '../../../../../shared/types/component-interface';
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';
import AttendeesTab from './attendees-tab';

// ----

const AttendeesModal = ({ meeting }: MeetingDetailsProps) => {
	const { t } = useTranslation();

	const domains = Object.keys(meeting.group);
	const results = domains.map((domain) => ({
		domain,
		emailAddresses: meeting.group[domain].map((item: Attendees) => ({
			name: item.emailAddress.name,
			address: item.emailAddress.address,
		})),
	}));

	// --------------

	const [activeTabIndex, setActiveTabIndex] = React.useState(0);

	const [tabs] = React.useState<Tab[]>([
		{
			label: t('All'),
			component: <AttendeesTab results={results} />,
		},
		...results.map((result: Domain) => ({
			label: result.domain,
			component: <AttendeesTab emailAddresses={result.emailAddresses} />,
		})),
	]);

	return (
		<>
			<div className={styles.modal}>
				<div className={styles.tabs}>
					{tabs.map((tab, index) => (
						<a
							style={{ display: 'flex', gap: '10px' }}
							key={index}
							onClick={() => setActiveTabIndex(index)}
							className={index === activeTabIndex ? styles.activeTab : ''}>
							{tab.label}
						</a>
					))}
				</div>
				<div>{tabs[activeTabIndex].component}</div>
			</div>
		</>
	);
};

export default AttendeesModal;
