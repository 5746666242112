import React, { CSSProperties, MouseEventHandler } from 'react';
import { IconSimplePlus } from '../../../utils/icon';
import styles from './rounded-btn.module.scss';

interface Props {
    onClick: MouseEventHandler,
    style?: CSSProperties,
}

const RoundedButton: React.FC<Props> = ({ onClick, style }) => {
    
    return (
        <div className={styles.roundedBtn} onClick={onClick} style={style}>
            <IconSimplePlus />
        </div>
    );
};

export default RoundedButton;