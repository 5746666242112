import React from 'react';
import { App } from './app/app';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import './index.scss';

import { createRoot } from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { AuthProvider as GoogleAuthProvider, useAuth } from 'react-oidc-context';
import { googleAuthConfig } from './app/config/google-auth-config.';
import { msalInstance } from './app/config/msal-auth-config.';
import { RuntimeConfig } from './app/config/runtime-config';

import * as Sentry from '@sentry/react';

import {
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

Sentry.init({
	dsn: RuntimeConfig.getSentryDSN(),
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		}
	}
});

const container = document.querySelector('#root');
const root = createRoot(container!);

root.render(
	<>
		<Provider store={store}>
			<GoogleAuthProvider {...googleAuthConfig}>
				<MsalProvider instance={msalInstance}>
					<QueryClientProvider client={queryClient}>
						<App i18n={i18n} />
					</QueryClientProvider>
				</MsalProvider>
			</GoogleAuthProvider>
		</Provider>
	</>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
