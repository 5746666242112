import React from 'react';
import styles from './hours-header.component.module.scss';

interface HoursHeaderProps {
	hours: string[];
}

const HoursHeaderComponent: React.FC<React.PropsWithChildren<HoursHeaderProps>> = ({ hours }) => {
	return (
		<>
			<div className={styles.hours}>
				{hours.map((hour, index) => (
					<div key={index} className={styles.hour}>
						<span>{hour}</span>
					</div>
				))}
			</div>
		</>
	);
};

export default HoursHeaderComponent;
