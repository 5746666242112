import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ErrorUtil from '../../shared/utils/error.util';
import { createRequestStatus, RequestStatus, RequestStatusType } from '../shared/types';
import {
	UserContract,
	UpdateCurrentUserContract,
	ContactsGraphContract,
	TourUpdate,
	UpdateCurrentUserColContract,
	UsersCompanyContract,
} from './type';
import UsersAPI from '../../api/user-api';
import { TagContract } from '../meetings/types';

interface UserState {
	getCurrentUserRequestStatus: RequestStatus;
	updateCurrentUserRequestStatus: RequestStatus;
	user?: UserContract;
	contacts?: ContactsGraphContract[];
	getContactsRequestStatus: RequestStatus;
	usersJobList?: UsersCompanyContract[];
	usersJobListRequestStatus: RequestStatus;
}

const initialState: UserState = {
	user: undefined,
	getCurrentUserRequestStatus: createRequestStatus(RequestStatusType.New),
	updateCurrentUserRequestStatus: createRequestStatus(RequestStatusType.New),
	contacts: undefined,
	getContactsRequestStatus: createRequestStatus(RequestStatusType.New),
	usersJobList: undefined,
	usersJobListRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async (): Promise<UserContract> => {
	return UsersAPI.getCurrentUser();
});

export const updateCurrentUser = createAsyncThunk(
	'user/updateCurrentUser',
	async (updateCurrentUserContract: UpdateCurrentUserContract): Promise<UserContract> => {
		return UsersAPI.updateCurrentUser(updateCurrentUserContract);
	},
);

export const updateCurrentUserTour = createAsyncThunk(
	'user/current/tour',
	async (tour: TourUpdate): Promise<UserContract> => {
		return UsersAPI.updateCurrentUserTour(tour);
	},
);

export const getUserByID = createAsyncThunk('user/getUserByID', async (userMail: string): Promise<UserContract> => {
	return UsersAPI.getUserByID(userMail);
});

export const getCurrentUserContacts = createAsyncThunk(
	'user/getCurrentUserContacts',
	async (filter: string): Promise<ContactsGraphContract[]> => {
		return UsersAPI.getCurrentUserContacts(filter);
	},
);

export const checkUserFromFivedays = createAsyncThunk('user/check/fivedays', async (mail: string): Promise<boolean> => {
	return UsersAPI.checkUser(mail);
});

export const updateCurrentUserCol = createAsyncThunk(
	'user/updateCurrentUser/col/todo',
	async (body: UpdateCurrentUserColContract): Promise<UserContract> => {
		return UsersAPI.updateCurrentUserCol(body);
	},
);

export const getAllUsersJobList = createAsyncThunk(
	'user/fivedays/jobList',
	async (): Promise<UsersCompanyContract[]> => {
		return UsersAPI.getUsersJobList();
	},
);

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUserTags: (state, action: PayloadAction<TagContract>) => {
			const newTag = action.payload;
			if (state.user) state.user.tags = [...state.user.tags, newTag];
		},
		updateUserTour: (state, action: PayloadAction<boolean>) => {
			if (state.user) state.user.appTour = action.payload;
		},
		updateUserCreditCopilot: (state, action: PayloadAction<number>) => {
			if (state.user) state.user.creditCopilot = action.payload;
		},
		updateUserCol: (state, action: PayloadAction<UpdateCurrentUserColContract>) => {
			const body = action.payload;
			if (state.user) {
				state.user.todoCol = body.todoCol;
				state.user.doingCol = body.doingCol;
				state.user.blockedCol = body.blockedCol;
				state.user.doneCol = body.doneCol;
			}
		},
		addNewContact: (state, action: PayloadAction<ContactsGraphContract>) => {
			const newContact = action.payload;
			if (state.contacts) {
				state.contacts.push(newContact);
			}
		},
		addNewContacts: (state, action: PayloadAction<ContactsGraphContract[]>) => {
			const newContacts = action.payload;

			if (state.contacts) {
				// Remove duplicates from newContacts before adding
				const uniqueNewContacts = newContacts.filter(
					(newContact) =>
						!state.contacts?.some(
							(contact) => contact.emailAddresses[0].address === newContact.emailAddresses[0].address,
						),
				);

				return {
					...state,
					contacts: [...state.contacts, ...uniqueNewContacts],
				};
			}

			return {
				...state,
				contacts: newContacts,
			};
		},
	},
	extraReducers(builder): void {
		builder.addCase(getCurrentUser.pending, (state) => {
			state.getCurrentUserRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCurrentUser.fulfilled, (state, action) => {
			state.getCurrentUserRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.user = action.payload;
		});
		builder.addCase(getCurrentUser.rejected, (state, action) => {
			state.getCurrentUserRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
		builder.addCase(updateCurrentUser.pending, (state) => {
			state.updateCurrentUserRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(updateCurrentUser.fulfilled, (state, action) => {
			state.user = action.payload;
			state.updateCurrentUserRequestStatus = createRequestStatus(RequestStatusType.Success);
		});
		builder.addCase(updateCurrentUser.rejected, (state, action) => {
			state.updateCurrentUserRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getCurrentUserContacts.pending, (state) => {
			state.getContactsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCurrentUserContacts.fulfilled, (state, action) => {
			state.contacts = action.payload;
			state.getContactsRequestStatus = createRequestStatus(RequestStatusType.Success);
		});
		builder.addCase(getCurrentUserContacts.rejected, (state, action) => {
			state.getContactsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAllUsersJobList.pending, (state) => {
			state.usersJobListRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllUsersJobList.fulfilled, (state, action) => {
			state.usersJobList = action.payload;
			state.usersJobListRequestStatus = createRequestStatus(RequestStatusType.Success);
		});
		builder.addCase(getAllUsersJobList.rejected, (state, action) => {
			state.usersJobListRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const { updateUserTags, addNewContact, addNewContacts, updateUserCol, updateUserCreditCopilot } = actions;
export default reducer;
