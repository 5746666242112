import { TagContract } from '../meetings/types';

export class TodosContract {
	constructor(
		public id: string,
		public title: string,
		public text: string,
		public state: TodosStates,
		public dueDate: string,
		public duration: number,
		public graphUserId: string,
		public noteId: string,
		public assigneeDisplayName: string,
		public assigneeName: string,
		public graphiCalUId: string,
		public meetingGraphId: string,
		public meetingName: string,
		public meetingStartDate: string,
		public createdOn: Date,
		public tags: TagContract[],
		public archived: boolean,
		public projectId: string,
		public orderAssignee?: string,
		public orderProject?: string,
		public meetingAttendees?: string[],
		public columnUserId?: string | null,
		public columnProjectId?: string | null,
	) { }

	public static toArchived(todo: TodosContract): TodosContract {
		return new TodosContract(
			todo.id,
			todo.title,
			todo.text,
			todo.state,
			todo.dueDate,
			todo.duration,
			todo.graphUserId,
			todo.noteId,
			todo.assigneeDisplayName,
			todo.assigneeName,
			todo.graphiCalUId,
			todo.meetingGraphId,
			todo.meetingName,
			todo.meetingStartDate,
			todo.createdOn,
			[],
			todo.archived === false ? true : false,
			todo.projectId,
		);
	}

	public static toNewState(todo: TodosContract, state: TodosStates): TodosContract {
		return new TodosContract(
			todo.id,
			todo.title,
			todo.text,
			state,
			todo.dueDate,
			todo.duration,
			todo.graphUserId,
			todo.noteId,
			todo.assigneeDisplayName,
			todo.assigneeName,
			todo.graphiCalUId,
			todo.meetingGraphId,
			todo.meetingName,
			todo.meetingStartDate,
			todo.createdOn,
			[],
			todo.archived,
			todo.projectId,
		);
	}
}

export class TodosContractCreate {
	constructor(
		public id: string | undefined,
		public title: string | undefined,
		public text: string | undefined,
		public state: TodosStates | undefined,
		public dueDate: string | undefined,
		public duration: number | undefined,
		public graphUserId: string | undefined,
		public noteId: string | undefined,
		public assigneeDisplayName: string | undefined,
		public graphiCalUId: string | undefined,
		public meetingGraphId: string | undefined,
		public meetingName: string | undefined,
		public meetingStartDate: string | undefined,
		public createdOn: Date | undefined,
		public tags: TagContract[] | undefined,
		public projectId: string | undefined,
		public assigneeName?: string,
		public requestId?: string,
	) { }
}

export class TodoAttendee {
	constructor(
		public email: string,
		public userName: string,
	) { }
};

export class TodosContractComplete {
	constructor(public state: TodosStates | undefined) { }
}

export enum TodosStates {
	TODO = 'TO_DO',
	DONE = 'DONE',
	DOING = 'DOING',
	BLOCKED = 'BLOCKED',
}

export interface UpdateTitlePayload {
	todoId: string;
	newTitle: string;
}

export interface UpdateDatePayload {
	todoId: string;
	newDate: string;
}

export interface UpdateTagPayload {
	todoId: string,
	tag: TagContract,
}

export interface UpdateStatePayload {
	todoId: string;
	stateTodo: TodosStates;
	index?: number;
	indexProject?: number;
	userCol?: string | null;
}

export interface UpdateTodo {
	todoId: string;
	newTodo: TodosContract;
}
