import React from 'react';
import { MeetingContract } from '../../../store/meetings/types';
import styles from './meeting-invite.modal.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AddCollaboratorShare, getMeetingsCollaborators, shareMeeting } from '../../../store/sharing/slice';
import { toast } from '../../../shared/components/modals/toast/toast-manager';
import { Avatar } from '../../../shared/utils/avatar';
import illustration from '../../../../images/illustration/account-group-4 1.svg';
import { IconClose } from '../../../shared/utils/icon';

interface Props {
	meeting: MeetingContract;
	nonUserAttendees: { type: string; emailAddress: { name: string; address: string } }[];
	close: (start: string, id: string) => void;
}

const MeetingFormModalInvite: React.FC<Props> = ({ meeting, nonUserAttendees, close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { shareCollaborators } = useAppSelector((state) => state.sharing);
	const [link, setLink] = React.useState('');
	const [selectedAttendees, setSelectedAttendees] = React.useState<string[]>([]);

	const handleShareMeeting = () => {
		selectedAttendees.forEach((mail) => {
			dispatch(shareMeeting({ eventId: meeting.graphEventId, mail })).then((res) => {
				dispatch(AddCollaboratorShare(mail));
				toast.show({
					id: user?.graphUserId,
					title: t('Invitation send') as string,
					duration: 10000,
					type: 'success',
				});
				setLink(res.payload as string);
			});
		});
		dispatch(getMeetingsCollaborators(meeting.iCalUId));
		close(meeting.start, meeting.iCalUId);
	};

	const handleCheckboxChange = (mail: string) => {
		setSelectedAttendees((prevSelected) => {
			return prevSelected.includes(mail) ? prevSelected.filter((item) => item !== mail) : [...prevSelected, mail];
		});
	};

	return (
		<>
			<div className={styles.main}>
				<div className={styles.modal}>
					<div onClick={() => close(meeting.start, meeting.iCalUId)} className={styles.close}>
						<IconClose />
					</div>
					<div className={styles.in}>
						<div className={styles.left}>
							<h2>{t('Collaborate on Fivedays')}</h2>
							<p className={styles.text}>{t('These people do not have access to Fivedays, ')}</p>
							<p className={styles.text}>
								{t('would you like to invite them to collaborate to the meeting?')}
							</p>
							<div className={styles.form}>
								<div className={styles.send}>
									{nonUserAttendees.some(
										(attendee) =>
											attendee.emailAddress.address.toLowerCase() !== user?.email.toLowerCase() &&
											!shareCollaborators?.includes(attendee.emailAddress.address.toLowerCase()),
									) && (
										<div className={styles.mails}>
											<input
												type='checkbox'
												checked={selectedAttendees.length === meeting.attendees.length}
												onChange={() => {
													if (selectedAttendees.length === meeting.attendees.length) {
														setSelectedAttendees([]);
													} else {
														setSelectedAttendees(
															meeting.attendees.map(
																(attendee) => attendee.emailAddress.address,
															),
														);
													}
												}}
											/>
											<div className={styles.mail}>
												<span>{t('Select All')}</span>
											</div>
										</div>
									)}

									{nonUserAttendees
										.filter(
											(attendee) =>
												attendee.emailAddress.address.toLowerCase() !==
													user?.email.toLowerCase() &&
												!shareCollaborators?.includes(
													attendee.emailAddress.address.toLowerCase(),
												),
										)
										.map((attendee, index) => (
											<div className={styles.mails} key={index}>
												<input
													type='checkbox'
													checked={selectedAttendees.includes(attendee.emailAddress.address)}
													onChange={() => handleCheckboxChange(attendee.emailAddress.address)}
												/>
												<div className={styles.mail}>
													<Avatar
														name={attendee.emailAddress.name}
														mail={attendee.emailAddress.address}
														index={index}
													/>
													<span>{attendee.emailAddress.name}</span>
													<span className={styles.grey}>{attendee.emailAddress.address}</span>
												</div>
											</div>
										))}
								</div>
								<div className={styles.buttons}>
									<button onClick={handleShareMeeting}>{t('Invite to collaborate')}</button>
									<button
										className={styles.cancel}
										onClick={() => close(meeting.start, meeting.iCalUId)}>
										{t('No, go to next step')}
									</button>
								</div>
							</div>
						</div>
						<div className={styles.right}>
							<div className={styles.flex}>
								<div className={styles.image}>
									<img src={illustration} alt='illustration' />
								</div>
								<h2>{t('What happen if I invite them?')}</h2>
								<div className={styles.lign}></div>
								<p>{t('They will be able to collaborate on this meeting as invitee')}</p>
								<p>{t('Users invited will not be charged on your subscription')}</p>
								<p className={styles.link}>{t('Know more')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MeetingFormModalInvite;
