import React from 'react';
import styles from './settings-user-management.component.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../../../store/user/type';
import { getCompanyForUser } from '../../../../store/settings/slice';
import { toast } from '../../../../shared/components/modals/toast/toast-manager';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconClose } from '../../../../shared/utils/icon';
import { sendInvitationUser } from '../../../../store/invitations/slice';
import { InvitationSendContract, InvitationSendInvalidReason } from '../../../../store/invitations/type';
import { extractDomain } from '../../../../shared/utils/domainext';

interface Props {
	close: () => void;
}

const SettingsUserInvite: React.FC<Props> = ({ close }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const [userRole, setUserRole] = React.useState<string[]>(['User']);

	const [animation, setAnimation] = React.useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			close();
			setAnimation(false);
		}, 300);
	};

	const [userDomain, setUserDomain] = React.useState<string>('');

	React.useEffect(() => {
		if (user) {
			const domain = extractDomain(user.email);
			setUserDomain(domain);
		}
	}, [user, setUserDomain]);

	type InviteFormValues = {
		mailInvite: string;
	};

	const inviteFormSchema = yup.object().shape({
		mailInvite: yup.string().required(),
	});

	const { register: registerInviteForm, handleSubmit: handleSubmitInviteForm } = useForm<InviteFormValues>({
		resolver: yupResolver(inviteFormSchema),
	});

	const handleInviteUser = (data: InviteFormValues) => {
		const body = {
			mail: data.mailInvite,
			role: UserRole.USER,
			companyId: undefined,
		};

		const mailInviteDomain = extractDomain(data.mailInvite);

		if (mailInviteDomain) {
			user &&
				dispatch(sendInvitationUser(body)).then((result) => {
					closeModal();
					dispatch(getCompanyForUser());

					if ((result.payload as InvitationSendContract).success === true) {
						toast.show({
							id: user?.graphUserId,
							title: t('Invitation send') as string,
							duration: 10000,
							type: 'success',
						});
					} else if ((result.payload as InvitationSendContract).success === false) {
						if (
							(result.payload as InvitationSendContract).invalidReason ===
							InvitationSendInvalidReason.EXISTING_USER
						) {
							toast.show({
								id: user?.graphUserId,
								title: t('This user already exist') as string,
								duration: 10000,
								type: 'failed',
							});
						} else {
							toast.show({
								id: user?.graphUserId,
								title: t('Something went wrong') as string,
								duration: 10000,
								type: 'failed',
							});
						}
					}
				});
		} else {
			toast.show({
				id: user?.graphUserId,
				title: t('Please only invite user from your own domain') as string,
				duration: 10000,
				type: 'failed',
			});
		}
	};

	return (
		<div className={`${styles.update} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div
					className={styles.icon}
					onClick={() => {
						closeModal();
					}}>
					<IconClose />
				</div>
				<h1>{t('Invite user')}</h1>
				<form onSubmit={handleSubmitInviteForm(handleInviteUser)}>
					<div className={styles.inputWrapper}>
						<label>{t('Email address')}</label>
						<input type='email' {...registerInviteForm('mailInvite')} defaultValue={''} />
					</div>
					<br />
					<button type='submit'>{t('Save')}</button>
					<button
						className={styles.cancel}
						type='button'
						onClick={() => {
							closeModal();
						}}>
						{t('Cancel')}
					</button>
				</form>
			</div>
		</div>
	);
};

export default SettingsUserInvite;
