import React from 'react';
import { ShimmerDiv } from 'shimmer-effects-react';

interface Props {
	width: number;
	height: number;
	col1?: string;
	col2?: string;
	colVia?: string;
	rounded?: number;
}

const ShimmerText: React.FC<Props> = ({
	width,
	height,
	rounded = 2,
	col1 = '#F7F7F7',
	col2 = '#F7F7F7',
	colVia = 'white',
}) => {
	return (
		<ShimmerDiv mode='custom' height={height} width={width} rounded={rounded} from={col1} via={colVia} to={col2} />
	);
};

export default ShimmerText;
