import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../store/hooks';
import styles from './project-description.module.scss';
import { IconMeetings, IconTag, IconText } from '../../../../../shared/utils/icon';
import { getDateFormatWithYear } from '../../../../../shared/utils/date.util';
import { hexToRgb } from '../../../../../shared/utils/hextorgb';

const ProjectDescription = () => {
	const { t } = useTranslation();
	const { selectProject } = useAppSelector((state) => state.project);

	let text = '';

	if (selectProject) {
		const data = selectProject.text !== '' ? JSON.parse(selectProject.text) : undefined;

		if (data && data.content && Array.isArray(data.content)) {
			for (const content of data.content) {
				if (content && content.content && Array.isArray(content.content) && content.content.length > 0) {
					text += content.content[0].text || '';
				}
			}
		}
	}

	return (
		<div className={styles.body}>
			<div className={styles.info}>
				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconText />
						</div>
						{t('Description')}
					</div>
					<div className={styles.des}>{selectProject && <p>{text}</p>}</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconMeetings />
						</div>
						{t('Created on')}
					</div>
					<div className={styles.des}>
						{selectProject && getDateFormatWithYear(selectProject.createdOn.toString())}
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconTag />
						</div>
						{t('Tags')}
					</div>
					<div className={styles.des}>
						<div className={styles.tags}>
							{selectProject?.tags?.map((tag, index) => (
								<div
									key={index}
									className={styles.tag}
									style={{
										backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
										border: `1px solid ${tag.color}`,
									}}>
									<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectDescription;
