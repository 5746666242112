import { createTheme } from '@fluentui/react';

export const calendar = createTheme({
	palette: {
		themePrimary: '#00ad56',
		themeLighterAlt: '#f7fbf7',
		themeLighter: '#e1efe2',
		themeLight: '#c7e1c9',
		themeTertiary: '#97c39a',
		themeSecondary: '#6fa773',
		themeDarkAlt: '#578c5a',
		themeDark: '#49764c',
		themeDarker: '#365738',
		neutralLighterAlt: '#faf9f8',
		neutralLighter: '#f3f2f1',
		neutralLight: '#edebe9',
		neutralQuaternaryAlt: '#e1dfdd',
		neutralQuaternary: '#d0d0d0',
		neutralTertiaryAlt: '#c8c6c4',
		neutralTertiary: '#a19f9d',
		neutralSecondary: '#605e5c',
		neutralPrimaryAlt: '#3b3a39',
		neutralPrimary: '#323130',
		neutralDark: '#201f1e',
		black: '#201F1E',
		white: '#ffffff',
	},
});
