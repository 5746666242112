export class DataMeetingBotContract {
	constructor(
		public id: string,
		public graphiCalUId: string,
		public onlineMeetingUrl: string,
		public meetingStartDate: string,
		public meetingEndDate: string,
		public botId: string,
		public userGraphId: string,
		public userMail: string,
		public meetingName: string,
		public createdOn: Date,
		public updatedOn: Date,
		public status?: string,
	) {}
}

export class BotNotesContract {
	constructor(
		public id: string,
		public graphiCalUId: string,
		public onlineMeetingUrl: string,
		public meetingStartDate: string,
		public meetingEndDate: string,
		public botId: string,
		public note: string,
		public jsonDataBot: object,
		public transcript: string,
		public speakerTimeline: object,
		public objectTranscript: JsonOutputTranscript[],
		public createdOn: Date,
		public updatedOn: Date,
	) {}
}

export class BotTodosContract {
	constructor(
		public id: string,
		public graphiCalUId: string,
		public botId: string,
		public todos: object,
		public createdOn: Date,
		public updatedOn: Date,
	) {}
}

export enum BotStatus {
	ready = 'ready',
	joining_call = 'joining_call',
	in_waiting_room = 'in_waiting_room',
	in_call_recording = 'in_call_recording',
	in_call_not_recording = 'in_call_not_recording',
	call_ended = 'call_ended',
	done = 'done',
	analysis_done = 'analysis_done',
	analysis_failed = 'analysis_failed',
	recording_done = 'recording_done',
	media_expired = 'media_expired',
	participant_in_waiting_room = 'participant_in_waiting_room',
}

export class JsonOutputTranscript {
	constructor(
		public index: number,
		public text: string,
		public name: string,
		public position: string,
		public startTimesStamp: number,
		public endTimesStamp: number,
	) {}
}

export interface MistralCall {
	botId: string;
	question: string;
	transcript: string;
}

export interface MistralTodoUpdate {
	id: string;
	newAttName: string;
	newAttMail: string;
	newTitle: string;
	projectId: string;
}

export interface MistralTodos {
	todo: string;
	assignee: string;
}
