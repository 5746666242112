import React, { useEffect } from 'react';
import styles from './account.module.scss';
import { useTranslation } from 'react-i18next';
import MyPreferences from './components/my-preferences.component';
import { Tab } from '../../shared/types/component-interface';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { useAppDispatch } from '../../store/hooks';
// import EmailPreferences from './components/email-preferences.component';

function AccountPage() {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
	}, [dispatch]);

	const [activeTabIndex, setActiveTabIndex] = React.useState(0);

	const [tabs] = React.useState<Tab[]>([
		{ label: t('My preferences'), component: <MyPreferences /> },
		// {
		// 	label: t('Email preferences'),
		// 	component: <EmailPreferences />,
		// },
	]);

	return (
		<div className={styles.container}>
			<div>
				<h1>{t('My account')}</h1>
				<div className={styles.tabs}>
					{tabs.map((tab, index) => (
						<a
							key={tab.label}
							onClick={() => setActiveTabIndex(index)}
							className={index === activeTabIndex ? styles.activeTab : ''}>
							{tab.label}
						</a>
					))}
				</div>
			</div>
			{tabs[activeTabIndex].component}
		</div>
	);
}

export default AccountPage;
