import { CheckTokenContract, SharingDataContract } from '../pages/publicMeeting/types/confirm-mail-step';
import HttpClient from '../shared/http/http-client';
import { AgendaContract } from '../store/agenda/type';
import { FileContract } from '../store/meetings/types';
import { NotesContract, People } from '../store/notes/type';
import { UpdateMeetingFilesAccess } from '../store/shared/types';
import { SharingContract, SharingContractForMap } from '../store/sharing/type';
import { TodosContract, TodosContractComplete, TodosContractCreate } from '../store/todos/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';

export default class SharingAPI {
	public static async ShareMeeting(eventId: string, email: string): Promise<SharingContract> {
		const { data } = await HttpClient.get<SharingContract>(
			ApiUrls.ShareMeeting,
			new UrlParamBuilder().addEventId(eventId).addUserMail(email).build(),
		);
		return data;
	}

	public static async getShareDetails(shareId: string): Promise<SharingContractForMap> {
		const { data } = await HttpClient.get<SharingContractForMap>(
			ApiUrls.GetShareDetail,
			new UrlParamBuilder().addShareId(shareId).build(),
		);
		return data;
	}

	public static async sendToken(shareId: string, email: string): Promise<SharingDataContract> {
		const { data } = await HttpClient.get<SharingDataContract>(
			ApiUrls.SendTokenRequest,
			new UrlParamBuilder().addShareId(shareId).addUserMail(email).build(),
		);
		return data;
	}

	public static async checkToken(shareId: string, code: string): Promise<CheckTokenContract> {
		const { data } = await HttpClient.get<CheckTokenContract>(
			ApiUrls.CheckSecurityCode,
			new UrlParamBuilder().addShareId(shareId).addCode(code).build(),
		);
		return data;
	}

	public static async getNewToken(shareId: string, mail: string): Promise<undefined> {
		const { data } = await HttpClient.get<undefined>(
			ApiUrls.RequestNewToken,
			new UrlParamBuilder().addUserMail(mail).addShareId(shareId).build(),
		);
		return data;
	}

	public static async getShareMeeting(shareId: string): Promise<undefined> {
		const { data } = await HttpClient.get<undefined>(
			ApiUrls.GetSharedMeeting,
			new UrlParamBuilder().addShareId(shareId).build(),
		);
		return data;
	}

	public static async getSelectMeetingTodos(eventId: string): Promise<TodosContract[]> {
		const { data } = await HttpClient.get<TodosContract[]>(
			ApiUrls.GetSelectMeetingTodosForShared,
			new UrlParamBuilder().addEventiCalUId(eventId).build(),
		);
		return data;
	}

	public static async getAllNotesForAMeeting(iCalUId: string, userId: string): Promise<NotesContract[]> {
		const { data } = await HttpClient.get<NotesContract[]>(
			ApiUrls.GetSelectMeetingNotesForShared,
			new UrlParamBuilder().addEventiCalUId(iCalUId).addUserId(userId).build(),
		);
		return data;
	}

	public static async getAgendaItemsForAMeeting(iCalUId: string): Promise<AgendaContract[]> {
		const { data } = await HttpClient.get<AgendaContract[]>(
			ApiUrls.GetSelectMeetingAgendaForShared,
			new UrlParamBuilder().addEventiCalUId(iCalUId).build(),
		);
		return data;
	}

	public static async getFilesForAMeeting(iCalUId: string, userId: string): Promise<FileContract[]> {
		const { data } = await HttpClient.get<FileContract[]>(
			ApiUrls.GetSelectMeetingFilesShared,
			new UrlParamBuilder().addEventiCalUId(iCalUId).addUserId(userId).build(),
		);
		return data;
	}

	public static async downloadFileMeetingShare(selectedMeetingID: string, fileId: string): Promise<Blob> {
		const { data } = await HttpClient.getFile<Blob>(
			ApiUrls.DownloadSelectMeetingFilesShare,
			new UrlParamBuilder().addEventiCalUId(selectedMeetingID).addFileId(fileId).build(),
		);
		return data;
	}

	public static async getNotesTodosShare(notesId: string): Promise<TodosContract[]> {
		const { data } = await HttpClient.get<TodosContract[]>(
			ApiUrls.GetTodosForNotesShare,
			new UrlParamBuilder().addNotesId(notesId).build(),
		);
		return data;
	}

	public static async createTodo(body: TodosContractCreate, shareId: string): Promise<TodosContractCreate> {
		const { data } = await HttpClient.post<TodosContractCreate, TodosContract>(
			ApiUrls.ShareMeetingPostTodo,
			body,
			new UrlParamBuilder().addShareId(shareId).build(),
		);
		return data;
	}

	public static async updateTodo(body: TodosContractCreate, todoId: string): Promise<TodosContractCreate> {
		const { data } = await HttpClient.patch<TodosContractCreate, TodosContract>(
			ApiUrls.ShareMeetingUpdateTodo,
			body,
			new UrlParamBuilder().addtodoId(todoId).build(),
		);
		return data;
	}

	public static async deleteTodo(todoId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.ShareMeetingDeleteTodo,
			new UrlParamBuilder().addtodoId(todoId).build(),
		);
		return data;
	}

	public static async createAgendaItem(body: AgendaContract): Promise<AgendaContract> {
		const { data } = await HttpClient.post<AgendaContract, AgendaContract>(ApiUrls.ShareMeetingPostAgenda, body);
		return data;
	}

	public static async deleteAgendaItem(itemId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.ShareMeetingDeleteAgenda,
			new UrlParamBuilder().addItemId(itemId).build(),
		);
		return data;
	}

	public static async updateAgendaItem(body: AgendaContract): Promise<AgendaContract> {
		const { data } = await HttpClient.patch<AgendaContract, AgendaContract>(ApiUrls.ShareMeetingPatchAgenda, body);
		return data;
	}

	public static async createNotes(body: NotesContract, shareId: string): Promise<NotesContract> {
		const { data } = await HttpClient.post<NotesContract, NotesContract>(
			ApiUrls.ShareMeetingPostNote,
			body,
			new UrlParamBuilder().addShareId(shareId).build(),
		);
		return data;
	}

	public static async deleteNote(noteId: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.ShareMeetingDeleteNote,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}

	public static async updateNote(noteId: string, body: NotesContract): Promise<NotesContract> {
		const { data } = await HttpClient.patch<NotesContract, NotesContract>(
			ApiUrls.ShareMeetingUpdateNote,
			body,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}

	public static async addFileToMeeting(body: FormData, selectedMeetingID: string): Promise<FormData> {
		const { data } = await HttpClient.postFile<FormData, FormData>(
			ApiUrls.ShareMeetingPostFiles,
			body,
			new UrlParamBuilder().addEventiCalUId(selectedMeetingID).build(),
		);
		return data;
	}

	public static async deleteFileToMeeting(
		selectedMeetingID: string,
		fileId: string,
		userId: string,
	): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.ShareMeetingDeleteFiles,
			new UrlParamBuilder().addEventiCalUId(selectedMeetingID).addFileId(fileId).addUserId(userId).build(),
		);
		return data;
	}

	public static async updateFileToMeetingAccess(
		body: UpdateMeetingFilesAccess,
		selectedMeetingID: string,
		fileId: string,
		userId: string,
	): Promise<FileContract> {
		const { data } = await HttpClient.patch<UpdateMeetingFilesAccess, FileContract>(
			ApiUrls.ShareMeetingUpdateFiles,
			body,
			new UrlParamBuilder().addEventiCalUId(selectedMeetingID).addFileId(fileId).addUserId(userId).build(),
		);
		return data;
	}

	public static async checkUser(userMail: string): Promise<boolean> {
		const { data } = await HttpClient.get<boolean>(
			ApiUrls.ShareMeetingCheckUsersFD,
			new UrlParamBuilder().addUserMail(userMail).build(),
		);
		return data;
	}

	public static async getMeetingCollaborators(iCalUId: string): Promise<string[]> {
		const { data } = await HttpClient.get<string[]>(
			ApiUrls.getMeetingCollaborators,
			new UrlParamBuilder().addEventiCalUId(iCalUId).build(),
		);
		return data;
	}

	public static async deleteAMeetingCollaborator(iCalUId: string, mail: string): Promise<any> {
		const { data } = await HttpClient.delete<any>(
			ApiUrls.deleteMeetingCollaborators,
			new UrlParamBuilder().addEventiCalUId(iCalUId).addUserMail(mail).build(),
		);
		return data;
	}

	public static async getShareMeetingPrev(shareId: string, token: string): Promise<undefined> {
		const { data } = await HttpClient.get<undefined>(
			ApiUrls.GetSharedMeetingPrev,
			new UrlParamBuilder().addShareId(shareId).addToken(token).build(),
		);
		return data;
	}

	public static async getShareMeetingNext(shareId: string, token: string): Promise<undefined> {
		const { data } = await HttpClient.get<undefined>(
			ApiUrls.GetSharedMeetingNext,
			new UrlParamBuilder().addShareId(shareId).addToken(token).build(),
		);
		return data;
	}

	public static async getPeopleShare(noteId: string): Promise<People[]> {
		const { data } = await HttpClient.get<People[]>(
			ApiUrls.GetPeopleSharePublic,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}

	public static async deleteAPeopleShare(noteId: string, mail: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeletePeopleSharePublic,
			new UrlParamBuilder().addNotesId(noteId).addUserMail(mail).build(),
		);
		return data;
	}

	public static async addPeopleShare(body: People, noteId: string): Promise<People> {
		const { data } = await HttpClient.post<People, People>(
			ApiUrls.PostPeopleSharePublic,
			body,
			new UrlParamBuilder().addNotesId(noteId).build(),
		);
		return data;
	}

	public static async deleteAPeopleShareFileShare(fileId: string, mail: string): Promise<undefined> {
		const { data } = await HttpClient.delete<undefined>(
			ApiUrls.DeletePeopleShareFileShare,
			new UrlParamBuilder().addFileId(fileId).addUserMail(mail).build(),
		);
		return data;
	}

	public static async addPeopleShareFileShare(body: People, fileId: string): Promise<People> {
		const { data } = await HttpClient.post<People, People>(
			ApiUrls.PostPeopleShareFileShare,
			body,
			new UrlParamBuilder().addFileId(fileId).build(),
		);
		return data;
	}

	public static async getPeopleShareFileShare(fileId: string): Promise<People[]> {
		const { data } = await HttpClient.get<People[]>(
			ApiUrls.GetPeopleShareFileShare,
			new UrlParamBuilder().addFileId(fileId).build(),
		);
		return data;
	}
}
