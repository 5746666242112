import { TagContract } from '../meetings/types';
import { UserLanguage } from '../shared/types';

export class UserContract {
	constructor(
		public graphUserId: string,
		public userId: string,
		public displayName: string,
		public email: string,
		public name: string,
		public userPrincipalName: string,
		public status: UserStatus,
		public role: UserRole,
		public tags: TagContract[],
		public preferences: PreferencesContract,
		public companyName: string,
		public userName: string,
		public nameU: string,
		public appTour: boolean,
		public userCompany: UsersCompanyContract[],
		public todoCol: string,
		public doingCol: string,
		public blockedCol: string,
		public doneCol: string,
		public jobTitle: string,
		public roleFivedays: UserRoleFivedays,
		public creditCopilot: number,
		public invitationId?: string,
		public provider?: AuthenticationProvider,
		public picture?: string,
	) {}
}

export class UsersCompanyContract {
	constructor(
		public companyId: string,
		public companyName: string,
		public userName: string,
		public name: string,
		public jobTitle: string,
		public creditCopilot: number,
	) {}
}

export class UpdateCurrentUserContract {
	constructor(public preferences: PreferencesContract, public userId: string, public jobTitle?: string) {}
}

export class UpdateCurrentUserColContract {
	constructor(public todoCol: string, public doingCol: string, public blockedCol: string, public doneCol: string) {}
}

export class PreferencesContract {
	constructor(
		public emailDailyRecap: boolean,
		public emailProductUpdates: boolean,
		public language: UserLanguage,
		public workingHours: WorkingHoursContract[],
	) {}
}

export class TourUpdate {
	constructor(public appTour: boolean) {}
}

export class WorkingHoursContract {
	constructor(public weekday: number, public from: string, public to: string) {}
}

export enum UserStatus {
	INVITED = 'Invited',
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	PENDING = 'Pending',
	NEW = 'NEW',
}

export enum UserRole {
	USER = 'User',
	ADMIN = 'Admin',
}

export enum UserRoleFivedays {
	USER = 'User',
	SUPERADMIN = 'SuperAdmin',
}

export class ContactsGraphContract {
	constructor(
		public displayName: string,
		public emailAddresses: EmailAdd[],
		public imAddresses: string[],
		public givenName?: string,
		public surname?: string,
	) {}
}

export class EmailAdd {
	constructor(public name: string, public address: string) {}
}

export enum AuthenticationProvider {
	Microsoft = 'Microsoft',
	Google = 'Google',
	NotSet = 'NotSet',
}
