import React from 'react';
import ShimmerHeader from '../../../../shared/components/shimmers/shimmerHeader/shimmer-header.component';
import ShimmerText from '../../../../shared/components/shimmers/shimmer-text.component';
import styles from './note-shimmer.module.scss';
import ShimmerNote from '../../../../shared/components/shimmers/shimmerNote/shimmer-note.component';


const NoteShimmer = () => {
    return (
        <>
            <ShimmerHeader />
            <div className={styles.bodyShimmer}>
                <ShimmerText width={170} height={16} />
                <ShimmerNote />
            </div>
        </>
    );
};

export default NoteShimmer;