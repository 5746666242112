import { MutableRefObject, useEffect } from 'react';

const useClickOutside = <T extends HTMLElement>(
    ref: MutableRefObject<T | null>, 
    callback: () => void
) => {
    const handleClickOutside = (event: MouseEvent) => {
        if(ref.current && !ref.current.contains(event.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
    
};

export default useClickOutside;