import React from 'react';
import styles from './login.module.scss';
import LoginIllustrationComponent from './components/login-illustration.component';
import LoginSignInComponent from './components/login-sign-in.component';
import { RouteUrls } from '../../routes/routes-config';

function LoginPage() {
	const location = window.location;
	const storedReturnUrl = localStorage.getItem('returnUrl');
	if (location && !storedReturnUrl && !['', RouteUrls.Home, RouteUrls.Login].includes(location.pathname)) {
		localStorage.setItem('returnUrl', location.pathname);
	}

	return (
		<div className={styles.container}>
			<LoginIllustrationComponent />
			<LoginSignInComponent />
		</div>
	);
}

export default LoginPage;
