import React from 'react';
import styles from './my-preferences.component.module.scss';
import { useTranslation } from 'react-i18next';
import { hoursPerThirty } from '../../../shared/utils/hours';
import { useAppSelector } from '../../../store/hooks';

interface Props {
	dayChecked: number;
	from: string;
	to: string;
	onChange: (fromUp: string, toUp: string) => void;
	onUnChecked: (day: number) => void;
}

const FormWorkHours: React.FC<React.PropsWithChildren<Props>> = ({ dayChecked, from, to, onChange, onUnChecked }) => {
	const { user } = useAppSelector((state) => state.user);
	const { t } = useTranslation();
	const [checkedDays, setCheckedDays] = React.useState<{ [key: string]: boolean }>({});
	const [fromTime, setFromTime] = React.useState<string>(from);
	const [toTime, setToTime] = React.useState<string>(to);

	const handleDayChange = (day: number, checked: boolean) => {
		setCheckedDays((prevState) => ({
			...prevState,
			[day]: checked,
		}));
		if (checked === false) {
			setFromTime(hoursPerThirty[0]);
			setToTime(hoursPerThirty[0]);
			onUnChecked(day);
		} else {
			setFromTime(hoursPerThirty[0]);
			setToTime(hoursPerThirty[0]);
		}
	};

	React.useEffect(() => {
		user &&
			user.preferences.workingHours.map(
				(workinghour) =>
					workinghour.weekday === dayChecked &&
					setCheckedDays((prevState) => ({
						...prevState,
						[dayChecked]: true,
					})),
			);

		user &&
			user.preferences.workingHours.map(
				(workinghour) => workinghour.weekday === dayChecked && setFromTime(workinghour.from),
			);
		user &&
			user.preferences.workingHours.map(
				(workinghour) => workinghour.weekday === dayChecked && setToTime(workinghour.to),
			);
	}, [dayChecked]);

	return (
		<div className={!checkedDays[dayChecked] ? styles.disableDay : styles.day}>
			<div className={styles.checkDay}>
				<input
					type='checkbox'
					name={dayChecked.toString()}
					id={dayChecked.toString()}
					checked={checkedDays[dayChecked]}
					onChange={(e) => handleDayChange(dayChecked, e.target.checked)}
				/>
				<label htmlFor={dayChecked.toString()}>{t(dayChecked.toString())}</label>
			</div>
			<div className={styles.hours}>
				<div className={styles.fromTo}>
					<label>{t('From')}</label>
					<br />
					<select
						name='from'
						id='from'
						disabled={!checkedDays[dayChecked]}
						value={fromTime}
						onChange={(e) => {
							setFromTime(e.target.value);
							onChange(e.target.value, toTime);
						}}>
						{hoursPerThirty.map((hour, index) => (
							<option key={index} value={hour}>
								{hour}
							</option>
						))}
					</select>
				</div>
				<div className={styles.fromTo}>
					<label>{t('To')}</label>
					<br />
					<select
						name='to'
						id='to'
						disabled={!checkedDays[dayChecked]}
						value={toTime}
						onChange={(e) => {
							setToTime(e.target.value);
							onChange(fromTime, e.target.value);
						}}>
						{hoursPerThirty.map((hour, index) => (
							<option key={index} value={hour}>
								{hour}
							</option>
						))}
					</select>
				</div>
			</div>
		</div>
	);
};

export default FormWorkHours;
