export class AttendeeMerge {
    constructor(
        public name: string,
        public email: string
    ) {}
};

export const attendeeMergeMapper = (name: string, email: string) => {
    return new AttendeeMerge(
        name,
        email
    );
    
};