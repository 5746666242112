import HttpClient from '../shared/http/http-client';
import { BillingContract } from '../store/billing/type';
import { ApiUrls, UrlParamBuilder } from './api-urls';

export default class BillingAPI {
	public static async getAdminBillingInfo(): Promise<BillingContract> {
		const { data } = await HttpClient.get<BillingContract>(ApiUrls.GetBillingInfo);
		return data;
	}
}
