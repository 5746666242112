export class RuntimeConfig {
	public static getApiURL(): string {
		return this.getRuntimeConfig('apiUrl');
	}

	public static getWebSocketURL(): string {
		return this.getRuntimeConfig('webSocketUrl');
	}

	public static getSentryDSN(): string {
		return this.getRuntimeConfig('SENTRY_DSN');
	}

	public static getEnvironment(): string {
		return this.getRuntimeConfig('environment');
	}

	public static getMicrosoftClientID(): string {
		return this.getRuntimeConfig('microsoftClientId');
	}

	public static getMicrosoftClientAuthorityURI(): string {
		return this.getRuntimeConfig('microsoftAuthority');
	}

	public static getMicrosoftClientScopes(): string[] {
		return this.getRuntimeConfig('microsoftScopes');
	}

	public static getGoogleClientID(): string {
		return this.getRuntimeConfig('googleClientId');
	}

	public static getGoogleClientSecret(): string {
		return this.getRuntimeConfig('googleClientSecret');
	}

	public static getGoogleClientAuthorityURI(): string {
		return this.getRuntimeConfig('googleAuthority');
	}

	public static getGoogleClientScopes(): string {
		return this.getRuntimeConfig<string[]>('googleScopes').join(' ');
	}

	public static getRedirectURI(): string {
		return this.getRuntimeConfig('redirectUri');
	}

	public static getLogoutRedirectURI(): string {
		return this.getRuntimeConfig('logoutRedirectUri');
	}

	private static getRuntimeConfig<T>(configProp: string): T {
		// @ts-ignore
		return window.runConfig[configProp];
	}
}
