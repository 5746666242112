import React from 'react';
import styles from './shimmers-tabs.module.scss';
import ShimmerText from '../../../../../shared/components/shimmers/shimmer-text.component';

const TabsNotesShimmers = () => {
	return (
		<div>
			<div className={styles.text}>
				<div className={styles.box}>
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<br />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<br />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
				</div>
			</div>
		</div>
	);
};

export default TabsNotesShimmers;
