/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styles from './calendar.module.scss';
import classNames from 'classnames';
import {
	getDayFormat,
	getDayLessOneMonth,
	getDayLessOneWeek,
	getDayPastOneMonth,
	getDayPastOneWeek,
	getDayPastSevenDays,
	getFullDateFormatTZ,
	getMinutesPastDay,
	getMonthYearFormat,
	getNowTime,
	getStartOfDay,
	getStartOfDayNow,
	getStartOfWeek,
	getStartOfWeekToDate,
	isBetween,
} from '../../shared/utils/date.util';
import { getMeetingsForAWeek } from '../../store/meetings/slice';
import { IconDown, IconLeft, IconRight, IconUp } from '../../shared/utils/icon';
import { hours } from '../../shared/utils/hours';
import { useTranslation } from 'react-i18next';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { endOfDay, isSameDay, isWithinInterval, startOfDay } from 'date-fns';
import { RouteSearchParams, RouteUrls } from '../../routes/routes-config';
import { useNavigate } from 'react-router-dom';
import HoursHeaderComponent from './components/hours-header.component';
import DayOverviewComponent from './components/day-overview.component';

const CalendarPage = () => {
	const dispatch = useAppDispatch();
	const cx = classNames.bind(styles);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { meetingsForWeek, selectedMeetingForWeek } = useAppSelector((state) => state.meetings);
	const [workDays, setWorkDays] = React.useState(true);

	const [dateSelect, setDateSelect] = React.useState(new Date());

	const start = getStartOfWeek(dateSelect);
	const weekDays = workDays
		? Array.from({ length: 5 }, (v, i) => start.clone().add(i, 'days').toDate())
		: Array.from({ length: 7 }, (v, i) => start.clone().add(i, 'days').toDate());

	const startDate = getStartOfWeekToDate(dateSelect);
	const endDate = getDayPastSevenDays(startDate);
	const dateRange = { startDate, endDate };

	React.useEffect(() => {
		dispatch(getMeetingsForAWeek(dateRange));
	}, [dispatch, dateSelect]);

	const getMeetingsByDay = (day: Date) => {
		if (!meetingsForWeek) {
			return [];
		}
		return meetingsForWeek.filter((meeting) => isBetween(meeting.start, day));
	};

	// -----

	const handleNextWeek = () => {
		const newDate = getDayPastOneWeek(dateSelect);

		setDateSelect(newDate);
	};

	const handlePastWeek = () => {
		const newDate = getDayLessOneWeek(dateSelect);

		setDateSelect(newDate);
	};

	const handleNextMonth = () => {
		const newDate = getDayPastOneMonth(dateSelect);

		setDateSelect(newDate);
	};

	const handlePastMonth = () => {
		const newDate = getDayLessOneMonth(dateSelect);

		setDateSelect(newDate);
	};

	// ---------------

	const [minutesPast, setMinutesPast] = React.useState(0);

	React.useEffect(() => {
		const interval = setInterval(() => {
			const actualTime = getMinutesPastDay();
			setMinutesPast(actualTime);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	React.useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
	}, [dispatch]);

	const scrollToRef = React.useRef<HTMLDivElement>(null);
	const now = getNowTime();

	React.useEffect(() => {
		const interval = setInterval(() => {
			const midnight = getStartOfDayNow();
			const actualTime = now.diff(midnight, 'minutes');
			setMinutesPast(actualTime);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	React.useEffect(() => {
		if (scrollToRef.current) {
			const scrollPosition = Math.floor((minutesPast / 60) * 60 - 50);
			scrollToRef.current.scrollTop = scrollPosition;
		}
	}, [minutesPast]);

	return (
		<div className={styles.main}>
			<div className={styles.title}>
				<h1>{t('Calendar')}</h1>
				<div>
					{workDays ? (
						<button className={styles.today} onClick={() => setWorkDays(false)}>
							{t('Week')}
						</button>
					) : (
						<button className={styles.today} onClick={() => setWorkDays(true)}>
							{t('Workweek')}
						</button>
					)}
					<button className={styles.today} onClick={() => setDateSelect(new Date())}>
						{t('Today')}
					</button>
				</div>
			</div>
			<div className={styles.container}>
				<div className={styles.timeInfo}>
					<div className={styles.monthAndWeek}>
						<div className={styles.icon}>
							<h2>{getMonthYearFormat(dateSelect)}</h2>
							<div onClick={() => handlePastMonth()}>
								<IconUp />
							</div>
							<div onClick={() => handleNextMonth()}>
								<IconDown />
							</div>
						</div>
						<div className={styles.icon}>
							<h2>{getDayFormat(dateSelect)}</h2>
							<div onClick={() => handlePastWeek()}>
								<IconLeft />
							</div>
							<div onClick={() => handleNextWeek()}>
								<IconRight />
							</div>
						</div>
					</div>
				</div>
				<div className={styles.headers}>
					<div className={styles.timeHeader}>
						<div>{getFullDateFormatTZ(dateSelect)}</div>
					</div>
					<div className={styles.dayHeaders}>
						{weekDays.map((day, indexDayCalendar) => {
							return (
								<div key={indexDayCalendar} className={styles.dayHeader}>
									<div
										className={cx(styles.daySlot, {
											selectingDate: getDayFormat(dateSelect) === getDayFormat(day),
										})}>
										<h2 style={{ color: isSameDay(day, new Date()) ? '#00ad56' : '#201f1e' }}>
											{getDayFormat(day)}
										</h2>
									</div>
									{meetingsForWeek && (
										<div className={styles.allDayPart}>
											{meetingsForWeek
												.filter((meetingAllDay) => {
													const startDateAD = new Date(meetingAllDay.start);
													const endDateAD = new Date(meetingAllDay.end);

													const interval = {
														start: startOfDay(startDateAD),
														end: endOfDay(endDateAD),
													};

													return (
														meetingAllDay.isAllDay &&
														isWithinInterval(day, interval) &&
														!isSameDay(day, new Date(meetingAllDay.end))
													);
												})
												?.map((meeting, indexADCalendar) => {
													return (
														<div
															className={styles.allDay}
															onClick={() => {
																const params: any = {};
																params[RouteSearchParams.Date] = getStartOfDay(
																	meeting.start,
																);
																params[RouteSearchParams.MeetingId] = meeting.iCalUId;
																navigate(
																	`${RouteUrls.Meetings}?${new URLSearchParams(
																		params,
																	).toString()}`,
																);
															}}
															key={indexADCalendar}>
															{meeting.subject}
														</div>
													);
												})}
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
				<div className={styles.agenda} ref={scrollToRef}>
					<div className={styles.week}>
						<HoursHeaderComponent hours={hours}></HoursHeaderComponent>

						{weekDays.map((day, indexMeetingDayCalendar) => {
							const meetingsByDay = getMeetingsByDay(day);
							return (
								<>
									<div
										style={{ width: workDays ? '20%' : '14.25%' }}
										className={styles.day}
										key={indexMeetingDayCalendar}>
										<div style={{ top: minutesPast - 12 }} className={styles.now}></div>
										<DayOverviewComponent
											date={day}
											meetings={meetingsByDay}
											selectedMeeting={selectedMeetingForWeek}
											hours={hours}
											toggle={() => undefined}
											onDateSelect={() => undefined}></DayOverviewComponent>
									</div>
								</>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CalendarPage;
